import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AppRouteUi } from "../configs";
import { useVersionCheck } from "./hooks";
import {
  CallReturn,
  ExternalLoginPage,
  PageActivateAccount,
  PageForgotPassword,
  PageLobby,
  PageLogin,
  PageNotFound,
  PageResetPassword,
  PageUserCalendar,
  ReturnHomeScreenCallPage
} from './public';
import { PageChangePassword } from "./public/ChangePasswordPage";

export function LayoutPublic(): JSX.Element {
  useVersionCheck("login");
  const location = useLocation();
  if (
    AppRouteUi.Lobby.Root === location.pathname ||
    AppRouteUi.UserCalendar.Root === location.pathname
  ) {
    return <Redirect to={AppRouteUi.NotFound.Root} />;
  }

  return (
    <>
      <Switch>
        <Route path={AppRouteUi.Login.Root} exact component={PageLogin} />
        <Route
          path={AppRouteUi.Login.ExternalLogin}
          exact
          component={ExternalLoginPage}
        />
        <Route
          path={AppRouteUi.ActivateAccount.Root}
          exact
          component={PageActivateAccount}
        />
        <Route
          path={AppRouteUi.ForgotPassword.Root}
          exact
          component={PageForgotPassword}
        />
        <Route
          path={AppRouteUi.ResetPassword.Root}
          exact
          component={PageResetPassword}
        />
         <Route
          path={AppRouteUi.ChangePassword.Root}
          exact
          component={PageChangePassword}
        />
        <Route
          path={AppRouteUi.Lobby.WaitingRoom.Root()}
          exact
          component={PageLobby}
        />
        <Route
          path={AppRouteUi.VideoConference.Rejoin.Root()}
          exact
          component={CallReturn}
        />
        <Route
          path={AppRouteUi.Consumer.Load()}
          exact
          component={ReturnHomeScreenCallPage}
        />
        <Route
          path={AppRouteUi.UserCalendar.Scheduler.Root()}
          exact
          component={PageUserCalendar}
        />
        <Route path={AppRouteUi.NotFound.Root} exact component={PageNotFound} />
      </Switch>
    </>
  );
}
