import { useEffect, useState } from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { batch, useDispatch, Provider } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AppConstants,
  PlatformApi,
  ExternalLoginConstants,
  AppRouteUi,
  CalendarProvider,
} from "../../../configs";
import {
  ActionAuth,
  AxiosAuth,
  StorageAuth,
  useAppSelector,
} from "../../../core";
import { ApiBodyExternalLogin, ApiSchemaLogin } from "../../../models";
import { useFunctionalityUserConnections } from "../../../pages/hooks";

const clientId = ExternalLoginConstants.Google.ClientId;

type Props = {
  setChangeProp: any;
  key: number;
};
function GoogleLoginComponentForCalender(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();

  const { connect } = useFunctionalityUserConnections();

  const previousPath = useAppSelector((state) => state.ui.previousRoute);
  let userRole = "";

  const [loading, setLoading] = useState<boolean>(false);

  const onConnect = (connectionType: CalendarProvider, code: string) => {
    connect(connectionType, code)
      .then((r) => {
        // toast
        if (r) {
          props.setChangeProp((prev: number) => prev + 1);
        }
      })
      .catch((e: any) => {
        console.log(e.message);
      });
  };

  const onSuccess = async (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    setLoading(true);
    onConnect(CalendarProvider.Google, res.code ?? "");
    if (!isGoogleLoginResponse(res)) {
      setLoading(false);
      toast.error("Failed to Login!");
      return;
    }

    setLoading(false);
    toast.success("Google Login Successfull!");
    props.setChangeProp((prev: number) => prev + 1);
    // try {
    // 	const response = await AxiosAuth.post<ApiSchemaLogin>(
    // 		PlatformApi.Authentication.ExternalLogin.Root(),
    // 		{ idToken: res.getAuthResponse(true).id_token, provider: ExternalLoginConstants.LoginProviders.Google } as ApiBodyExternalLogin
    // 	);
    // 	if (response.status === AppConstants.Api.StatusOK && response.data.token) {
    // 		setLoading(false)
    // 		StorageAuth.AccessToken = response.data.token;
    // 		userRole = response.data.profile.userRole;

    // 		batch(() => {
    // 			dispatch(ActionAuth.SetIsLoggedIn(true));
    // 			dispatch(ActionAuth.SetProfileData(response.data.profile));
    // 		});

    // 		toast.success('Login Successfully!')

    // 		if (!!previousPath && userRole == AppConstants.UserRoles.Consumer)
    // 			history.push(previousPath);
    // 		else
    // 			history.push(AppRouteUi.Consumer.Load())
    // 	}
    // } catch (e) {
    // 	setLoading(false)
    // 	toast.error('Failed to Login!')
    // 	console.log(e);
    // }
  };

  const onFailure = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    // toast.error('Failed to Login!')
    if (!res) {
      toast.error("Failed to Login!");
      return;
    }
  };

  const isGoogleLoginResponse = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ): response is GoogleLoginResponseOffline => {
    return (
      !!response &&
      typeof response === "object" &&
      !!(response as GoogleLoginResponseOffline).code
    );
  };

  return (
    <div>
      <GoogleLogin
        className="btn"
        clientId={clientId}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="btn btn-outline-purple"
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm m-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
            CONNECT WITH GOOGLE
          </button>
        )}
        buttonText="LOGIN"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        accessType="offline"
        responseType="code"
        scope="https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar"
        prompt="consent"
      />
    </div>
  );
}

export default GoogleLoginComponentForCalender;
