import React from "react";
import useSWR from "swr/dist/use-swr";
import { AppConstants } from "../../configs/appConstants";
import { PlatformApi } from "../../configs/appRoute";
import { AxiosAuth } from "../../core/Axios/AxiosInstance";
import { ApiResponse, ApiSchemaLanguagePackData, Option, PlatformType, WithPagination } from "../../models";
import { UtilsJQuery } from "../../utils";

export function useFunctionalityDropDownList() {
  const LoadDescriptionList = async (
    companyId: string,
    appointmentCategoryId: string
  ) => {
      try{
        if(companyId && appointmentCategoryId){
          const appointmentListUrl = PlatformApi.AppointmentDescription.GetNames(
              companyId,
              appointmentCategoryId
            );
            const res = await AxiosAuth.get<ApiResponse<Option[]>>(appointmentListUrl);
            if ((res.status = AppConstants.Api.StatusOK)) {
              return Promise.resolve(res.data);
            }
        }
      }catch
      {

      }
      return Promise.resolve(null);
  };
  const LoadAppointmentCategoryList = async (
    companyId: string 
  ) => {
      try{
        const appointmentListUrl = PlatformApi.AppointmentCategory.GetNames(
            companyId,
          );
          const res = await AxiosAuth.get<ApiResponse<Option[]>>(appointmentListUrl);
          if ((res.status = AppConstants.Api.StatusOK)) {
            return Promise.resolve(res.data);
          }
      }catch
      {

      }
      return Promise.resolve(null);
  };
  const LoadModalityList = async (
    companyId: string,
    serviceType: PlatformType
  ) => {
      try{
        const appointmentListUrl = PlatformApi.Modality.GetNames(
            companyId,serviceType
          );
          const res = await AxiosAuth.get<ApiResponse<Option[]>>(appointmentListUrl);
          if ((res.status = AppConstants.Api.StatusOK)) {
            return Promise.resolve(res.data);
          }
      }catch
      {

      }
      return Promise.resolve(null);
  };
  const LoadLanguagePackage = async (
    companyId: string,
    serviceType: PlatformType
  ) => {
    const btnLoading = UtilsJQuery.Ladda(".language-pack-load");
    btnLoading.start?.();
      try{
        const appointmentListUrl = PlatformApi.LanguagePack.GetAll(companyId)
          const res = await AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaLanguagePackData[]>>>(appointmentListUrl);
          if ((res.status = AppConstants.Api.StatusOK)) {
            return Promise.resolve(res.data);
          }
      }catch
      {

      }
      btnLoading.stop?.();
      return Promise.resolve(null);
  };

  return {
    LoadDescriptionList,
    LoadAppointmentCategoryList,
    LoadModalityList
  };
}
