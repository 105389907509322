import React from 'react';
import { Note } from '../../components/common/note';
import { ModalAddNotes } from '../modals';
import { useForm } from "react-hook-form";
import { ApiSchemaJobDetailsById } from "../../models";
import { useAppSelector } from "../../core";
import { AppConstants } from "../../configs";
import { AssetSvg } from '../../assets';

type Props = {
  jobData?: ApiSchemaJobDetailsById
  setSweetChangeProp?: any
}
export function JobNotes(props: Props): JSX.Element {
  const [show, setShow] = React.useState(false);
  const { register, handleSubmit, errors, setValue } = useForm<ApiSchemaJobDetailsById>();
  const { jobData, setSweetChangeProp } = props;
  const data = useAppSelector((state) => ({
    userRole: state.auth.profile?.userRole
  }));

  return (
    <>
      <div className="row">
        <div className="col-12 ">
          <div className="border-top my-3"></div>
          {
            (data.userRole === AppConstants.UserRoles.Admin ||
              data.userRole === AppConstants.UserRoles.Finance ||
              data.userRole === AppConstants.UserRoles.ResellerAdmin ||
              data.userRole === AppConstants.UserRoles.ResellerFinance) && <button
                className="btn btn-link text-site font-weight-light pl-0"
                onClick={() => {
                  setShow(true);
                }}
              >
              <div className="btn mr-2 svg-color" title="bookingNoteEdit">
                <span >
                  <AssetSvg.Edit />
                </span>
              </div>
              {
                (jobData?.customerNote || jobData?.operatorNote || jobData?.adminNote) ? 'Edit' : 'Add'
              } notes
            </button>
          }
          <div className="pt-3">
            {
              jobData?.customerNote && <Note type="Customer" value={jobData?.customerNote} />
            }
          </div>
          <div className="pt-3">
            {
              jobData?.operatorNote && <Note type="Operator" value={jobData?.operatorNote} />
            }
          </div>
          <div className="pt-3">
            {
              jobData?.adminNote && <Note type="Admin" value={jobData?.adminNote} />
            }
          </div>
        </div>
      </div>

      <ModalAddNotes
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        setValue={setValue}
        jobData={jobData}
        show={show}
        handleShow={setShow}
        setSweetChangeProp={setSweetChangeProp}
      />
    </>
  )
}