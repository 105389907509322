import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { AssetsPng, AssetSvg } from "../../assets";
import { FormErrorMessage } from "../../components";
import { AppRouteUi } from "../../configs/appRoute";
import { ApiBodyChangePassword } from "../../models";
import { useQuery } from "../../utils";
import { useFunctionalityAuth } from "../hooks";

type TypePasswordForm = {
  newPassword: string;
  confirmPassword: string;
  currentPassword: string;
};

export function PageActivateAccount(): JSX.Element {
  const { onActivateAccount } = useFunctionalityAuth();
  const [inputReadOnly, setInputReadyOnly] = React.useState(true);
  const location = useLocation<{
    username: string;
  }>();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm<TypePasswordForm>();

  const [errMsg, setErrMsg] = React.useState<string>();
  const clearErrMsg = React.useCallback(() => setErrMsg(undefined), []);

  let query = useQuery();
  const queryemail = query.get("email");
  const email = queryemail?.replace(" ", "+");
  const onSubmit = (form: TypePasswordForm) => {
    if (form.newPassword === form.confirmPassword) {
      const data: ApiBodyChangePassword = {
        email: email ? email : location.state.username,
        currentPassword: form.currentPassword,
        newPassword: form.newPassword,
      };

      onActivateAccount(data);
    } else {
      setErrMsg("Password doesn't match");
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setInputReadyOnly(false);
    }, 500);
  }, []);
  return (
    <>
      <div className="position-fixed h-100 w-100 login-container-bg overflow-auto">
        <div className="container-md container-login-sm py-3 px-0 px-sm-0 px-md-3 px-lg-3">
          <div className="row m-auto">
            <div className="col-12 text-center my-5">
              <h1 className="page-title">Activate Account</h1>
            </div>
          </div>
          <div className="row m-auto login-container-card">
            <div className="col-12 bg-white">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="login-logo m-3">
                    <AssetSvg.VRILogo />
                  </div>
                </div>
                <div className="col-12">
                  <form
                    className="login-form p-4 "
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                  >
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text text-muted">
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          name="currentPassword"
                          //defaultValue={location.state.password}
                          placeholder="Verification Code"
                          ref={register({
                            required: true,
                          })}
                          onClick={clearErrMsg}
                          readOnly={inputReadOnly}
                          autoFocus
                        />
                      </div>
                      {errors.newPassword && (
                        <FormErrorMessage message="required" />
                      )}
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text text-muted">
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          name="newPassword"
                          placeholder="New Password"
                          ref={register({
                            required: true,
                          })}
                          onClick={clearErrMsg}
                          readOnly={inputReadOnly}
                          autoFocus
                        />
                      </div>
                      {errors.newPassword && (
                        <FormErrorMessage message="required" />
                      )}
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text text-muted">
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          ref={register({
                            required: true,
                          })}
                          onClick={clearErrMsg}
                        />
                      </div>
                      {errors.confirmPassword && (
                        <FormErrorMessage message="required" />
                      )}
                    </div>
                    <div className="form-group d-flex justify-content-sm-between align-items-sm-center flex-wrap mb-1 pt-3">
                      <button
                        type="submit"
                        className="ladda-button font-sz-14 change-form-submit-btn btn btn-purple btn-site-sm order-0 order-sm-1"
                      >
                        Submit
                      </button>
                      {errMsg && <FormErrorMessage message={errMsg} />}
                      <a
                        className="btn btn-link font-sz-14 btn-site-sm mt-3 mt-sm-0 px-0 order-1 order-sm-0"
                        onClick={() => {
                          history.push(AppRouteUi.Login.Root);
                        }}
                      >
                        Back to login
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
