import { Modal } from "react-bootstrap";
import { ApiSchemaFaceToFace } from '../../models/api/operatorProfile';
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  faceToFaceAssignmentData: ApiSchemaFaceToFace;
  setMileAgeToggleHandler: any;
  onSaveHandler: any;
};

export function ModalFaceToFaceAssignment(props: Props): JSX.Element {
  const { show, handleShow, setMileAgeToggleHandler, faceToFaceAssignmentData, onSaveHandler } = props;
  const { handleLimitDecimalPlaces } = useFunctionalityInputChecker()
  const { register, handleSubmit, errors } = useForm<ApiSchemaFaceToFace>();
  return (
    <Modal show={show} onHide={() => handleShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Face to Face Assignment</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSaveHandler)}>
        <Modal.Body>
          <div className="component-card rounded mb-3">
            <div className="component-card-header d-flex align-items-center justify-content-between rounded-top border"
              data-toggle="collapse"
              data-target="#Updatesystem-OPI-collapse"
              aria-expanded="true"
              aria-controls="Updatesystem-OPI-collapse"
            >
              <div className="col-6 pl-0">Mileage</div>
              <div className="col-6 pr-0 text-right">
                <button
                  type="button"
                  data-toggle="button"
                  className={faceToFaceAssignmentData?.isMileageEnabled ? 'btn btn-toggle active  on-off-btn' : 'btn btn-toggle  on-off-btn'}
                  onClick={() => { setMileAgeToggleHandler(!faceToFaceAssignmentData?.isMileageEnabled) }}
                >
                  <div className="handle"></div>
                </button>
              </div>
            </div>
            <div
              className={faceToFaceAssignmentData?.isMileageEnabled ? "component-card-body rounded-bottom  border-left border-right border-bottom collapse show" : "component-card-body rounded-bottom  border-left border-right border-bottom collapse"}
              id="Updatesystem-OPI-collapse"
            >
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="" className="position-relative required">Distance</label>
                    <div className="input-group ">
                      <input
                        type="text"
                        className="form-control"
                        name="mileageDistance"
                        aria-describedby="mileageDistance"
                        placeholder="Input miles"
                        defaultValue={faceToFaceAssignmentData?.mileageDistance ?? 0}
                        ref={register({ required: true })}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          id="flat-rate"
                        >
                          Miles
                        </span>
                      </div>
                    </div>
                    {errors.mileageDistance && <FormErrorMessage message="required" />}
                  </div>
                  <div className="form-group mb-0">
                    <label htmlFor="" className="position-relative required">Additional Miles Rate</label>
                    <div className="input-group ">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="flat-rate"
                        >
                          $
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="mileageAdditionalRate"
                        aria-describedby="mileageAdditionalRate"
                        placeholder="Rate"
                        defaultValue={faceToFaceAssignmentData?.mileageAdditionalRate ?? 0}
                        ref={register({ required: true })}
                        onInput={(event) => handleLimitDecimalPlaces(event, 2)}
                      />
                    </div>
                    {errors.mileageAdditionalRate && <FormErrorMessage message="required" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-link text-uppercase text-site" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleShow(false);
          }}
          >Cancel</button>
          <button className="btn btn-primary text-uppercase form-submit-btn" type="submit">Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
