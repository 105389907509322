import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { ApiSchemaReasonRequestModel } from "../../../models/api/CompanyCancellationReason";

type Props = {
    show: boolean,
    handleShow: (value: boolean) => void,
    header: string,
    onSubmitHandler:any;
    reason:any;
    resetReasonData:any;
}

export function ModalReasonSave(props: Props): JSX.Element {
    const { register, handleSubmit } = useForm<ApiSchemaReasonRequestModel>();
    const{show, handleShow, header, onSubmitHandler, reason, resetReasonData}=props;
    return (
        <Modal
            show={show}
            onHide={() =>{
                handleShow(false);
                resetReasonData();
            }}
            centered
        >
            <form action="" onSubmit={handleSubmit(onSubmitHandler)}>
                <Modal.Header closeButton>
                    <Modal.Title>{header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <textarea
                        name="reason"
                        id="reason"
                        ref={register({ required: true })}
                        data-resize="none"
                        className="form-control"
                        defaultValue={reason}
                  />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={() =>{
                            handleShow(false);
                            resetReasonData();
                        }}
                        className="btn text-uppercase btn-link text-site"

                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn text-uppercase btn-primary"
                    >
                        Save
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}