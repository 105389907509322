import React, { useEffect, useState } from "react";
import { useFunctionalityNotification } from "../../pages/hooks/functionalityNotification";
import { ApiSchemaNotificationData } from "../../models/api/notification";
import { dateFormatterInYMD, timeSpanCalculator } from "../../utils";
import { useHistory } from "react-router-dom";
import { PlatformApi, AppRouteUi } from "../../configs/appRoute";
import { ActionNotification, AxiosAuth, incrementAsync, useAppDispatch, useAppSelector } from "../../core";
import { AppConstants, BookingNotificationType, PaginationSettings, SocketNotificationType } from "../../configs";
import { toast } from "react-toastify";
import { mutate } from "swr";
import moment from "moment";
import { batch, shallowEqual } from 'react-redux'
import { SingleNotification } from "./singleNotification";
import { ITypeNotificationContent } from ".";

export function NotificationList(): JSX.Element {
  const stateData = useAppSelector(
    (state) => ({
      pageSize: state.notification?.pageSize,
      pageNo: state.notification?.pageNo,
      searchText: state.notification?.searchText,
      unreadNotificationCount: state.notification?.unreadNotificationCount,
      notificationShown: state.notification?.notificationShown,
    }),
    shallowEqual
  );
  const { pageSize, pageNo, searchText, unreadNotificationCount, notificationShown } = stateData
  const { notificationList } = useFunctionalityNotification(pageSize!, pageNo!, searchText!, unreadNotificationCount!, notificationShown!);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [notificationData, setNotificationData] = useState<ApiSchemaNotificationData[]>([])

  useEffect(() => {
    if (pageNo === PaginationSettings.initialPageIndex) {
      const newNotificationData = notificationList ?? []
      setNotificationData(newNotificationData)
    }
    else {
      const newNotificationData = notificationData?.concat(notificationList ?? [])
      setNotificationData(newNotificationData)
    }
  }, [notificationList, pageNo])

  const readNorificaiton = (notification: ApiSchemaNotificationData, notificationContent?: ITypeNotificationContent) => {
    const readNotificationUrl = PlatformApi.Notification.Read.Root(notification.id || '');
    dispatch(ActionNotification.SetPageNo(PaginationSettings.initialPageIndex))
    AxiosAuth.post(readNotificationUrl)
      .then(r => {
        if (r.status === AppConstants.Api.StatusOK) {
          dispatch(incrementAsync())
          mutate(PlatformApi.Notification.GetAll.Root(PaginationSettings.initialPageSize, PaginationSettings.initialPageIndex, searchText!));
        }
      }).catch(() => {
      })
    if (notification.type === SocketNotificationType.Booking) {
      batch(() => {
        dispatch(ActionNotification.SetBookingNotificationShow(true))
        dispatch(ActionNotification.SetBookingIdForModalData(notificationContent?.BookingId!))
      })
      history.push(AppRouteUi.Jobs.Root);
    }
  }

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    dispatch(ActionNotification.IncrementPageNo())
  }

  return (
    <div className="container-fluid px-0">
      {(notificationList === null && notificationData.length === 0) ?
        <div className="d-flex flex-nowrap notification-thread p-2">
          <div className="col-fixed-width notification-msg flex-shrink-0 flex-grow-1">
            Loading...
          </div>
        </div>
        : null}

      {notificationData?.length ?
        notificationData?.map((notification: ApiSchemaNotificationData) => (
          <SingleNotification notification={notification} readNorificaiton={readNorificaiton} />
        )
        ) : null
      }

      {(notificationList !== null && notificationData.length === 0) ?
        <div className="d-flex flex-nowrap notification-thread p-2">
          <div className="col-fixed-width notification-msg flex-shrink-0 flex-grow-1">
            Have no notification !
          </div>
        </div> : null}

      {notificationData?.length ?
        (
          <div className="btn-load-more d-flex w-100 justify-content-end">
            <button type="button" className="btn btn-link py-2 font-sz-12" onClick={handleLoadMore} disabled={notificationList === null}>
              {notificationList === null ? <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span> : null}
              Load More
            </button>
          </div>
        )
        : null
      }
    </div>
  );
}
