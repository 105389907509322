import React, { useContext, useEffect, useState } from 'react';
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList';
import AudioOutputList from './AudioOutputList';
import AudioInputList from './AudioInputList';
import { VideoCallContext } from '../../../../core';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
}));
function DeviceSelectionDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();
  const { room, isVideoEnabled, isAudioEnabled } = useContext(VideoCallContext);
  const [selectedVideoTrack, setSelectedVideoTrack] =
    useState<LocalVideoTrack>();
  const [selectedAudioTrack, setSelectedAudioTrack] =
    useState<LocalAudioTrack>();
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState<
    MediaDeviceInfo[]
  >([]);
  const [VideoInputDeviceChangeStatus, setVideoInputDeviceChangeStatus] =
    useState<boolean>(false);

  const [AudioInputDeviceChangeStatus, setAudioInputDeviceChangeStatus] =
    useState<boolean>(false);

  const handleChangeDevice = (e: any) => {
    e.preventDefault();
    if (selectedVideoTrack && VideoInputDeviceChangeStatus) {
      room!.localParticipant.videoTracks.forEach((track) => {
        if (track.trackName !== 'screen') {
          track.unpublish();
          track.track.stop();
        }
      });
      if (!isVideoEnabled) {
        selectedVideoTrack.disable();
      }
      room!.localParticipant.publishTrack(selectedVideoTrack).catch((error) => {
        console.error(error);
      });
      setVideoInputDeviceChangeStatus(false);
    }
    if (selectedAudioTrack && AudioInputDeviceChangeStatus) {
      room!.localParticipant.audioTracks.forEach((track) => {
        if (track.trackName !== 'screen') {
          track.unpublish();
          track.track.stop();
        }
      });
      if (!isAudioEnabled) {
        selectedAudioTrack.disable();
      }
      room!.localParticipant.publishTrack(selectedAudioTrack).catch((error) => {
        console.error(error);
      });
    }
    onClose();
  };

  const handleOnClose = () => {
    selectedVideoTrack?.stop();
    selectedAudioTrack?.stop();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Audio and Video Settings</DialogTitle>
      <Divider />
      <DialogContent className={classes.container}>
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Video
          </Typography>
          <VideoInputList
            selectedVideoTrack={selectedVideoTrack}
            setSelectedVideoTrack={setSelectedVideoTrack}
            setVideoInputDeviceChangeStatus={setVideoInputDeviceChangeStatus}
          />
        </div>
        <Divider />
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Audio
          </Typography>
          <AudioInputList
            selectedAudioTrack={selectedAudioTrack}
            setSelectedAudioTrack={setSelectedAudioTrack}
            setAudioInputDeviceChangeStatus={setAudioInputDeviceChangeStatus}
          />
        </div>
        <div className={classes.listSection}>
          <AudioOutputList
            selectedAudioOutputDevice={selectedAudioOutputDevice}
            setSelectedAudioOutputDevice={setSelectedAudioOutputDevice}
          />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={(e) => {
            handleChangeDevice(e);
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeviceSelectionDialog;
