
import { Modal } from 'react-bootstrap'
type Props = {
    show: boolean,
    handleShow: (value: boolean) => void,
    header: string,
    onSaveHandler: any,
    isThreeWayCallEnabled: boolean
    toggleHandler: any
}
export function ModalIvrThreeWayCall(props: Props) {
    const { isThreeWayCallEnabled, header, show, handleShow, onSaveHandler, toggleHandler } = props;
    return (
        <Modal
            show={show}
            onHide={() => handleShow(false)}
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-wrap pb-4 justify-content-between align-items-center">
                    <div className="col px-0">
                        <div>Three Way Call </div>
                    </div>
                    <div className="px-3 mr-3">
                        <button
                            type="button"
                            data-toggle="button"
                            className={isThreeWayCallEnabled ? `btn btn-toggle text-right on-off-btn active` : `btn btn-toggle text-right on-off-btn`}
                            onClick={() => { toggleHandler(isThreeWayCallEnabled) }}
                        >
                            <div className="handle"></div>
                        </button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-link text-site text-uppercase"
                    onClick={() => { handleShow(false) }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary text-uppercase btn-message-submit"
                    onClick={() => { onSaveHandler() }}
                >
                    Save
                </button>

            </Modal.Footer>

        </Modal>
    )
}
