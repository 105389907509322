import FullCalendar, { DatesSetArg, EventClickArg, EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import { ActionCalender, useAppDispatch, useAppSelector } from "../../../core";

export interface EventData {
    id?: string,
    start?: string,
    end?: string,
    title?: string,
    extendedProps?: any,
    backgroundColor?: string,
    borderColor?: string,
    display?: string
}

type Props = {
    calendarEvents?: EventData[],
    handleDateClick?: any,
    handleSelectClick?: any,
    initialDate?: Date,
    handleChangeDatesSet?: Function,
    handleEventClick?: Function,
    whichPage?: string,
};

export function Calendar(props: Props): JSX.Element {
    const {
        calendarEvents,
        handleDateClick,
        handleSelectClick,
        initialDate,
        handleChangeDatesSet,
        handleEventClick,
        whichPage
    } = props;

    const viewType = useAppSelector((state) => state.calender.viewType);
    const dispatch = useAppDispatch();

    const renderEventContent = (eventInfo : EventContentArg) => {
        if(whichPage === 'calendarPage'){
            return (
              <div className="pl-1" style={{border: eventInfo.borderColor, backgroundColor: eventInfo.backgroundColor}}>
                <b>{eventInfo.timeText}</b><span> #{eventInfo.event.title}</span>
                <div>{eventInfo.event.extendedProps.company}</div>
              </div>
            )
        } else if (whichPage === 'userCalendarPage'){
            return (
                <div  className="h-100 d-flex align-items-center justify-content-center" style={{border: eventInfo.borderColor, backgroundColor: eventInfo.backgroundColor}}>
                  <p className="text-center my-auto">{`${moment(eventInfo.event.start).format('h:mmA')} - ${moment(eventInfo.event.end).format('h:mmA')}`}</p>
                </div>
            )
        }
    }

    return (
        <>
            <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={calendarEvents}
                    initialView={viewType}
                    selectable={true}
                    dateClick={handleDateClick}
                    select={handleSelectClick}
                    headerToolbar={{
                        left: "prevYear,prev,next,nextYear today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    initialDate={initialDate ?? new Date()}
                    nowIndicator={true}
                    datesSet={(dateInfo: DatesSetArg) => {
                        handleChangeDatesSet && handleChangeDatesSet(dateInfo)
                        dispatch(ActionCalender.SetViewType(dateInfo.view.type))
                    }}
                    dayMaxEventRows={4}
                    eventClick={(eventInfo: EventClickArg) => handleEventClick && handleEventClick(eventInfo)}
                    eventContent={renderEventContent}
                />
        </>
    );
}
