import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { AppRouteUi, CompanyType, CreateCompanyType, PaginationSettings, StepNumber, } from "../../configs";
import { useAppSelector } from "../../core";
import { AdminPageTemplate, ColumnToolTip, ModalCreateCompany, ModalDeleteCompany, } from "../../features";
import { ApiSchemaCompanyData } from "../../models/api/company";
import { useFunctionalityPagedCompany } from "../hooks";

type CompanyProps = {
  parentCompanyId: string | null;
  parentCompanyName?: string
};

export function PageCompany(props: CompanyProps): JSX.Element {
  const { parentCompanyId } = props;
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [stepCounter, setStepCounter] = useState(StepNumber.firstStep);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");
  const [deletedCompanyId, setDeletedCompanyId] = useState<string>("");
  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSearchText(e.target.value);
        setPageIndex(1);
      });
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const currentUserInfo = useAppSelector(
    (state) => ({
      companyId: state.auth.profile?.companyId || "",
    }),
    shallowEqual
  );

  const { pagedCompanyListCount, pagedCompanyList } =
    useFunctionalityPagedCompany({
      parentCompanyId: parentCompanyId ?? currentUserInfo.companyId,
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
    });

  useEffect(() => {
    if (pagedCompanyList?.length) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedCompanyList]);

  const companyTableColumns = [
    {
      name: <ColumnToolTip data="Code" />,
      selector: (row: ApiSchemaCompanyData) => {
        return <ColumnToolTip data={row.code} />;
      },
    },
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaCompanyData) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: <ColumnToolTip data="Users" />,
      selector: (row: ApiSchemaCompanyData) => {
        return <ColumnToolTip data={row.users} />;
      },
    },
    {
      name: <ColumnToolTip data="Location" />,
      selector: (row: ApiSchemaCompanyData) => {
        return <ColumnToolTip data={row.locations} />;
      },
    },
    {
      name: <ColumnToolTip data="Departments" />,
      selector: (row: ApiSchemaCompanyData) => {
        return <ColumnToolTip data={row.departments} />;
      },
    },
    {
      name: <ColumnToolTip data="Status" />,
      selector: (row: ApiSchemaCompanyData) => {
        return (
          <>
            <ColumnToolTip data={row.isActive ? "Active" : "Inactive"} />
          </>
        );
      },
    },
    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      selector: (row: ApiSchemaCompanyData) => {
        return (
          <>
            <Link
              to={{
                pathname: AppRouteUi.Company.Profile.Load(row.id ?? ""),
                state: { parentCompanyName: props.parentCompanyName,parentCompanyId:props.parentCompanyId }
              }}
              //   {AppRouteUi.Company.Profile.Load(row.id ?? "")
              // }
              className="btn svg-color pl-0"
              title="Edit"
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </Link>
            <div
              className="btn svg-color"
              title="Delete"
              onClick={() => {
                setDeletedCompanyId(row.id ?? "");
                setDeleteModalShow?.(true);
              }}
            >
              <span >
                <AssetSvg.Delete />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Company"
        modalControlBtnLabel="Create Company"
        tableHeaders={companyTableColumns}
        tableData={pagedCompanyList}
        setCreateModalShow={setCreateModalShow}
        setStepCounter={setStepCounter}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedCompanyListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        isHideAddButton={true}
      />
      {createModalShow ? (
        <ModalCreateCompany
          title={CreateCompanyType.Company}
          show={createModalShow}
          handleShow={setCreateModalShow}
          setStepCounter={setStepCounter}
          stepCounter={stepCounter}
          companyType={CompanyType.Customer}
          parentId={parentCompanyId ?? currentUserInfo.companyId}
        />
      ) : null}
      <ModalDeleteCompany
        companyId={deletedCompanyId}
        setDeletedCompanyId={setDeletedCompanyId}
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
      />
    </>
  );
}
