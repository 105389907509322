import { ImageSupportedType } from "../../configs";

export function useFunctionalityInputChecker() {
  const handleSpecialCharacterWhiteSpaces = (inputValue: any) => {
    if (inputValue == "") return true;
    var format = /[!@#$%^&*()_+\-=~\[\]{};':"\\|,<>\/?]+/;
    return !((!inputValue.replace(/\s/g, '').length) || (format.test(inputValue)))
  }
  const handleWhiteSpacesValidation = (inputValue: string) => {
    if (inputValue == "") return true;
    return !(!inputValue.replace(/\s/g, '').length)
  }
  const handleSpecialCharacterWhiteSpacesForJobField = (inputValue: any) => {

    if (inputValue == "") return true
    if (!(inputValue.length < 2 || inputValue.length > 160)) {
      var sectionToCheck = inputValue;
      var allFoundSpecialCharacters = sectionToCheck.match(/[@#$%^&*()_+\-=\[\]{}~;'!:"\\|,.<>\/?]/g);
      var allFoundWhiteSpaces = sectionToCheck.match(/[" "]/g);
      if (allFoundWhiteSpaces == null) allFoundWhiteSpaces = []
      if (allFoundSpecialCharacters == null) allFoundSpecialCharacters = []

      if ((allFoundSpecialCharacters.length + allFoundWhiteSpaces.length) == inputValue.length) {
        return false
      }
      else return true
    }
    else {
      return false
    }
  }
  const useFunctionlityInputFieldValidation = (inputValue: string) => {
    if (inputValue == "") return true;
    if (!((inputValue.trim()).length < 3 || (inputValue.trim()).length > 160)) {
      return true
    }
    else {
      return false
    }
  }
  const handleSpecialCharacterWhiteSpacesForJobFieldExtraInfo = (inputValue: any) => {

    if (inputValue == "") return true
    if (!(inputValue.length < 2 || inputValue.length > 300)) {
      var sectionToCheck = inputValue;
      var allFoundSpecialCharacters = sectionToCheck.match(/[@#$%^&*()_+\-=\[\]{};~'!:"\\|,.<>\/?]/g);
      var allFoundWhiteSpaces = sectionToCheck.match(/[" "]/g);
      if (allFoundWhiteSpaces == null) allFoundWhiteSpaces = []
      if (allFoundSpecialCharacters == null) allFoundSpecialCharacters = []

      if ((allFoundSpecialCharacters.length + allFoundWhiteSpaces.length) == inputValue.length) {
        return false
      }
      else return true
    }
    else {
      return false
    }
  }


  const handleSpecialCharacterWhiteSpacesForNote = (inputValue: any) => {

    if (inputValue == "") return true
    if (!(inputValue.length < 2 || inputValue.length > 500)) {
      var sectionToCheck = inputValue;
      var allFoundSpecialCharacters = sectionToCheck.match(/[@#$%^&*()_+\-=\~[\]{};'!:"\\|,.<>\/?]/g);
      var allFoundWhiteSpaces = sectionToCheck.match(/[" "]/g);
      if (allFoundWhiteSpaces == null) allFoundWhiteSpaces = []
      if (allFoundSpecialCharacters == null) allFoundSpecialCharacters = []

      if ((allFoundSpecialCharacters.length + allFoundWhiteSpaces.length) == inputValue.length) {
        return false
      }
      else return true
    }
    else {
      return false
    }
  }


  const numberFieldValidation = (evt: any) => {
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
  }

  const numberFieldValidationForDuration = (evt: any) => {
    ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
  }

  const handleCopyPasteInvalidValueForDuration = (input: any) => {
    var arr = ["e", "E", "+", "-", "."]
    var isValid: boolean = false;
    var i = 0
    for (i = 0; i < arr.length; i++) {
      isValid = input.includes(arr[i])
      if (isValid) break;
    }

    return !isValid
  }

  const handleCopyPasteInvalidValue = (input: any) => {
    var arr = ["e", "E", "+", "-"]
    var isValid: boolean = false;
    var i = 0
    for (i = 0; i < arr.length; i++) {
      isValid = input?.includes(arr[i])
      if (isValid) break;
    }

    return !isValid
  }

  const fileContainsImage = (fileType: string) => {
    if (fileType === ImageSupportedType.JPEG || fileType === ImageSupportedType.JPG || fileType === ImageSupportedType.PNG || fileType === ImageSupportedType.SVG || fileType === ImageSupportedType.GIF) {
      return true;
    }
    return false;
  };

  const fileContainsWordFile = (fileType: string) => {
    const wordFileType = ['application/msword'];
    return wordFileType.indexOf(fileType) > -1;
  }

  const fileContainsPdf = (fileType: string) => {
    const pdfType = ['application/pdf'];
    return pdfType.indexOf(fileType) > -1;
  };

  const fileContainsExcelFile = (fileType: string) => {
    const excelFileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    return excelFileType.indexOf(fileType) > -1;
  }

  const fileContainsDocFile = (fileType: string) => {
    const docFileType = ['text/plain'];
    return docFileType.indexOf(fileType) > -1;
  };

  const handleFileChecker = (event: any) => {
    const fileType = event.target.files[0]?.type;

    if (fileContainsImage(fileType) || fileContainsWordFile(fileType) || fileContainsPdf(fileType) || fileContainsExcelFile(fileType) || fileContainsDocFile(fileType)) {
      return true;
    }
    return false;
  }

  const handleLimitDecimalPlaces = (e: any, count: number) => {
    if (e.target.value.indexOf('.') == -1) { return; }
    if ((e.target.value.length - e.target.value.indexOf('.')) > count) {
      e.target.value = ((e.target.value).toString()).slice(0, e.target.value.indexOf(".") + 3)
    }
  }

  return {
    handleSpecialCharacterWhiteSpaces,
    numberFieldValidation,
    handleCopyPasteInvalidValue,
    handleSpecialCharacterWhiteSpacesForJobField,
    handleFileChecker,
    handleSpecialCharacterWhiteSpacesForJobFieldExtraInfo,
    handleSpecialCharacterWhiteSpacesForNote,
    numberFieldValidationForDuration,
    handleCopyPasteInvalidValueForDuration,
    handleLimitDecimalPlaces,
    useFunctionlityInputFieldValidation,
    handleWhiteSpacesValidation
  }
}