import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import Select, { createFilter } from "react-select";
import { FormErrorMessage } from "../../../components";
import { AppConstants, PlatformType, reactSelectFilterConfig } from "../../../configs";
import { useAppSelector } from "../../../core";
import { ApiSchemaJobFormFields, ApiSchemaUserProfileData, Option } from "../../../models";
import { useFunctionalityCompanyNames, useFunctionalityUser } from "../../../pages/hooks";
import { useFunctionalityDropDownList } from "../../../pages/hooks/functionalityDropdown";
import { useFunctionalityInputChecker } from "../../../pages/hooks/functionalityInputChecker";
import { ServiceType } from "./ServiceType";

type Props = {
  register: any;
  setValue: any;
  errors: any;
  selectedServiceType?: any;
  setSelectedServiceType?: any;
  handleServiceTypeChange?: any;
  setJobFormData?: any;
  setCompanyId?: any;
  setDefaultRequestedUser?: any;
  setSelectedUser?: any;
  contactList?: any;
  dialUser?: any;
  jobFormData?: ApiSchemaJobFormFields | null;
  defaultRequestedUser?: any;
  selectCompanyFirst?: boolean;
  setSelectedCompanyId?: Function;
  havePlatformContractEnabled: boolean;
};

export function AppointmentType(props: Props): JSX.Element {
  const data = useAppSelector(
    (state) => ({
      userProfile: state.auth.profile,
    }),
    shallowEqual
  );

  const { setDefaultRequestedUser, contactList, dialUser, jobFormData, defaultRequestedUser, selectCompanyFirst, setSelectedCompanyId } = props;
  const { numberFieldValidation, handleCopyPasteInvalidValue } = useFunctionalityInputChecker()
  // const { handleCopyPasteInvalidValue } = useFunctionalityInputChecker()

  const [serviceType, setServiceType] = useState<PlatformType>(
    PlatformType.OnSite
  );

  const [modalityList, setModalityList] = useState<Option[]>([]);

  const { selectedServiceType, setSelectedServiceType, handleServiceTypeChange, setSelectedUser } = props;

  const [appointmentCategoryList, setAppointmentCategoryList] = useState<
    Option[]
  >([]);
  const [descriptionList, setDescriptionList] = useState<Option[]>([]);
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<Option>(Object);
  const [selectedUserProfile, setSelectedUserProfile] =
    useState<ApiSchemaUserProfileData>(Object);

  const { register, setValue, errors, setJobFormData, setCompanyId } = props;

  useEffect(() => {

    setSelectedUserProfile(dialUser);
    setCompanyId(dialUser?.companyId);
  }, [dialUser])

  const { LoadModalityList, LoadAppointmentCategoryList, LoadDescriptionList } = useFunctionalityDropDownList();

  useEffect(() => {
    if (selectedUserProfile.companyId) {
      LoadModalityList(
        selectedUserProfile.companyId ?? "",
        selectedServiceType
      ).then((r) => {
        setModalityList(r?.data ?? []);
      });
    }
  }, [selectedUserProfile, selectedServiceType]);

  useEffect(() => {
    if (selectedUserProfile.companyId) {
      LoadAppointmentCategoryList(selectedUserProfile?.companyId ?? "").then(
        (r) => {
          setAppointmentCategoryList(r?.data ?? []);
        }
      );
    }
  }, [selectedUserProfile]);
  useEffect(() => {
    if (selectedUserProfile.companyId) {
      LoadDescriptionList(
        selectedUserProfile?.companyId ?? "",
        selectedAppointmentType.value
      ).then((r) => {
        setDescriptionList(r?.data ?? []);
      });
    }
  }, [selectedUserProfile, selectedAppointmentType]);

  useEffect(() => {
    if (dialUser?.id) {
      props.setValue("requestedByUserId", dialUser?.id, {
        shouldValidate: true,
      });
    }
  }, [dialUser])


  const [companyNames, setCompanyNames] = useState<any[]>([]);
  const { onCompanyNames } = useFunctionalityCompanyNames();

  useEffect(() => {
    if (selectCompanyFirst) {
      onCompanyNames(data.userProfile?.companyId ?? "").then((res: any) => {
        setCompanyNames(res.data);
      });
    }
  }, [])

  return (
    <>
      <div className="component-card-header d-flex align-items-center justify-content-between  border rounded-top">
        <div className="text-capitalize">Appointment type</div>
      </div>
      <div className="component-card-body rounded-bottom  border-left border-right border-bottom">
        {selectCompanyFirst ?
          <div className="form-group row">
            <label
              htmlFor=""
              className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
            >
              Company
            </label>
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
              <input
                className="d-none"
                // need to clearify it *********************************************************************************
                name="companyId"
                ref={props.register({ required: selectCompanyFirst })}

              />
              <Select
                className={` ${errors['companyId'] ? 'field-required' : ''} `}

                placeholder="select company"
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={companyNames}
                filterOption={createFilter(reactSelectFilterConfig)}
                onChange={(value) => {
                  if (value && setSelectedCompanyId) {
                    setSelectedCompanyId(value.value);
                  }
                  props.setValue("companyId", value?.value, {
                    shouldValidate: true,
                  });
                  if (defaultRequestedUser) {
                    setDefaultRequestedUser({ value: '', label: '' })
                    setSelectedUser({ value: '', label: '' })
                    props.setValue("requestedByUserId", undefined, {
                      shouldValidate: true,
                    });
                    setSelectedServiceType(PlatformType.VRI)
                  }
                }}
                style={{ border: props.errors.companyId ? '1px solid red' : '' }}

              />
              {props.errors.companyId && (
                <FormErrorMessage message="required" />
              )}
            </div>
          </div>
          : null}
        <div className="form-group row">
          <label
            htmlFor=""
            className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
          >
            Requested By
          </label>
          <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
            <input
              className="d-none"
              name="requestedByUserId"
              ref={props.register({ required: true })}

            />
            <Select
              className={` ${errors['requestedByUserId'] ? 'field-required' : ''} `}

              placeholder="select user"
              components={{
                IndicatorSeparator: () => null,
              }}
              value={defaultRequestedUser}
              isDisabled={data?.userProfile?.userRole === AppConstants.UserRoles.User}
              options={contactList}
              filterOption={createFilter(reactSelectFilterConfig)}
              onChange={(value) => {
                if (value) {
                  setDefaultRequestedUser(value);
                  setSelectedUser(value)
                }
                props.setValue("requestedByUserId", value?.value, {
                  shouldValidate: true,
                });
              }}
              style={{ border: props.errors.requestedByUserId ? '1px solid red' : '' }}

            />
            {props.errors.requestedByUserId && (
              <FormErrorMessage message="required" />
            )}
            {/* <div className="col text-right "> */}
            <div className="col text-right pr-1">
              <span className="font-300 font-sz-10">{dialUser?.companyName}</span>
            </div>
          </div>
        </div>

        {
          (dialUser?.id && jobFormData) && <ServiceType
            register={register}
            setValue={setValue}
            errors={errors}
            userId={dialUser?.id}
            selectedServiceType={selectedServiceType}
            handleServiceTypeChange={handleServiceTypeChange}
            jobFormData={jobFormData}
            setJobFormData={setJobFormData}
          />
        }
        {
          (props?.havePlatformContractEnabled && dialUser?.id && modalityList.length !== 0) && <div className="form-group">
            <label htmlFor="" className="position-relative required">
              Modality
            </label>
            <input className="d-none"
              name="modality"
              ref={props.register({ required: modalityList.length === 0 ? false : true })}
            />
            <Select
              className={` ${errors['modality'] ? 'field-required' : ''} `}

              placeholder="Select Modality Type"
              isDisabled={modalityList.length === 0 ? true : false}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={modalityList}
              filterOption={createFilter(reactSelectFilterConfig)}
              onChange={(value) => {
                props.setValue("modality", value?.label, {
                  shouldValidate: true,
                });
              }}
              style={{ border: props.errors.modality ? '1px solid red' : '' }}

            />
            {props.errors.modality && props.errors.modality.type === "required" && (
              <FormErrorMessage message="required" />
            )}
          </div>
        }


        {
          (props?.havePlatformContractEnabled && dialUser?.id && appointmentCategoryList.length !== 0) && <div className="form-group">
            <label htmlFor="" className="position-relative required">
              Appointment Type
            </label>
            <input className="d-none"
              name="appointmentCategory"
              ref={props.register({ required: appointmentCategoryList.length === 0 ? false : true })}
            />
            <Select
              className={` ${errors['appointmentCategory'] ? 'field-required' : ''} `}

              placeholder="Select Appointment Type"
              isDisabled={appointmentCategoryList.length === 0 ? true : false}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={appointmentCategoryList}
              filterOption={createFilter(reactSelectFilterConfig)}
              onChange={(value) => {
                if (value) setSelectedAppointmentType(value);
                props.setValue("appointmentCategory", value?.label, {
                  shouldValidate: true,
                });
              }}
              style={{ border: props.errors.appointmentCategory ? '1px solid red' : '' }}

            />
            {props.errors.appointmentCategory && props.errors.appointmentCategory.type === "required" && (
              <FormErrorMessage message="required" />
            )}
          </div>
        }
        {
          (props.havePlatformContractEnabled && dialUser?.id && descriptionList.length !== 0) && <div className="form-group">
            <label htmlFor="" className="position-relative required">
              Description
            </label>
            <input className="d-none"
              name="appointmentDescription"
              ref={props.register({ required: descriptionList.length === 0 ? false : true })}
            />
            <Select
              className={` ${errors['appointmentDescription'] ? 'field-required' : ''} `}
              placeholder="Select Appointment Description"
              isDisabled={descriptionList.length === 0 ? true : false}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={descriptionList}
              filterOption={createFilter(reactSelectFilterConfig)}
              onChange={(value) => {
                props.setValue("appointmentDescription", value?.label, {
                  shouldValidate: true,
                });
              }}
              style={{ border: props.errors.appointmentDescription ? '1px solid red' : '' }}

            />
          </div>
        }
        {
          (props.havePlatformContractEnabled && dialUser?.id && selectedServiceType === PlatformType.OnSite) && <div className="form-group row">
            <label
              htmlFor=""
              className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
            >
              No. of Interpreters
            </label>
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
              <input
                name="noOfInterpreter"
                type="number"
                //  step="any"
                min="1"
                className="form-control"
                defaultValue={1}
                ref={props.register({
                  required: true,
                  validate: handleCopyPasteInvalidValue
                })}
                onKeyDown={(evt) => numberFieldValidation(evt)}
                onKeyPress={(event) => {
                  if (!/[1-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                style={{ border: props.errors.noOfInterpreter ? '1px solid red' : '' }}
              />
              {props.errors.noOfInterpreter && props.errors.noOfInterpreter.type === "required" && (
                <FormErrorMessage message="required" />
              )}
              {props.errors.noOfInterpreter && props.errors.noOfInterpreter.type === "validate" && (
                <FormErrorMessage message="Please enter a valid value" />
              )}

            </div>
          </div>
        }
      </div>
    </>
  );
}
