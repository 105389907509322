import FormFile from "react-bootstrap/esm/FormFile";
import {IvrCustomField} from "./company";

export interface IvrDialKeyMappingModel {
  key?: string;
  value?: string;
  name?: string;
}

export interface APISchemaUpdateDialKeyMapping {
  criteriaType?: number;
  isEnabled?: boolean;
  isZeroEnabledToPinInput?: boolean;
  ivrDialKeyMappingModels?: IvrDialKeyMappingModel[];
}
export interface APISchemaUpdateMessage {
  id: string;
  ivrMessageId: string;
  audioMediaKey: string;
  message: string;
  title: string;
  category: string;
  isDefault: boolean;
  file: FormFile | null;
}
 

export interface APISchemaUpdateCustomField {
  id: string;
  name: string;
  code: string;
  isEnabled: boolean;
  inputType: number;
  enterKey: string;
  min: number;
  max: number;
}

export enum IvrCustomInputType
{
  IVRInput = 1,
  OperatorInput = 2,
  None = 3
}

export interface APIEnabledIvrCustomFields {
  ivrCustomFieldModels: IvrCustomField[]
}
