import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AssetsAudio } from '../../assets';
import { AppConstants } from '../../configs';
import {
  CallStatusType,
  CallType,
  DefaultImageType,
} from '../../configs/appConstants';
import { AppRouteUi, FileBucketURL } from '../../configs/appRoute';
import { ActionVideoCall, SocketContext, StorageAuth, useAppSelector } from '../../core';
import { useFunctionalityUserGetUserById } from '../hooks/functionalityUser';
import { useFunctionalityImageView } from '../hooks';
import { useFunctionalityCalls } from '../hooks/functionalityCalls';
import { controlBrowserBackAndReloadButton } from '../../helpers';
import { logger, UserMediaUtils } from '../../utils';
import { MediaPreviewer } from '../../components';

export function PageVideoIncoming(): JSX.Element {
  const interval = useRef<any>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const history = useHistory();
  const { onUpdate } = useFunctionalityCalls();
  const { controlBrowserBackButton } = controlBrowserBackAndReloadButton();
  const data = useAppSelector((state) => ({
    VRItoken: state.auth.VRItoken,
    callerId: state.ui.callerId,
    languageName: state.ui.languageName,
    roomId: state.ui.roomId,
    userRole: state.auth.profile?.userRole,
    callStatus: state.videoCall.CallStatus,
    callerDeviceId: state.ui.callerDeviceId,
    receiverId: state.ui.receiverId,
    callType: state.ui.callType,
    curentUserDeviceId: state.auth.sessionId,
    isBackdatedVersion: state.common.isBackdatedVersion,
    videoCallTracks: state.videoCall.tracks,
  }));
  const { userData } = useFunctionalityUserGetUserById({
    userId: data.callerId,
  });
  
  const isCalleeOperator = StorageAuth.ProfileData?.userRole === AppConstants.UserRoles.Operator;
  const homePage = isCalleeOperator ? AppRouteUi.CallLog.Root : AppRouteUi.Home.Root;
  data?.callStatus?.current === CallStatusType.Zero && history.push(homePage);
  
  const CallStatusRef = React.useRef(data.callStatus);
  CallStatusRef.current = data.callStatus;

  const showCallerName = () => {
    let callerName = userData?.name as string;
    return callerName;
  };
  const showCompanyName = () => {
    let companyName = userData?.companyName as string;
    return companyName;
  };
  const showLangaugeName = () => {
    let languageName = data?.languageName as string;
    return languageName;
  };
  const [currentState, SetCurrentState] = useState<CallStatusType>(
    CallStatusType.Zero
  );
  const location = useLocation<{ CallStatus: CallStatusType }>();
  const StateCallStatus = location.state?.CallStatus;

  React.useEffect(() => {
    onUpdate(data.roomId, {
      callStatus: CallStatusType.Ringing,
      eventTime: new Date().toISOString(),
      isCallStarted: false,
      callerUserId: data.callerId,
    });
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    const audio = audioRef.current;
    interval.current = setTimeout(
      (function (x) {
        return function () {
          if (
            data.callStatus?.current === CallStatusType.Ringing &&
            data.callStatus?.prev === CallStatusType.Initiated
          ) {
            onUpdate(data.roomId, {
              callStatus:
                StateCallStatus == CallStatusType.ParticipantInvited
                  ? CallStatusType.ParticipantMissed
                  : CallStatusType.Missed,
              callerUserId: data.callerId,
              eventTime: new Date().toISOString(),
              isCallStarted: false,
            });
            history.push({
              pathname:
                data.userRole === AppConstants.UserRoles.Operator
                  ? AppRouteUi.CallLog.Root
                  : AppRouteUi.Home.Root,
            });
          }
        };
      })(data),
      40000
    );

    return ()=> {
      audio?.pause();
      clearTimeout(interval.current);
    }
  }, []);

  const videoIncoming = (socketConnection: any) => {
    if (data.VRItoken) {
      if (StateCallStatus == CallStatusType.ParticipantInvited) {
        if (data.callType == CallType.Video) {
          history.push({
            pathname: AppRouteUi.VideoCall.Room.Load(data.roomId),
          });
        } else if (data.callType == CallType.VideoConference) {
          history.push({
            pathname: AppRouteUi.VideoConference.Room.LoadConsumer(data.roomId),
          });
        }
      } else {
        onUpdate(data.roomId, {
          callStatus: CallStatusType.Received,
          eventTime: new Date().toISOString(),
          isCallStarted: false,
        });
      }
    }
  };

  const videoRejected = (socketConnection: any) => {
    audioRef.current?.pause();
    clearTimeout(interval.current);
    if (data.VRItoken) {
      onUpdate(data.roomId, {
        callStatus:
          StateCallStatus == CallStatusType.ParticipantInvited
            ? CallStatusType.ParticipantRejected
            : CallStatusType.Rejected,
        eventTime: new Date().toISOString(),
        callerUserId: data.callerId,
        isCallStarted: false,
      });
      history.push({
        pathname:
          data.userRole === AppConstants.UserRoles.Operator
            ? AppRouteUi.CallLog.Root
            : AppRouteUi.Home.Root,
      });
    }
  };
  const { defaultImageLoad, imageView } = useFunctionalityImageView();

  useEffect(() => {
    if (
      data.callStatus?.current == CallStatusType.Received ||
      data.callStatus?.current == CallStatusType.Rejected
    ) {
      clearTimeout(interval.current);
    }
  }, [data?.callStatus?.current]);

  const OnReceivedPermissionHandler = async (value: any) => {
    audioRef.current?.pause();
    clearTimeout(interval.current);
    const tracks = data.videoCallTracks || (await UserMediaUtils.startMedia());
    tracks && dispatch(ActionVideoCall.setTracks(tracks));
    if (tracks) {
      SetCurrentState(CallStatusType.Received);
      dispatch(
        ActionVideoCall.SetCallStatus({
          prev: CallStatusType.Ringing,
          current: CallStatusType.Received,
        })
      );
      videoIncoming(value.socketConnection);
    } else {
      SetCurrentState(CallStatusType.Rejected);
      dispatch(
        ActionVideoCall.SetCallStatus({
          prev: CallStatusType.Ringing,
          current: CallStatusType.Rejected,
        })
      );
      videoRejected(value.socketConnection);
      history.push({
        pathname: AppRouteUi.OutgoingCallFailedForMedia.Root,
      });
    }
  };
  const onEndedPlay = (ev: any) => {
    ev.target.currentTime = 0;
    ev.target.play();
  };

  useEffect(() => {
    if (data.callStatus?.current === CallStatusType.Ringing) {
      controlBrowserBackButton();
    }
  }, [data.callStatus?.current]);
  useEffect(() => {
    if (data.callStatus?.current === CallStatusType.Ringing) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [data.callStatus?.current]);
  return (
    <>
      {currentState != CallStatusType.Received && (
        <audio
          src={AssetsAudio.ReceiverTone}
          autoPlay
          onEnded={onEndedPlay}
          ref={audioRef}
        />
      )}
      <div className="site-call-page ">
        <div className="site-call-page-wrapper">
          <div className="container-fluid h-100">
            <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
              <div className="col-12 text-center d-flex align-items-center justify-content-center pt-3">
                <ul className=" babelCallAnimation bg-white caller-image">
                  <img
                    src={`${FileBucketURL.Root}${data.callerId}?${imageView.fileTimeStamp}`}
                    alt=""
                    onError={(ev) => {
                      defaultImageLoad(
                        ev,
                        DefaultImageType.UserProfile,
                        '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                      );
                    }}
                    className="img-fluid"
                  />

                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>

              <div className="col-12 text-center p-3 text-white">
                <p className="mb-3 pb-3">Incoming VRI call</p>
                {currentState == CallStatusType.Received && (
                  <h2 className="my-4">Connecting to the room...</h2>
                )}
                <p className="pb-3 mb-3 mt-0 h4 font-400">{showCallerName()}</p>
                {!isCalleeOperator && <p className="mb-0 mt-0 h5 font-500">{showCompanyName()}</p>}
                {data?.languageName && (
                  <p className="mb-3  mt-0">Language: {showLangaugeName()}</p>
                )}
              </div>
              {currentState !== CallStatusType.Received && (
                <div className="col-12 text-center pb-3">
                  <SocketContext.Consumer>
                    {(value) => (
                      <div className="d-flex align-items-center justify-content-center container">
                        <button
                          className="btn  mx-4  callPage-controlBtn btn-red"
                          onClick={() => {
                            logger({ message: 'Call rejection button pressed'});
                            dispatch(
                              ActionVideoCall.SetCallStatus({
                                prev: CallStatusType.Ringing,
                                current: CallStatusType.Rejected,
                              })
                            );
                            SetCurrentState(CallStatusType.Rejected);
                            videoRejected(value.socketConnection);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                          </svg>
                        </button>

                        <button
                          // to={AppRouteUi.Room.VideoConference.Load(data.roomId)}
                          className="btn mx-4  callPage-controlBtn btn-green"
                          onClick={() => {
                            logger({ message: 'Call receive button pressed'});
                            OnReceivedPermissionHandler(value);
                          }}
                          disabled={data.isBackdatedVersion}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                          </svg>
                        </button>
                      </div>
                    )}
                  </SocketContext.Consumer>
                </div>
              )}
            </div>
          </div>
        </div>
        <MediaPreviewer/>
      </div>
    </>
  );
}
