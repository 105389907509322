import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { InputFieldValidationErrorMessage } from "../../configs";
import { AppointmentCategoryRequestModel, AppointmentCategoryResponseModel } from "../../models/api/modality";
import { useFunctionalityAppointmentCategory } from "../../pages/hooks/functionalityAppointmentCategory";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  dataToEdit?: AppointmentCategoryResponseModel;
  setDataToEdit?: (value?: AppointmentCategoryResponseModel) => void;
};
export function ModalAppointmentCategory(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, setDataToEdit } = props;
  const { onAddAppointmentCategory, onEditAppointmentCategory, } = useFunctionalityAppointmentCategory();
  const { register, handleSubmit, errors } = useForm<AppointmentCategoryRequestModel>();
  const [errMsg, setErrMsg] = useState("");
  const { useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();

  const modalHideHandler = () => {
    setDataToEdit?.(undefined);
    handleShow(false);
  };
  const onEditHandler = (form: AppointmentCategoryRequestModel) => {
    form = {
      ...form,
      name: form.name.trim()
    }
    onEditAppointmentCategory(form, dataToEdit?.id ?? "")
      .then(() => modalHideHandler())
      .catch((e) => { });
  };

  const onCreateHandler = (form: AppointmentCategoryRequestModel) => {
    form = {
      ...form,
      name: form.name.trim()
    }
    onAddAppointmentCategory(form)
      .then(() => modalHideHandler())
      .catch((e) => { });
  };

  const onValidatePin = (value: any) => {
    if (value < 2 || isNaN(value)) {
      setErrMsg("Please enter at least 2 numbers.");
      return false;
    }
    setErrMsg("");
    return true;
  };

  return (
    <Modal show={show} onHide={modalHideHandler} centered backdrop="static">
      <form
        onSubmit={handleSubmit(dataToEdit ? onEditHandler : onCreateHandler)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {dataToEdit
              ? "Update Appointment Type"
              : "Add  Appointment Type"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Appointment Type
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Appointment Type"
                  defaultValue={dataToEdit?.name}
                  ref={register({ required: true, validate: useFunctionlityInputFieldValidation })}
                />
                {errors.name?.type === "required" && <FormErrorMessage message="required" />}
                {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
              </div>

              {/* <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Pin
                </label>
                <input
                  type="text"
                  name="pin"
                  className="form-control"
                  placeholder="Enter PIN(Min 2 digits, Max 4 digits)"
                  defaultValue={dataToEdit?.pin}
                  ref={register({ required: true, validate: onValidatePin })}
                  maxLength={4}
                />
                {(errors.pin || errMsg) && (
                  <FormErrorMessage message={errMsg || "required"} />
                )}
              </div> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={modalHideHandler}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="appointmentCategory-form-submit-btn btn btn-primary text-uppercase"
          >
            {dataToEdit ? "Update" : "Add"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
