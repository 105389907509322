import { useEffect, useState } from "react";
import { ModalEditUserInfo } from "../../../modals/userInfoEditModal";
import { ModalResetPassword } from "../../../modals/common/resetPasswordModal";
import { ApiSchemaCalenderProvider, ApiSchemaUserDetailsData, genderType, } from "../../../../models";
import { getKeyByValue } from "../../../../utils";
import { ModalChangeProfilePhoto } from "../../..";
import { AssetSvg } from "../../../../assets";
import { DefaultImageType, FileBucketURL, } from "../../../../configs";
import { useFunctionalityImageView } from "../../../../pages/hooks/functionalityImageView";
import { useFunctionalityDepartment, useFunctionalityLocation, useFunctionalityState, useFunctionalityUserConnections, } from "../../../../pages/hooks";
import { genders, timeZones } from "./../../../modals/jobFormData";
import { DefaultCountry, CalendarProvider, AppConstants, } from "../../../../configs/appConstants";
import GoogleLogoutCom from "../../../../components/common/google/googleLogout";
import GoogleLogoutComForCalendar from "../../../../components/common/google/googleLogoutForCalendar";
import GoogleLoginComponentForCalender from "../../../../components/common/google/googleLoginForCalendar";
import { isElectron } from "../../../../utils/isElectron";
import UserProfileImageLoader from "./UserProfileImageLoader";
import { useAppSelector } from "../../../../core";

type Props = {
  profile: ApiSchemaUserDetailsData;
  onUpdateHandler: any;
  userDetailsData: ApiSchemaUserDetailsData;
  setEditModalShow: any;
  editModalShow: boolean;
};
export function UserGeneralInfo(props: Props) {
  const userRole = useAppSelector((state) => state.auth.profile?.userRole);
  const { profile, onUpdateHandler, userDetailsData, editModalShow, setEditModalShow, } = props;
  const { defaultImageLoad, imageView, onUploadComplete } = useFunctionalityImageView();
  const [changeProp, setChangeProp] = useState<number>(0);
  const [resetModalShow, setResetModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profilePictureModalShow, setProfilePictureModalShow] = useState(false);
  const { getConnections } = useFunctionalityUserConnections();
  const [connectionNames, setConnectionNames] = useState<any>();
  const { stateList } = useFunctionalityState(
    userDetailsData?.country ?? DefaultCountry.USA,
    editModalShow
  );
  const initialStateList = [...stateList];
  const [modalKey, setModalKey] = useState(Date.now() + "");
  const { optionMappedLocationList } = useFunctionalityLocation({
    companyId: userDetailsData?.companyId || "",
    shouldLocationGetAllInvoke: editModalShow,
  });
  const { optionMappedDepartmentList } = useFunctionalityDepartment({
    companyId: userDetailsData?.companyId || "",
    shouldDepartmentGetAllInvoke: editModalShow,
  });

  const havePermissionOfGoogleLogin = () => {
    if (
      userRole === AppConstants.UserRoles.CompanyAdmin ||
      userRole === AppConstants.UserRoles.CompanyFinance ||
      userRole === AppConstants.UserRoles.User
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setModalKey(Date.now() + "");
  }, [userDetailsData]);

  const getTimeZone = (timeZone: string) => {
    return timeZones.find((x) => x.value === timeZone)?.label ?? "N/A";
  };
  useEffect(() => {
    getConnections().then((r) => {
      setConnectionNames(r);
    });
  }, [changeProp]);

  return (
    <>
      <div className="component-card my-3  d-xl-block d-lg-block d-md-block  user-general-info">
        <div className="component-card-header d-flex align-items-center justify-content-between  border-top border-right border-left flex-wrap ">
          <div>General Information</div>
          <button
            className="btn btn-link px-0 text-site btn-sm text-uppercase svg-color"
            onClick={() => {
              if (genders && userDetailsData && initialStateList) {
                setEditModalShow(true);
              }
            }}
          >
            <span className="mr-2">
              <AssetSvg.Edit />
            </span>
            <span className="font-sz-10">Edit Info</span>
          </button>
        </div>
        <div className="component-card-body border">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="profile-image d-flex justify-content-center align-items-center">
                <div
                  className="imagehover-uploadbtn"
                  onClick={() => setProfilePictureModalShow(true)}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="btn text-white font-sz-12"
                  >
                    Change
                  </a>
                </div>
                {loading && <UserProfileImageLoader />}

                {userDetailsData?.id && (
                  <img
                    src={`${FileBucketURL?.Root}${userDetailsData?.id}?${imageView?.fileTimeStamp}`}
                    // alt=""
                    onError={(ev) => {
                      defaultImageLoad(
                        ev,
                        DefaultImageType.UserProfile,
                        "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      );
                    }}
                    onLoad={() => setLoading(false)}
                    className={`img-fluid ${loading ? "d-none" : ""}`}
                  />
                )}
              </div>

              <div className="profile-info text-center">
                <p className="profile-name mb-0 mt-3">{profile?.name}</p>
                <hr />
                <p className="profile-email mb-0">{profile?.email}</p>
                <a
                  className="btn btn-link  reset-password-link "
                  onClick={() => setResetModalShow(true)}
                >
                  <span className="font-sz-16 mr-1">
                    <AssetSvg.ResetPassword />
                  </span>
                  <span>Reset Password</span>
                </a>
                {
                  havePermissionOfGoogleLogin() && (
                    <>
                      <hr className="mt-1" />
                      <div className="mb-1">
                        {connectionNames &&
                          connectionNames.map((connection: ApiSchemaCalenderProvider) => {
                            return connection.calendarProvider === CalendarProvider.Google && (
                              connection.isConnected ? (
                                <GoogleLogoutComForCalendar
                                  key={Math.floor(Math.random() * 10)}
                                  setChangeProp={setChangeProp}
                                />
                              ) : (
                                <GoogleLoginComponentForCalender
                                  key={Math.floor(Math.random() * 10)}
                                  setChangeProp={setChangeProp}
                                />
                              )
                            )
                          })}
                      </div>
                    </>
                  )
                }
              </div>
            </div>

            <div className="col-xl-9 col-lg-8 col-md-6 col-sm-12 col-12 profile-detail-column">
              <div className="profile-detail ">
                <div className="row pl-3 mb-3">
                  <div className="profile-detail-label">Company : </div>
                  <div className="profile-detail-info">
                    {profile?.companyName}
                  </div>
                </div>
                <div className="row pl-3  mb-3">
                  <div className="profile-detail-label ">Address : </div>
                  <div className="profile-detail-info ">
                    {profile?.displayAddress}
                  </div>
                </div>
                <div className="row pl-3  mb-3">
                  <div className="profile-detail-label ">Phone Number : </div>
                  <div className="profile-detail-info ">
                    {profile?.phoneNumber ? profile?.phoneNumber : "N/A"}
                  </div>
                </div>
                <div className="row pl-3  mb-3">
                  <div className="profile-detail-label ">Education : </div>
                  <div className="profile-detail-info ">
                    {profile?.education}
                  </div>
                </div>
                <div className="row pl-3 mb-3">
                  <div className="profile-detail-label ">Gender : </div>
                  <div className="profile-detail-info ">
                    {profile?.gender
                      ? getKeyByValue(genderType, profile?.gender)
                      : "Any"}
                  </div>
                </div>

                <div className="row pl-3  mb-3">
                  <div className="profile-detail-label ">Time Zone : </div>
                  <div className="profile-detail-info ">
                    {getTimeZone(profile?.timeZone ?? "")}
                  </div>
                </div>
                {userRole !== AppConstants.UserRoles.Operator && (
                  <div className="row pl-3">
                    <div className="profile-detail-label">Notes : </div>
                    <div className="profile-detail-info">
                      {profile?.notes ? profile?.notes : 'N/A'}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalChangeProfilePhoto
        useId={profile?.id ?? ""}
        onUploadComplete={onUploadComplete}
        show={profilePictureModalShow}
        handleShow={setProfilePictureModalShow}
      />
      {editModalShow && (
        <ModalEditUserInfo
          onUpdateHandler={onUpdateHandler}
          userDetailsData={userDetailsData}
          optionMappedLocationList={optionMappedLocationList}
          optionMappedDepartmentList={optionMappedDepartmentList}
          show={editModalShow}
          handleShow={setEditModalShow}
          initialStateList={initialStateList}
          genders={genders}
        />
      )}
      <ModalResetPassword
        show={resetModalShow}
        handleShow={setResetModalShow}
      />
    </>
  );
}
