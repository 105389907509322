import { Modal } from "react-bootstrap";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactToPrint from "react-to-print";
import { InvoiceItemCodeConstants, InvoiceStatus, InvoiceUnit, } from "../../configs/appConstants";
import { useRef } from "react";
import { RptInvoiceViewModel } from "../../models/api/Finance";
import { useFunctionalityInvoice } from "../../pages/hooks/functionalityInvoice";
import moment from "moment";
type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  invoice?: RptInvoiceViewModel;
  bookingSerila?: any;
};

export function ModalPrintInvoice(props: Props): JSX.Element {
  var { invoice, bookingSerila } = props;
  const componentRef = useRef<HTMLDivElement>(null);
  const { downloadInvoicePdf } = useFunctionalityInvoice({});

  const downloadPdf = () => {
    downloadInvoicePdf(
      invoice?.invoice?.id ?? "",
      invoice?.invoiceType ?? 0
    ).then((data) => {
      if (data) {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = `${invoice?.invoice.formattedInvoiceId
          }-${moment().format("MM-YYYY")}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  };

  const GetFormattedAmountAsString = (amount?: number) => {
    if (Math.abs(amount!) < 0.0000001)
      return "$0.00";
    return amount! < 0 ?
      `($${(amount! * -1)?.toFixed(2)})`
      : `$${(amount!)?.toFixed(2)}`;
  };
  const GetTotalOf = (code: string) => {
    let total: number = 0;
    if (
      invoice?.invoice.invoiceItems != null &&
      invoice.invoice.invoiceItems.length > 0
    ) {
      let sum = 0;
      for (var item in invoice.invoice.invoiceItems) {
        if (invoice.invoice.invoiceItems[item].code == code) {
          sum += invoice.invoice.invoiceItems[item].total;
        }
      }
      total = sum;
    }
    return total;
  };
  const GetTotalExclude = (code: string) => {
    return invoice?.invoice.amount! - GetTotalOf(code);
  }
  const SecondsToFormattedTime = (seconds: number) => {
    var date = new Date(0);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
  };
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      id="invoice-modal"
    >
      <form action="">
        <Modal.Header closeButton>
          <Modal.Title>Print Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 mx-1">
          <div ref={componentRef} id="printInvoice" className="printInvoice">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <p className="mb-2 font-600 font-sz-16">
                  {invoice?.fromBillingAddress.name}
                </p>
                <p className="mb-1">
                  {invoice?.fromBillingAddress.displayAddress}
                </p>
                <p className="mb-1">
                  Tel: {invoice?.fromBillingAddress.address.phone}
                </p>
                {invoice?.fromBillingAddress.address.tollFreePhone && (
                  <p className="mb-1">
                    Toll-Free:{" "}
                    {invoice.fromBillingAddress.address.tollFreePhone}
                  </p>
                )}
                {invoice?.fromBillingAddress.address.fax && (
                  <p className="mb-1">
                    Fax: {invoice.fromBillingAddress.address.fax}
                  </p>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-6 mt-sm-3 mt-3 mt-md-0">
                <p className="mb-2 font-600 font-sz-16 text-md-right ">
                  {invoice?.invoice.formattedInvoiceId}
                </p>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    {invoice?.invoice.bookingSerial && (
                      <tr>
                        <td>Booking</td>
                        <td>{invoice?.invoice.bookingSerial}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Date</td>
                      <td>{invoice?.invoice.invoiceDate}</td>
                    </tr>
                    <tr>
                      <td>Due Date</td>
                      <td>{invoice?.invoice.dueDate}</td>
                    </tr>
                    <tr>
                      <td>Billing Period</td>
                      <td>{invoice?.invoice.billingPeriod}</td>
                    </tr>
                    <tr>
                      <td>Total Due</td>
                      <td>{GetFormattedAmountAsString(invoice?.invoice.amount)}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <p className="mb-1 font-600 font-sz-16">Bill To</p>
                <p className="mb-1">{invoice?.toBillingAddress?.name}</p>
                <p className="mb-1">
                  {invoice?.toBillingAddress?.displayAddress}
                </p>
                <p className="mb-0">
                  {invoice?.toBillingAddress?.address?.email}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tr>
                      <td scope="row" colSpan={2} className="text-dark">
                        Description
                      </td>
                      <td className="text-dark">Quantity</td>
                      <td className="text-dark">Unit Price</td>
                      <td className="text-dark">Amount</td>
                    </tr>
                    {invoice?.invoice.invoiceItems.map(
                      (item) =>
                        item.code !== InvoiceItemCodeConstants.lateFeeItem &&
                        item.code !==
                        InvoiceItemCodeConstants.inFlowCallLogItem && (
                          <tr>
                            <td scope="row" colSpan={2}>
                              {item.item}
                            </td>
                            {item.invoiceUnit == InvoiceUnit.InSeconds ? (
                              <td className="text-right">
                                {SecondsToFormattedTime(item.quantity)}
                              </td>
                            ) : (
                              <td className="text-right">{item.quantity}</td>
                            )}
                            <td className="text-right">{GetFormattedAmountAsString(item.unitPrice)}</td>
                            <td className="text-right">
                              {GetFormattedAmountAsString(item.amount)}
                            </td>
                          </tr>
                        )
                    )}
                    <tr>
                      <td
                        scope="row"
                        colSpan={2}
                        className={`invisible border-0 ${invoice &&
                          invoice.invoice.invoiceItems.filter(
                            (item) =>
                              item.code == InvoiceItemCodeConstants.lateFeeItem
                          ).length == 0 &&
                          invoice &&
                          invoice.invoice.invoiceItems.filter(
                            (item) =>
                              item.code ==
                              InvoiceItemCodeConstants.inFlowCallLogItem
                          ).length == 0
                          ? "whiteBottomBorder"
                          : ""
                          }`}
                      ></td>
                      <td className="invisible border-0"></td>
                      <td className="text-dark">Total Payable</td>
                      <td className="text-dark text-right">
                        {GetFormattedAmountAsString(GetTotalExclude(
                          InvoiceItemCodeConstants.inFlowCallLogItem
                        ))}
                      </td>
                    </tr>
                    {invoice?.invoice.invoiceItems.map(
                      (item) =>
                        item.code == InvoiceItemCodeConstants.lateFeeItem && (
                          <tr data-inv="B">
                            <td scope="row" colSpan={2}>{item.item}</td>

                            <td className="text-right">{item.quantity}</td>

                            <td className="text-right">{GetFormattedAmountAsString(item.unitPrice)}</td>
                            <td className="text-right">
                              {GetFormattedAmountAsString(item.amount)}
                            </td>
                          </tr>
                        )
                    )}
                    {invoice?.invoice.invoiceItems.map(
                      (item) =>
                        item.code ==
                        InvoiceItemCodeConstants.inFlowCallLogItem && (
                          <tr data-inv="C">
                            <td scope="row" colSpan={2}>{item.item}</td>

                            {item.invoiceUnit == InvoiceUnit.InSeconds ? (
                              <td className="text-right">
                                {SecondsToFormattedTime(item.quantity)}
                              </td>
                            ) : (
                              <td className="text-right">{item.quantity}</td>
                            )}
                            <td className="text-right">{GetFormattedAmountAsString(item.unitPrice)}</td>
                            <td className="text-right">
                              {GetFormattedAmountAsString(item.amount)}
                            </td>
                          </tr>
                        )
                    )}
                    {invoice &&
                      invoice?.invoice.invoiceItems.filter(
                        (item) =>
                          item.code ==
                          InvoiceItemCodeConstants.inFlowCallLogItem
                      ).length > 0 && (
                        <tr>
                          <td className="invisible border-0" colSpan={2}></td>
                          <td className="invisible border-0"></td>
                          <td>Total Receivable</td>
                          <td className=" text-right">
                            {GetFormattedAmountAsString(
                              GetTotalOf(
                                InvoiceItemCodeConstants.inFlowCallLogItem
                              )
                            )}
                          </td>
                        </tr>
                      )}
                    <tr>
                      <td
                        scope="row"
                        colSpan={2}
                        className="invisible border-0 whiteBottomBorder"
                      ></td>
                      <td className="invisible border-0"></td>
                      <td className="text-dark">
                        {invoice?.isNetPayable
                          ? "Net Payable"
                          : "Net Receivable"}
                      </td>
                      <td className="text-dark text-right">
                        {GetFormattedAmountAsString(invoice?.invoice.amount)}
                      </td>
                    </tr>
                    {invoice?.isNetPayable && (
                      <>
                        <tr>
                          <td
                            scope="row"
                            colSpan={2}
                            className="invisible border-0 whiteBottomBorder"
                          ></td>
                          <td className="invisible border-0"></td>
                          <td className="text-dark">Amount Paid</td>
                          <td className="text-dark text-right">
                            {invoice?.invoice.status == InvoiceStatus.PAID
                              ? GetFormattedAmountAsString(invoice?.invoice.totalPaid)
                              : "$0.00"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            scope="row"
                            colSpan={2}
                            className="invisible border-0"
                          ></td>
                          <td className="invisible border-0"></td>
                          <td className="text-dark">Amount Due</td>
                          <td className="text-dark text-right">
                            {invoice?.invoice.status == InvoiceStatus.PAID
                              ? "$0.00"
                              : GetFormattedAmountAsString(invoice?.invoice.amount)}
                          </td>
                        </tr>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-1">
          <div className="col-12  py-2 rounded">
            <button
              type="button"
              className="skill-form-submit-btn btn btn-primary mx-2"
              onClick={() => {
                downloadPdf();
              }}
            >
              <FontAwesomeIcon icon={faDownload} className="mr-1 ml-1" />
            </button>
            <ReactToPrint
              copyStyles={true}
              trigger={() => {
                return (
                  <a href="#" className="btn btn-primary  mx-2">
                    <FontAwesomeIcon icon={faPrint} className="mr-1 ml-1" />
                  </a>
                );
              }}
              content={() => componentRef.current}
            />
          </div>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
