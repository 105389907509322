import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { CallStatusType, CallType, Modals, SocketConstants } from "../../configs";
import { ActionUi, SocketContext, useAppSelector } from "../../core";
import { useFunctionalityCalls } from "../../pages/hooks/functionalityCalls";
import {
  useFunctionalityUser2,
  useFunctionalityUserGetNames
} from "../../pages/hooks/functionalityUser";
import { logger } from "../../utils";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  callType: CallType
};
export function ModalAddParticipant(props: Props): JSX.Element {
  const currentUserCompanyId = useAppSelector((state) => state.auth.profile?.companyId);
  const { userNames } = useFunctionalityUserGetNames({ companyId: currentUserCompanyId });
  const currentUserId = useAppSelector((state) => state.auth.profile?.id)
  const userNamesWithoutCurrentUser = userNames.filter(user => user.value !== currentUserId)
  const { onUpdate } =
    useFunctionalityCalls();
  const data = useAppSelector((state) => ({
    callerId: state.auth.profile?.id,
    roomId: state.ui.roomId,
    chatSid: state.ui.chatSid,
    languageName: state.ui.languageName
  }));
  const { getUserStatus } = useFunctionalityUser2({});
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = React.useState<any>();
  const [selectedUserName, setSelectedUserName] = React.useState<any>();
  const videoConnect = (connection: any, event: any) => {
    event?.preventDefault();
    logger({ message: `Trying to invite user in call: ${selectedUserName} (${selectedUserId})` });
    getUserStatus(selectedUserId ?? "").then((d) => {
      props.handleShow(false);
      if (d == SocketConstants.online) {
        // var RequestModel = { RoomId: data.roomId, CallerId: data.callerId, ReceiverId: selectedUserId, Status: VideoParticipentStatus.Invited };
        // connection?.send(
        //   JSON.stringify({ "message": SocketConstants.onOutGoing, "data": JSON.stringify(RequestModel) })
        // );
        onUpdate(data.roomId, {
          callStatus: CallStatusType.ParticipantInvited,
          callType: props.callType,
          eventTime: new Date().toISOString(),
          isCallStarted: false,
          newParticipantId: selectedUserId,
          chatSid: data.chatSid,
          callerUserId: data.callerId,
          languageName: data.languageName
        }).then(() => {
          toast.info("Inviting User " + selectedUserName);
          logger({ message: `Inviting User: ${selectedUserName} (${selectedUserId})` });
        });

      } else {
        toast.error(selectedUserName + " not available online");
      }
      setSelectedUserId("");
      setSelectedUserName("")
    })

  };
  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  // };
  const onModalClose = () => {
    props.handleShow(false);
    setSelectedUserId("");
    setSelectedUserName("")
  };

  return (
    <Modal show={props.show} onHide={() => onModalClose()} centered backdrop='static'>
      {/* <form> */}
      <Modal.Header closeButton>
        <Modal.Title> Add to Call</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label
                htmlFor=""
                className="text-muted font-sz-12"
              >
                User
              </label>

              <Select
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={userNamesWithoutCurrentUser}
                placeholder="Select an user..."
                onChange={(value) => {
                  setSelectedUserId(value?.value);
                  if (!!value?.value) {
                    setSelectedUserName(value?.label);
                    dispatch(ActionUi.SetReceiverName(value?.label));
                    dispatch(ActionUi.SetReceiverId(value?.value));
                  }
                }}
              />
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div
          className="form-row 
              justify-content-lg-end justify-content-xl-end justify-content-md-end justify-content-sm-center justify-content-center 
              flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
              mx-0"
        >
          <SocketContext.Consumer>
            {(connection) => (
              <button
                disabled={(!selectedUserId && !selectedUserName)}
                className="  btn btn-outline-dark border-grey user-from-submit-btn btn btn-primary text-uppercase
                    d-flex d-sm-flex d-md-inline-block d-lg-inline-block d-xl-inline-block 
                    flex-grow-1 flex-sm-grow-1 flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 
                    justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 "
                onClick={(event) => {
                  videoConnect(connection.socketConnection, event);
                  logger({ message: `Pressed button to add call: ${selectedUserName} (${selectedUserId})` });
                }}
              >
                Add to Call
              </button>
            )}
          </SocketContext.Consumer>
        </div>

      </Modal.Footer>
      {/* </form> */}
    </Modal>

  );
}
