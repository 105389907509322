import { dateFormatInUSA, time24hrFormatter } from "../../utils";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type Props = {
  callId: string
  callStartTime: string,
  callEndTime: string;
  language: string | "Any";
  skill?: string | "Any";
  gender?: string | "Any";
  bookingSerialNo?: string;
}
export function CallSummaryCallDetail(props: Props): JSX.Element {
  const { callStartTime, callEndTime, callId, language, skill, gender, bookingSerialNo } = props;
  return (
    <>
      <div className="card">
        <div
          className="card-header px-0 py-1" id="headingCallDetail"
        >
          <div className="call-sum-details  text-left text-decoration-none text-site siteAccordion collapsed" role="button" data-toggle="collapse" data-target="#call-id-collapse" aria-expanded="true" aria-controls="call-id-collapse">

            <div className="d-flex justify-content-between align-items-center">
              <div><p className="m-0"><span className="font-weight-bold">{bookingSerialNo ? 'Serial No:' : 'Call Id: '}  </span> <span>{bookingSerialNo ? `# ${bookingSerialNo}` : callId}</span></p></div>
              <div className="btn p-0">
                <FontAwesomeIcon icon={faChevronDown} className="closed-panel-icon" />
                <FontAwesomeIcon icon={faChevronUp} className="open-panel-icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="collapse" id="call-id-collapse" aria-labelledby="headingCallDetail" data-parent="#call-summary-panels">
          <div className="card-body px-3 py-2">
            <div className="row mt-1">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="row mb-2">
                  <div className="col-4">
                    Date:
                  </div>
                  <div className="col-8 text-muted">
                    {callStartTime ? dateFormatInUSA(callStartTime) : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    Started:
                  </div>
                  <div className="col-8 text-muted">
                    {callStartTime ? time24hrFormatter(callStartTime) : ""}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    Ended:
                  </div>
                  <div className="col-8 text-muted">
                    {callEndTime ? time24hrFormatter(callEndTime) : ""}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 border-sm-top">
                <div className="row mb-2">
                  <div className="col-4">
                    Language:
                  </div>
                  <div className="col-8 text-muted">
                    {language}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    Skill:
                  </div>
                  <div className="col-8 text-muted">
                    {skill ?? 'N/A'}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    Gender:
                  </div>
                  <div className="col-8 text-muted">
                    {gender}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}