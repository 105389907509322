import { Modal } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { FormErrorMessage } from "../../components";
import { ApiSchemaLanguageData } from '../../models/api/language';
import { ModalFooter } from "../Common";
import { useFunctionalityLanguage } from '../../pages/hooks/functionalityLanguage';
import { useState } from "react";
import { useFunctionalityExists } from "../../pages/hooks/functionalityExists";
import { PlatformType } from "../../models";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { InputFieldValidationErrorMessage } from "../../configs";

type Props = {
  show: boolean,
  handleShow: (value: boolean) => void,
  dataToEdit?: ApiSchemaLanguageData,
  setDataToEdit?: (value?: ApiSchemaLanguageData) => void
}

export function ModalAddLanguage(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, setDataToEdit } = props;
  const { onAdd, editLanguage } = useFunctionalityLanguage({ plaformType: PlatformType.All });
  const { register, handleSubmit, errors } = useForm<ApiSchemaLanguageData>();
  const [alertText, setAlertText] = useState<boolean>(false);
  const [languageNameErrMsg, setLanguageNameErrMsg] = useState("");
  const [codeExistsMsg, setCodeExistsMsg] = useState("");
  const [pinExistsMsg, setPinExistsMsg] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();

  const { onLanguagePinExists, onLanguageCodeExists, onLanguageNameExists } = useFunctionalityExists();
  const onEdit = async (form: ApiSchemaLanguageData) => {
    const lname = document?.getElementById("name") as HTMLInputElement
    const lnameValue = lname.value
    const lCode = document.getElementById("code") as HTMLInputElement
    const lcodeValue = lCode.value
    const lpin = document.getElementById("pin") as HTMLInputElement
    const lpivalue = lpin.value
    form = {
      ...form,
      name: form.name.trim()
    }
    await onLanguageNameExists(lnameValue, dataToEdit)
      .then((r) => {
        if (r) setLanguageNameErrMsg("Language Already Exists")
        else setLanguageNameErrMsg("")
        onLanguagePinExists(lpivalue, dataToEdit)
          .then((rr) => {
            if (rr) setPinExistsMsg("Pin Already Exists")
            else setPinExistsMsg("")
            onLanguageCodeExists(lcodeValue, dataToEdit)
              .then((rrr) => {
                if (rrr) setCodeExistsMsg("Pin Already Exists")
                else setCodeExistsMsg("")
                if (!r && !rr && !rrr) {
                  editLanguage(form, dataToEdit?.id ?? '').then(() => {
                    handleShow(false);
                  }).catch((err) => {
                    console.error(err);
                  });
                }
              })
          })
      })
  }
  const onSuccessLanguageAdd = async (form: ApiSchemaLanguageData) => {
    const lname = document?.getElementById("name") as HTMLInputElement
    const lnameValue = lname.value
    const lCode = document.getElementById("code") as HTMLInputElement
    const lcodeValue = lCode.value
    const lpin = document.getElementById("pin") as HTMLInputElement
    const lpivalue = lpin.value
    form = {
      ...form,
      name: form.name.trim()
    }
    await onLanguageNameExists(lnameValue, dataToEdit)
      .then((r) => {
        onLanguagePinExists(lpivalue, dataToEdit)
          .then((rr) => {
            onLanguageCodeExists(lcodeValue, dataToEdit)
              .then((rrr) => {
                if (!r && !rr && !rrr) {
                  onAdd(form).then(() => {
                    handleShow(false);
                  }).catch((err) => {
                    console.error(err);
                  })
                }

              })
          })
      })
  }

  const pinInputValidation = (e: { target: { value: any; }; }) => {
    if (e.target.value.length == 1) {
      setAlertText(true)
    }
    else setAlertText(false)
  }

  const onPinExistValidation = async (value: any) => {
    await onLanguagePinExists(value, dataToEdit)
      .then((r) => {
        if (r) setPinExistsMsg("Pin Already Exists")
        else setPinExistsMsg("")
      })
  }

  const onCodeExistsValidation = async (value: any) => {
    await onLanguageCodeExists(value, dataToEdit)
      .then((r) => {
        if (r) setCodeExistsMsg("Code Already Exists")
        else setCodeExistsMsg("")
      })

  }

  const onLanguageNameValidation = async (value: any) => {
    await onLanguageNameExists(value, dataToEdit)
      .then((r) => {
        if (r) setLanguageNameErrMsg("Name Already Exists")
        else setLanguageNameErrMsg("")
      })
  }
  return (
    <Modal
      show={show}
      onHide={() => {
        setDataToEdit?.(undefined);
        handleShow(false);
      }}
      centered
    >
      <form onSubmit={handleSubmit(dataToEdit ? onEdit : onSuccessLanguageAdd)}>
        <Modal.Header closeButton>
          <Modal.Title className="test-modal" >{dataToEdit ? 'Edit Language' : 'Add Language'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Language</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Language"
                  id='name'
                  defaultValue={dataToEdit?.name}
                  onChange={(e) => { onLanguageNameValidation(e.target.value) }}
                  ref={register({ required: true, validate: useFunctionlityInputFieldValidation })}
                />
                {errors.name?.type === "required" && (<FormErrorMessage message="required" />)}
                {languageNameErrMsg && (<FormErrorMessage message={languageNameErrMsg} />)}
                {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Code</label>
                <input
                  type="text"
                  name="code"
                  className="form-control"
                  placeholder="Code"
                  id='code'
                  defaultValue={dataToEdit?.code}
                  onChange={(e) => { onCodeExistsValidation(e.target.value) }}
                  ref={register({ required: true })}
                />
                {codeExistsMsg && (<FormErrorMessage message={codeExistsMsg} />)}
                {errors.code?.type === "required" && (<FormErrorMessage message="required" />)}
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">PIN</label>
                <input
                  onChange={(e) => {
                    pinInputValidation(e);
                    onPinExistValidation(e.target.value);
                  }}
                  type="text"
                  name="pin"
                  className="form-control"
                  placeholder="Enter PIN. (Min 2 digits, Max 4 digits)"
                  minLength={2}
                  maxLength={4}
                  id='pin'
                  defaultValue={dataToEdit?.pin}
                  ref={register({ required: true })}
                />
                {errors.pin?.type === "required" && (<FormErrorMessage message="required" />)}
                {pinExistsMsg && (<FormErrorMessage message={pinExistsMsg} />)}
                {alertText && <span className="form-text m-b-none text-left pl-1 text-danger"  >Please enter at least 2 characters.</span>}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <ModalFooter handleShow={handleShow} setDataToEdit={setDataToEdit} handleSubmit={handleSubmit} disbaled={buttonDisabled} />
          {/* {errMsg && <FormErrorMessage message={errMsg} />} */}
        </Modal.Footer>
      </form>
    </Modal>
  )
}