import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { AppRouteUi, CallCenterActivityTypeOptions, ExportTableType, PaginationSettings, topLoaderProgress, } from "../../configs";
import { AdminPageTemplate, ColumnToolTip, ModalCallSummary, } from "../../features";
import { useFunctionalityCallCenterActivity } from "../hooks/functionalityCallCenterActivity";
import { useAppSelector } from "../../core";
import ReactDOM from "react-dom";
import { ColumnSelectionsModel, TableColumnExtend } from "../../models";
import { CallCenterActivityColumns } from "../../configs/AppJson/CallCenterActivity";
import moment from "moment";
import { ApiSchemaFilter } from "../../models/api/filter";
import { ApiSchemaCallCenterActivityData } from "../../models/api/CallCenterActivity";
import { Link } from "react-router-dom";
import { CallSummaryResponseModel } from "../../models/api/Call";
import { useFunctionalityCallSummary } from "../hooks";
import { AssetSvg } from "../../assets";

export function PageActivity() {
  const callCenterId = useAppSelector(
    (state) => state.auth.menuSettings?.callCenterSettings.callCenterId
  );
  const [show, setShow] = useState<boolean>(false);
  const [callSummaryData, setCallSummaryData] = useState<CallSummaryResponseModel>();
  const { LoadById } = useFunctionalityCallSummary();
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [columnSelection, setColumnSelection] = useState<ColumnSelectionsModel[]>(CallCenterActivityColumns);
  const [columnSelectionModalShow, setColumnSelectionModalShow] = React.useState(false);

  let initialFilterData: ApiSchemaFilter = {
    filterQueries: [],
    sortQuery: {},
  };
  const [filterData, setFilterData] = useState<ApiSchemaFilter>(initialFilterData);
  const [filterDataChange, setFilterDataChange] = useState<number>(0);

  const {
    pagedActivityList,
    pagedActivityListCount,
    pagedCallCenterActivityUrl,
  } = useFunctionalityCallCenterActivity({
    callCenterId: callCenterId || "",
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    setLoaderProgress,
    filterData: filterData,
    filterDataChange: filterDataChange,
  });

  useEffect(() => {
    if (pagedActivityListCount) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedActivityList]);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const handleShowCallSummary = (callId: string) => {
    if (callId) {
      setLoaderProgress(topLoaderProgress.start);
      LoadById(callId).then((data) => {
        if (data?.id) {
          setLoaderProgress(topLoaderProgress.complete);
          setCallSummaryData(data);
          setShow(true);
        }
      });
    }
  };

  const activityTableColumns: TableColumnExtend<ApiSchemaCallCenterActivityData>[] =
    [
      {
        name: <ColumnToolTip data="Name" />,
        sortable: true,
        sortField: "Actor.Name",
        selector: (row: ApiSchemaCallCenterActivityData) => {
          return (
            <Link to={AppRouteUi.Operator.Profile.Load(row.operatorId ?? "")}>
              <ColumnToolTip data={row.name ?? ""} />
            </Link>
          );
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Name")?.isEnabled ? false : true,
      },
      {
        name: <ColumnToolTip data="Email" />,
        selector: (row: ApiSchemaCallCenterActivityData) => {
          return <ColumnToolTip data={row.email ?? "-"} />;
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Email")?.isEnabled ? false : true,
      },
      {
        name: <ColumnToolTip data="Activity" />,
        selector: (row: ApiSchemaCallCenterActivityData) =>
          CallCenterActivityTypeOptions.find(
            (name: any) => name.value === row?.activity
          )?.label ?? "-",
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Action")?.isEnabled ? false : true,
      },
      {
        name: <ColumnToolTip data="Device" />,
        sortable: true,
        sortField: "DeviceOs",
        selector: (row: ApiSchemaCallCenterActivityData) => {
          return <ColumnToolTip data={row.device ?? "-"} />;
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Device")?.isEnabled ? false : true,
      },
      {
        name: <ColumnToolTip data="Date Time" />,
        sortable: true,
        sortField: "CreatedAt",
        selector: (row: ApiSchemaCallCenterActivityData) => {
          return (
            <ColumnToolTip
              data={
                row.dateTime
                  ? moment(row.dateTime).format("MMM DD, YYYY HH:mm:ss")
                  : "-"
              }
            />
          );
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "DateTime")?.isEnabled ? false : true,
      },
      {
        name: <ColumnToolTip data="Details" />,
        selector: (row: ApiSchemaCallCenterActivityData) => (
          <>
            {row.callId && (
              <div
                className="btn svg-color"
                title="Edit"
                onClick={() => handleShowCallSummary(row.callId ?? "")}
              >
                <span >
                  <AssetSvg.ViewProfile />
                </span>
              </div>
            )}
            {row.description && (
              <ColumnToolTip
                data={
                  row.description
                }
              />
            )}
          </>
        ),
      },
    ];
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        showExportOptions={true}
        showFilter={true}
        filterPanel={"commonAuditActivityFilterPanel"}
        pageBreadCrumbTile="Call Center"
        pageTitle="Activity"
        tableHeaders={activityTableColumns}
        tableData={pagedActivityList || []}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedActivityListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        columnSelectionModalShow={columnSelectionModalShow}
        setColumnSelectionModalShow={setColumnSelectionModalShow}
        columnSelection={columnSelection}
        setColumnSelection={setColumnSelection}
        setFilterDataForAnyTable={setFilterData}
        setFilterDataChangeForAnyTable={setFilterDataChange}
        pageName="activityPage"
        tableType={ExportTableType.CallCenterActivityLog}
        downloadedFileName={"CallCenterActivityLog Report"}
        callCenterId={callCenterId}
        filterData={filterData}
        setLoaderProgress={setLoaderProgress}
        showAutoRefresh={true}
        mutateUrl={pagedCallCenterActivityUrl}
        setIsShowCommonFilter={setIsShowCommonFilter}
        isShowCommonFilter={isShowCommonFilter}
      />
      <ModalCallSummary
        summary={callSummaryData}
        show={show}
        handleShow={setShow}
      />
    </>
  );
}
