import { useState } from "react";
import { Modal } from "react-bootstrap"
import { useForm } from 'react-hook-form';
import { FormErrorMessage } from "../../components";
import { InputFieldValidationErrorMessage } from "../../configs";
import { useAppSelector } from "../../core";
import { ApiSchemaCreateVendor } from "../../models";
import { useFunctionalityVendors } from "../../pages/hooks";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import useFunctionalityValidateUSAPhoneNumber from "../../pages/hooks/functionalityValidateUSAPhoneNumber";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  parentCompanyId: string;
  pagedVendorUrl: string;
}

export function ModalCreateVendor(props: Props): JSX.Element {
  const { parentCompanyId, handleShow, show, pagedVendorUrl } = props;
  const { profile } = useAppSelector((state) => state.auth);
  const { onAddVendor } = useFunctionalityVendors({ parentCompanyId: parentCompanyId ? parentCompanyId : profile?.companyId, handleShow: handleShow });
  const { register, handleSubmit, errors } = useForm<ApiSchemaCreateVendor>();
  const { useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();
  const { validateTeNumber, numberErrMsg } = useFunctionalityValidateUSAPhoneNumber();

  const onCreateHandler = (form: ApiSchemaCreateVendor) => {
    form = {
      ...form,
      name: form.name.trim(),
      parentCompanyId: parentCompanyId ? parentCompanyId : profile?.companyId!
    }
    onAddVendor(form)
  }
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      id="createVendorModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Vendor</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onCreateHandler)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  ref={register({ required: true, validate: useFunctionlityInputFieldValidation })}
                />
                {
                  errors.name?.type === "required" && (
                    <FormErrorMessage message="required" />
                  )
                }
                {errors.name &&
                  errors.name.type == "validate" && (
                    <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />
                  )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Phone Number"
                  ref={register({ required: true })}
                  onChange={(event) => validateTeNumber(event)}
                />
                {
                  errors.phoneNumber && (
                    <FormErrorMessage message="required" />
                  )
                }
                {errors.phoneNumber &&
                  errors.phoneNumber.type == "validate" && (
                    <FormErrorMessage message="Please enter a valid contact number" />
                  )}
                {numberErrMsg && <FormErrorMessage message={numberErrMsg} />}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-uppercase text-site"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase login-from-submit-btn"
            disabled={numberErrMsg ? true : false}
          >
            Create
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}