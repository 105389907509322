import { Modal, ModalBody } from "react-bootstrap";
import { useState } from "react";

export function ModalVideoConferenceLink(props: {
    show: boolean;
  roomId:string;
   
}): JSX.Element {
  const rootUrl = process.env.REACT_APP_CLIENT_ROOT || "";
  const[showModal,setShowModal]=useState<boolean>(props.show)
  const generateRoomLink = (roomId: string) => {
    return `${rootUrl}/lobby/${roomId}`;
  };
  return (
    <Modal show={showModal}  
      onHide={() => setShowModal(false)}
      centered>
      <Modal.Header closeButton >
        </Modal.Header>
      <ModalBody>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="" className="text-muted font-sz-12">
              Copy this link and save it so you can use it later, too.
            </label>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="" className="text-muted font-sz-12" onClick={() => {
              navigator.clipboard.writeText(generateRoomLink(props.roomId));
            }}>
              {generateRoomLink(props.roomId)}
            </label>
          </div>
        </div>
        <div
          className="form-row 
              justify-content-lg-end justify-content-xl-end justify-content-md-end justify-content-sm-center justify-content-center 
              flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
              mx-0"
        >
          <button
            className="  btn btn-outline-dark border-grey 
                    d-flex d-sm-flex d-md-inline-block d-lg-inline-block d-xl-inline-block 
                    flex-grow-1 flex-sm-grow-1 flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 
                    justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 "
            onClick={() => {
              navigator.clipboard.writeText(generateRoomLink(props.roomId));
              setShowModal(false)
            }}
          >
            Copy
          </button>

        </div>
      </ModalBody>
    </Modal>
  );
}
