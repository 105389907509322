import React from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { FooterButtons } from "..";
import { StepIndicatorTabs } from "../../..";
import { FormErrorMessage } from "../../../../components";
import { CompanyType, PaymentCycleType, StepNumber, SubscriptionType, } from "../../../../configs";
import { ActionCompany, useAppSelector } from "../../../../core";
import { Option, TypeCreateCompanyStep2 } from "../../../../models";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useFunctionalityInputChecker } from "../../../../pages/hooks/functionalityInputChecker";

type Props = {
  stepCounter: number;
  setStepCounter: (value: number) => void;
  handleShow: (value: boolean) => void;
  companyType?: CompanyType;
};

export function Step2CreateCompany(props: Props): JSX.Element {
  const { stepCounter, setStepCounter, handleShow, companyType } = props;
  const { register, handleSubmit, errors, setValue, control } = useForm<TypeCreateCompanyStep2>();
  const dispatch = useDispatch();
  const step2Data = useAppSelector((state) => state.company.companyStep2);
  const [panelToShow, setPanelToShow] = React.useState(step2Data?.type ?? SubscriptionType.Postpaid);

  const {
    numberFieldValidation,
    handleCopyPasteInvalidValue,
    handleLimitDecimalPlaces,
  } = useFunctionalityInputChecker();

  const paymentCycleList = () => {
    const paymentCycleArray: Option[] = [];
    for (let i = 1; i < Object.keys(PaymentCycleType).length / 2; i++) {
      paymentCycleArray.push({
        label: PaymentCycleType[i],
        value: i,
      });
    }
    return paymentCycleArray;
  };

  const onSubmitStep2 = (data: TypeCreateCompanyStep2) => {
    data.startDate = moment(data.startDate).format("YYYY-MM-DD");
    dispatch(ActionCompany.SetCompanyStep2(data));
    setStepCounter(stepCounter + 1);
  };

  const onError = (e: any) => { };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmitStep2, onError)}
        id="companyCreateForm"
      >
        <Modal.Body>
          <div className=" component-padding border border-gry">
            <div className="row">
              <div className="col-12">
                <StepIndicatorTabs step={stepCounter || StepNumber.firstStep} />

                {companyType == CompanyType.Customer ? (
                  <>
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="form-check form-check-inline mr-3">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="type"
                            ref={register({ required: true })}
                            // defaultValue={step2Data?.type}
                            value={SubscriptionType.Postpaid}
                            onClick={() =>
                              setPanelToShow(SubscriptionType.Postpaid)
                            }
                            // defaultChecked={step2Data?.type == SubscriptionType.Postpaid??true}
                            defaultChecked={
                              !step2Data?.type ||
                                step2Data?.type == SubscriptionType.Postpaid
                                ? true
                                : false
                            }
                          />
                          <label
                            htmlFor="postPaidradio"
                            className="form-check-label"
                          >
                            PostPaid{" "}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          {/* <input
                            type="radio"
                            className="form-check-input"
                            name="type"
                            // defaultValue={step2Data?.type}
                            ref={register()}
                            value={SubscriptionType.Prepaid}
                            onClick={() =>
                              setPanelToShow(SubscriptionType.Prepaid)
                            }
                            defaultChecked={
                              step2Data?.type == SubscriptionType.Prepaid
                            }
                          /> */}
                          {/* <label
                            htmlFor="prePaidradio"
                            className="form-check-label"
                          >
                            PrePaid{" "}
                          </label> */}
                        </div>
                      </div>
                    </div>

                    {panelToShow == SubscriptionType.Postpaid ? (
                      <div className="row postPaidOptions">
                        <div className="col-12">
                          <p className="mb-0">
                            The postpaid mobile phone is a mobile phone for
                            which service is provided by a prior arrangement
                            with a mobile network operator. The user in this
                            situation is billed after the fact according to
                            their use of mobile services at the end of each
                            month.
                          </p>
                        </div>
                        <div className="col-12">
                          <div className="my-3">
                            <div className="form-group">
                              <div className="row">
                                <div className="col">
                                  <label
                                    htmlFor=""
                                    className="position-relative required"
                                  >
                                    Start Date
                                  </label>

                                  <Controller
                                    defaultValue={
                                      moment(step2Data?.startDate).toDate() ??
                                      ""
                                    }
                                    name="startDate"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ onChange, value, ref }) => (
                                      <DatePicker
                                        // minDate={moment().toDate()}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Start date"
                                        className="form-control"
                                        selected={value}
                                        onChange={onChange}
                                        showYearDropdown
                                        showMonthDropdown
                                        dropdownMode="select"
                                      />
                                    )}
                                  />
                                </div>
                                <div className="col">
                                  <label
                                    htmlFor=""
                                    className="position-relative required"
                                  >
                                    Payment Cycle
                                  </label>
                                  <input
                                    className="d-none"
                                    name="paymentCycle"
                                    defaultValue={
                                      step2Data?.paymentCycle ??
                                      paymentCycleList()[0].value
                                    }
                                    ref={register({
                                      required:
                                        panelToShow == SubscriptionType.Postpaid
                                          ? true
                                          : false,
                                    })}
                                  />
                                  <Select
                                    className={` ${errors.paymentCycle?.type == "required"
                                      ? "field-required"
                                      : ""
                                      } `}
                                    defaultValue={
                                      step2Data?.paymentCycle
                                        ? paymentCycleList().find(
                                          (x) =>
                                            x.value == step2Data.paymentCycle
                                        )
                                        : paymentCycleList()[0]
                                    }
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    options={paymentCycleList()}
                                    onChange={(value) => {
                                      setValue("paymentCycle", value?.value, {
                                        shouldValidate: true,
                                      });
                                    }}
                                  />
                                  {errors.paymentCycle && (
                                    <FormErrorMessage message="required" />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="subscriptionRate"
                                className="position-relative required"
                              >
                                Subscription rate
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="subscriptionRateValue"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  type="float"
                                  name="subscriptionRate"
                                  placeholder="Subscription Rate"
                                  defaultValue={
                                    step2Data?.type == SubscriptionType.Postpaid
                                      ? step2Data?.subscriptionRate
                                      : ""
                                  }
                                  ref={register({
                                    required:
                                      panelToShow == SubscriptionType.Postpaid
                                        ? true
                                        : false,
                                    validate: handleCopyPasteInvalidValue,
                                  })}
                                  className="form-control"
                                  id="subscriptionRate"
                                  aria-describedby="subscriptionRateValue"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]|\./.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onKeyDown={(evt) =>
                                    numberFieldValidation(evt)
                                  }
                                  onInput={(event) =>
                                    handleLimitDecimalPlaces(event, 2)
                                  }
                                />
                              </div>
                              {errors.subscriptionRate &&
                                errors.subscriptionRate.type === "required" && (
                                  <FormErrorMessage message="required" />
                                )}
                              {errors.subscriptionRate &&
                                errors.subscriptionRate.type === "validate" && (
                                  <FormErrorMessage message="Please enter a valid value" />
                                )}
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="creditLimit"
                                className="position-relative required"
                              >
                                Credit Limit
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="creditLimitValue"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  type="float"
                                  name="creditLimit"
                                  defaultValue={
                                    step2Data?.type == SubscriptionType.Postpaid
                                      ? step2Data?.creditLimit
                                      : 100
                                  }
                                  ref={register({
                                    required:
                                      companyType == CompanyType.Customer &&
                                        panelToShow == SubscriptionType.Postpaid
                                        ? true
                                        : false,
                                    validate: handleCopyPasteInvalidValue,
                                  })}
                                  className="form-control"
                                  id="creditLimit"
                                  aria-describedby="creditLimitValue"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]|\./.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onKeyDown={(evt) =>
                                    numberFieldValidation(evt)
                                  }
                                  onInput={(event) =>
                                    handleLimitDecimalPlaces(event, 2)
                                  }
                                />
                              </div>
                              {errors.creditLimit &&
                                errors.creditLimit.type === "required" && (
                                  <FormErrorMessage message="required" />
                                )}
                              {errors.creditLimit &&
                                errors.creditLimit.type === "validate" && (
                                  <FormErrorMessage message="Please enter a valid value" />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : panelToShow == SubscriptionType.Prepaid ? (
                      <div className="row prePaidOptions">
                        <div className="col-12">
                          <p className="mb-0">PrePaid Package Description</p>
                        </div>
                        <div className="col-12">
                          <div className="my-3">
                            <div className="form-group">
                              <label
                                htmlFor="creditLimit"
                                className="position-relative required"
                              >
                                Credit Limit
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="creditLimitValue"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  type="float"
                                  className="form-control"
                                  id="creditLimit"
                                  aria-describedby="creditLimitValue"
                                  name="creditLimit"
                                  defaultValue={
                                    step2Data?.type == SubscriptionType.Prepaid
                                      ? step2Data?.creditLimit
                                      : 100
                                  }
                                  ref={register({
                                    required:
                                      companyType == CompanyType.Customer &&
                                        panelToShow == SubscriptionType.Prepaid
                                        ? true
                                        : false,
                                    validate: handleCopyPasteInvalidValue,
                                  })}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]|\./.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onKeyDown={(evt) =>
                                    numberFieldValidation(evt)
                                  }
                                  onInput={(event) =>
                                    handleLimitDecimalPlaces(event, 2)
                                  }
                                />
                              </div>
                              {errors.creditLimit &&
                                errors.creditLimit.type === "required" && (
                                  <FormErrorMessage message="required" />
                                )}

                              {errors.creditLimit &&
                                errors.creditLimit.type === "validate" && (
                                  <FormErrorMessage message="Please enter a valid value" />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div className="row postPaidOptions">
                    <div className="col-12">
                      <div className="my-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col">
                              <input
                                hidden={true}
                                className="form-check-input"
                                name="type"
                                ref={register({ required: true })}
                                defaultValue={SubscriptionType.Postpaid}
                                value={SubscriptionType.Postpaid}
                                onClick={() =>
                                  setPanelToShow(SubscriptionType.Postpaid)
                                }
                                defaultChecked={
                                  step2Data?.type == SubscriptionType.Postpaid
                                }
                              />
                              <label
                                htmlFor=""
                                className="position-relative required"
                              >
                                Start Date
                              </label>

                              <Controller
                                defaultValue={
                                  moment(step2Data?.startDate).toDate() ?? ""
                                }
                                name="startDate"
                                control={control}
                                rules={{ required: true }}
                                render={({ onChange, value, ref }) => (
                                  <DatePicker
                                    // minDate={moment().toDate()}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Start date"
                                    className="form-control"
                                    selected={value}
                                    onChange={onChange}
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                  />
                                )}
                              />
                              {errors.startDate && (
                                <FormErrorMessage message="required" />
                              )}
                            </div>
                            <div className="col">
                              <label
                                htmlFor=""
                                className="position-relative required"
                              >
                                Payment Cycle
                              </label>
                              <input
                                className="d-none"
                                name="paymentCycle"
                                // defaultValue={step2Data?.paymentCycle ? PaymentCycleType[step2Data.paymentCycle] : 0}
                                defaultValue={
                                  step2Data?.paymentCycle ??
                                  paymentCycleList()[0].value
                                }
                                ref={register({
                                  required:
                                    panelToShow == SubscriptionType.Postpaid
                                      ? true
                                      : false,
                                })}
                              />
                              <Select
                                className={` ${errors.paymentCycle?.type == "required"
                                  ? "field-required"
                                  : ""
                                  } `}
                                defaultValue={
                                  step2Data?.paymentCycle
                                    ? paymentCycleList().find(
                                      (x) => x.value == step2Data.paymentCycle
                                    )
                                    : paymentCycleList()[0]
                                }
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                options={paymentCycleList()}
                                onChange={(value) => {
                                  setValue("paymentCycle", value?.value, {
                                    shouldValidate: true,
                                  });
                                }}
                              />
                              {errors.paymentCycle && (
                                <FormErrorMessage message="required" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="subscriptionRate"
                            className="position-relative required"
                          >
                            Subscription rate
                          </label>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="subscriptionRateValue"
                              >
                                $
                              </span>
                            </div>
                            <input
                              type="float"
                              name="subscriptionRate"
                              placeholder="Subscription Rate"
                              defaultValue={step2Data?.subscriptionRate ?? ""}
                              ref={register({
                                required:
                                  panelToShow == SubscriptionType.Postpaid
                                    ? true
                                    : false,
                                validate: handleCopyPasteInvalidValue,
                              })}
                              className="form-control"
                              id="subscriptionRate"
                              aria-describedby="subscriptionRateValue"
                              onKeyPress={(event) => {
                                if (!/[0-9]|\./.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onKeyDown={(evt) => numberFieldValidation(evt)}
                            />
                          </div>
                          {errors.subscriptionRate &&
                            errors.subscriptionRate.type === "required" && (
                              <FormErrorMessage message="required" />
                            )}
                          {errors.subscriptionRate &&
                            errors.subscriptionRate.type === "validate" && (
                              <FormErrorMessage message="Please enter a valid value" />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <FooterButtons
            counter={stepCounter || StepNumber.firstStep}
            setCounter={setStepCounter}
            handleShow={handleShow}
          />
        </Modal.Footer>
      </form>
    </>
  );
}
