import { LocalVideoTrack, RemoteVideoTrack } from "twilio-video";
import { TypeCreateCompanyStep1, TypeCreateCompanyStep5 } from "../models";

export const AppConstants = {
  Api: {
    StatusOK: 200,
  },
  UserRoles: {
    Admin: "BABEL_ADMIN",
    Finance: "BABEL_FINANCE",
    Operator: "BABEL_OPERATOR",
    Manager: "BABEL_MANAGER",

    ResellerAdmin: "BABEL_RESELLER_ADMIN",
    ResellerFinance: "BABEL_RESELLER_FINANCE",
    ResellerManager: "BABEL_RESELLER_MANAGER",

    CompanyAdmin: "BABEL_COMPANY_ADMIN",
    CompanyFinance: "BABEL_COMPANY_FINANCE",
    User: "BABEL_COMPANY_USER",

    Consumer: "EXTERNAL_USER",
  },
  GenericUserRole: {
    Admin: "Administrator",
    Finance: "Finance",
    User: "User",
    Manager: "Manager",
  },
  CompanyUserSetRole: {
    Administrator: "BABEL_COMPANY_ADMIN",
    Finance: "BABEL_COMPANY_FINANCE",
    User: "BABEL_COMPANY_USER",
  },
  ResellerUserSetRole: {
    Administrator: "BABEL_RESELLER_ADMIN",
    Finance: "BABEL_RESELLER_FINANCE",
    Manager: "BABEL_RESELLER_MANAGER",
  },
  BabelUserSetRole: {
    Administrator: "BABEL_ADMIN",
    Finance: "BABEL_FINANCE",
    Manager: "BABEL_MANAGER",
  },
  WebSocket: {
    reconnectDelay: 1000,
    pingDelay: 5000,
    pongDelay: 5000,
  },
};

export const ApplicationName = "Gateway to Access";

export const InvoiceItemCodeConstants = {
  callLogItem: "100",
  subscriptionItem: "101",
  lateFeeItem: "102",
  otherItem: "104",
  overFlowCallLogItem: "105",
  inFlowCallLogItem: "106",
  operatorEarnings: "107",
};
export const ExternalLoginConstants = {
  LoginProviders: {
    Google: "Google",
  },
  Google: {
    ClientId:
      "912720247655-n0vms9qebb2863q2u5e94i006hh9d2mo.apps.googleusercontent.com",
  },
};

export const Modals = {
  AddParticipant: "addToCallModal",
  ScreenShare: "screenShareModal",
  CalendarUpcomingScheduleModal: "calendarUpcomingScheduleModal",
  InfoModal: "infoModal",
  LobbyAudioCallData: "lobbyAudioCallData",
  CreateScheduleModal: "createScheduleModal",
};

export const TwilioNumber = {
  ivrNumberForVideoConference: "+1 313 362 8545",
};

export enum CompanyType {
  Provider = 1,
  Reseller = 2,
  Customer = 3,
}

export enum ConversationType {
  OneOnOne = 1,
  Group,
}

export enum SubscriptionType {
  Postpaid = 1,
  Prepaid,
}
export enum SubscriptionValue {
  Postpaid = "Post Paid",
  Prepaid = "Pre Paid",
}

export enum PaymentCycleType {
  Monthly = 1,
  //Yearly,
  Daily = 3,
}

export enum PlatformType {
  All = 0,
  VRI = 1,
  OPI = 2,
  OnSite = 3,
}

export enum PlatformType2 {
  VRI = 1,
  OPI = 2,
  OnSite = 3,
  TravelTime,
}

export enum StepNumber {
  firstStep,
  secondStep,
  thirdStep,
  fourthStep,
}

export enum OperatorCriteriaType {
  Language = 1,
  Skill,
  MedicalHistory,
}

export const ConferenceCallSettingCode = "Video Conference";
export const MaxCallRetryCount = 3;

export enum topLoaderProgress {
  start = 70,
  complete = 100,
}

export enum PaginationSettings {
  initialPageSize = 10,
  initialPageSizeForBooking = 50,
  initialPageIndex = 1,
}

export enum TinModalHeader {
  createTin = "Create TIN",
  userTin = "User TIN",
}
export enum CriteriaType {
  Language = 1,
  Skill,
  Gender,
}
export enum AccountType {
  Company = 1,
  Operator = 2,
}

export enum TransactionType {
  CustomerBilling = 1,
  OperatorBilling = 2,
}

export enum CallStatusType {
  Zero = 0,
  Initiated = 1,
  Ringing = 2,
  Missed = 3,
  Rejected = 4,
  Received = 5,
  Established = 6,
  Failed = 7,
  Completed = 8,
  Canceled = 9,
  Abort = 10,
  ParticipantInvited = 11,
  ParticipantRejected = 12,
  ParticipantMissed = 13,
  ParticipantJoined = 14,
  ParticipantLeft = 15,
  PreparingCallSummary = 16,
  IvrCustomFieldsUpdate = 17,
  OutboundCallReceived = 18,
  OutboundCallCompleted = 19,
  ForceLogout = 20,
  OutboundCallInitiated = 21,
  OutboundCallRinging = 22,
  OutboundCallNotAnswered = 23,
  OutboundCallNotReceived = 24,
  Searching = 25,
  OperatorNotFound = 26,
  UserNotFound = 27,
}
export enum DeviceType {
  Computer = 1,
  Mobile,
  Tablet,
  GameConsole,
  DigitalMediaReceiver,
  WearableComputer,
  Unknown,
}
export enum GenderType {
  Any = 0,
  Male = 1,
  Female = 2,
}
export enum SupportType {
  DirectCall = 1,
  OnDemand,
  PreScheduled,
}
export enum ExtendedCriteriaType {
  Language = 1,
  Skill,
  Gender,
  CustomField1,
  CustomField2,
  CustomField3,
  CustomField4,
  Department,
  Location,
}

export interface CallInitiateParam {
  Language: string;
  Skill: string;
  Gender: string;
  CustomField1: string;
  CustomField2: string;
  CustomField3: string;
  CustomField4: string;
  Department: string;
  Location: string;
}

export enum CallType {
  None,
  Video,
  Audio,
  VideoConference,
  AudioConference,
  Acknowledge,
}
export enum CallParticipantAddStatusType {
  ParticipantInvited = 1,
  ParticipantJoined,
}

export enum CallLogType {
  InternalCall = 1,
  OverflowCall,
  InflowCall,
  OutflowCall,
  CallLog,
}

export enum ColumnSelectionTableType {
  InternalCall = 1,
  OverflowCall,
  InflowCall,
  OutflowCall,
  CallLog,
  Booking,
  Operator,
  CallCenterActivityLog,
}

export enum ExportTableType {
  InternalCall = 1,
  OverflowCall,
  InflowCall,
  OutflowCall,
  CallLog,
  Booking,
  Operator,
  CallCenterActivityLog,
  AuditLog,
  User,
}

export enum VideoViewType {
  GridView = 1,
  SpeakerView = 2,
}
// export const VideoParticipentStatus = {
//   Invited: "ParticipantInvited",
//   Rejected: "ParticipantRejected",
//   Missed: "ParticipantMissed",
//   Joined: "ParticipantJoined",
// };

export enum InputFieldType {
  InputBox = 1,
  DropDown,
}
export enum BookingCustomFieldType {
  BookingCustomField1 = 1,
  BookingCustomField2,
  BookingCustomField3,
  BookingCustomField4,
}
export enum BookingStatusType {
  //	/**
  //	 * New. A job that is newly created in the system by a Manager or by a Company
  //	 * user.
  //	 */
  ////	NEW,

  /**
   * Open. A job that was newly created that was opened by the service providers
   * but not accepted. (Current system)
   */
  Open = 1,

  /**
   * Offered. When a Manager manually offers a job toTime one or more service
   * providers and they haven’t accepted it yet. (Current System)
   */
  Offered,

  //	/**
  //	 * Assigned. When a job is manually assigned to a specific service provider by
  //	 * the Managers. (Current system)
  //	 */
  //	ASSIGNED,

  /**
   * Confirmed. When a service provider accepts a job.
   */
  Confirmed,

  Billed,

  Paid,

  //FIXME: this can be removed or automated.

  /**
   * Closed. When a job is completed.
   */
  Closed,

  /**
   * Cancelled. When a job is Cancelled, by the Customer or by the service
   * provider.
   */
  Cancelled,

  /**
   * Unfulfilled
   */
  Unfulfilled,

  /**
   * Nonattendance. When service providers fail toTime show up for the job in due
   * date, time and location.
   */
  NoShow,

  /**
   * Some kind of system error.
   */
  Error,
}

// export const CallStatus = {
//   Incoming: "audio call incoming",
//   Received: "audio call received",
//   Ringing: "audio call ringing",
//   Canceled: "audio call canceled",
//   Missed: "audio call missed",
//   Rejected: "audio call rejected",
//   Initiated: "audio call incoming",
//   Searching: "audio call searching",
//   OperatorNotFound: "operator not found",
//   ErrorOccurred: "audio call error",
// };
// export const CallStatus = {
//   Incoming: "video call incoming",
//   Received: "video call received",
//   Ringing: "video call ringing",
//   Canceled: "video call canceled",
//   Missed: "video call missed",
//   Rejected: "video call rejected",
//   Initiated: "video call incoming",
//   Searching: "video call searching",
//   OperatorNotFound: "operator not found",
//   ErrorOccurred: "video call error",
//   Established: "video call established",
// };
export const CallStatus = {
  Initiated: "Initiated",
  Received: "Received",
  Ringing: "Ringing",
  Canceled: "Canceled",
  Missed: "Missed",
  Rejected: "Rejected",
  Searching: "Searching",
  OperatorNotFound: "Operator not found",
  ErrorOccurred: "Error Occured",
  Established: "Established",
  Completed: "Completed",
  NotFound:
    "The user you have called is not available at the moment. Please try again later",
};
export const InfoBoxTitle = {
  Confirmed: "Confirmed",
  Closed: "Closed",
  Offered: "Offered",
  Unfulfilled: "Unfulfilled",
  Paid: "Paid",
  Due: "Due",
  Total: "Total",
  TotalOnline: "Total Online",
  TotalIncall: "Total Incall",
  TotalOperators: "Total Operators",
  Open: "Open",
  Cancelled: "Cancelled",
  Billed: "Billed",
  NoShow: "NoShow",
};
export const CallInitiateTabs = {
  searchInterpreter: "Search Interpreter",
  directCall: "Direct Call",
};

export const SocketStatus = {
  logout: "logout",
};

export const SocketConstants = {
  onOutGoing: "OnOutGoing",
  onAcknowledged: "OnAcknowledged",
  onIncoming: "OnIncoming",
  onReceived: "OnReceived",
  forceLogout: "ForceLogout",
  online: "Online",
  offline: "Offline",
};
export enum DefaultImageType {
  SideNavProfile = 1,
  UserProfile,
  CompanyProfile,
  DefaultProfile,
  DefaultCompanyProfile,
  DefaultChat,
}

export enum AllocationFlowType {
  InternalPool = 1,
  SharedPool = 2,
}
export enum BookingFlowType {
  Manual = 1,
  DirectAssignment = 2,
  OfferConfirm = 3,
}

export const PlatformTypeConstant = {
  OnSite: "Face to Face",
  VRI: "Video",
  OPI: "Phone",
};

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;
export type RoomType = "group" | "group-small" | "peer-to-peer" | "go";

export type RecordingRule = {
  type: "include" | "exclude";
  all?: boolean;
  kind?: "audio" | "video";
  publisher?: string;
};

export type RecordingRules = RecordingRule[];

export const SELECTED_AUDIO_INPUT_KEY = "TwilioVideoApp-selectedAudioInput";
export const SELECTED_AUDIO_OUTPUT_KEY = "TwilioVideoApp-selectedAudioOutput";
export const SELECTED_VIDEO_INPUT_KEY = "TwilioVideoApp-selectedVideoInput";

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY =
  "TwilioVideoApp-selectedBackgroundSettings";

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};
export const DefaultCountry = {
  USA: "bc281336-9f3b-4f3e-b94a-2eacbee56cb9",
};

export const ImageSupportedType = {
  JPG: "image/jpg",
  JPEG: "image/jpeg",
  PNG: "image/png",
  SVG: "image/svg+xml",
  GIF: "image/gif",
};

export const AudioSupportedType = {
  MP3: "audio/mpeg",
  WAV: "audio/wav",
};

export const CreateCompanyType = {
  Reseller: "Add Reseller",
  Company: "Create Company",
};

export const DefaultStep5Data: TypeCreateCompanyStep5 = {
  firstName: "",
  lastName: "",
  contactNumber: "",
  email: "",
  retypeEmail: "",
  userRole: "",
  userType: "",
};

export const DefaultStep1Data: TypeCreateCompanyStep1 = {
  name: "",
  code: "",
  address: "",
  country: "",
  countryCode: "",
  state: "",
  stateCode: "",
  city: "",
  zipCode: "",
};

export enum NotificationType {
  Mail = 1,
  PhoneNumber,
}
export enum SocketNotificationType {
  Call = 1,
  Booking,
  VideoConference,
  OutBoundCall = 4,
  UserPresence = 5,
  Pong
}
export enum InvoiceUnit {
  InSeconds = 0,
  Quantity = 1,
}

export enum InvoiceStatus {
  PAID = "PAID",
  DUE = "DUE",
  CANCELED = "CANCELED",
}
export enum InvoiceType {
  Company = 1,
  Operator = 2,
  Customer = 3,
  CallCenter = 4,
  AllOperator = 5,
  All = 6,
  CompanyBooking = 7,
  OperatorBooking = 8,
}
export enum InvoiceGenerationType {
  Company = 1,
  Reseller,
  Operator,
  CompanyBooking,
  OperatorBooking
}
export enum BookingNotificationType {
  OfferBooking = 1,
  OperatorConfirmed,
  OperatorAssigned,
  Updated,
  BookingStarted,
  BookingClosed = "BookingClosed",
}

export const BookingStatusColor = {
  Open: "#800080",
  Offered: "#01c4cd",
  Confirmed: "#008000",
  Billed: "#b16600",
  Paid: "#0000ff",
  Closed: "#808080",
  Cancelled: "#ff0000",
  Unfulfilled: "#ffc0cb",
  NoShow: "#ffc107",
  Error: "#ff0000",
};

export enum ChatType {
  Recent = 1,
  AllContact = 2,
  Group = 3,
}

export enum AuditLogActivityType {
  AddUser = 1,
  DeleteUser,
  AddCompany,
  DeleteCompany,
  MakePaymentToOperator,
  GetPaymentFromCompany,
  AddOperator,
  DeleteOperator,
}
export enum CallCenterActivityType {
  Online = 1,
  Offline,
  CallReceived,
  CallMissed,
  CallIgnored,
  CallEnded,
  Login,
  Logout,
  ParticipantJoined,
  ParticipantLeft,
  Busy,
  NotifiedForForceLogout,
  ForceOffline,
}
export const CallCenterActivityTypeOptions = [
  { value: CallCenterActivityType.Online, label: "Online" },
  { value: CallCenterActivityType.Offline, label: "Offline" },
  { value: CallCenterActivityType.CallReceived, label: "Call received" },
  { value: CallCenterActivityType.CallMissed, label: "Call missed" },
  { value: CallCenterActivityType.CallIgnored, label: "Call ignored" },
  { value: CallCenterActivityType.CallEnded, label: "Call ended" },
  { value: CallCenterActivityType.Login, label: "Logged in" },
  { value: CallCenterActivityType.Logout, label: "Logged out" },
  {
    value: CallCenterActivityType.ParticipantJoined,
    label: "Participant joined",
  },
  { value: CallCenterActivityType.ParticipantLeft, label: "Participant left" },
  { value: CallCenterActivityType.Busy, label: "Busy" },
  { value: CallCenterActivityType.NotifiedForForceLogout, label: "Notified for force logout" },
  { value: CallCenterActivityType.ForceOffline, label: "Force Offline" },
];

export const AuditLogActivityTypeOptions = [
  { value: AuditLogActivityType.AddUser, label: "User Added" },
  { value: AuditLogActivityType.AddCompany, label: "Company Added" },
  { value: AuditLogActivityType.AddOperator, label: "Operator  Added" },
  { value: AuditLogActivityType.DeleteCompany, label: "Company Deleted" },
  { value: AuditLogActivityType.DeleteOperator, label: "Operator Deleted" },
  { value: AuditLogActivityType.DeleteUser, label: "User Deleted" },
  {
    value: AuditLogActivityType.GetPaymentFromCompany,
    label: "Company Payment Done",
  },
  {
    value: AuditLogActivityType.MakePaymentToOperator,
    label: "Operator Payment Done",
  },
];

export const BookingSelectOptions = [
  { value: 0, label: "Any" },
  { value: BookingStatusType.Open, label: "Open" },
  { value: BookingStatusType.Offered, label: "Offered" },
  { value: BookingStatusType.Confirmed, label: "Confirmed" },
  { value: BookingStatusType.Billed, label: "Billed" },
  { value: BookingStatusType.Paid, label: "Paid" },
  { value: BookingStatusType.Closed, label: "Closed" },
  { value: BookingStatusType.Cancelled, label: "Cancelled" },
  { value: BookingStatusType.Unfulfilled, label: "Unfulfilled" },
  { value: BookingStatusType.NoShow, label: "NoShow" },
  { value: BookingStatusType.Error, label: "Error" },
];
export enum UserLoginStatusType {
  Active = 1,
  InActive = 2,
  Pending = 3,
  UserNotFound = 4,
  UserPasswordNotMatch = 5,
  UserAlreadyInCall = 6,
}
export const modifiedTimeZones = {
  "Pacific/Midway": "(UTC-11:00) Pacific, Midway",
  "Pacific/Niue": "(UTC-11:00) Pacific, Niue",
  "Pacific/Pago_Pago": "(UTC-11:00) Pacific, Pago-Pago",
  "Pacific/Samoa": "(UTC-11:00) Pacific, Samoa",
  "US/Samoa": "(UTC-11:00) US, Samoa",
  "America/Adak": "(UTC-09:00) America, Adak",
  "America/Atka": "(UTC-09:00) America, Atka",
  "Pacific/Honolulu": "(UTC-10:00) Pacific, Honolulu",
  "Pacific/Johnston": "(UTC-10:00) Pacific, Johnston",
  "Pacific/Rarotonga": "(UTC-10:00) Pacific, Rarotonga",
  "Pacific/Tahiti": "(UTC-10:00) Pacific, Tahiti",
  "SystemV/HST10": "(UTC-10:00) SystemV, HST10",
  "US/Aleutian": "(UTC-09:00) US, Aleutian",
  "US/Hawaii": "(UTC-10:00) US, Hawaii",
  HST: "(UTC-10:00) HST",
  "Pacific/Marquesas": "(UTC-09:30) Pacific, Marquesas",
  "America/Anchorage": "(UTC-08:00) America, Anchorage",
  "America/Juneau": "(UTC-08:00) America, Juneau",
  "America/Metlakatla": "(UTC-08:00) America, Metlakatla",
  "America/Nome": "(UTC-08:00) America, Nome",
  "America/Sitka": "(UTC-08:00) America, Sitka",
  "America/Yakutat": "(UTC-08:00) America, Yakutat",
  "Pacific/Gambier": "(UTC-09:00) Pacific, Gambier",
  "SystemV/YST9": "(UTC-09:00) SystemV, YST9",
  "SystemV/YST9YDT": "(UTC-09:00) SystemV, YST9YDT",
  "US/Alaska": "(UTC-08:00) US, Alaska",
  AST: "(UTC-08:00) AST",
  "America/Dawson": "(UTC-07:00) America, Dawson",
  "America/Ensenada": "(UTC-07:00) America, Ensenada",
  "America/Los_Angeles": "(UTC-07:00) America, Los-Angeles",
  "America/Santa_Isabel": "(UTC-07:00) America, Santa-Isabel",
  "America/Tijuana": "(UTC-07:00) America, Tijuana",
  "America/Vancouver": "(UTC-07:00) America, Vancouver",
  "America/Whitehorse": "(UTC-07:00) America, Whitehorse",
  "Canada/Pacific": "(UTC-07:00) Canada, Pacific",
  "Canada/Yukon": "(UTC-07:00) Canada, Yukon",
  "Mexico/BajaNorte": "(UTC-07:00) Mexico, BajaNorte",
  PST8PDT: "(UTC-07:00) PST8PDT",
  "Pacific/Pitcairn": "(UTC-08:00) Pacific, Pitcairn",
  "SystemV/PST8": "(UTC-08:00) SystemV, PST8",
  "SystemV/PST8PDT": "(UTC-08:00) SystemV, PST8PDT",
  "US/Pacific": "(UTC-07:00) US, Pacific",
  "US/Pacific-New": "(UTC-07:00) US, Pacific-New",
  PST: "(UTC-07:00) PST",
  "America/Boise": "(UTC-06:00) America, Boise",
  "America/Cambridge_Bay": "(UTC-06:00) America, Cambridge-Bay",
  "America/Chihuahua": "(UTC-07:00) America, Chihuahua",
  "America/Creston": "(UTC-07:00) America, Creston",
  "America/Dawson_Creek": "(UTC-07:00) America, Dawson-Creek",
  "America/Denver": "(UTC-06:00) America, Denver",
  "America/Edmonton": "(UTC-06:00) America, Edmonton",
  "America/Fort_Nelson": "(UTC-07:00) America, Fort-Nelson",
  "America/Hermosillo": "(UTC-07:00) America, Hermosillo",
  "America/Inuvik": "(UTC-06:00) America, Inuvik",
  "America/Mazatlan": "(UTC-07:00) America, Mazatlan",
  "America/Ojinaga": "(UTC-06:00) America, Ojinaga",
  "America/Phoenix": "(UTC-07:00) America, Phoenix",
  "America/Shiprock": "(UTC-06:00) America, Shiprock",
  "America/Yellowknife": "(UTC-06:00) America, Yellowknife",
  "Canada/Mountain": "(UTC-06:00) Canada, Mountain",
  MST7MDT: "(UTC-06:00) MST7MDT",
  "Mexico/BajaSur": "(UTC-07:00) Mexico, BajaSur",
  Navajo: "(UTC-06:00) Navajo",
  "SystemV/MST7": "(UTC-07:00) SystemV, MST7",
  "SystemV/MST7MDT": "(UTC-07:00) SystemV, MST7MDT",
  "US/Arizona": "(UTC-07:00) US, Arizona",
  "US/Mountain": "(UTC-06:00) US, Mountain",
  MST: "(UTC-07:00) MST",
  PNT: "(UTC-07:00) PNT",
  "America/Bahia_Banderas": "(UTC-06:00) America, Bahia-Banderas",
  "America/Belize": "(UTC-06:00) America, Belize",
  "America/Chicago": "(UTC-05:00) America, Chicago",
  "America/Costa_Rica": "(UTC-06:00) America, Costa-Rica",
  "America/El_Salvador": "(UTC-06:00) America, El-Salvador",
  "America/Guatemala": "(UTC-06:00) America, Guatemala",
  "America/Indiana/Knox": "(UTC-05:00) America, Indiana, Knox",
  "America/Indiana/Tell_City": "(UTC-05:00) America, Indiana, Tell-City",
  "America/Knox_IN": "(UTC-05:00) America, Knox-IN",
  "America/Managua": "(UTC-06:00) America, Managua",
  "America/Matamoros": "(UTC-05:00) America, Matamoros",
  "America/Menominee": "(UTC-05:00) America, Menominee",
  "America/Merida": "(UTC-06:00) America, Merida",
  "America/Mexico_City": "(UTC-06:00) America, Mexico-City",
  "America/Monterrey": "(UTC-06:00) America, Monterrey",
  "America/North_Dakota/Beulah": "(UTC-05:00) America, North-Dakota, Beulah",
  "America/North_Dakota/Center": "(UTC-05:00) America, North-Dakota, Center",
  "America/North_Dakota/New_Salem":
    "(UTC-05:00) America, North-Dakota, New-Salem",
  "America/Rainy_River": "(UTC-05:00) America, Rainy-River",
  "America/Rankin_Inlet": "(UTC-05:00) America, Rankin-Inlet",
  "America/Regina": "(UTC-06:00) America, Regina",
  "America/Resolute": "(UTC-05:00) America, Resolute",
  "America/Swift_Current": "(UTC-06:00) America, Swift-Current",
  "America/Tegucigalpa": "(UTC-06:00) America, Tegucigalpa",
  "America/Winnipeg": "(UTC-05:00) America, Winnipeg",
  CST6CDT: "(UTC-05:00) CST6CDT",
  "Canada/Central": "(UTC-05:00) Canada, Central",
  "Canada/Saskatchewan": "(UTC-06:00) Canada, Saskatchewan",
  "Chile/EasterIsland": "(UTC-05:00) Chile, EasterIsland",
  "Mexico/General": "(UTC-06:00) Mexico, General",
  "Pacific/Easter": "(UTC-05:00) Pacific, Easter",
  "Pacific/Galapagos": "(UTC-06:00) Pacific, Galapagos",
  "SystemV/CST6": "(UTC-06:00) SystemV, CST6",
  "SystemV/CST6CDT": "(UTC-06:00) SystemV, CST6CDT",
  "US/Central": "(UTC-05:00) US, Central",
  "US/Indiana-Starke": "(UTC-05:00) US, Indiana-Starke",
  CST: "(UTC-05:00) CST",
  "America/Atikokan": "(UTC-05:00) America, Atikokan",
  "America/Bogota": "(UTC-05:00) America, Bogota",
  "America/Cancun": "(UTC-05:00) America, Cancun",
  "America/Cayman": "(UTC-05:00) America, Cayman",
  "America/Coral_Harbour": "(UTC-05:00) America, Coral-Harbour",
  "America/Detroit": "(UTC-04:00) America, Detroit",
  "America/Eirunepe": "(UTC-05:00) America, Eirunepe",
  "America/Fort_Wayne": "(UTC-04:00) America, Fort-Wayne",
  "America/Grand_Turk": "(UTC-04:00) America, Grand-Turk",
  "America/Guayaquil": "(UTC-05:00) America, Guayaquil",
  "America/Havana": "(UTC-04:00) America, Havana",
  "America/Indiana/Indianapolis": "(UTC-04:00) America, Indiana, Indianapolis",
  "America/Indiana/Marengo": "(UTC-04:00) America, Indiana, Marengo",
  "America/Indiana/Petersburg": "(UTC-04:00) America, Indiana, Petersburg",
  "America/Indiana/Vevay": "(UTC-04:00) America, Indiana, Vevay",
  "America/Indiana/Vincennes": "(UTC-04:00) America, Indiana, Vincennes",
  "America/Indiana/Winamac": "(UTC-04:00) America, Indiana, Winamac",
  "America/Indianapolis": "(UTC-04:00) America, Indianapolis",
  "America/Iqaluit": "(UTC-04:00) America, Iqaluit",
  "America/Jamaica": "(UTC-05:00) America, Jamaica",
  "America/Kentucky/Louisville": "(UTC-04:00) America, Kentucky, Louisville",
  "America/Kentucky/Monticello": "(UTC-04:00) America, Kentucky, Monticello",
  "America/Lima": "(UTC-05:00) America, Lima",
  "America/Louisville": "(UTC-04:00) America, Louisville",
  "America/Montreal": "(UTC-04:00) America, Montreal",
  "America/Nassau": "(UTC-04:00) America, Nassau",
  "America/New_York": "(UTC-04:00) America, New-York",
  "America/Nipigon": "(UTC-04:00) America, Nipigon",
  "America/Panama": "(UTC-05:00) America, Panama",
  "America/Pangnirtung": "(UTC-04:00) America, Pangnirtung",
  "America/Port-au-Prince": "(UTC-04:00) America, Port-au-Prince",
  "America/Porto_Acre": "(UTC-05:00) America, Porto-Acre",
  "America/Rio_Branco": "(UTC-05:00) America, Rio-Branco",
  "America/Thunder_Bay": "(UTC-04:00) America, Thunder-Bay",
  "America/Toronto": "(UTC-04:00) America, Toronto",
  "Brazil/Acre": "(UTC-05:00) Brazil, Acre",
  "Canada/Eastern": "(UTC-04:00) Canada, Eastern",
  Cuba: "(UTC-04:00) Cuba",
  EST5EDT: "(UTC-04:00) EST5EDT",
  Jamaica: "(UTC-05:00) Jamaica",
  "SystemV/EST5": "(UTC-05:00) SystemV, EST5",
  "SystemV/EST5EDT": "(UTC-05:00) SystemV, EST5EDT",
  "US/East-Indiana": "(UTC-04:00) US, East-Indiana",
  "US/Eastern": "(UTC-04:00) US, Eastern",
  "US/Michigan": "(UTC-04:00) US, Michigan",
  EST: "(UTC-05:00) EST",
  IET: "(UTC-04:00) IET",
  "America/Anguilla": "(UTC-04:00) America, Anguilla",
  "America/Antigua": "(UTC-04:00) America, Antigua",
  "America/Aruba": "(UTC-04:00) America, Aruba",
  "America/Asuncion": "(UTC-03:00) America, Asuncion",
  "America/Barbados": "(UTC-04:00) America, Barbados",
  "America/Blanc-Sablon": "(UTC-04:00) America, Blanc-Sablon",
  "America/Boa_Vista": "(UTC-04:00) America, Boa-Vista",
  "America/Campo_Grande": "(UTC-04:00) America, Campo-Grande",
  "America/Caracas": "(UTC-04:00) America, Caracas",
  "America/Cuiaba": "(UTC-04:00) America, Cuiaba",
  "America/Curacao": "(UTC-04:00) America, Curacao",
  "America/Dominica": "(UTC-04:00) America, Dominica",
  "America/Glace_Bay": "(UTC-03:00) America, Glace-Bay",
  "America/Goose_Bay": "(UTC-03:00) America, Goose-Bay",
  "America/Grenada": "(UTC-04:00) America, Grenada",
  "America/Guadeloupe": "(UTC-04:00) America, Guadeloupe",
  "America/Guyana": "(UTC-04:00) America, Guyana",
  "America/Halifax": "(UTC-03:00) America, Halifax",
  "America/Kralendijk": "(UTC-04:00) America, Kralendijk",
  "America/La_Paz": "(UTC-04:00) America, La-Paz",
  "America/Lower_Princes": "(UTC-04:00) America, Lower-Princes",
  "America/Manaus": "(UTC-04:00) America, Manaus",
  "America/Marigot": "(UTC-04:00) America, Marigot",
  "America/Martinique": "(UTC-04:00) America, Martinique",
  "America/Moncton": "(UTC-03:00) America, Moncton",
  "America/Montserrat": "(UTC-04:00) America, Montserrat",
  "America/Port_of_Spain": "(UTC-04:00) America, Port-of-Spain",
  "America/Porto_Velho": "(UTC-04:00) America, Porto-Velho",
  "America/Puerto_Rico": "(UTC-04:00) America, Puerto-Rico",
  "America/Santiago": "(UTC-03:00) America, Santiago",
  "America/Santo_Domingo": "(UTC-04:00) America, Santo-Domingo",
  "America/St_Barthelemy": "(UTC-04:00) America, St-Barthelemy",
  "America/St_Kitts": "(UTC-04:00) America, St-Kitts",
  "America/St_Lucia": "(UTC-04:00) America, St-Lucia",
  "America/St_Thomas": "(UTC-04:00) America, St-Thomas",
  "America/St_Vincent": "(UTC-04:00) America, St-Vincent",
  "America/Thule": "(UTC-03:00) America, Thule",
  "America/Tortola": "(UTC-04:00) America, Tortola",
  "America/Virgin": "(UTC-04:00) America, Virgin",
  "Atlantic/Bermuda": "(UTC-03:00) Atlantic, Bermuda",
  "Brazil/West": "(UTC-04:00) Brazil, West",
  "Canada/Atlantic": "(UTC-03:00) Canada, Atlantic",
  "Chile/Continental": "(UTC-03:00) Chile, Continental",
  "SystemV/AST4": "(UTC-04:00) SystemV, AST4",
  "SystemV/AST4ADT": "(UTC-04:00) SystemV, AST4ADT",
  PRT: "(UTC-04:00) PRT",
  "America/St_Johns": "(UTC-02:30) America, St-Johns",
  "Canada/Newfoundland": "(UTC-02:30) Canada, Newfoundland",
  CNT: "(UTC-02:30) CNT",
  "America/Araguaina": "(UTC-03:00) America, Araguaina",
  "America/Argentina/Buenos_Aires":
    "(UTC-03:00) America, Argentina, Buenos-Aires",
  "America/Argentina/Catamarca": "(UTC-03:00) America, Argentina, Catamarca",
  "America/Argentina/ComodRivadavia":
    "(UTC-03:00) America, Argentina, ComodRivadavia",
  "America/Argentina/Cordoba": "(UTC-03:00) America, Argentina, Cordoba",
  "America/Argentina/Jujuy": "(UTC-03:00) America, Argentina, Jujuy",
  "America/Argentina/La_Rioja": "(UTC-03:00) America, Argentina, La-Rioja",
  "America/Argentina/Mendoza": "(UTC-03:00) America, Argentina, Mendoza",
  "America/Argentina/Rio_Gallegos":
    "(UTC-03:00) America, Argentina, Rio-Gallegos",
  "America/Argentina/Salta": "(UTC-03:00) America, Argentina, Salta",
  "America/Argentina/San_Juan": "(UTC-03:00) America, Argentina, San-Juan",
  "America/Argentina/San_Luis": "(UTC-03:00) America, Argentina, San-Luis",
  "America/Argentina/Tucuman": "(UTC-03:00) America, Argentina, Tucuman",
  "America/Argentina/Ushuaia": "(UTC-03:00) America, Argentina, Ushuaia",
  "America/Bahia": "(UTC-03:00) America, Bahia",
  "America/Belem": "(UTC-03:00) America, Belem",
  "America/Buenos_Aires": "(UTC-03:00) America, Buenos-Aires",
  "America/Catamarca": "(UTC-03:00) America, Catamarca",
  "America/Cayenne": "(UTC-03:00) America, Cayenne",
  "America/Cordoba": "(UTC-03:00) America, Cordoba",
  "America/Fortaleza": "(UTC-03:00) America, Fortaleza",
  "America/Godthab": "(UTC-03:00) America, Godthab",
  "America/Jujuy": "(UTC-03:00) America, Jujuy",
  "America/Maceio": "(UTC-03:00) America, Maceio",
  "America/Mendoza": "(UTC-03:00) America, Mendoza",
  "America/Miquelon": "(UTC-02:00) America, Miquelon",
  "America/Montevideo": "(UTC-03:00) America, Montevideo",
  "America/Paramaribo": "(UTC-03:00) America, Paramaribo",
  "America/Punta_Arenas": "(UTC-03:00) America, Punta-Arenas",
  "America/Recife": "(UTC-03:00) America, Recife",
  "America/Rosario": "(UTC-03:00) America, Rosario",
  "America/Santarem": "(UTC-03:00) America, Santarem",
  "America/Sao_Paulo": "(UTC-03:00) America, Sao-Paulo",
  "Antarctica/Palmer": "(UTC-03:00) Antarctica, Palmer",
  "Antarctica/Rothera": "(UTC-03:00) Antarctica, Rothera",
  "Atlantic/Stanley": "(UTC-03:00) Atlantic, Stanley",
  "Brazil/East": "(UTC-03:00) Brazil, East",
  AGT: "(UTC-03:00) AGT",
  BET: "(UTC-03:00) BET",
  "America/Noronha": "(UTC-02:00) America, Noronha",
  "Atlantic/South_Georgia": "(UTC-02:00) Atlantic, South-Georgia",
  "Brazil/DeNoronha": "(UTC-02:00) Brazil, DeNoronha",
  "America/Scoresbysund": "(UTC-01:00) America, Scoresbysund",
  "Atlantic/Azores": "(UTC-01:00) Atlantic, Azores",
  "Atlantic/Cape_Verde": "(UTC-01:00) Atlantic, Cape-Verde",
  "Africa/Abidjan": "(UTC+00:00) Africa, Abidjan",
  "Africa/Accra": "(UTC+00:00) Africa, Accra",
  "Africa/Bamako": "(UTC+00:00) Africa, Bamako",
  "Africa/Banjul": "(UTC+00:00) Africa, Banjul",
  "Africa/Bissau": "(UTC+00:00) Africa, Bissau",
  "Africa/Conakry": "(UTC+00:00) Africa, Conakry",
  "Africa/Dakar": "(UTC+00:00) Africa, Dakar",
  "Africa/Freetown": "(UTC+00:00) Africa, Freetown",
  "Africa/Lome": "(UTC+00:00) Africa, Lome",
  "Africa/Monrovia": "(UTC+00:00) Africa, Monrovia",
  "Africa/Nouakchott": "(UTC+00:00) Africa, Nouakchott",
  "Africa/Ouagadougou": "(UTC+00:00) Africa, Ouagadougou",
  "Africa/Timbuktu": "(UTC+00:00) Africa, Timbuktu",
  "America/Danmarkshavn": "(UTC+00:00) America, Danmarkshavn",
  "Antarctica/Troll": "(UTC+00:00) Antarctica, Troll",
  "Atlantic/Canary": "(UTC+00:00) Atlantic, Canary",
  "Atlantic/Faeroe": "(UTC+00:00) Atlantic, Faeroe",
  "Atlantic/Faroe": "(UTC+00:00) Atlantic, Faroe",
  "Atlantic/Madeira": "(UTC+00:00) Atlantic, Madeira",
  "Atlantic/Reykjavik": "(UTC+00:00) Atlantic, Reykjavik",
  "Atlantic/St_Helena": "(UTC+00:00) Atlantic, St-Helena",
  Eire: "(UTC+00:00) Eire",
  "Europe/Belfast": "(UTC+00:00) Europe, Belfast",
  "Europe/Dublin": "(UTC+00:00) Europe, Dublin",
  "Europe/Guernsey": "(UTC+00:00) Europe, Guernsey",
  "Europe/Isle_of_Man": "(UTC+00:00) Europe, Isle-of-Man",
  "Europe/Jersey": "(UTC+00:00) Europe, Jersey",
  "Europe/Lisbon": "(UTC+00:00) Europe, Lisbon",
  "Europe/London": "(UTC+00:00) Europe, London",
  GB: "(UTC+00:00) GB",
  "GB-Eire": "(UTC+00:00) GB-Eire",
  GMT: "(UTC+00:00) GMT",
  GMT0: "(UTC+00:00) GMT0",
  Greenwich: "(UTC+00:00) Greenwich",
  Iceland: "(UTC+00:00) Iceland",
  Portugal: "(UTC+00:00) Portugal",
  UCT: "(UTC+00:00) UCT",
  UTC: "(UTC+00:00) UTC",
  Universal: "(UTC+00:00) Universal",
  WET: "(UTC+00:00) WET",
  Zulu: "(UTC+00:00) Zulu",
  "Africa/Algiers": "(UTC+01:00) Africa, Algiers",
  "Africa/Bangui": "(UTC+01:00) Africa, Bangui",
  "Africa/Brazzaville": "(UTC+01:00) Africa, Brazzaville",
  "Africa/Casablanca": "(UTC+01:00) Africa, Casablanca",
  "Africa/Ceuta": "(UTC+01:00) Africa, Ceuta",
  "Africa/Douala": "(UTC+01:00) Africa, Douala",
  "Africa/El_Aaiun": "(UTC+01:00) Africa, El-Aaiun",
  "Africa/Kinshasa": "(UTC+01:00) Africa, Kinshasa",
  "Africa/Lagos": "(UTC+01:00) Africa, Lagos",
  "Africa/Libreville": "(UTC+01:00) Africa, Libreville",
  "Africa/Luanda": "(UTC+01:00) Africa, Luanda",
  "Africa/Malabo": "(UTC+01:00) Africa, Malabo",
  "Africa/Ndjamena": "(UTC+01:00) Africa, Ndjamena",
  "Africa/Niamey": "(UTC+01:00) Africa, Niamey",
  "Africa/Porto-Novo": "(UTC+01:00) Africa, Porto-Novo",
  "Africa/Sao_Tome": "(UTC+01:00) Africa, Sao-Tome",
  "Africa/Tunis": "(UTC+01:00) Africa, Tunis",
  "Arctic/Longyearbyen": "(UTC+01:00) Arctic, Longyearbyen",
  "Atlantic/Jan_Mayen": "(UTC+01:00) Atlantic, Jan-Mayen",
  CET: "(UTC+01:00) CET",
  "Europe/Amsterdam": "(UTC+01:00) Europe, Amsterdam",
  "Europe/Andorra": "(UTC+01:00) Europe, Andorra",
  "Europe/Belgrade": "(UTC+01:00) Europe, Belgrade",
  "Europe/Berlin": "(UTC+01:00) Europe, Berlin",
  "Europe/Bratislava": "(UTC+01:00) Europe, Bratislava",
  "Europe/Brussels": "(UTC+01:00) Europe, Brussels",
  "Europe/Budapest": "(UTC+01:00) Europe, Budapest",
  "Europe/Busingen": "(UTC+01:00) Europe, Busingen",
  "Europe/Copenhagen": "(UTC+01:00) Europe, Copenhagen",
  "Europe/Gibraltar": "(UTC+01:00) Europe, Gibraltar",
  "Europe/Ljubljana": "(UTC+01:00) Europe, Ljubljana",
  "Europe/Luxembourg": "(UTC+01:00) Europe, Luxembourg",
  "Europe/Madrid": "(UTC+01:00) Europe, Madrid",
  "Europe/Malta": "(UTC+01:00) Europe, Malta",
  "Europe/Monaco": "(UTC+01:00) Europe, Monaco",
  "Europe/Oslo": "(UTC+01:00) Europe, Oslo",
  "Europe/Paris": "(UTC+01:00) Europe, Paris",
  "Europe/Podgorica": "(UTC+01:00) Europe, Podgorica",
  "Europe/Prague": "(UTC+01:00) Europe, Prague",
  "Europe/Rome": "(UTC+01:00) Europe, Rome",
  "Europe/San_Marino": "(UTC+01:00) Europe, San-Marino",
  "Europe/Sarajevo": "(UTC+01:00) Europe, Sarajevo",
  "Europe/Skopje": "(UTC+01:00) Europe, Skopje",
  "Europe/Stockholm": "(UTC+01:00) Europe, Stockholm",
  "Europe/Tirane": "(UTC+01:00) Europe, Tirane",
  "Europe/Vaduz": "(UTC+01:00) Europe, Vaduz",
  "Europe/Vatican": "(UTC+01:00) Europe, Vatican",
  "Europe/Vienna": "(UTC+01:00) Europe, Vienna",
  "Europe/Warsaw": "(UTC+01:00) Europe, Warsaw",
  "Europe/Zagreb": "(UTC+01:00) Europe, Zagreb",
  "Europe/Zurich": "(UTC+01:00) Europe, Zurich",
  MET: "(UTC+01:00) MET",
  Poland: "(UTC+01:00) Poland",
  ECT: "(UTC+01:00) ECT",
  "Africa/Blantyre": "(UTC+02:00) Africa, Blantyre",
  "Africa/Bujumbura": "(UTC+02:00) Africa, Bujumbura",
  "Africa/Cairo": "(UTC+02:00) Africa, Cairo",
  "Africa/Gaborone": "(UTC+02:00) Africa, Gaborone",
  "Africa/Harare": "(UTC+02:00) Africa, Harare",
  "Africa/Johannesburg": "(UTC+02:00) Africa, Johannesburg",
  "Africa/Khartoum": "(UTC+02:00) Africa, Khartoum",
  "Africa/Kigali": "(UTC+02:00) Africa, Kigali",
  "Africa/Lubumbashi": "(UTC+02:00) Africa, Lubumbashi",
  "Africa/Lusaka": "(UTC+02:00) Africa, Lusaka",
  "Africa/Maputo": "(UTC+02:00) Africa, Maputo",
  "Africa/Maseru": "(UTC+02:00) Africa, Maseru",
  "Africa/Mbabane": "(UTC+02:00) Africa, Mbabane",
  "Africa/Tripoli": "(UTC+02:00) Africa, Tripoli",
  "Africa/Windhoek": "(UTC+02:00) Africa, Windhoek",
  "Asia/Amman": "(UTC+02:00) Asia, Amman",
  "Asia/Beirut": "(UTC+02:00) Asia, Beirut",
  "Asia/Damascus": "(UTC+02:00) Asia, Damascus",
  "Asia/Famagusta": "(UTC+02:00) Asia, Famagusta",
  "Asia/Gaza": "(UTC+02:00) Asia, Gaza",
  "Asia/Hebron": "(UTC+02:00) Asia, Hebron",
  "Asia/Jerusalem": "(UTC+02:00) Asia, Jerusalem",
  "Asia/Nicosia": "(UTC+02:00) Asia, Nicosia",
  "Asia/Tel_Aviv": "(UTC+02:00) Asia, Tel-Aviv",
  EET: "(UTC+02:00) EET",
  Egypt: "(UTC+02:00) Egypt",
  "Europe/Athens": "(UTC+02:00) Europe, Athens",
  "Europe/Bucharest": "(UTC+02:00) Europe, Bucharest",
  "Europe/Chisinau": "(UTC+02:00) Europe, Chisinau",
  "Europe/Helsinki": "(UTC+02:00) Europe, Helsinki",
  "Europe/Kaliningrad": "(UTC+02:00) Europe, Kaliningrad",
  "Europe/Kiev": "(UTC+02:00) Europe, Kiev",
  "Europe/Mariehamn": "(UTC+02:00) Europe, Mariehamn",
  "Europe/Nicosia": "(UTC+02:00) Europe, Nicosia",
  "Europe/Riga": "(UTC+02:00) Europe, Riga",
  "Europe/Sofia": "(UTC+02:00) Europe, Sofia",
  "Europe/Tallinn": "(UTC+02:00) Europe, Tallinn",
  "Europe/Tiraspol": "(UTC+02:00) Europe, Tiraspol",
  "Europe/Uzhgorod": "(UTC+02:00) Europe, Uzhgorod",
  "Europe/Vilnius": "(UTC+02:00) Europe, Vilnius",
  "Europe/Zaporozhye": "(UTC+02:00) Europe, Zaporozhye",
  Israel: "(UTC+02:00) Israel",
  Libya: "(UTC+02:00) Libya",
  ART: "(UTC+02:00) ART",
  CAT: "(UTC+02:00) CAT",
  "Africa/Addis_Ababa": "(UTC+03:00) Africa, Addis-Ababa",
  "Africa/Asmara": "(UTC+03:00) Africa, Asmara",
  "Africa/Asmera": "(UTC+03:00) Africa, Asmera",
  "Africa/Dar_es_Salaam": "(UTC+03:00) Africa, Dar-es-Salaam",
  "Africa/Djibouti": "(UTC+03:00) Africa, Djibouti",
  "Africa/Juba": "(UTC+03:00) Africa, Juba",
  "Africa/Kampala": "(UTC+03:00) Africa, Kampala",
  "Africa/Mogadishu": "(UTC+03:00) Africa, Mogadishu",
  "Africa/Nairobi": "(UTC+03:00) Africa, Nairobi",
  "Antarctica/Syowa": "(UTC+03:00) Antarctica, Syowa",
  "Asia/Aden": "(UTC+03:00) Asia, Aden",
  "Asia/Baghdad": "(UTC+03:00) Asia, Baghdad",
  "Asia/Bahrain": "(UTC+03:00) Asia, Bahrain",
  "Asia/Istanbul": "(UTC+03:00) Asia, Istanbul",
  "Asia/Kuwait": "(UTC+03:00) Asia, Kuwait",
  "Asia/Qatar": "(UTC+03:00) Asia, Qatar",
  "Asia/Riyadh": "(UTC+03:00) Asia, Riyadh",
  "Europe/Istanbul": "(UTC+03:00) Europe, Istanbul",
  "Europe/Kirov": "(UTC+03:00) Europe, Kirov",
  "Europe/Minsk": "(UTC+03:00) Europe, Minsk",
  "Europe/Moscow": "(UTC+03:00) Europe, Moscow",
  "Europe/Simferopol": "(UTC+03:00) Europe, Simferopol",
  "Indian/Antananarivo": "(UTC+03:00) Indian, Antananarivo",
  "Indian/Comoro": "(UTC+03:00) Indian, Comoro",
  "Indian/Mayotte": "(UTC+03:00) Indian, Mayotte",
  Turkey: "(UTC+03:00) Turkey",
  "W-SU": "(UTC+03:00) W-SU",
  EAT: "(UTC+03:00) EAT",
  "Asia/Tehran": "(UTC+03:30) Asia, Tehran",
  Iran: "(UTC+03:30) Iran",
  "Asia/Baku": "(UTC+04:00) Asia, Baku",
  "Asia/Dubai": "(UTC+04:00) Asia, Dubai",
  "Asia/Muscat": "(UTC+04:00) Asia, Muscat",
  "Asia/Tbilisi": "(UTC+04:00) Asia, Tbilisi",
  "Asia/Yerevan": "(UTC+04:00) Asia, Yerevan",
  "Europe/Astrakhan": "(UTC+04:00) Europe, Astrakhan",
  "Europe/Samara": "(UTC+04:00) Europe, Samara",
  "Europe/Saratov": "(UTC+04:00) Europe, Saratov",
  "Europe/Ulyanovsk": "(UTC+04:00) Europe, Ulyanovsk",
  "Europe/Volgograd": "(UTC+04:00) Europe, Volgograd",
  "Indian/Mahe": "(UTC+04:00) Indian, Mahe",
  "Indian/Mauritius": "(UTC+04:00) Indian, Mauritius",
  "Indian/Reunion": "(UTC+04:00) Indian, Reunion",
  NET: "(UTC+04:00) NET",
  "Asia/Kabul": "(UTC+04:30) Asia, Kabul",
  "Antarctica/Mawson": "(UTC+05:00) Antarctica, Mawson",
  "Asia/Aqtau": "(UTC+05:00) Asia, Aqtau",
  "Asia/Aqtobe": "(UTC+05:00) Asia, Aqtobe",
  "Asia/Ashgabat": "(UTC+05:00) Asia, Ashgabat",
  "Asia/Ashkhabad": "(UTC+05:00) Asia, Ashkhabad",
  "Asia/Atyrau": "(UTC+05:00) Asia, Atyrau",
  "Asia/Dushanbe": "(UTC+05:00) Asia, Dushanbe",
  "Asia/Karachi": "(UTC+05:00) Asia, Karachi",
  "Asia/Oral": "(UTC+05:00) Asia, Oral",
  "Asia/Samarkand": "(UTC+05:00) Asia, Samarkand",
  "Asia/Tashkent": "(UTC+05:00) Asia, Tashkent",
  "Asia/Yekaterinburg": "(UTC+05:00) Asia, Yekaterinburg",
  "Indian/Kerguelen": "(UTC+05:00) Indian, Kerguelen",
  "Indian/Maldives": "(UTC+05:00) Indian, Maldives",
  PLT: "(UTC+05:00) PLT",
  "Asia/Calcutta": "(UTC+05:30) Asia, Calcutta",
  "Asia/Colombo": "(UTC+05:30) Asia, Colombo",
  "Asia/Kolkata": "(UTC+05:30) Asia, Kolkata",
  IST: "(UTC+05:30) IST",
  "Asia/Kathmandu": "(UTC+05:45) Asia, Kathmandu",
  "Asia/Katmandu": "(UTC+05:45) Asia, Katmandu",
  "Antarctica/Vostok": "(UTC+06:00) Antarctica, Vostok",
  "Asia/Almaty": "(UTC+06:00) Asia, Almaty",
  "Asia/Bishkek": "(UTC+06:00) Asia, Bishkek",
  "Asia/Dacca": "(UTC+06:00) Asia, Dacca",
  "Asia/Dhaka": "(UTC+06:00) Asia, Dhaka",
  "Asia/Kashgar": "(UTC+06:00) Asia, Kashgar",
  "Asia/Omsk": "(UTC+06:00) Asia, Omsk",
  "Asia/Qyzylorda": "(UTC+06:00) Asia, Qyzylorda",
  "Asia/Thimbu": "(UTC+06:00) Asia, Thimbu",
  "Asia/Thimphu": "(UTC+06:00) Asia, Thimphu",
  "Asia/Urumqi": "(UTC+06:00) Asia, Urumqi",
  "Indian/Chagos": "(UTC+06:00) Indian, Chagos",
  BST: "(UTC+06:00) BST",
  "Asia/Rangoon": "(UTC+06:30) Asia, Rangoon",
  "Asia/Yangon": "(UTC+06:30) Asia, Yangon",
  "Indian/Cocos": "(UTC+06:30) Indian, Cocos",
  "Antarctica/Davis": "(UTC+07:00) Antarctica, Davis",
  "Asia/Bangkok": "(UTC+07:00) Asia, Bangkok",
  "Asia/Barnaul": "(UTC+07:00) Asia, Barnaul",
  "Asia/Ho_Chi_Minh": "(UTC+07:00) Asia, Ho-Chi-Minh",
  "Asia/Hovd": "(UTC+07:00) Asia, Hovd",
  "Asia/Jakarta": "(UTC+07:00) Asia, Jakarta",
  "Asia/Krasnoyarsk": "(UTC+07:00) Asia, Krasnoyarsk",
  "Asia/Novokuznetsk": "(UTC+07:00) Asia, Novokuznetsk",
  "Asia/Novosibirsk": "(UTC+07:00) Asia, Novosibirsk",
  "Asia/Phnom_Penh": "(UTC+07:00) Asia, Phnom-Penh",
  "Asia/Pontianak": "(UTC+07:00) Asia, Pontianak",
  "Asia/Saigon": "(UTC+07:00) Asia, Saigon",
  "Asia/Tomsk": "(UTC+07:00) Asia, Tomsk",
  "Asia/Vientiane": "(UTC+07:00) Asia, Vientiane",
  "Indian/Christmas": "(UTC+07:00) Indian, Christmas",
  VST: "(UTC+07:00) VST",
  "Antarctica/Casey": "(UTC+08:00) Antarctica, Casey",
  "Asia/Brunei": "(UTC+08:00) Asia, Brunei",
  "Asia/Choibalsan": "(UTC+08:00) Asia, Choibalsan",
  "Asia/Chongqing": "(UTC+08:00) Asia, Chongqing",
  "Asia/Chungking": "(UTC+08:00) Asia, Chungking",
  "Asia/Harbin": "(UTC+08:00) Asia, Harbin",
  "Asia/Hong_Kong": "(UTC+08:00) Asia, Hong-Kong",
  "Asia/Irkutsk": "(UTC+08:00) Asia, Irkutsk",
  "Asia/Kuala_Lumpur": "(UTC+08:00) Asia, Kuala-Lumpur",
  "Asia/Kuching": "(UTC+08:00) Asia, Kuching",
  "Asia/Macao": "(UTC+08:00) Asia, Macao",
  "Asia/Macau": "(UTC+08:00) Asia, Macau",
  "Asia/Makassar": "(UTC+08:00) Asia, Makassar",
  "Asia/Manila": "(UTC+08:00) Asia, Manila",
  "Asia/Shanghai": "(UTC+08:00) Asia, Shanghai",
  "Asia/Singapore": "(UTC+08:00) Asia, Singapore",
  "Asia/Taipei": "(UTC+08:00) Asia, Taipei",
  "Asia/Ujung_Pandang": "(UTC+08:00) Asia, Ujung-Pandang",
  "Asia/Ulaanbaatar": "(UTC+08:00) Asia, Ulaanbaatar",
  "Asia/Ulan_Bator": "(UTC+08:00) Asia, Ulan-Bator",
  "Australia/Perth": "(UTC+08:00) Australia, Perth",
  "Australia/West": "(UTC+08:00) Australia, West",
  Hongkong: "(UTC+08:00) Hongkong",
  PRC: "(UTC+08:00) PRC",
  Singapore: "(UTC+08:00) Singapore",
  CTT: "(UTC+08:00) CTT",
  "Australia/Eucla": "(UTC+08:45) Australia, Eucla",
  "Asia/Chita": "(UTC+09:00) Asia, Chita",
  "Asia/Dili": "(UTC+09:00) Asia, Dili",
  "Asia/Jayapura": "(UTC+09:00) Asia, Jayapura",
  "Asia/Khandyga": "(UTC+09:00) Asia, Khandyga",
  "Asia/Pyongyang": "(UTC+09:00) Asia, Pyongyang",
  "Asia/Seoul": "(UTC+09:00) Asia, Seoul",
  "Asia/Tokyo": "(UTC+09:00) Asia, Tokyo",
  "Asia/Yakutsk": "(UTC+09:00) Asia, Yakutsk",
  Japan: "(UTC+09:00) Japan",
  "Pacific/Palau": "(UTC+09:00) Pacific, Palau",
  ROK: "(UTC+09:00) ROK",
  JST: "(UTC+09:00) JST",
  "Australia/Adelaide": "(UTC+10:30) Australia, Adelaide",
  "Australia/Broken_Hill": "(UTC+10:30) Australia, Broken-Hill",
  "Australia/Darwin": "(UTC+09:30) Australia, Darwin",
  "Australia/North": "(UTC+09:30) Australia, North",
  "Australia/South": "(UTC+10:30) Australia, South",
  "Australia/Yancowinna": "(UTC+10:30) Australia, Yancowinna",
  ACT: "(UTC+09:30) ACT",
  "Antarctica/DumontDUrville": "(UTC+10:00) Antarctica, DumontDUrville",
  "Asia/Ust-Nera": "(UTC+10:00) Asia, Ust-Nera",
  "Asia/Vladivostok": "(UTC+10:00) Asia, Vladivostok",
  "Australia/ACT": "(UTC+11:00) Australia, ACT",
  "Australia/Brisbane": "(UTC+10:00) Australia, Brisbane",
  "Australia/Canberra": "(UTC+11:00) Australia, Canberra",
  "Australia/Currie": "(UTC+11:00) Australia, Currie",
  "Australia/Hobart": "(UTC+11:00) Australia, Hobart",
  "Australia/Lindeman": "(UTC+10:00) Australia, Lindeman",
  "Australia/Melbourne": "(UTC+11:00) Australia, Melbourne",
  "Australia/NSW": "(UTC+11:00) Australia, NSW",
  "Australia/Queensland": "(UTC+10:00) Australia, Queensland",
  "Australia/Sydney": "(UTC+11:00) Australia, Sydney",
  "Australia/Tasmania": "(UTC+11:00) Australia, Tasmania",
  "Australia/Victoria": "(UTC+11:00) Australia, Victoria",
  "Pacific/Chuuk": "(UTC+10:00) Pacific, Chuuk",
  "Pacific/Guam": "(UTC+10:00) Pacific, Guam",
  "Pacific/Port_Moresby": "(UTC+10:00) Pacific, Port-Moresby",
  "Pacific/Saipan": "(UTC+10:00) Pacific, Saipan",
  "Pacific/Truk": "(UTC+10:00) Pacific, Truk",
  "Pacific/Yap": "(UTC+10:00) Pacific, Yap",
  AET: "(UTC+11:00) AET",
  "Australia/LHI": "(UTC+11:00) Australia, LHI",
  "Australia/Lord_Howe": "(UTC+11:00) Australia, Lord-Howe",
  "Antarctica/Macquarie": "(UTC+11:00) Antarctica, Macquarie",
  "Asia/Magadan": "(UTC+11:00) Asia, Magadan",
  "Asia/Sakhalin": "(UTC+11:00) Asia, Sakhalin",
  "Asia/Srednekolymsk": "(UTC+11:00) Asia, Srednekolymsk",
  "Pacific/Bougainville": "(UTC+11:00) Pacific, Bougainville",
  "Pacific/Efate": "(UTC+11:00) Pacific, Efate",
  "Pacific/Guadalcanal": "(UTC+11:00) Pacific, Guadalcanal",
  "Pacific/Kosrae": "(UTC+11:00) Pacific, Kosrae",
  "Pacific/Norfolk": "(UTC+11:00) Pacific, Norfolk",
  "Pacific/Noumea": "(UTC+11:00) Pacific, Noumea",
  "Pacific/Pohnpei": "(UTC+11:00) Pacific, Pohnpei",
  "Pacific/Ponape": "(UTC+11:00) Pacific, Ponape",
  SST: "(UTC+11:00) SST",
  "Antarctica/McMurdo": "(UTC+13:00) Antarctica, McMurdo",
  "Antarctica/South_Pole": "(UTC+13:00) Antarctica, South-Pole",
  "Asia/Anadyr": "(UTC+12:00) Asia, Anadyr",
  "Asia/Kamchatka": "(UTC+12:00) Asia, Kamchatka",
  Kwajalein: "(UTC+12:00) Kwajalein",
  NZ: "(UTC+13:00) NZ",
  "Pacific/Auckland": "(UTC+13:00) Pacific, Auckland",
  "Pacific/Fiji": "(UTC+12:00) Pacific, Fiji",
  "Pacific/Funafuti": "(UTC+12:00) Pacific, Funafuti",
  "Pacific/Kwajalein": "(UTC+12:00) Pacific, Kwajalein",
  "Pacific/Majuro": "(UTC+12:00) Pacific, Majuro",
  "Pacific/Nauru": "(UTC+12:00) Pacific, Nauru",
  "Pacific/Tarawa": "(UTC+12:00) Pacific, Tarawa",
  "Pacific/Wake": "(UTC+12:00) Pacific, Wake",
  "Pacific/Wallis": "(UTC+12:00) Pacific, Wallis",
  NST: "(UTC+13:00) NST",
  "NZ-CHAT": "(UTC+13:45) NZ-CHAT",
  "Pacific/Chatham": "(UTC+13:45) Pacific, Chatham",
  "Pacific/Apia": "(UTC+14:00) Pacific, Apia",
  "Pacific/Enderbury": "(UTC+13:00) Pacific, Enderbury",
  "Pacific/Fakaofo": "(UTC+13:00) Pacific, Fakaofo",
  "Pacific/Tongatapu": "(UTC+13:00) Pacific, Tongatapu",
  MIT: "(UTC+14:00) MIT",
  "Pacific/Kiritimati": "(UTC+14:00) Pacific, Kiritimati",
};

export const FileSize = {
  AudioSizeLimit: 3145728,
};

export enum CalendarProvider {
  Google = 1,
  Microsoft,
  Apple,
  Amazon,
}

export const EmailValidation = {
  Error: "Please enter a valid email address",
};

export const PhoneValidation = {
  Error: "Please enter a valid contact number",
};
export const InputFieldValidationErrorMessage = {
  ErrorMessage: "Please input atleast 3 character",
};
export const ValidateEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
export const USAPhoneNumberValidationRegex = /^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/;

export const sortedOptions = ["Language Selection", "Gender Selection", "Skills Selection", "Manager Response", "Show Phone Number", "Scheduled Job", "Add Participant", "Direct Call", "OPI Call Recording", "Video Conference"];