import React from "react";
import { batch, shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PlatformApi, AppConstants, device } from "../../configs";
import {
  AxiosAuth,
  StorageAuth,
  ActionAuth,
  useAppDispatch,
  useAppSelector,
  StorageUserCalendar,
  ActionApp,
  SocketContext,
} from "../../core";
import {
  ApiBodyLogin,
  ApiResponse,
  ApiSchemaLogin,
  UserStatusType,
} from "../../models";
import { UtilsJQuery } from "../../utils";
import { toast } from "react-toastify";
import { AppRouteUi } from "../../configs/appRoute";
import {
  ApiBodyChangePassword,
  ApiBodyResetPassword,
  ApiSchemaLogout,
  LoginResponseModel,
} from "../../models/login";
import { v4 as uuidv4 } from "uuid";

export function useFunctionalityAuth() {
  const dispatch = useDispatch();
  const history = useHistory();
  const email = useAppSelector((state) => state.auth.profile?.email);

  const data = useAppSelector(
    (state) => ({
      isLoggedIn: state.auth.isLoggedIn,
      role: state.auth.profile?.userRole,
      previousPath: state.ui.previousRoute,
    }),
    shallowEqual
  );

  const onLogin = React.useCallback(
    async (form: ApiBodyLogin): Promise<null> => {
      const btnLoading = UtilsJQuery.Ladda(".login-form-submit-btn");
      btnLoading.start?.();

      try {
        const res = await AxiosAuth.post<ApiResponse<LoginResponseModel>>(
          PlatformApi.Authentication.Login.Root(),
          form
        );

        if (res.status === AppConstants.Api.StatusOK) {
          if (res.data.data.token) {
            StorageAuth.AccessToken = res.data.data.token;
            batch(() => {
              dispatch(ActionAuth.SetIsLoggedIn(true));
              dispatch(ActionAuth.SetSessionId(res.data.data.sessionId));
              dispatch(ActionAuth.SetProfileData(res.data.data.profile));
              //dispatch(ActionAuth.SetMenuSettingsData(res.data.menuSettings));
            });
            StorageAuth.loginStatus = 'true'
            if (!!data.previousPath) history.push(data.previousPath);
          } else if (res.data.message === "Please Change Password for Login") {
            history.push(AppRouteUi.ActivateAccount.Root,{username: form.username});
            //toast.error("This account has not been activated yet.");
          } else {
            toast.error(res.data.message);
          }
        }
      } catch (e: any) {
        // setErrMsg((e as Error).message);
        toast.error(e.message);
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );
  const onCheckUserStatus = React.useCallback(
    async (form: any): Promise<null> => {
      const btnLoading = UtilsJQuery.Ladda(".login-form-submit-btn");
      btnLoading.start?.();

      try {
        const res = await AxiosAuth.post<ApiResponse<UserStatusType>>(
          PlatformApi.Authentication.GetUserStatus(),
          form
        );

        if (res.status === AppConstants.Api.StatusOK) {
          if (res.data.data == UserStatusType.Pending) {
            history.push(AppRouteUi.ActivateAccount.Root,{
              username: form.username,
            });
          } else if (res.data.data == UserStatusType.Enabled) {
            toast.error("User has already been activated");
          } else if (res.data.data == UserStatusType.Disabled) {
            toast.error("User has already been deactivated");
          }
        }
      } catch (e: any) {
        // setErrMsg((e as Error).message);
        toast.error(e.message);
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const onResetPass = React.useCallback(async (form: ApiBodyChangePassword) => {
    const btnLoading = UtilsJQuery.Ladda(".change-form-submit-btn");
    btnLoading.start?.();
    const pass: string = form.newPassword;
    const passConf: string = form.confirmPassword ? form.confirmPassword : "";

    if (pass === passConf) {
      const newform: ApiBodyChangePassword = { ...form, email: email };
      try {
        const res = await AxiosAuth.post<ApiBodyChangePassword>(
          PlatformApi.Authentication.ChangePassword.Root(),
          newform
        );
        if (res.status === AppConstants.Api.StatusOK) {
          toast.success("Password changed");
          history.push(AppRouteUi.Login.Root);
        }
      } catch (e) {
        toast.error("Task failed");
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    }
  }, []);


  const onChangePassword = React.useCallback(async (form: ApiBodyChangePassword) => {
    const btnLoading = UtilsJQuery.Ladda(".change-form-submit-btn");
    btnLoading.start?.();

      try {
        const res = await AxiosAuth.post<ApiBodyChangePassword>(
          PlatformApi.Authentication.ChangePassword.Root(),
          form
        );
        if (res.status === AppConstants.Api.StatusOK) {
          toast.success("Password changed");
          history.push(AppRouteUi.Login.Root);
        }
      } catch (e) {
        toast.error("Task failed");
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    
  }, []);

  const onChangePass = React.useCallback(
    async (form: ApiBodyChangePassword) => {
      const btnLoading = UtilsJQuery.Ladda(".change-form-submit-btn");
      btnLoading.start?.();
      try {
        const res = await AxiosAuth.post<ApiResponse<boolean>>(
          PlatformApi.Authentication.ChangePassword.Root(),
          form
        );
        if (res.status === AppConstants.Api.StatusOK) {
          if (res.data.isSuccess) {
            toast.success("Account has been activated");
            history.push(AppRouteUi.Login.Root);
          } else {
            toast.error(res.data.message);
          }
        }
      } catch (e: any) {
        toast.error(e.message);
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );
  const onActivateAccount = React.useCallback(
    async (form: ApiBodyChangePassword) => {
      const btnLoading = UtilsJQuery.Ladda(".change-form-submit-btn");
      btnLoading.start?.();
      try {
        const res = await AxiosAuth.post<ApiResponse<boolean>>(
          PlatformApi.Authentication.ActivateAccount.Root(),
          form
        );
        if (res.status === AppConstants.Api.StatusOK) {
          if (res.data.isSuccess) {
            toast.success("Account has been activated");
            history.push(AppRouteUi.Login.Root);
          } else {
            toast.error(res.data.message);
          }
        }
      } catch (e: any) {
        toast.error(e.message);
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const onForget = React.useCallback(async (form: { email: string }) => {
    const btnLoading = UtilsJQuery.Ladda(".forget-form-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.post(
        PlatformApi.Authentication.ForgotPassword.Root(),
        form
      );

      if (res.status === AppConstants.Api.StatusOK && res.data.isSuccess) {
        toast.success("Please Check your email");
        history.push({
          pathname: AppRouteUi.ResetPassword.Root,
          state: form.email,
        });
      }
    } catch (e: any) {
      toast.error(e.message);
    }
    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onReset = React.useCallback(async (form: ApiBodyResetPassword) => {
    const btnLoading = UtilsJQuery.Ladda(".reset-pass-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.post(
        PlatformApi.Authentication.ResetPassword.Root(),
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        toast.success("Password reset done.");
        history.push(AppRouteUi.Login.Root);
      }
    } catch (e: any) {
      toast.error(e.message);
    }
    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onLogout = React.useCallback(
    async (isForceFullyLoggedout: boolean = false): Promise<null> => {
      const btnLoading = UtilsJQuery.Ladda(".logout-form-submit-btn");
      btnLoading.start?.();

      try {
        const res = await AxiosAuth.post<ApiSchemaLogout>(
          PlatformApi.Authentication.Logout.Root()
        );

        if (res.status === AppConstants.Api.StatusOK) {
          if (res.data.isSuccess) {
            if (isForceFullyLoggedout) {
              history.push({
                pathname: AppRouteUi.Login.Root,
                state: {
                  ForceFullyLoggedout: isForceFullyLoggedout,
                },
              });
            } else {
              StorageAuth.Clear();
              StorageUserCalendar.Clear();
              dispatch(ActionApp.ResetStore());
              device.destroy();
              toast.success(res.data.message);
              history.push(AppRouteUi.Login.Root);
            }
            StorageAuth.loginStatus = 'false'
          } else {
            toast.error(res.data.message);
          }
        }
      } catch (e: any) {
        toast.error(e.message);
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    onLogin,
    onChangePass,
    onForget,
    onReset,
    onResetPass,
    onLogout,
    onChangePassword,
    onCheckUserStatus,
    onActivateAccount,
  };
}
