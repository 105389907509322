import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, OpiApi } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaCreateVendor, ApiSchemaCreateVendorCompany, ApiSchemaVendorData, WithPagination } from "../../models";
import { UtilsJQuery } from "../../utils/jQuery";
import { topLoaderProgress } from '../../configs/appConstants';

type Props = {
  parentCompanyId?: string,
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;

};
let pagedVendorUrl: string;
export function useFunctionalityPagedVendors(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress, parentCompanyId } = props;
  const [pagedVendorListCount, setPagedVendorListCount] = useState(0);

  pagedVendorUrl = OpiApi.Vendors.PagedGetAll(parentCompanyId, pageSize, pageNo, searchText);

  const { data: apiPagedVendorList } = useSWR<ApiSchemaVendorData[]>(
    pagedVendorUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaVendorData[]>>>(
        pagedVendorUrl
      )
        .then(r => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedVendorListCount(r.data.data.count);
          return r.data.data.data;
        })
        .catch(e => {
          setLoaderProgress(topLoaderProgress.complete);
          // toast.error("Failed to load vendor data.");
          return e;
        });
    }
  );

  const pagedVendorList = useMemo(() => {
    const data = Array.isArray(apiPagedVendorList) ? apiPagedVendorList : [];
    return data;
  }, [apiPagedVendorList]);

  return {
    pagedVendorList,
    pagedVendorListCount,
    pagedVendorUrl
  }
}
type vendroProps = {
  parentCompanyId?: string;
  shouldVendorGetAllInvoke?: boolean;
  handleShow?: any;
}
export function useFunctionalityVendors(prop: vendroProps) {
  const { parentCompanyId, shouldVendorGetAllInvoke = false, handleShow } = prop;
  const vendorUrl = OpiApi.Vendors.GetAll(parentCompanyId);

  const { data: apiVendorListData } = useSWR<ApiSchemaVendorData[]>(
    shouldVendorGetAllInvoke ? vendorUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaVendorData[]>>>(
        vendorUrl
      )
        .then((r) => r.data.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const vendorList = React.useMemo(() => {
    const data = Array.isArray(apiVendorListData) ? apiVendorListData : [];
    return data;
  }, [apiVendorListData]);


  const onAddVendor = React.useCallback(async (form: ApiSchemaCreateVendor) => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.post(
        OpiApi.Vendors.Create(),
        form
      );

      if (response.status === AppConstants.Api.StatusOK) {
        toast.success("Vendor Added Successfully");
        mutate(pagedVendorUrl)
        handleShow(false)
      }
      else {
        handleShow(true)
      }
    } catch (e) {

      //console.log(e)
      toast.error("Vender Already Exist!");

      // else {
      //   toast.error("Adding Vendor Failed");
      // }
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);


  const onEditPhone = React.useCallback(async (form: { phoneNumber: string }, vendorId) => {
    const btnLoading = UtilsJQuery.Ladda(".editPhone-from-submit-btn");
    btnLoading.start?.();
    try {
      if (vendorId) {
        const res = await AxiosAuth.put(
          OpiApi.Vendors.UpdatePhoneNumber(vendorId, form.phoneNumber)
        );

        if ((res.status = AppConstants.Api.StatusOK)) {
          // mutate(vendorUrl);
          // mutate(pagedVendorUrl);
          toast.success("Phone number edited");
        }
      } else {
        toast.error("no vendor id found");
      }
    } catch (e) {
      toast.error("Failed to update phone number");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);


  const onDeleteVendor = React.useCallback(async (dataToDelete: string) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(
        OpiApi.Vendors.DeleteVendor(dataToDelete)
      );

      if (response.status === AppConstants.Api.StatusOK) {
        // mutate(vendorUrl);
        // mutate(pagedVendorUrl);
        toast.success("Vendor deleted successfully");
      }
    } catch (e) {
      toast.error("Failed to delete vendor.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    vendorList,
    onAddVendor,
    onEditPhone,
    onDeleteVendor
  };
}
