import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select, { createFilter, OptionsType } from 'react-select';
import { toast } from 'react-toastify';
import { AssetSvg } from '../../assets';
import { FormErrorMessage } from '../../components';
import {
  InputFieldValidationErrorMessage,
  PlatformType,
  reactSelectFilterConfig,
} from '../../configs';
import { useAppSelector } from '../../core/Redux/ReduxStore';
import {
  ApiSchemaCreateLanguagePack,
  ApiSchemaLanguagePackData,
  Option,
  TypeLanguageList,
} from '../../models';
import { useFunctionalityLanguage, useFunctionalityLanguagePack } from '../../pages/hooks';
import { useFunctionalityInputChecker } from '../../pages/hooks/functionalityInputChecker';
import { ModalFooter } from '../Common';

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  dataToEdit: ApiSchemaLanguagePackData;
  companyId: string;
  onUpdateSuccess?: boolean;
  setOnUpdateSuccess: any;
  selectedPlatformType?: number;
  isCopyLanguagePack?: boolean;
  onCopyLanguagePackHandler?: any;
};

export function ModalLanguagePackV2(props: Props): JSX.Element {
  const {
    show,
    handleShow,
    dataToEdit,
    companyId,
    selectedPlatformType,
    isCopyLanguagePack,
    onUpdateSuccess,
    setOnUpdateSuccess,
    onCopyLanguagePackHandler
  } = props;
  const { register, handleSubmit, errors } =
    useForm<ApiSchemaCreateLanguagePack>();
  const vriCompanyId =
    useAppSelector((state) => state.auth.profile?.companyId) ?? '';

  let tempOption: Option[] = [];
  let tempLanguageList: TypeLanguageList[] = [];
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(tempOption);
  const [listOfLanguages, setListOfLanguages] = useState(tempLanguageList);
  const [flatRateToggle, setFlatRateToggle] = useState<boolean>(false);
  const [languageFlatRateError, setLanguageFlatRateError] =useState<string>('');
  const [zeroRateState, setZeroRateState] = useState<any>([]);
  const [disableProp, setDisableProp] = useState<boolean>(false);

  let { languageList } = useFunctionalityLanguage({
    plaformType: PlatformType.All,
    shouldLanguageGetAllInvoke: show,
  });
  const { onLanguagePackNameIsExists, onSubmit } = useFunctionalityLanguagePack({ companyId: companyId, });

  if (selectedPlatformType === PlatformType.OPI) {
    languageList = languageList.filter((language) => language.label !== 'ASL');
  }
  const languageListForSelect = [
    { label: 'Select All', value: 'all' },
    ...languageList,
  ];
  const {
    numberFieldValidation,
    handleCopyPasteInvalidValue,
    handleLimitDecimalPlaces,
    useFunctionlityInputFieldValidation,
  } = useFunctionalityInputChecker();

  useEffect(() => {
    if (dataToEdit?.languageRates) {
      tempOption = dataToEdit?.languageRates.map((t) => {
        return {
          value: t.languageId,
          label: t.languageName,
        };
      });
      tempLanguageList = dataToEdit?.languageRates.map((option) => {
        return {
          rate: option?.rate ? option.rate : 0.0,
          languageId: option.languageId,
          languageName: option.languageName,
        };
      });
      let initialRates: any = [];
      dataToEdit?.languageRates?.forEach((option, index) => {
        initialRates = [
          ...initialRates,
          {
            name: `languageRates[${index}].rate`,
            value: option?.rate,
          },
        ];
      });
      setZeroRateState(initialRates);
    }

    dataToEdit && dataToEdit.flatRate > 0
      ? setFlatRateToggle(true)
      : setFlatRateToggle(false);

    setSelectedOptions(tempOption);
    setListOfLanguages(tempLanguageList);
  }, [dataToEdit]);

  const doCopyUpdateOrAddLanguagePack = (form: ApiSchemaCreateLanguagePack) => {
    const isEmptyObject = Object.keys(dataToEdit).length === 0 && dataToEdit.constructor === Object;
    if (isEmptyObject) {
      onLanguagePackNameIsExists(vriCompanyId, form?.name)?.then((res) => {
        if (res) {
          toast.error("Language pack already exists");
          handleShow(false);
          return;
        }
        onSubmit(form).then((res) => {
          setOnUpdateSuccess?.(!onUpdateSuccess);
          handleShow(false);
        });
      }).catch((error)=> {
        console.error(error);
      });
    } else {
      if (isCopyLanguagePack) {
        onLanguagePackNameIsExists(vriCompanyId, form?.name)?.then((res) => {
          if (res) {
            toast.error("Language pack already exists");
            handleShow(false);
            return;
          }
          onSubmit(form).then((res) => {
            setOnUpdateSuccess?.(!onUpdateSuccess);
            handleShow(false);
            onCopyLanguagePackHandler?.(res?.id ?? "", dataToEdit?.id);
          });
        }).catch((error)=> {
          console.error(error);
        });
      } else {
        if (form?.name !== dataToEdit?.name) {
          onLanguagePackNameIsExists(vriCompanyId, form?.name, dataToEdit.id)?.then((res) => {
            if (res) {
              toast.error("Language pack already exists");
              handleShow(false);
              return;
            }
            onSubmit(form, dataToEdit.id).then(() => {
              handleShow(false);
              setOnUpdateSuccess?.(!onUpdateSuccess);
            });
          }).catch((error)=> {
            console.error(error);
          });
        } else {
          onSubmit(form, dataToEdit.id).then(() => {
            handleShow(false);
            setOnUpdateSuccess?.(!onUpdateSuccess);
          });
        }
      }
    }
  };
  const onSubmitHandler = (form: ApiSchemaCreateLanguagePack) => {
    form = {
      ...form,
      flatRate: form.flatRate && parseFloat(form.flatRate.toString()),
      name: form.name.trim(),
      languageRates: listOfLanguages.map((t) => {
        return {
          languageId: t.languageId,
          languageName: t.languageName,
          rate: parseFloat(t.rate?.toString() ?? "0"),
        };
      }),
      vendorCompanyId: isCopyLanguagePack ? vriCompanyId : companyId,
    };
    doCopyUpdateOrAddLanguagePack(form);
  };

  const doOperation = (dataToEdit: any, isCopyLanguagePack?: boolean) => {
    if (dataToEdit && Object.keys(dataToEdit).length !== 0) {
      return isCopyLanguagePack ? 'Copy' : 'Update';
    } else {
      return 'Add';
    }
  };
  const handleAddLanguage = (selected: OptionsType<Option>) => {
    const selectedOptions: any =
      selected.length && selected.find((option) => option.value === 'all')
        ? languageListForSelect.slice(1)
        : selected;
    setSelectedOptions(selectedOptions);
    const list: TypeLanguageList[] =
      selected.length && selected.find((option) => option.value === 'all')
        ? languageListForSelect.slice(1).map((option) => {
            return {
              rate: 0,
              languageId: option.value,
              languageName: option.label,
            };
          })
        : selected.map((option) => {
            const languageAlreadyExists = listOfLanguages?.find(
              (language) => language?.languageId === option?.value
            );
            return {
              rate: languageAlreadyExists ? languageAlreadyExists?.rate : 0,
              languageId: option.value,
              languageName: option.label,
            };
          });

    setListOfLanguages(list);
    let selectAllRateState: any = [];
    list?.forEach((option, index) => {
      selectAllRateState = [
        ...selectAllRateState,
        {
          name: `languageRates[${index}].rate`,
          value: option?.rate,
        },
      ];
    });
    setZeroRateState(selectAllRateState);
  };
  const handleFlatRateButtonToggle = (previousToggle: boolean) => {
    setFlatRateToggle(!flatRateToggle);
    if (
      (!previousToggle && dataToEdit?.flatRate <= 0) ||
      dataToEdit?.flatRate === undefined
    ) {
      setDisableProp(true);
      setLanguageFlatRateError('flat rate must be greater than zero');
    } else {
      setDisableProp(false);
      setLanguageFlatRateError('');
    }
  };
  const handleFlatRate = (event: any) => {
    const value = event.target.value;
    if (parseFloat(value) <= 0 || value.length===0) {
      setDisableProp(true);
      setLanguageFlatRateError('flat rate must be greater than zero');
    } else {
      setDisableProp(false);
      setLanguageFlatRateError('');
    }
  };
  const handleRateChange = (event: any, language: TypeLanguageList) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    const changeRate = listOfLanguages.map((item) => {
      if (item.languageId === language.languageId) {
        item.rate = value;
      }
      return item;
    });
    setListOfLanguages(changeRate);
    const newState = [
      ...zeroRateState?.filter((zeroRate: any) => zeroRate?.name !== name),
      {
        name: name,
        value: value,
      },
    ];
    setZeroRateState(newState);
  };
  const onRemoveItem = (id: string) => {
    const remainingItem = selectedOptions.filter((item) => item.value !== id);
    setSelectedOptions(remainingItem);
    const listAfterRemove = listOfLanguages?.filter(
      (language) => language?.languageId !== id
    );
    setListOfLanguages(listAfterRemove);
    let rateStateAfterRemoveLanguage: any = [];
    listAfterRemove?.forEach((option, index) => {
      rateStateAfterRemoveLanguage = [
        ...rateStateAfterRemoveLanguage,
        {
          name: `languageRates[${index}].rate`,
          value: option?.rate,
        },
      ];
    });

    setZeroRateState(rateStateAfterRemoveLanguage);
  };

  useEffect(() => {
    const hasZeroRate = zeroRateState?.find((zero: any) => zero?.value < 0.01);
    if (hasZeroRate && zeroRateState?.length > 0 && !flatRateToggle) {
      setDisableProp(true);
    } else if (flatRateToggle && languageFlatRateError) {
      setDisableProp(true);
    } else {
      setDisableProp(false);
    }
  }, [zeroRateState, flatRateToggle]);

  return (
    <Modal show={show} onHide={() => handleShow(false)} centered>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {doOperation(dataToEdit, isCopyLanguagePack)} Language Pack{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label
                  htmlFor=""
                  className="position-relative required font-sz-14"
                >
                  Package Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Package name"
                  defaultValue={dataToEdit?.name}
                  ref={register({
                    required: true,
                    validate: useFunctionlityInputFieldValidation,
                  })}
                />
                {errors.name && errors.name.type == 'validate' && (
                  <FormErrorMessage
                    message={InputFieldValidationErrorMessage.ErrorMessage}
                  />
                )}
                {errors.name?.type === 'required' && (
                  <FormErrorMessage message="required" />
                )}
              </div>
              <div className="form-inline mb-3 flex-wrap align-items-sm-center">
                <div className="form-group col-12 pl-0 pr-0 ">
                  <Select
                    placeholder="Select Language"
                    className="w-100"
                    options={languageListForSelect}
                    value={selectedOptions}
                    isMulti
                    isDisabled={languageList.length === 0 ? true : false}
                    closeMenuOnSelect={false}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(selected) => handleAddLanguage(selected)}
                  />
                </div>
              </div>
              <div className="component-card mb-3 shadow-none">
                <div className="component-card-header border-top border-right border-left">
                  <div className="row align-items-start">
                    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div>Language</div>
                      {listOfLanguages && listOfLanguages.length === 0 ? (
                        <div className="text-danger font-sz-12 ">
                          Contains no language
                        </div>
                      ) : (
                        <div className="text-muted font-sz-12 ">
                          Total {listOfLanguages.length} Selected Languages
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <div className="row  flex-wrap  align-items-start mx-0">
                        <button
                          type="button"
                          className={`btn btn-toggle language-toggle-btn mt-1 ${
                            dataToEdit.flatRate != undefined &&
                            dataToEdit.flatRate > 0
                              ? 'active'
                              : ''
                          }`}
                          data-toggle="button"
                          aria-pressed="false"
                          onClick={() =>
                            handleFlatRateButtonToggle(flatRateToggle)
                          }
                        >
                          <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label  text-muted ">
                          Flat Language Rate
                        </span>
                        {flatRateToggle ? (
                          <>
                            <div className="input-group col pr-0">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="flat-rate"
                                >
                                  {' '}
                                  ${' '}
                                </span>
                              </div>
                              <input
                                type="number"
                                step="any"
                                min="0"
                                name="flatRate"
                                className={`form-control ${
                                  languageFlatRateError ? 'field-required' : ''
                                }`}
                                defaultValue={dataToEdit.flatRate ?? 0}
                                aria-describedby="flatRate"
                                ref={register({
                                  // required: true,
                                  validate: handleCopyPasteInvalidValue,
                                })}
                                style={{
                                  border:
                                    errors.flatRate || languageFlatRateError
                                      ? '1px solid red'
                                      : '',
                                }}
                                onKeyDown={(evt) => numberFieldValidation(evt)}
                                onKeyPress={(event) => {
                                  if (!/[0-9]|\./.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(event) => {
                                  handleFlatRate(event);
                                }}
                                onInput={(event) =>
                                  handleLimitDecimalPlaces(event, 2)
                                }
                              />
                              {errors.flatRate &&
                                errors.flatRate.type === 'validate' && (
                                  <FormErrorMessage message="Please enter a valid value" />
                                )}
                              {languageFlatRateError && (
                                <div className="font-sz-10 text-danger">
                                  {languageFlatRateError}
                                </div>
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="component-card-body border">
                  <ul className="list-group">
                    {listOfLanguages &&
                      listOfLanguages.map(
                        (language: TypeLanguageList, index) => (
                          <li
                            className="list-group-item"
                            key={language.languageId}
                          >
                            <div className="row">
                              <div className="col-12 col-sm-6 col-md-6">
                                <p className="word-wrap mb-sm-0 mb-2 pt-2">
                                  {language.languageName}
                                </p>
                              </div>
                              <div className="col-12 col-sm-5 col-md-5 pr-sm-0 mb-2 mb-sm-0">
                                {flatRateToggle ? null : (
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="flat-rate"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="hidden"
                                      className="form-control"
                                      name={`languageRates[${index}].languageName`}
                                      aria-describedby="languageName"
                                      defaultValue={language.languageName}
                                      ref={register({
                                        required: flatRateToggle ? true : false,
                                      })}
                                    />
                                    <input
                                      type="hidden"
                                      className="form-control"
                                      name={`languageRates[${index}].languageId`}
                                      aria-describedby="languageId"
                                      defaultValue={language.languageId}
                                      ref={register({
                                        required: flatRateToggle ? true : false,
                                      })}
                                    />
                                    <input
                                      // key={CountryFieldkey}
                                      type="number"
                                      min="0"
                                      step="any"
                                      name={`languageRates[${index}].rate`}
                                      aria-describedby="rate"
                                      className="form-control"
                                      defaultValue={
                                        language.rate ? language.rate : 0
                                      }
                                      ref={register({
                                        required: true,
                                        validate: handleCopyPasteInvalidValue,
                                      })}
                                      style={{
                                        border: !language.rate
                                          ? '1px solid red'
                                          : '',
                                      }}
                                      onKeyDown={(evt) =>
                                        numberFieldValidation(evt)
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]|\./.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(event) => {
                                        handleRateChange(event, language);
                                      }}
                                      onInput={(event) =>
                                        handleLimitDecimalPlaces(event, 2)
                                      }
                                    />

                                    {!language.rate && (
                                      <span className="font-sz-14 text-danger">
                                        must be greater than 0
                                      </span>
                                    )}
                                    {errors.languageRates?.[index]?.rate ||
                                      (errors.languageRates?.[index]?.rate
                                        ?.type === 'validate' && (
                                        <FormErrorMessage message="Please enter a valid value" />
                                      ))}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-sm-1 col-md-1 px-sm-2 text-left text-sm-center ">
                                <button
                                  type="button"
                                  className="btn btn-link px-0 text-site"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onRemoveItem(language.languageId);
                                  }}
                                >
                                  <span>
                                    <AssetSvg.Delete />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter
            handleShow={handleShow}
            operationName={doOperation(dataToEdit, isCopyLanguagePack)}
            zeroRateLanguagePack={disableProp}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
}
