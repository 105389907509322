import { Modal } from 'react-bootstrap';
import { IScreen, IScreenPickerModalProp } from '../../models';

export default function ScreenPickerModal(props: IScreenPickerModalProp) {
  const { setAllScreens, allScreens, getDesktopVideoStream } = props;
  return (
    <Modal
      show={allScreens.length}
      size="lg"
      onHide={() => setAllScreens([])}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose what to share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            {allScreens.map((screen: IScreen, index: number) => {
              return (
                <div
                  className="col-12 col-md-5 screen-container mb-3 pt-4 pb-3 mx-auto"
                  key={index}
                  onClick={() => {
                    getDesktopVideoStream(screen);
                  }}
                >
                  <img
                    src={screen.image}
                    className="img-fluid"
                    alt="source image"
                  />
                  <p className="mb-0 text-site mt-3 screen-title">
                    {screen.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
