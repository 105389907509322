import { Modal } from "react-bootstrap";
import { TinModalHeader } from "../../configs";
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {  ApiSchemaUpdateTin } from "../../models";
import { FormErrorMessage } from "../../components";
import { useFunctionalityExists } from "../../pages/hooks/functionalityExists";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  modalHeader: string;
  userTin?: string;
  onSaveHandler: any;
};

export function ModalUserTin(props: Props): JSX.Element {
  const { modalHeader, userTin, onSaveHandler } = props;
  const { register, handleSubmit, errors, setValue, setError } =
    useForm<ApiSchemaUpdateTin>();
  const [onClickEditTin, setOnClickEditTin] = useState(false);
  const [tinInput, setTinInput] = useState(userTin || "");
  const [tinError, setTinError] = useState("")
  const [lenghtError, setlenghtError] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(false)


  const {onTinExists} = useFunctionalityExists();

  const onTinSubmitHandler = async(tin: string) => {
    setButtonDisabled(true)
    var tinElem = document?.getElementById("tin") as HTMLInputElement
    var tinElemvalue = tinElem.value

    await onTinExists(tinElemvalue, userTin)
    .then((r) => {

      if (r) setTinError("TIN Already Exists")
      else setTinError("")

      if (!r)  onSaveHandler(tinElemvalue);   
    })

    setButtonDisabled(false)
  };

  const tinCallBack = (cb: any) => {
    return cb();
  }
  const onModalClose = () => {
    props.handleShow(false);
    setOnClickEditTin(false);
  };

  const onTinExistsValidation = async(value: any) => {
    setButtonDisabled(true)
    await onTinExists(value, userTin)
      .then((r) => {
        if (r) setTinError("TIN Already Exists")
        else setTinError("")
      })
      setButtonDisabled(false)

   
  }

  const onValidateLength = (value: any) => {
    if(value.length < 3 || value.length > 5 ){
      setlenghtError("Please enter minimun 3 digits to maximum 5 digits")
    }
    else {
      setlenghtError("")
    }
   
  };

  return (

    <Modal show={props.show} onHide={() => onModalClose()} centered backdrop='static'>
      {/* <form> */}
      <Modal.Header closeButton>
        <Modal.Title  className="test-modal">{modalHeader}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-12">
            {modalHeader === TinModalHeader.userTin && !onClickEditTin ? <div className="d-flex flex-wrap justify-content-center align-items-center">
              {
                userTin &&
                tinCallBack(() => {
                  const row = [];
                  for (var i = 0; i < userTin.length; i++) {
                    row.push(<div className="TINbox">
                      <span className="TIN-number">
                        {userTin[i]}
                      </span>
                    </div>);
                  }
                  return row;
                })
              }
            </div> : <div className="form-group">
              <label htmlFor="" className="position-relative required">
                Type Tin
              </label>
              <input
                type="text"
                name="tin"
                className="form-control"
                placeholder="Tin"
                defaultValue={userTin} 
                id="tin"
                minLength={3}
                maxLength={5}
                onChange={(e) => { 
                  onTinExistsValidation(e.target.value)
                  onValidateLength(e.target.value)
                  console.log("Value ", e.target.value) 
                   }}
                ref={register({ required: true, minLength: 3, maxLength: 5, })}
              />
              {tinError && <FormErrorMessage message={tinError} />}
              {lenghtError && <FormErrorMessage message={lenghtError} />}
              {errors.tin && errors.tin.type === "required" && <FormErrorMessage message="required" />}
              {errors.tin && errors.tin.type === "minLength" && <FormErrorMessage message="Please enter at least 3 numbers." />}
              {errors.tin && errors.tin.type === "maxLength" && <FormErrorMessage message="Please enter less than 6 numbers." />}


            </div>}
          </div>


        </div>
      </Modal.Body>

      <Modal.Footer>
        {modalHeader === TinModalHeader.userTin && !onClickEditTin && <button type="button" className=" btn btn-outline-dark text-uppercase mx-2" onClick={() => { setOnClickEditTin((prev) => !prev) }}>Edit tin</button>}
        <button className=" btn btn-primary text-uppercase" onClick={() => onModalClose()}>Close</button>
        {(modalHeader === TinModalHeader.createTin || onClickEditTin) && <button type="button"  disabled={buttonDisabled} className="btn btn-primary text-uppercase user-from-submit-btn" onClick={() => { onTinSubmitHandler(tinInput)  }}>Save</button>}

      </Modal.Footer>
      {/* </form> */}
    </Modal>
  );
}
