import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FooterButtons } from "..";
import { StepIndicatorTabs } from "../../..";
import { FormErrorMessage } from "../../../../components";
import { useAppSelector } from "../../../../core";
import { ActionCompany } from "../../../../core/Redux/Slices/sliceCompany";
import { TypeCreateCompanyStep5 } from "../../../../models";
import { CompanyType, DefaultCountry, InputFieldValidationErrorMessage, StepNumber, USAPhoneNumberValidationRegex } from "../../../../configs";
import { ExistModel } from "../../../../models/api/Operator";
import { useFunctionalityInputChecker } from "../../../../pages/hooks/functionalityInputChecker";
import { UtilsJQuery } from "../../../../utils";
import { useFunctionalityCheckEmailExistence } from "../../../../pages/hooks/functionalityCheckEmailExistence";
import { useFunctionalityValidateEmail } from "../../../../pages/hooks";

type Props = {
  stepCounter: number;
  setStepCounter: (value: number) => void;
  handleShow: (value: boolean) => void;
  companyType?: CompanyType;
};

export function Step5CreateCompany(props: Props): JSX.Element {
  const { stepCounter, setStepCounter, handleShow, companyType } = props;
  const { register, handleSubmit, errors } = useForm<TypeCreateCompanyStep5>();
  const dispatch = useDispatch();
  const step5Data = useAppSelector((state) => state.company.companyStep5);
  const step1Data = useAppSelector((state) => state.company.companyStep1);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [retypeEmailForMatch, setRetypeEmailForMatch] = useState<string>("");
  const [emailForMatch, setEmailForMatch] = useState<string>("");
  const [numberErrMsg, setNumberErrMsg] = useState("");

  const { handleSpecialCharacterWhiteSpaces, useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();
  const { onCheckEmailExistence, emailExistMsg } = useFunctionalityCheckEmailExistence();
  const { validateEmail, emailValidationMsg, } = useFunctionalityValidateEmail()

  const onSubmitStep5 = async (data: TypeCreateCompanyStep5) => {
    const btnLoading = UtilsJQuery.Ladda(".next-btn");
    btnLoading.start?.();
    const mail = document?.getElementById("email") as HTMLInputElement;
    const finalEmailValidation = await checkEmailExistence(mail.value);
    btnLoading.stop?.();
    data = {
      ...data,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim()
    }
    if (
      emailExistMsg === "" &&
      emailValidationMsg === "" &&
      errorMsg === ""
      && !finalEmailValidation
    ) {
      dispatch(ActionCompany.SetCompanyStep5(data));
      setStepCounter(stepCounter + 1);
    }
  };

  const confirmEmail = (e: any) => {
    var mail = document?.getElementById("email") as HTMLInputElement;
    var mailValue = mail.value;
    var confemail = document.getElementById("retypeEmail") as HTMLInputElement;
    var confEmailValue = confemail.value;
    if (mailValue !== "" && confEmailValue !== "") {
      if (mailValue != confEmailValue) {
        setErrorMsg("Emails don't match");
      } else {
        setErrorMsg("");
      }
    }
  };

  const checkEmailExistence = async (value: string) => {
    setEmail(value);
    const isEmailExist = await onCheckEmailExistence(value ?? "")?.then((res) => {
      const exist: ExistModel = res?.data!;
      return exist;
    })
      .catch((e: any) => {
        console.error(e);
      });
    return isEmailExist;
  }
  const validateTeNumber = (e: any) => {
    let phoneNumber = e.target.value;
    let phoneNumberForRegexCheck = phoneNumber.replace(/\s+/g, "");
    if (step1Data?.countryCode === DefaultCountry.USA) {
      if (phoneNumber?.length === 0) {
        setNumberErrMsg("");
      } else if (phoneNumberForRegexCheck?.match(USAPhoneNumberValidationRegex)) {
        setNumberErrMsg("");
      } else {
        setNumberErrMsg("Please enter a valid contact number");
      }
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitStep5)} id="companyCreateForm">
        <Modal.Body>
          <div className=" component-padding border border-gry">
            <div className="row">
              <div className="col-12">
                <StepIndicatorTabs
                  step={stepCounter || StepNumber.firstStep}
                  companyType={companyType}
                />
                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="" className="position-relative required">
                        First Name
                      </label>
                      <input
                        name="firstName"
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        ref={register({
                          required: true,
                          validate: useFunctionlityInputFieldValidation
                          // validate: handleSpecialCharacterWhiteSpaces,
                        })}
                        defaultValue={step5Data?.firstName}
                      />
                      {errors.firstName &&
                        errors.firstName.type == "validate" && (
                          <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />
                        )}
                      {errors.firstName &&
                        errors.firstName.type == "required" && (
                          <FormErrorMessage message="required" />
                        )}
                    </div>
                    <div className="col">
                      <label htmlFor="" className="position-relative required">
                        Last Name
                      </label>
                      <input
                        name="lastName"
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        ref={register({
                          required: true,
                          validate: useFunctionlityInputFieldValidation
                          // validate: handleSpecialCharacterWhiteSpaces,
                        })}
                        defaultValue={step5Data?.lastName}
                      />
                      {errors.lastName &&
                        errors.lastName.type == "validate" && (
                          <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />
                        )}
                      {errors.lastName &&
                        errors.lastName.type == "required" && (
                          <FormErrorMessage message="required" />
                        )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="" className="position-relative">
                    Mobile Number
                  </label>
                  <input
                    name="contactNumber"
                    type="text"
                    className="form-control"
                    placeholder="+1-555-666-7777"
                    ref={register({})}
                    onChange={(event) => validateTeNumber(event)}
                    defaultValue={step5Data?.contactNumber}
                  />
                  {errors.contactNumber &&
                    errors.contactNumber.type == "validate" && (
                      <FormErrorMessage message="Please enter a valid contact number" />
                    )}
                  {numberErrMsg && <FormErrorMessage message={numberErrMsg} />}
                </div>
                <div className="form-group">
                  <label htmlFor="" className="position-relative required">
                    E-mail Address
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    onBlur={(e) => {
                      setEmailForMatch(e.target.value);
                      validateEmail(e);
                      checkEmailExistence(e.target.value);
                      confirmEmail(e);
                    }}
                    ref={register({
                      required: true,
                    })}
                    defaultValue={step5Data?.email}
                  />

                  {errors.email && errors.email.type == "required" && (
                    <FormErrorMessage message="required" />
                  )}
                  {emailExistMsg && (
                    <FormErrorMessage message={emailExistMsg} />
                  )}
                  {emailValidationMsg && (
                    <FormErrorMessage message={emailValidationMsg} />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="" className="position-relative required">
                    Retype E-mail
                  </label>
                  <input
                    name="retypeEmail"
                    type="email"
                    className="form-control"
                    placeholder="Retype Email"
                    id="retypeEmail"
                    ref={register({
                      required: true,
                    })}
                    defaultValue={step5Data?.retypeEmail}
                    onBlur={(e) => {
                      setRetypeEmailForMatch(e.target.value);
                      confirmEmail(e);
                    }}
                  />
                  {errors.retypeEmail &&
                    errors.retypeEmail.type == "required" && (
                      <FormErrorMessage message="required" />
                    )}
                  {errorMsg && <FormErrorMessage message={errorMsg} />}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <FooterButtons
            counter={stepCounter || StepNumber.firstStep}
            setCounter={setStepCounter}
            handleShow={handleShow}
          />
        </Modal.Footer>
      </form>
    </>
  );
}
