import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../../../assets";
import { AppConstants, AppRouteUi, ExportTableType, PaginationSettings, TinModalHeader, } from "../../../../configs";
import { UserPageColumns } from "../../../../configs/AppJson/userPageColumns";
import {
  ApiSchemaChangeRole,
  ApiSchemaChangeStatus,
  ApiSchemaUpdatePassword,
  ApiSchemaUserData,
  ColumnSelectionsModel,
  UserStatusType,
} from "../../../../models";
import { useFunctionalityPagedUser, useFunctionalityUser2, } from "../../../../pages/hooks/functionalityUser";
import { ApiDataMapper, getKeyByValue } from "../../../../utils";
import { AdminPageTemplate } from "../../../admin";
import { ColumnToolTip } from "../../../Jobs";
import { ModalAddUser, ModalConfirmDelete, ModalEditUserRole, ModalEditUserStatus, ModalUserResetPassword, ModalUserTin, } from "../../../modals";

type Props = {
  pageTitle?: string;
  modalControlBtnLabel?: string;
  companyId: string;
  adminRole: string;
  pageName?: string
};

export function CommonUser(props: Props): JSX.Element {
  const { pageTitle, modalControlBtnLabel, companyId, adminRole, pageName } = props;
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState("");
  const [userStatus, setUserStatus] = useState(0);
  const [createModalShow, setCreateModalShow] = React.useState(false);
  const [editRoleModalShow, setEditRoleModalShow] = React.useState(false);
  const [editStatusModalShow, setEditStatusModalShow] = React.useState(false);
  const [resetPasswordModalShow, setResetPasswordModalShow] = React.useState(false);
  const [tinModalShow, setTinModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [userId, setUserId] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userTinModalHeader, setUserTinModalHeader] = useState("");
  const [userTin, setUserTin] = useState<string>("");
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSearchText(e.target.value);
        setPageIndex(1);
      });
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const { userList, pagedListCount } = useFunctionalityPagedUser({
    companyId: companyId,
    setLoaderProgress: setLoaderProgress,
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
  });
  const {
    onUpdateTin,
    onChangeStatus,
    onChangeRole,
    onUpdatePassword,
    userTinData,
    onDeleteUser,
  } = useFunctionalityUser2({
    userId: userId,
    companyId: companyId,
  });

  useEffect(() => {
    if (userList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [userList]);

  const onChangeUserStatus = (status: number) => {
    setUserStatus(status);
  };

  const onUpdateUserStatus = (status: number) => {
    setUserStatus(status);
    const statusModel: ApiSchemaChangeStatus = {
      userId: userId,
      status: status,
    };
    onChangeStatus(statusModel).then(() => {
      setEditStatusModalShow(false);
    });
  };
  const onChangePassword = (form: ApiSchemaUpdatePassword) => {
    onUpdatePassword({ ...form, userId: userId })
      .then(() => setResetPasswordModalShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };

  const onChangeTin = (tin: string) => {
    onUpdateTin({ tin: tin, userId: userId })
      .then(() => setTinModalShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };

  const onChangeUseRole = (role: string) => {
    setUserRole(role);
  };

  const onUpdateUserRole = (role: string) => {
    setUserRole(role);
    const roleModel: ApiSchemaChangeRole = {
      userId: userId,
      role: role,
    };
    onChangeRole(roleModel).then(() => {
      setEditRoleModalShow(false);
    });
  };

  const onDeleteHandler = () => {
    onDeleteUser(userId)
      .then(() => {
        setDeleteModalShow(false);
      })
      .catch((e) => console.error(e));
  };

  const onTinClickHandler = (user: ApiSchemaUserData) => {
    setUserId(user.id);
    userTinData(user.id)
      .then((res) => {
        setUserTin(res || "");
        const modalHeader = res
          ? TinModalHeader.userTin
          : TinModalHeader.createTin;
        setUserTinModalHeader(modalHeader);
        setTinModalShow(true);
      })
      .catch();
  };

  const columWithoutLocationAndDepartment: ColumnSelectionsModel[] = UserPageColumns?.filter((eachColumn) => pageName !== "companyProfilePage" && eachColumn.name !== "Department" && eachColumn.name !== "Location")
  const [columnSelection, setColumnSelection] = useState<ColumnSelectionsModel[]>(pageName !== "companyProfilePage" ? columWithoutLocationAndDepartment : UserPageColumns);
  const [columnSelectionModalShow, setColumnSelectionModalShow] = React.useState(false);

  const userTableColumns = [
    {
      name: <ColumnToolTip data="Id" />,
      selector: (row: ApiSchemaUserData) => {
        return <ColumnToolTip data={row.name} />;
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Id")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaUserData) => {
        return <ColumnToolTip data={row.name} />;
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Name")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="Email" />,
      selector: (row: ApiSchemaUserData) => {
        return <ColumnToolTip data={row.email} />;
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Email")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="Department" />,
      selector: (row: ApiSchemaUserData) => {
        return <ColumnToolTip data={row.departmentName} />;
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "DepartmentName")?.isEnabled ? pageName === "companyProfilePage" ? false : true : true,
    },
    {
      name: <ColumnToolTip data="Location" />,
      selector: (row: ApiSchemaUserData) => (
        <ColumnToolTip data={row.locationName}></ColumnToolTip>
      ),
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "LocationName")?.isEnabled ? pageName === "companyProfilePage" ? false : true : true,
    },
    {
      name: <ColumnToolTip data="Role" />,
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "UserRole")?.isEnabled ? false : true,
      minWidth: "200px",
      selector: (row: ApiSchemaUserData) => {
        return (
          <>
            <ColumnToolTip
              data={ApiDataMapper.MapUserRole(row.userRole || "")}
            />{" "}
            <div
              className="btn svg-color"
              title="Edit"
              onClick={() => {
                setUserId(row.id);
                setUserRole(row.userRole || "");
                setEditRoleModalShow(true);
              }}
            >
              <span >
                <AssetSvg.Edit />
              </span>
            </div>
          </>
        );
      },
    },
    {
      name: <ColumnToolTip data="Status" />,
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Status")?.isEnabled ? false : true,
      selector: (row: ApiSchemaUserData) => {
        return (
          <>
            <span
              className={`badge p-1 rounded-0 text-uppercase text-white ${row.status === UserStatusType.Enabled
                ? `bg-success`
                : row.status === UserStatusType.Disabled
                  ? `bg-secondary`
                  : row.status === UserStatusType.Pending
                    ? `bg-orange`
                    : ""
                }`}
            >
              {getKeyByValue(UserStatusType, row.status)}
            </span>{" "}
            <div
              className="btn svg-color"
              title="Edit"
              onClick={() => {
                setUserId(row.id);
                setUserStatus(row.status);
                setEditStatusModalShow(true);
              }}
            >
              <span >
                <AssetSvg.Edit />
              </span>
            </div>
          </>
        );
      },
    },
    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      selector: (row: ApiSchemaUserData) => {
        return (
          <>
            <Link
              to={AppRouteUi.User.Profile.Load(row.id ?? "")}
              className="btn svg-color"
              title="View"
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </Link>

            <div
              className="btn svg-color"
              title="Edit TIN"
              onClick={() => {
                onTinClickHandler(row);
              }}
            >
              <span >
                <AssetSvg.EditTin />
              </span>
            </div>
            <div
              className="btn svg-color"
              title="Change Password"
              onClick={() => {
                setUserId(row.id);
                setResetPasswordModalShow(true);
              }}
            >
              <span >
                <AssetSvg.ChangePassword />
              </span>
            </div>
            <div
              className="btn svg-color"
              title="Delete"
              onClick={() => {
                setUserId(row.id);
                setDeleteModalShow(true);
              }}
            >
              <span >
                <AssetSvg.Delete />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle={pageTitle}
        modalControlBtnLabel={modalControlBtnLabel}
        tableHeaders={userTableColumns}
        tableData={userList}
        setCreateModalShow={setCreateModalShow}
        showExportOptions={true}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        columnSelection={columnSelection}
        setColumnSelection={setColumnSelection}
        columnSelectionModalShow={columnSelectionModalShow}
        setColumnSelectionModalShow={setColumnSelectionModalShow}
        tableType={ExportTableType.User}
        downloadedFileName={"user Report"}
        companyId={companyId}
        setLoaderProgress={setLoaderProgress}
        isHideAddButton={true}
      />
      <ModalAddUser
        show={createModalShow}
        handleShow={setCreateModalShow}
        companyId={companyId}
        adminRole={adminRole}
      />
      <ModalEditUserRole
        show={editRoleModalShow}
        handleShow={setEditRoleModalShow}
        onChangeHandler={onChangeUseRole}
        checkedItem={userRole}
        onSaveHandler={onUpdateUserRole}
        adminRole={adminRole}
      />
      <ModalEditUserStatus
        show={editStatusModalShow}
        handleShow={setEditStatusModalShow}
        onChangeHandler={onChangeUserStatus}
        checkedItem={userStatus}
        onSaveHandler={onUpdateUserStatus}
      />
      <ModalUserTin
        show={tinModalShow}
        handleShow={setTinModalShow}
        modalHeader={userTinModalHeader}
        userTin={userTin}
        onSaveHandler={onChangeTin}
      />
      <ModalUserResetPassword
        show={resetPasswordModalShow}
        handleShow={setResetPasswordModalShow}
        onSaveHandler={onChangePassword}
      />
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
    </>
  );
}
