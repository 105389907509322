import { Modal } from "react-bootstrap";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import { TypeCertificateUpload } from "../../../models/api/operatorProfile";
import { FormErrorMessage } from "../../../components";
import React, { useRef, useState } from "react";
import { useFunctionalityOperatorProfile } from "../../../pages/hooks";
import { AppRouteUi, OperatorCriteriaType } from "../../../configs";
import { useFunctionalityInputChecker } from "../../../pages/hooks/functionalityInputChecker";
import moment from "moment";
import DatePicker from "react-datepicker";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../core";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  criteriaType: OperatorCriteriaType;
  criteriaId: string;
};

export function ModalUploadCertificate(props: Props): JSX.Element {
  const data = useAppSelector(
    (state) => ({
      operatorId: state.auth.profile?.operatorId,
    }),
    shallowEqual
  );

  const { show, handleShow, criteriaType, criteriaId } = props;

  const operatorId = AppRouteUi.Operator.Profile.useParam() || data.operatorId!;

  const { onUploadCertificate } = useFunctionalityOperatorProfile({
    operatorId,
  });
  const { register, handleSubmit, errors, control } =
    useForm<TypeCertificateUpload>();
  const [selectedDocumentName, setSelectedDocumentName] = useState<string>("");
  const [selectedCertificate, setSelectedCertificate] = useState<any>();
  const [isExpiredDateEnabled, setIsExpiredDateEnabled] = useState(false);
  const [certificateStartDate, setCertificateStartDate] = useState(
    moment().toDate()
  );
  const { handleSpecialCharacterWhiteSpacesForJobField } =
    useFunctionalityInputChecker();
  const [certificateNameLengthError, setCertificateNameLengthError] =
    useState<string>("");
  const [certificateDateError, setCertificateDateError] = useState<string>("");

  const hasAscii = (inp: string) => {
    for (var i = 0; i < inp.length; i++) {
      var char1 = inp.charAt(i);
      var cc = char1.charCodeAt(0);

      if (
        (cc > 47 && cc < 58) ||
        (cc > 64 && cc < 91) ||
        (cc > 96 && cc < 123)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleCertificateName = (event: any) => {
    const certificateName = event.target.value;
    if (certificateName?.length < 2) {
      setCertificateNameLengthError("name should be at least 2 character");
    } else if (certificateName?.length > 70) {
      setCertificateNameLengthError("name should be at most 70 character");
    } else if (!hasAscii(certificateName)) {
      setCertificateNameLengthError(
        "name should have at least 1 alphanumeric character"
      );
    } else {
      setCertificateNameLengthError("");
    }
  };

  const clearState = () => {
    setSelectedDocumentName("");
    setSelectedCertificate(null);
  };

  const certificateUploadHandler = (form: any) => {
    const body = new FormData();

    body.append("Name", form.certificateName);
    body.append("IsExpirationDateEnabled", isExpiredDateEnabled.toString());

    if (isExpiredDateEnabled) {
      const startDate = moment(form.startDate).format("YYYY-MM-DD");
      const endDate = moment(form.endDate).format("YYYY-MM-DD");

      if (startDate > endDate) {
        setCertificateDateError("Must be greater than start date");
        return;
      }

      body.append("StartDate", startDate);
      body.append("EndDate", endDate);
    }

    setCertificateDateError("");

    body.append("OperatorCriteriaType", criteriaType.toString());
    body.append("OperatorCriteriaId", criteriaId.toString());
    body.append("OperatorId", operatorId);
    body.append("File", selectedCertificate);

    onUploadCertificate(body, criteriaType)
      .then(() => {
        clearState();
        handleShow(false);
      })
      .catch((e) => console.error(e));
  };
  const dateValue = () => {
    var curr = new Date(certificateStartDate);
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substr(0, 10);
    return date;
  };
  // const dateValidation = (e: React.FocusEvent<HTMLInputElement>) => {
  //
  //   if (certificateStartDate == "") {
  //     {
  //       (
  //         <FormErrorMessage message="Should have input Start Date First" />
  //
  //       )
  //     }
  //     return false;
  //   }
  //   return true;
  // }
  const onSelectedDocumentHandler = (event: any) => {
    const certificate = event.target?.files[0];
    if (certificate) {
      setSelectedCertificate(certificate);
      setSelectedDocumentName(event.target?.files[0]?.name);
    }
  };
  const onButtonClickHandler = () => {
    const input = document.getElementById("file");
    if (input) {
      input.click();
    }
  };

  const handleDateCheck = (date: any) => {
    const startDate = moment(certificateStartDate).format("YYYY-MM-DD");
    const endDate = moment(date).format("YYYY-MM-DD");

    if (startDate > endDate) {
      setCertificateDateError("Must be greater than start date");
    } else {
      setCertificateDateError("");
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        clearState();
        handleShow(false);
      }}
      centered
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Certificate</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(certificateUploadHandler)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Certificate Name
                </label>
                <input
                  name="certificateName"
                  type="text"
                  className="form-control"
                  ref={register({
                    required: true,
                    validate: handleSpecialCharacterWhiteSpacesForJobField,
                  })}
                  style={{
                    border: errors.certificateName ? "1px solid red" : "",
                  }}
                  onChange={handleCertificateName}
                />
                {errors.certificateName &&
                  errors.certificateName.type === "validate" && (
                    <FormErrorMessage message="Please enter valid value" />
                  )}
                {errors.certificateName &&
                  errors.certificateName.type === "required" && (
                    <FormErrorMessage message="required" />
                  )}
                {certificateNameLengthError && (
                  <FormErrorMessage message={certificateNameLengthError} />
                )}
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  onClick={() => setIsExpiredDateEnabled(!isExpiredDateEnabled)}
                  className="form-check-input"
                  id="enableExpirationDate"
                />
                <label
                  htmlFor="enableExpirationDate"
                  className="form-check-label mb-0"
                >
                  Expiration Date
                </label>
              </div>
              {isExpiredDateEnabled ? (
                <div className="form-group row">
                  <div className="col-6">
                    <label htmlFor="" className="position-relative required">
                      Start Date
                    </label>

                    <Controller
                      defaultValue={moment().toDate()}
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ onChange, value }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="From"
                          className="form-control"
                          selected={value}
                          onChange={(date) => {
                            onChange(date);
                            setCertificateStartDate(
                              date ? date : moment().toDate()
                            );
                          }}
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                        />
                      )}
                    />

                    {errors.startDate && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>

                  <div className="col-6">
                    <label htmlFor="" className="position-relative required">
                      End Date
                    </label>

                    <Controller
                      defaultValue={moment().toDate()}
                      name="endDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ onChange, value }) => (
                        <DatePicker
                          // minDate={moment().toDate()}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="From"
                          className={`form-control ${
                            certificateDateError ? "border border-danger" : ""
                          }`}
                          selected={value}
                          onChange={(date) => {
                            onChange(date);
                            handleDateCheck(date);
                          }}
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                        />
                      )}
                    />
                    {errors.endDate && <FormErrorMessage message="required" />}
                    {certificateDateError && (
                      <div>
                        <FormErrorMessage message={certificateDateError} />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <div className="form-group mb-0">
                <label htmlFor="" className="position-relative required">
                  Upload File
                </label>
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Choose a File"
                    aria-label="Upload file"
                    name="button-upload-file"
                    aria-describedby="button-upload-file"
                    value={selectedDocumentName}
                    readOnly
                  />
                  <input
                    className="d-none"
                    type="file"
                    id="file"
                    name="certificate"
                    onChange={onSelectedDocumentHandler}
                    ref={register({
                      required: !selectedDocumentName,
                    })}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      id="button-upload-file"
                      onClick={(e) => {
                        e.preventDefault();
                        onButtonClickHandler();
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperclip} className="mr-2" />
                      <label className="mb-0" htmlFor="file">
                        attach document
                      </label>
                    </button>
                  </div>
                </div>
                {errors.certificate && !selectedDocumentName && (
                  <FormErrorMessage message="required" />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-uppercase text-site"
            onClick={() => {
              clearState();
              handleShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase upload-button"
            disabled={!!certificateNameLengthError}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
