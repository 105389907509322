import { Modal } from "react-bootstrap";
import { ApiDataMapper } from "../../utils";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  onChangeHandler: any;
  checkedItem: any;
  onSaveHandler: any;
  adminRole: string;
};

export function ModalEditUserRole(props: Props): JSX.Element {
  const { show, handleShow, onChangeHandler, checkedItem, onSaveHandler, adminRole } = props;
  const role: Object = ApiDataMapper.UserRoleOptionMapper(adminRole);

  return (
    <Modal show={show} onHide={() => handleShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Change Role</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          Object.entries(role).map(([key, value], i) =>
            <div key={i} className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                defaultChecked={value === checkedItem}
                onClick={() => props.onChangeHandler(value)}
              />
              <label className="form-check-label">{key}</label>
            </div>
          )
        }
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-link text-uppercase text-site" onClick={() => handleShow(false)}>Cancel</button>
        <button className="btn btn-primary text-uppercase user-from-submit-btn" onClick={() => { onSaveHandler(checkedItem); handleShow(true) }}>Save</button>
      </Modal.Footer>
    </Modal>
  );
}
