import Select, { createFilter } from "react-select";
import { Option } from "../../models";
import {useAppSelector} from "../../core";
import {useFunctionalityOperatorCriteriaList} from "../../pages/hooks/functionalityOperatorCriteriaList";
import {OperatorCriteriaName} from "../../models/api/Operator";
import {ExtendedCriteriaType, InputFieldType, PlatformType, reactSelectFilterConfig} from "../../configs";
import React, {useState} from "react";
import {FormErrorMessage} from "../../components";

type Props = {
  languageList?: Option[];
  language?: any;
  setLanguage: (value: string) => void;
  setLanguageName: (value: string) => void;
  optionMappedSkillList?: Option[];
  optionSkillListForDefaultValue?: Option[];
  optionMappedGenderList?: Option[];
  optionMappedDepartmentList?: Option[];
  optionMappedLocationList?: Option[];
  skill?:any;
  setSkill: any;
  gender?: any;
  setGender: (value: string) => void;
  department?: any;
  setDepartment?: any;
  location?: any;
  setLocation?: any;
  customFields?:any;
  setCustomFields?:any;
  matchedRequiredFieldCount?:any;
  setMatchedRequiredFieldCount?:any;
  register: any;
  errors: any;
  setValue: any;
  pagedOperatorCriteriaList: any;
  initiateTab?: number;
};

export function SearchInterpreterForm(props: Props): JSX.Element {

  const { pagedOperatorCriteriaList, initiateTab } = props
  
  const {register, errors, setValue} = props;

  // tracking callParams
  const callFieldNameMap = new Map();
  callFieldNameMap.set(1, 'Language');
  callFieldNameMap.set(2, 'Skill');
  callFieldNameMap.set(3, 'Gender');
  callFieldNameMap.set(4, 'CustomField1');
  callFieldNameMap.set(5, 'CustomField2');
  callFieldNameMap.set(6, 'CustomField3');
  callFieldNameMap.set(7, 'CustomField4');
  callFieldNameMap.set(8, 'Department');
  callFieldNameMap.set(9, 'Location');

  // setting custom fields
  const setCustomFieldsValue = (event: any, criteria: any, value: string = "") => {
    const customField = {
      name: criteria.name,
      value: value ? value : event?.target?.value,
      isEnabled: true,
      criteriaType: criteria.criteriaType
    }

    if(customField.value) {
      props.setCustomFields([...props.customFields.filter((custom: any) => custom.name !== customField.name), customField]);
    }else {
      props.setCustomFields([...props.customFields.filter((custom: any) => custom.name !== customField.name)]);
    }
    return customField;
  }

  const handleCustomField1Events = (event: any, criteria: any) => {
    setCustomFieldsValue(event, criteria);
  }

  const handleCustomField2Events = (event: any, criteria: any) => {
    setCustomFieldsValue(event, criteria);
  }

  const handleCustomField3Events = (event: any, criteria: any) => {
    setCustomFieldsValue(event, criteria);
  }

  const handleCustomField4Events = (event: any, criteria: any) => {
    setCustomFieldsValue(event, criteria);
  }

  return (
    <>
      <div className="form-row">
        {
          pagedOperatorCriteriaList?.map( (criteria: any, index: number) =>
            <div className="form-group col-md-4 col-sm-12 col-12" key={index}>
              <label htmlFor="" className={`text-muted font-sz-12 ${criteria.isMandatory && initiateTab !== PlatformType.OPI ? 'required' : ''}`}>
                { criteria.name }
              </label>

              {
                criteria.inputType === InputFieldType.DropDown ?
                <>
                  <input className="d-none"
                     name={callFieldNameMap.get(criteria.criteriaType)}
                     ref={register({required: criteria.isMandatory})}
                  />
                  <Select
                    className={` ${errors[callFieldNameMap.get(criteria.criteriaType)] ? 'field-required' : ''} `}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={
                      criteria.criteriaType === ExtendedCriteriaType.Language ? props.languageList :
                      criteria.criteriaType === ExtendedCriteriaType.Skill ? props.optionMappedSkillList :
                      criteria.criteriaType === ExtendedCriteriaType.Gender ? props.optionMappedGenderList:
                      criteria.criteriaType === ExtendedCriteriaType.Department ? props.optionMappedDepartmentList:
                      criteria.criteriaType === ExtendedCriteriaType.Location ? props.optionMappedLocationList: []
                    }
                    filterOption={createFilter(reactSelectFilterConfig)}
                    placeholder={`Select a ${criteria.name}`}
                    defaultValue={
                      criteria.criteriaType === ExtendedCriteriaType.Skill ? props.optionMappedSkillList ? props.optionSkillListForDefaultValue?.find(x => x.label === 'Any') : null :
                      criteria.criteriaType === ExtendedCriteriaType.Gender ? props.optionMappedGenderList ? props.optionMappedGenderList?.find(x => x.label === 'Any') : null : null
                    }
                    onChange={(option) => {
                        props.setValue(callFieldNameMap.get(criteria.criteriaType), option?.value, {
                          shouldValidate: true,
                        });
                        if(criteria?.criteriaType === ExtendedCriteriaType.Language)
                        {
                          props.setLanguage(option?.value);
                        }
                        if(criteria?.criteriaType === ExtendedCriteriaType.Skill)
                        {
                          props.setSkill(option?.value);
                        }
                        if(criteria?.criteriaType === ExtendedCriteriaType.Gender)
                        {
                          props.setGender(option?.value);
                        }
                        if(criteria?.criteriaType === ExtendedCriteriaType.Department)
                        {
                          setCustomFieldsValue( "", criteria, option?.label);
                          props.setDepartment(option?.value);
                        }
                        if(criteria?.criteriaType === ExtendedCriteriaType.Location)
                        {
                          setCustomFieldsValue( "", criteria, option?.label);
                          props.setLocation(option?.value);
                        }
                        return criteria?.criteriaType === ExtendedCriteriaType.Language ? props.setLanguageName(option?.label ?? "") : ''
                      }
                    }
                  />
                </> :
                <>
                  <input type="text"
                     className={`form-control ${errors[callFieldNameMap.get(criteria.criteriaType)] ? 'field-required' : ''}`}
                     name={callFieldNameMap.get(criteria.criteriaType)}
                     ref={register({required: criteria.isMandatory})}
                     onBlur={ (event) =>
                         criteria.criteriaType === ExtendedCriteriaType.CustomField1 ? handleCustomField1Events(event, criteria) :
                         criteria.criteriaType === ExtendedCriteriaType.CustomField2 ? handleCustomField2Events(event, criteria) :
                         criteria.criteriaType === ExtendedCriteriaType.CustomField3 ? handleCustomField3Events(event, criteria) :
                         criteria.criteriaType === ExtendedCriteriaType.CustomField4 ? handleCustomField4Events(event, criteria) : ''
                     }
                  />
                </>
              }

              <span> {
                errors[callFieldNameMap.get(criteria.criteriaType)] && <FormErrorMessage message="This field is required" />
              }
              </span>

            </div>
          )
        }
      </div>
    </>
  );
}
