import { Modal } from "react-bootstrap";
import { Searchbar } from "../Common/searchbar";
import { useFunctionalityChangedAppointedOperator, useFunctionalityJobAppointedInterpreter, } from "../../pages/hooks";
import { AppointedInterpreter, SelectedAppointedInterpreter, SelectedAppointedInterpreterWithName, } from "../../models";
import { ModalChooseInterpreterData } from "./chooseInterpreterModalData";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  jobId?: string;
  selectedOperatorIds?: any;
  chooseMaxNoOfOperator?: number;
  setSweetChangeProp?: any
};

export function ModalChooseInterpreter(props: Props): JSX.Element {
  const { handleSubmit } = useForm<SelectedAppointedInterpreter>();
  const { allAppointedInterpreter, isLoading } = useFunctionalityJobAppointedInterpreter({ jobId: props.jobId || "" });
  const { ChangedAppointedOperator } = useFunctionalityChangedAppointedOperator();
  const [changedAppointedOperators, setChangedAppointedOperators] = useState<any>([]);
  const [allAppointedOperatorIsSelected, setAllAppointedOperatorIsSelected] = useState<boolean>(false);
  let trackUser = new Map();

  const handleOnSelectInterpreter = (event: any) => {
    if (event.target.checked) {
      setChangedAppointedOperators([
        ...changedAppointedOperators,
        event.target.value,
      ]);

      trackUser.set(event.target.value, true);
      setAllAppointedOperatorIsSelected(
        changedAppointedOperators?.length === props.chooseMaxNoOfOperator
          ? true
          : false
      );
    } else {
      const newChangedAppointedOperators = changedAppointedOperators.filter(
        (operatorId: any) => operatorId !== event.target.value
      );
      setChangedAppointedOperators(newChangedAppointedOperators);
      trackUser.set(event.target.value, false);
      setAllAppointedOperatorIsSelected(
        changedAppointedOperators?.length === props.chooseMaxNoOfOperator
          ? true
          : false
      );
    }
  };

  const modalHideHandler = () => {
    props.handleShow(false);
  };

  const onAppointInterpreterHandler = (form: SelectedAppointedInterpreter) => {
    form = {
      ...form,
      operatorUserIds: changedAppointedOperators,
    };

    let listOfSelectedOperator: SelectedAppointedInterpreterWithName[] = [];

    allAppointedInterpreter &&
      allAppointedInterpreter.forEach(
        (appointedInterpreter: AppointedInterpreter) => {
          changedAppointedOperators.forEach(
            (changedAppointedOperatorId: string) => {
              if (
                changedAppointedOperatorId ===
                appointedInterpreter.operatorUserId
              ) {
                listOfSelectedOperator.push({
                  id: appointedInterpreter.operatorUserId,
                  name: appointedInterpreter.name,
                });
              }
            }
          );
        }
      );

    ChangedAppointedOperator(
      props?.jobId ?? "",
      form,
      listOfSelectedOperator,
      props.chooseMaxNoOfOperator!
    )
      ?.then(() => {
        props?.setSweetChangeProp((prev: number) => prev + 1)
        modalHideHandler();
        setSearchText("");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setChangedAppointedOperators(props.selectedOperatorIds);
  }, [props.selectedOperatorIds]);

  const [searchText, setSearchText] = useState("");
  const [distanceSortAccending, setDistanceSortAccending] =
    useState<Boolean | null>(null);
  const [rateSortAccending, setRateSortAccending] = useState<Boolean | null>(
    null
  );
  const [filteredAllAppointedInterpreter, setFilteredAllAppointedInterpreter] =
    useState<AppointedInterpreter[]>(allAppointedInterpreter);

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleRateSorting = (e: any) => {
    setRateSortAccending(!rateSortAccending);
    setDistanceSortAccending(null);
  };

  const handleDistanceSorting = (e: any) => {
    setDistanceSortAccending(!distanceSortAccending);
    setRateSortAccending(null);
  };

  useEffect(() => {
    const nowFilteredAllAppointedInterpreter = allAppointedInterpreter.filter(
      (appointedInterpreter: AppointedInterpreter) => {
        return appointedInterpreter.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }
    );
    rateSortAccending !== null &&
      nowFilteredAllAppointedInterpreter.sort((firstElement: AppointedInterpreter, secondElement: AppointedInterpreter) => {
        const rateOfFirstElement = firstElement.rate;
        const rateOfSecondElement = secondElement.rate;
        if (rateOfFirstElement === rateOfSecondElement) {
          return 0;
        } else if (rateOfFirstElement === null) {
          return 1;
        } else if (rateOfSecondElement === null) {
          return -1;
        } else {
          if (rateSortAccending) {
            return rateOfFirstElement - rateOfSecondElement;
          } else {
            return rateOfSecondElement - rateOfFirstElement;
          }
        }
      });
    distanceSortAccending !== null &&
      nowFilteredAllAppointedInterpreter.sort((firstElement: AppointedInterpreter, secondElement: AppointedInterpreter) => {
        const distanceOfFirstElement = firstElement.distance;
        const distanceOfSecondElement = secondElement.distance;
        if (distanceOfFirstElement === distanceOfSecondElement) {
          return 0;
        } else if (distanceOfFirstElement === null) {
          return 1;
        } else if (distanceOfSecondElement === null) {
          return -1;
        } else {
          if (distanceSortAccending) {
            return distanceOfFirstElement - distanceOfSecondElement;
          } else {
            return distanceOfSecondElement - distanceOfFirstElement;
          }
        }
      });

    setFilteredAllAppointedInterpreter(nowFilteredAllAppointedInterpreter);
  }, [
    searchText,
    allAppointedInterpreter,
    rateSortAccending,
    distanceSortAccending,
  ]);
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleShow(false);
        setSearchText("");
      }}
      size="lg"
      centered
    >
      <form onSubmit={handleSubmit(onAppointInterpreterHandler)}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Interpreter</Modal.Title>
        </Modal.Header>
        {
          isLoading ? <div className="text-center"><h3 className="mt-3">Loading...</h3></div>
            :
            <Modal.Body>
              <div className="form-group row">
                <div className="col-12 py-2">
                  <Searchbar searchText={searchText} handleSearch={handleSearch} />
                </div>
              </div>

              <div className="row">
                <div className="table-responsive">
                  <table className="table table-hover sorting-header">
                    <thead>
                      <tr>
                        <th scope="col" className="call-log-icon-width"></th>
                        <th scope="col" onClick={handleDistanceSorting}>
                          <span className={`sort-by ${distanceSortAccending !== null ? distanceSortAccending === true ? "active" : "inactive" : "nutral"}`}>
                            Distance(Miles)
                          </span>
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col" onClick={handleRateSorting}>
                          <span className={`sort-by ${rateSortAccending !== null ? rateSortAccending === true ? "active" : "inactive" : "nutral"}`}>
                            Rate
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredAllAppointedInterpreter.length
                        ? filteredAllAppointedInterpreter.map(
                          (appointedInterpreter: AppointedInterpreter) => (
                            <ModalChooseInterpreterData
                              key={appointedInterpreter.operatorUserId}
                              appointedInterpreter={appointedInterpreter}
                              handleOnSelectInterpreter={
                                handleOnSelectInterpreter
                              }
                              changedAppointedOperators={
                                changedAppointedOperators
                              }
                              chooseMaxNoOfOperator={props.chooseMaxNoOfOperator}
                              allAppointedOperatorIsSelected={
                                allAppointedOperatorIsSelected
                              }
                              setAllAppointedOperatorIsSelected={
                                setAllAppointedOperatorIsSelected
                              }
                              trackUser={trackUser}
                            />
                          )
                        )
                        : <tr><td colSpan={5} className="text-center"><h3 className="mt-3">No Operator Found</h3></td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>

        }
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              props.handleShow(false);
              setSearchText("");
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="login-from-submit-btn btn btn-primary text-uppercase"
          >
            Assign
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
