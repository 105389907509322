import React, { useEffect, useState } from "react";
import { CalendarTime } from "../../models/calendar";
import moment from "moment";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

type Props = {
  calendarInfo: any;
  handleCreateSchedule: any;
  handleCloseModal: any;
  show: boolean;
  handleShow: (value: boolean) => void;
  startTime?: any;
  endTime?: any;
  startDate?: any;
  endDate?: any;
};

export function ModalCreateSchedule(props: Props): JSX.Element {
  const { handleCreateSchedule, calendarInfo, handleCloseModal } = props;
  const [startTime, setStartTime] = useState<any>(props.startTime);
  const [endTime, setEndTime] = useState(props.endTime);
  const [startDate, setStartDate] = useState<any>(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  useEffect(() => {
    setStartTime(props?.startTime);
    setStartDate(props?.startDate);
    setEndTime(props?.endTime);
    setEndDate(props?.endDate);
  }, [props]);

  const handleSave = () => {
    const start = new Date(startDate + " " + startTime);
    const end = new Date(endDate + " " + endTime);
    let parameter: CalendarTime = {
      start,
      end,
      startDate,
      endDate,
      startTime: moment(start.toString()).format("hh:mm:ss a"),
      endTime: moment(end.toString()).format("hh:mm:ss a"),
    };
    handleCreateSchedule(parameter);
  };
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      className="createSchedule-modal"
    >
      <ModalHeader closeButton>Create Schedule</ModalHeader>
      <ModalBody>
        <div className="form-row align-items-center">
          <div className="form-group col-12 col-sm-12 col-md-3">
            <label htmlFor="" className="mt-2">
              Start Time:
            </label>
          </div>
          <div className=" form-group col-12 col-sm-6 col-md-5">
            <input
              name="startDate"
              type="date"
              className="form-control"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-md-4">
            <input
              name="startTime"
              type="time"
              className="form-control"
              onChange={(e) => setStartTime(e.target.value)}
              value={startTime}
            />
          </div>
        </div>
        <div className="form-row align-items-center">
          <div className="form-group col-12 col-sm-12 col-md-3">
            <label htmlFor="" className="mt-2">
              End Time:
            </label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5">
            <input
              name="endDate"
              type="date"
              className="form-control"
              onChange={(e) => setEndDate(e.target.value)}
              defaultValue={endDate}
            />
          </div>
          <div className="form-group col-12 col-sm-6 col-md-4">
            <input
              name="endTime"
              type="time"
              className="form-control"
              onChange={(e) => setEndTime(e.target.value)}
              defaultValue={endTime}
            // value=
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 text-right">
            <button
              className=" btn btn-link text-site "
              onClick={() => {
                // BootstrapUtils.ModalHideById(Modals.CreateScheduleModal);
                // handleCloseModal()
                props.handleShow(false);
              }}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary make-schedule-submit-btn"
              onClick={() => {
                handleSave();
                // handleCloseModal();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
