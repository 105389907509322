import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { mutate } from "swr";
import { FormErrorMessage } from "../../components";
import { AppRouteUi } from "../../configs";
import { ApiSchemaCreateVendorCompany } from "../../models";
import { useFunctionalityVendorCompany } from "../../pages/hooks";

type Props = {
  companyId: string,
  show: boolean;
  handleShow: (value: boolean) => void;
  pagedVendorCompanyUrl: string;
  companyWithoutVendorUrl: string
};

export function ModalAddVendorCompany(props: Props): JSX.Element {
  const { companyId, pagedVendorCompanyUrl, companyWithoutVendorUrl } = props;
  const { onAddCompany, companyListWithoutVendor } = useFunctionalityVendorCompany({ companyId: companyId, showModal: props.show });
  const vendorId = AppRouteUi.Vendors.Profile.useParam();

  const { register, handleSubmit, errors, setValue } = useForm<ApiSchemaCreateVendorCompany>();
  const onCreateHandler = (form: ApiSchemaCreateVendorCompany) => {
    onAddCompany(form)
      .then(() => {
        mutate(pagedVendorCompanyUrl)
        mutate(companyWithoutVendorUrl)
        props.handleShow(false)
      })
      .catch((e: any) => { console.error(e) })
  }
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      id="addVendorCompanyModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Company</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onCreateHandler)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Company
                </label>
                <input
                  type="text"
                  name="companyId"
                  className="d-none"
                  ref={register({ required: true })}
                />
                <Select
                  className={` ${errors.companyId?.type == "required" ? 'field-required' : ''} `}

                  placeholder="Select company"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={companyListWithoutVendor}
                  onChange={(value) => {
                    setValue("companyId", value?.value);
                  }}
                />
                {errors.companyId && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase login-from-submit-btn"
          >
            Create
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
