import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select, { createFilter, OptionsType } from "react-select";
import { AssetSvg } from "../../assets";
import { FormErrorMessage } from "../../components";
import { reactSelectFilterConfig } from "../../configs";
import { InputFieldValidationErrorMessage, topLoaderProgress } from "../../configs/appConstants";
import { ApiSchemaCreateLanguagePack, ApiSchemaLanguagePackData, CallCenterInflowLanguagePackRequestModel, Option, PlatformType, TypeLanguageList } from "../../models";
import { useFunctionalityCallCenterInflow, useFunctionalityLanguage, } from "../../pages/hooks";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { ModalFooter } from "../Common";

type Props = {
  show?: boolean;
  handleShow: (value: boolean) => void;
  dataToEdit: ApiSchemaLanguagePackData;
  callCenterId: string;
  platformType: PlatformType;
  setLoaderProgress: any;
  setInflowStatus: any;
  setInflowSettings: any;
};

const languageRateMap = new Map();

export function ModalInflowLanguagePack(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, callCenterId, platformType, setInflowStatus, setInflowSettings, setLoaderProgress, } = props;
  const [disableProp, setDisableProp] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm<ApiSchemaCreateLanguagePack>();
  const [zeroRateState, setZeroRateState] = useState<any>([]);
  const [languageFlatRateError, setLanguageFlatRateError] = useState<string>("");
  const [flatRateToggle, setFlatRateToggle] = useState<boolean>(true);
  let tempOption: Option[] = [];
  let tempLanguageList: TypeLanguageList[] = [];
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(tempOption);
  const [listOfLanguages, setListOfLanguages] = useState<TypeLanguageList[]>(tempLanguageList);

  const { numberFieldValidation, handleCopyPasteInvalidValue, handleLimitDecimalPlaces } = useFunctionalityInputChecker();

  const { languageList } = useFunctionalityLanguage({ plaformType: platformType, shouldLanguageGetAllInvoke: show, });
  const languageListForSelect = [
    { label: "Select All", value: "all" },
    ...languageList,
  ];
  const { onCreateCallInflowPack, onSubmit, LoadData } =
    useFunctionalityCallCenterInflow({
      callCenterId: callCenterId,
      setLoaderProgress,
    });

  const handleRateChange = (event: any, languageId: string) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    const newState = [
      ...zeroRateState?.filter((zeroRate: any) => zeroRate?.name !== name),
      {
        name: name,
        value: value,
      },
    ];
    setZeroRateState(newState);
    languageRateMap.set(languageId, value ? value : 0.00);
  };
  useEffect(() => {
    languageRateMap.clear();
    if (dataToEdit?.languageRates) {
      tempOption = dataToEdit?.languageRates.map((t) => {
        return {
          value: t.languageId,
          label: t.languageName,
        };
      });
      tempLanguageList = dataToEdit?.languageRates.map((option) => {
        return {
          rate: option?.rate,
          languageId: option.languageId,
          languageName: option.languageName,
        };
      });
      let initialRates: any = [];
      dataToEdit?.languageRates?.forEach((option, index) => {
        languageRateMap.set(option.languageId, option?.rate);
        initialRates = [
          ...initialRates,
          {
            name: `languageRates[${index}].rate`,
            value: option?.rate,
          },
        ];
      });
      setZeroRateState(initialRates);
    }
    dataToEdit && dataToEdit.flatRate > 0
      ? setFlatRateToggle(true)
      : setFlatRateToggle(false);

    setSelectedOptions(tempOption);
    setListOfLanguages(tempLanguageList);
  }, [dataToEdit]);

  const onSubmitHandler = (form: ApiSchemaCreateLanguagePack) => {
    const languageRate: TypeLanguageList[] = form.languageRates ? form.languageRates : listOfLanguages;
    form = {
      ...form,
      flatRate: form.flatRate && parseFloat(form.flatRate.toString()),
      name: dataToEdit.name,
      languageRates: languageRate.map((t) => {
        return {
          languageId: t.languageId,
          languageName: t.languageName,
          rate: parseFloat(t.rate?.toString() ?? "0"),
          id: t.id,
        };
      }),
      vendorCompanyId: "",
    };
    let createForm: CallCenterInflowLanguagePackRequestModel = {
      platformType: platformType,
      callCenterId: callCenterId,
      languagePackRequestModel: form,
    };
    dataToEdit.id
      ? onSubmit(form, dataToEdit.id).then(() => {
        handleShow(false);
        LoadData().then((d) => {
          if (d?.data) {
            setInflowSettings(d?.data);
            setInflowStatus(d?.data.enabled);
          }
          setLoaderProgress(topLoaderProgress.complete);
        });
      })
      : onCreateCallInflowPack(createForm).then(() => {
        handleShow(false);
        LoadData().then((d) => {
          if (d?.data) {
            setInflowSettings(d?.data);
            setInflowStatus(d?.data.enabled);
          }
          setLoaderProgress(topLoaderProgress.complete);
        });
      });
  };

  const handleFlatRateButtonToggle = (previousToggle: boolean) => {
    setFlatRateToggle(!flatRateToggle);
    if (!previousToggle && dataToEdit?.flatRate <= 0 || dataToEdit?.flatRate === undefined) {
      setDisableProp(true);
      setLanguageFlatRateError("flat rate must be greater than zero");
    } else {
      setDisableProp(false);
      setLanguageFlatRateError("");
    }
  };

  const handleFlatRate = (event: any) => {
    const value = event.target.value;
    if (value.length == 0) {
      setLanguageFlatRateError("")
    }
    if (Number(value) <= 0) {
      setDisableProp(true);
      setLanguageFlatRateError("flat rate must be greater than zero");
    } else {
      setDisableProp(false);
      setLanguageFlatRateError("");
    }
  };

  const onRemoveItem = (id: string) => {
    setSelectedOptions(
      selectedOptions.filter((t) => {
        return t.value != id;
      })
    );
    setListOfLanguages(
      listOfLanguages.filter((t) => {
        return t.languageId != id;
      })
    );
  };
  useEffect(() => {
    const hasZeroRate = zeroRateState?.find((zero: any) => zero?.value < 0.01);
    if (hasZeroRate && zeroRateState?.length > 0 && !flatRateToggle) {
      setDisableProp(true);
    } else if (flatRateToggle && languageFlatRateError) {
      setDisableProp(true);
    } else {
      setDisableProp(false);
    }
  }, [zeroRateState, flatRateToggle]);
  const handleAddLanguage = (selected: OptionsType<Option>) => {
    const selectedOptions: any =
      selected.length && selected.find((option) => option.value === "all")
        ? languageListForSelect.slice(1)
        : selected;
    setSelectedOptions(selectedOptions);
    const list: TypeLanguageList[] =
      selected.length && selected.find((option) => option.value === "all")
        ? languageListForSelect.slice(1).map((option) => {
          return {
            rate: 0,
            languageId: option.value,
            languageName: option.label,
          };
        })
        : selected.map((option) => {
          const languageAlreadyExists = listOfLanguages?.find(
            (language) => language?.languageId === option?.value
          );
          return {
            rate: languageAlreadyExists ? languageAlreadyExists?.rate : 0,
            languageId: option.value,
            languageName: option.label,
          };
        });

    setListOfLanguages(list);
    let selectAllRateState: any = [];
    list?.forEach((option, index) => {
      const previousRate = languageRateMap?.has(option?.languageId)
        ? languageRateMap?.get(option?.languageId)
        : option?.rate;
      languageRateMap?.set(option?.languageId, previousRate);
      selectAllRateState = [
        ...selectAllRateState,
        {
          name: `languageRates[${index}].rate`,
          value: previousRate ? previousRate : option?.rate,
        },
      ];
    });

    setZeroRateState(selectAllRateState);
  };
  return (
    <Modal centered show={props.show} onHide={() => props.handleShow(false)}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {dataToEdit.id ? "Update" : "Add"} Language Pack
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required font-sz-14">
                  Package Name
                </label>
                <input
                  type="text"
                  name="name"
                  disabled
                  className="form-control"
                  placeholder="Package name"
                  defaultValue={dataToEdit.name}
                  ref={register({ required: true })}
                />
                {errors.name?.type === "required" && <FormErrorMessage message="required" />}
              </div>
              <div className="form-inline mb-3 flex-wrap align-items-sm-center">
                <div className="form-group col-12 pl-0 pr-0 ">
                  <Select
                    placeholder="Select Language"
                    className="w-100"
                    options={languageListForSelect}
                    value={selectedOptions}
                    isMulti
                    isDisabled={languageList.length === 0 ? true : false}
                    closeMenuOnSelect={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    onChange={(selected) => handleAddLanguage(selected)}
                  />
                </div>
              </div>

              <div className="component-card rounded mb-3 shadow-none">
                <div className="component-card-header border rounded-top ">
                  <div className="row align-items-start">
                    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div>Language</div>
                      {listOfLanguages && listOfLanguages.length === 0 ? (
                        <div className="text-danger font-sz-12 ">
                          Contains no language
                        </div>
                      ) : (
                        <div className="text-muted font-sz-12 ">
                          Total {listOfLanguages.length} Selected Languages
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <div className="row  flex-wrap  align-items-start mx-0">
                        <button
                          type="button"
                          className={`btn btn-toggle language-toggle-btn mt-1 ${dataToEdit.flatRate != undefined &&
                            dataToEdit.flatRate > 0 ? "active" : ""}`}
                          data-toggle="button"
                          aria-pressed="false"
                          onClick={() =>
                            handleFlatRateButtonToggle(flatRateToggle)
                          }
                        >
                          <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label  text-muted ">
                          Flat Language Rate
                        </span>
                        {flatRateToggle ? (
                          <>
                            <div className="input-group col pr-0">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="flat-rate"> $ </span>
                              </div>
                              <input
                                type="float"
                                name="flatRate"
                                className={`form-control ${languageFlatRateError ? "field-required" : ""
                                  }`}
                                defaultValue={dataToEdit.flatRate ?? 0}
                                aria-describedby="flatRate"
                                ref={register({
                                  // required: true,
                                  validate: handleCopyPasteInvalidValue,
                                })}
                                style={{ border: errors.flatRate || languageFlatRateError ? '1px solid red' : '' }}
                                onKeyDown={(evt) =>
                                  numberFieldValidation(evt)
                                }
                                onKeyPress={(event) => {
                                  if (!/[0-9]|\./.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(event) => { handleFlatRate(event) }}
                                //onChange={handleFlatRate}
                                onInput={(event) =>
                                  handleLimitDecimalPlaces(event, 2)
                                }
                              />
                              {/* {
                                errors.flatRate?.type === "required" && (
                                  <FormErrorMessage message="required" />
                                )} */}
                              {errors.flatRate &&
                                errors.flatRate.type === "validate" && (
                                  <FormErrorMessage message="Please enter a valid value" />
                                )}
                              {languageFlatRateError && (
                                <div className="font-sz-10 text-danger">
                                  {languageFlatRateError}
                                </div>
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="component-card-body rounded-bottom border-left border-right border-bottom">
                  <ul className="list-group">
                    {listOfLanguages.map((language, index) => (
                      <li className="list-group-item" key={language.languageId}>
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-6">
                            <p className="word-wrap mb-sm-0 mb-2 pt-2">
                              {language.languageName}
                            </p>
                          </div>
                          <div className="col-12 col-sm-5 col-md-5 pr-sm-0 mb-2 mb-sm-0">
                            {flatRateToggle ? null : (
                              <span>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text" id="flat-rate">$</span>
                                  </div>
                                  <input
                                    type="hidden"
                                    className="form-control"
                                    name={`languageRates[${index}].languageName`}
                                    aria-describedby="languageName"
                                    defaultValue={language.languageName}
                                    ref={register({
                                      required: flatRateToggle ? true : false,
                                    })}
                                  />
                                  {/* <input
                                    type="hidden"
                                    className="form-control"
                                    name={`languageRates[${index}].id`}
                                    aria-describedby="id"
                                    defaultValue={language.id}
                                    ref={register({
                                      required: flatRateToggle ? true : false,
                                    })}
                                  /> */}
                                  <input
                                    type="hidden"
                                    className="form-control"
                                    name={`languageRates[${index}].languageId`}
                                    aria-describedby="languageId"
                                    defaultValue={language.languageId}
                                    ref={register({
                                      required: flatRateToggle ? true : false,
                                    })}
                                  />
                                  <input
                                    key={Date.now() + ""}
                                    type="number"
                                    min="0"
                                    step="any"
                                    name={`languageRates[${index}].rate`}
                                    className={`form-control ${languageRateMap.get(
                                      language?.languageId
                                    ) === 0
                                      ? "field-required"
                                      : ""
                                      }`}
                                    // onChange={handleFlatRate}
                                    defaultValue={languageRateMap.get(
                                      language?.languageId
                                    ) === 0.00 || languageRateMap.get(
                                      language?.languageId
                                    ) === null ? 0 : languageRateMap.get(
                                      language?.languageId
                                    )}
                                    aria-describedby="rate"
                                    ref={register({
                                      required:
                                        flatRateToggle ||
                                          languageRateMap.get(
                                            language?.languageId
                                          ) === 0
                                          ? true
                                          : false,
                                      validate: handleCopyPasteInvalidValue,
                                    })}
                                    style={{
                                      border: languageRateMap.get(language?.languageId) ===
                                        '0.00' ? '1px solid red' : ''
                                    }}
                                    onKeyDown={(evt) =>
                                      numberFieldValidation(evt)
                                    }
                                    onKeyPress={(event) => {
                                      if (!/[0-9]|\./.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onBlur={(event) => {
                                      handleRateChange(
                                        event,
                                        language?.languageId
                                      );
                                    }}
                                    onInput={(event) =>
                                      handleLimitDecimalPlaces(event, 2)
                                    }
                                  />
                                  {errors.languageRates?.[index]?.rate &&
                                    errors.languageRates?.[index]?.rate
                                      ?.type === "required" && (
                                      <FormErrorMessage message="required" />
                                    )}
                                  {errors.languageRates?.[index]?.rate &&
                                    errors.languageRates?.[index]?.rate
                                      ?.type === "validate" && (
                                      <FormErrorMessage message="Please enter a valid value" />
                                    )}
                                  {languageRateMap.get(language?.languageId) ===
                                    0.00 && (
                                      <span className="font-sz-14 text-danger">
                                        must be greater than 0
                                      </span>
                                    )}
                                </div>
                              </span>
                            )}
                          </div>
                          <span className="col-12 col-sm-1 col-md-1 px-sm-2 text-left text-sm-center ">
                            <button
                              type="button"
                              className="btn btn-link px-0 text-site svg-color"
                              onClick={() => {
                                onRemoveItem(
                                  //dataToEdit.languageRates[index]?.languageId
                                  language.languageId
                                );
                              }}
                            >
                              <span >
                                <AssetSvg.Delete />
                              </span>
                            </button>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <ModalFooter
            handleShow={handleShow}
            zeroRateLanguagePack={disableProp}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
}
