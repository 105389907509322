import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { TransactionType } from "../../configs";
import { MarkAsPaidRequestModel, } from "../../models";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { useFunctionalityInvoice } from "../../pages/hooks/functionalityInvoice";
import { useFunctionalityTransaction } from "../../pages/hooks/functionalityTransaction";
import DatePicker from "react-datepicker";
import { AssetSvg } from "../../assets";
import { handleNumberToTwoDecimalPoint } from "../../utils";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  companyId: string;
  adminRole: string;
  transactionType: TransactionType;
  dataToSave: MarkAsPaidRequestModel;
  setDataToSave?: (value: MarkAsPaidRequestModel) => void;
  modalTitle: string;
  setModalTitle?: (value: string) => void;
  pagedInvoiceUrl?: string;
  onUpdateSuccess?: boolean;
  setOnUpdateSuccess?: any;
};

export function ModalMarkAsPaid(props: Props): JSX.Element {
  const { show, handleShow, transactionType, dataToSave, modalTitle, setOnUpdateSuccess } = props;
  const { handleCopyPasteInvalidValue, } = useFunctionalityInputChecker();
  const { onMarkAsPaid } = useFunctionalityTransaction({
    transactionType: transactionType,
  });
  const { register, handleSubmit, errors, control } = useForm<MarkAsPaidRequestModel>();
  const modalHideHandler = () => {
    handleShow(false);
  };
  const onCreateHandler = (form: MarkAsPaidRequestModel) => {
    form = {
      ...form,
      accountId: dataToSave.accountId,
      invoiceId: dataToSave.invoiceId,
      amount: dataToSave.amount,
      currency: dataToSave.currency,
      invoiceType: dataToSave.invoiceType,
    };
    onMarkAsPaid(form)
      .then(() => {
        setOnUpdateSuccess?.((prev: boolean) => !prev);
        // mutate(pagedInvoiceUrl!);
        modalHideHandler();
      })
      .catch((e: any) => {
        console.error(e);
      });
  };
  const { downloadInvoicePdf } = useFunctionalityInvoice({});
  const downloadPdf = () => {
    downloadInvoicePdf(
      dataToSave.invoiceId ?? "",
      dataToSave.invoiceType ?? 0
    ).then((data) => {
      if (data) {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = `${dataToSave.invoice}-${moment().format(
          "MM-YYYY"
        )}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  };
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      id="markAsPaidModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onCreateHandler)}>
        <Modal.Body>
          {transactionType == TransactionType.CustomerBilling ? (
            <div className="row">
              <div className="col-8">
                <div className="form-group">
                  <label htmlFor="" className="position-relative">
                    {dataToSave.invoice} Billing for {dataToSave.from} to{" "}
                    {dataToSave.to}
                  </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor=""
                    className="position-relative"
                  >
                    {
                      `Amount: ${handleNumberToTwoDecimalPoint(dataToSave.amount ?? 0)}`
                    }
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="" className="position-relative required">
                      Amount
                    </label>
                    <input
                      type="float"
                      name="amount"
                      defaultValue={dataToSave.amount}
                      className="form-control"
                      ref={register({
                        required: true,
                        validate: handleCopyPasteInvalidValue,
                      })}
                      onKeyPress={(event) => {
                        if (!/[0-9]|\./.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.amount && errors.amount?.type === "required" && (
                      <FormErrorMessage message="required" />
                    )}
                    {errors.amount && errors.amount?.type === "validate" && (
                      <FormErrorMessage message="Please enter a valid value" />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Check Number
                </label>
                <input
                  type="text"
                  name="checkNumber"
                  className="form-control"
                  ref={register({ required: true })}
                />
                {errors.checkNumber && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Paid on
                </label>
                <Controller
                  defaultValue={moment().toDate()}
                  name="paidDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <DatePicker
                      // minDate={moment().toDate()}
                      dateFormat="MM/dd/yyyy"
                      className="form-control"
                      selected={value}
                      onChange={onChange}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  )}
                />
                {errors.paidDate && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative">
                  Note
                </label>
                <textarea
                  placeholder="Note"
                  name="notes"
                  data-resize="none"
                  className="form-control"
                  ref={register()}
                  rows={5}
                  cols={50}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-light border"
                  onClick={downloadPdf}
                >
                  <div
                    className="btn svg-color skill-form-submit-btn"
                    title="financeFileDownload"
                  >
                    <span >
                      <AssetSvg.Download />
                    </span>
                  </div>
                  Download to pc
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase btn-mark-as-paid company-user-from-submit-btn"
          >
            Confirm mark as paid
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
