import { useParams } from "react-router-dom";
import { CalendarProvider, InvoiceGenerationType } from "./appConstants";
import {
  AccountType,
  CallLogType,
  CallStatusType,
  CompanyType,
  GenderType,
  InvoiceType,
  OperatorCriteriaType,
  PlatformType,
} from ".";

const RootUi = process.env.PUBLIC_URL || "";
const RootPlatform = process.env.REACT_APP_PLATFORM_API || "";
const RootVRI = process.env.REACT_APP_VRI_ROOT_API || "";
const RootOPI = process.env.REACT_APP_OPI_ROOT_API || "";
const RootWebSocket = process.env.REACT_APP_VRIWebSocket_ROOT_API || "";
const RootFileBucketURL = process.env.REACT_APP_FileBucketURL_ROOT_URL || "";

export const PlatformApi = {
  Root: `${RootPlatform}/api`,
  Activity: {
    Root: () => `${PlatformApi.Root}/Activity`,
    AuditLogs: (
      pageSize?: number,
      pageNo?: number,
      searchText?: string,
      filterDataChange?: number
    ) => {
      let query = `${PlatformApi.Activity.Root()}/GetAuditLogs?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      if (filterDataChange) query = `${query}&randomQuery=${filterDataChange}`;
      return query;
    },
  },
  ErrorSubmit: {
    Root: () => `${PlatformApi.Root}/Error-submit`,
  },
  Address: {
    Root: () => `${PlatformApi.Root}/Address`,
    Countries: () => `${PlatformApi.Address.Root()}/GetCountryNames`,
    States: (id: string) =>
      `${PlatformApi.Address.Root()}/GetStateNames?countryId=${id}`,
  },
  Authentication: {
    Root: () => `${PlatformApi.Root}/Auth`,
    GetUserStatus: () => `${PlatformApi.Authentication.Root()}/GetUserStatus`,
    Token: () => `${PlatformApi.Authentication.Root()}/WebToken`,
    Login: {
      Root: () => `${PlatformApi.Authentication.Root()}/Login`,
    },
    ExternalLogin: {
      Root: () => `${PlatformApi.Authentication.Root()}/externalLogin`,
    },
    Register: {
      Root: () => `${PlatformApi.Authentication.Root()}/register`,
    },
    ChangePassword: {
      Root: () => `${PlatformApi.Authentication.Root()}/ChangePassword`,
    },
    ActivateAccount: {
      Root: () => `${PlatformApi.Authentication.Root()}/ActivateAccount`,
    },
    ForgotPassword: {
      Root: () => `${PlatformApi.Authentication.Root()}/ForgetPassword`,
    },
    ResetPassword: {
      Root: () => `${PlatformApi.Authentication.Root()}/ResetPassword`,
    },
    Logout: {
      Root: () => `${PlatformApi.Authentication.Root()}/Logout`,
    },
    ForceOffline:  {
      Root: (userId: string) => `${PlatformApi.Authentication.Root()}/ForceOffline/${userId}`,
    },
  },
  Call: {
    Root: () => `${PlatformApi.Root}/Call`,
    Create: () => `${PlatformApi.Call.Root()}/Create`,
    Update: (callId: string) => `${PlatformApi.Call.Root()}/Update/${callId}`,
    ParticipantDisconnected: (callId: string, participantUserId: string) =>
      `${PlatformApi.Call.Root()}/ParticipantDisconnected/${callId}/${participantUserId}`,
    CompleteCall: (callId: string, endTime: string) =>
      `${PlatformApi.Call.Root()}/CompleteCall/${callId}?endTime=${endTime}`,
    GetCallSummary: (callId: string) =>
      `${PlatformApi.Call.Root()}/GetCallSummary/${callId}`,
    GetRecentCalls: (
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Call.Root()}/GetRecentCalls?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetCallLog: (
      callLogType: CallLogType,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Call.Root()}/GetCallLog?callLogType=${callLogType}&PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    FilteredGetCallLog: (
      callLogType: CallLogType,
      pageSize?: number,
      pageNo?: number,
      searchText?: string,
      filterDataChange?: number
    ) => {
      let query = `${PlatformApi.Call.Root()}/FilteredGetCallLog/${callLogType}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      if (filterDataChange) query = `${query}&random=${filterDataChange}`;
      return query;
    },
    GetParticipantInfo: (roomId: string, userId: string) => {
      return`${PlatformApi.Call.Root()}/GetCallParticipantInfo/${roomId}/${userId}`;
    }
  },
  ColumnSelections: {
    Root: () => `${PlatformApi.Root}/ColumnSelection`,
    GetColumnSelections: (type: number) =>
      `${PlatformApi.ColumnSelections.Root()}/GetColumnSelections?type=${type}`,
  },
  CallCenter: {
    Root: () => `${PlatformApi.Root}/CallCenter`,
    GetAllMessages: (callCenterId: string) =>
      `${PlatformApi.CallCenter.Root()}/GetAllMessages/${callCenterId}`,
    UpdateCallCenterMessage: (id: string) =>
      `${PlatformApi.CallCenter.Root()}/UpdateCallCenterMessage/${id}`,
    Inflow: {
      GetInflowSettings: (callCenterId: string) =>
        `${PlatformApi.CallCenter.Root()}/GetInflowSettings/${callCenterId}`,
      UpdateCallInflow: () =>
        `${PlatformApi.CallCenter.Root()}/UpdateCallCenterInflow`,
      CreateInflowLanguagePack: () =>
        `${PlatformApi.CallCenter.Root()}/CreateInflowLanguagePack`,
    },
    Activity: (
      callCenterId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string,
      filterDataChange?: number
    ) => {
      let query = `${PlatformApi.Root}/Activity/GetCallCenterActivities/${callCenterId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      if (filterDataChange) query = `${query}&randomQuery=${filterDataChange}`;
      return query;
    },
    GetPhoneNumberList: (callCenterId: string) =>
      `${PlatformApi.CallCenter.Root()}/GetPhoneList/${callCenterId}`,
    AddPhoneNumber: (callCenterId: string, phoneNumber: string) =>
      `${PlatformApi.CallCenter.Root()}/AddPhoneNumber/${callCenterId}?phoneNumber=${phoneNumber}`,
    RemovePhoneNumber: (id: string) =>
      `${PlatformApi.CallCenter.Root()}/RemovePhoneNumber/${id}`,
  },
  CallNotification: {
    Root: () => `${PlatformApi.Root}/CallNotification`,
    OnOutGoing: {
      Root: () => `${PlatformApi.CallNotification.Root()}/OnOutGoing`,
    },
    OnReceived: {
      Root: () => `${PlatformApi.CallNotification.Root()}/OnReceived`,
    },
  },
  CallOutflow: {
    Root: () => `${PlatformApi.Root}/CallCenter`,
    GetOutflowListByCompanyId: (companyId: string) =>
      `${PlatformApi.CallOutflow.Root()}/GetOutflowList/${companyId}`,
    ChangeCallOutflowStatus: () =>
      `${PlatformApi.CallOutflow.Root()}/SetCallOutflowStatus`,
  },
  Company: {
    Root: () => `${PlatformApi.Root}/Company`,
    GetAll: (
      parentCompanyId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Company.Root()}/GetAll/${parentCompanyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetCompanyNames: (parentCompanyId: string, includeReseller?: boolean) => {
      let query = `${PlatformApi.Company.Root()}/GetCompanyNames`;
      if (parentCompanyId)
        query = `${query}?parentCompanyId=${parentCompanyId}`;
      if (includeReseller)
        query = `${query}?includeReseller=${includeReseller}`;
      return query;
    },
    Create: () => `${PlatformApi.Company.Root()}/Create`,
    GetById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetById/${companyId}`,
    UpdateCompanySkill: (companyId: string) =>
      `${PlatformApi.Company.Root()}/UpdateCompanySkill/${companyId}`,
    UpdateCompanyModality: (companyId: string) =>
      `${PlatformApi.Company.Root()}/UpdateCompanyModality/${companyId}`,
    UpdateCompanyAppointmentCategory: (companyId: string) =>
      `${PlatformApi.Company.Root()}/UpdateCompanyAppointmentCategory/${companyId}`,
    UpdateCompanyAppointmentDescription: (companyId: string) =>
      `${PlatformApi.Company.Root()}/UpdateCompanyAppointmentDescription/${companyId}`,
    UpdateCompanyPack: (id: string) =>
      `${PlatformApi.Company.Root()}/UpdateCompanyLanguagePack/${id}`,
    UpdateCompanyProfile: (id: string) =>
      `${PlatformApi.Company.Root()}/UpdateCompanyProfile/${id}`,
    UpdateCompanyBookingPolicy: (companyId: string) =>
      `${PlatformApi.Company.Root()}/UpdateCompanyBookingPolicy/${companyId}`,
    UploadLogo: (id: string) =>
      `${PlatformApi.Company.Root()}/UploadLogo/${id}`,
    Delete: (id: string) => `${PlatformApi.Company.Root()}/Delete/${id}`,
    IsCodeExist: (companyCode: string) =>
      `${PlatformApi.Company.Root()}/IsCodeExist?code=${companyCode}`,

    GetCompanyProfileById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetCompanyProfileById/${companyId}`,
    GetContractById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetContractById/${companyId}`,
    GetIvrById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetIvrById/${companyId}`,
    GetPackageById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetPackageById/${companyId}`,
    GetSkillById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetSkillById/${companyId}`,
    GetContactById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetContactById/${companyId}`,
    GetSettingsById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetSettingsById/${companyId}`,
    GetModalityById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetModalityById/${companyId}`,
    GetAppointmentTypeById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetAppointmentTypeById/${companyId}`,
    GetDescriptionById: (companyId: string) =>
      `${PlatformApi.Company.Root()}/GetDescriptionById/${companyId}`,
    IsCompanyStatusActive: (companyId: string) =>
      `${PlatformApi.Company.Root()}/IsActive/${companyId}`,
    ChangeCompanyContact: (companyId: string, userId: string) =>
      `${PlatformApi.Company.Root()}/ChangeCompanyContact/${companyId}/${userId}`,
  },
  CompanyContract: {
    Root: () => `${PlatformApi.Root}/CompanyContract`,
    UpdateSubscription: (companyId: string) =>
      `${PlatformApi.CompanyContract.Root()}/UpdateSubscription/${companyId}`,
    UpdateVri: (contractId: string) =>
      `${PlatformApi.CompanyContract.Root()}/UpdateVriContractFee/${contractId}`,
    UpdateOpi: (contractId: string) =>
      `${PlatformApi.CompanyContract.Root()}/UpdateOpiContractFee/${contractId}`,
    UpdateOnSite: (contractId: string) =>
      `${PlatformApi.CompanyContract.Root()}/UpdateOnSiteContractFee/${contractId}`,
    UpdateLateFee: (companyId: string) =>
      `${PlatformApi.CompanyContract.Root()}/UpdateContractLateFee/${companyId}`,
    UploadDocument: (contractId: string) =>
      `${PlatformApi.CompanyContract.Root()}/UploadDocument/${contractId}`,
    DeleteDocument: (contractId: string) =>
      `${PlatformApi.CompanyContract.Root()}/DeleteDocument/${contractId}`,
    UpdateBossSystem: (contractId: string) =>
      `${PlatformApi.CompanyContract.Root()}/UpdateBossSystem/${contractId}`,
  },
  CompanyIvr: {
    Root: () => `${PlatformApi.Root}/CompanyIvr`,
    UpdateDialKeyMapping: (ivrId: string) =>
      `${PlatformApi.CompanyIvr.Root()}/UpdateDialKeyMapping/${ivrId}`,
    UpdateMessage: (ivrId: string) =>
      `${PlatformApi.CompanyIvr.Root()}/UpdateMessage/${ivrId}`,
    UpdateCustomFields: (ivrId: string) =>
      `${PlatformApi.CompanyIvr.Root()}/UpdateCustomFields/${ivrId}`,
    UpdateThreeWayCall: (ivrId: string) =>
      `${PlatformApi.CompanyIvr.Root()}/UpdateThreeWayCall/${ivrId}`,
  },
  CompanyCancellationReason: {
    Root: () => `${PlatformApi.Root}/CompanyCancellationReason`,
    GetAll: (
      companyId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.CompanyCancellationReason.Root()}/Get/${companyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetById: (id: string) =>
      `${PlatformApi.CompanyCancellationReason.Root()}/GetById/${id}`,
    Create: (companyId: string) =>
      `${PlatformApi.CompanyCancellationReason.Root()}/Create/${companyId}`,
    Update: (id: string) =>
      `${PlatformApi.CompanyCancellationReason.Root()}/Update/${id}`,
    Delete: (id: string) =>
      `${PlatformApi.CompanyCancellationReason.Root()}/Delete/${id}`,
  },

  CompanySettings: {
    Root: () => `${PlatformApi.Root}/CompanySettings`,
    ChangeAccountStatus: (companyId: string, isActive?: boolean) =>
      `${PlatformApi.CompanySettings.Root()}/ChangeAccountStatus/${companyId}?isActive=${isActive}`,
    ChangeCallSettingStatus: (callSettingId: string, isActive?: boolean) =>
      `${PlatformApi.CompanySettings.Root()}/ChangeCallSettingStatus/${callSettingId}?isActive=${isActive}`,
    ChangeCustomSettingStatus: (customSettingId: string, isActive?: boolean) =>
      `${PlatformApi.CompanySettings.Root()}/ChangeCustomSettingStatus/${customSettingId}?isActive=${isActive}`,
    ChangeCallCenterSettingStatus: (
      customSettingId: string,
      isActive?: boolean
    ) =>
      `${PlatformApi.CompanySettings.Root()}/ChangeCallCenterSettingStatus/${customSettingId}?isActive=${isActive}`,
    ChangeCustomFieldName: (customSettingId: string) =>
      `${PlatformApi.CompanySettings.Root()}/ChangeCustomFieldName/${customSettingId}`,
  },
  Department: {
    Root: () => `${PlatformApi.Root}/Department`,
    PagedGetAll: (
      companyId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Department.Root()}/GetAll/${companyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetAll: (companyId: string) =>
      `${PlatformApi.Department.Root()}/GetAll/${companyId}`,
    GetNames: (companyId: string) =>
      `${PlatformApi.Department.Root()}/GetNames?companyId=${companyId}`,
    GetDetailsById: (departmentId: string) =>
      `${PlatformApi.Department.Root()}/${departmentId}`,
    Create: () => `${PlatformApi.Department.Root()}/Create `,
    Update: (id: string) => `${PlatformApi.Department.Root()}/Update/${id}`,
    Delete: (id: string) => `${PlatformApi.Department.Root()}/${id}`,
  },
  Export: {
    Root: () => `${PlatformApi.Root}/Export`,
    ExportAsExcel: (
      tableType: number,
      callCenterId?: string,
      companyId?: string
    ) => {
      let query = `${PlatformApi.Export.Root()}/ExportAsExcel/${tableType}?callCenterId=${callCenterId}&companyId=${companyId}`;
      return query;
    },
    ExportAsPdf: (
      tableType: number,
      callCenterId?: string,
      companyId?: string
    ) => {
      let query = `${PlatformApi.Export.Root()}/ExportAsPdf/${tableType}?callCenterId=${callCenterId}&companyId=${companyId}`;
      return query;
    },
    SendEmailReport: (
      tableType: number,
      email: string,
      callCenterId?: string,
      companyId?: string
    ) => {
      let query = `${PlatformApi.Export.Root()}/SendEmail/${tableType}?callCenterId=${callCenterId}&companyId=${companyId}&email=${email}`;
      return query;
    },
    eVosExportAsPdf: () => `${PlatformApi.Export.Root()}/ExportAsPdf `,
  },
  Finance: {
    Root: () => `${PlatformApi.Root}/Finance`,
    Account: {
      PagedGetAll: (
        parentCompanyId: string,
        accountType: AccountType,
        pageSize?: number,
        pageNo?: number,
        searchText?: string,
        filterDataChange?: number
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetAllAccounts/${parentCompanyId}/${accountType}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        if (filterDataChange) query = `${query}&random=${filterDataChange}`;
        return query;
      },
      GetAll: (parentCompanyId: string, accountType: AccountType) =>
        `${PlatformApi.Finance.Root()}/GetAllAccounts/${parentCompanyId}/${accountType}`,
    },
    Invoice: {
      Regenerate: () => `${PlatformApi.Finance.Root()}/RegenerateInvoice`,
      PagedGetAll: (
        companyType: CompanyType,
        pageSize?: number,
        pageNo?: number,
        searchText?: string
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetAllInvoices/${companyType}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        return query;
      },
      SendEmail: (invoiceId: string, invoiceType: InvoiceType) => {
        let query = `${PlatformApi.Finance.Root()}/SendEmailByInvoiceId/${invoiceId}/${invoiceType}`;
        return query;
      },
      SaveBillingAddress: (invoiceType: InvoiceType, billerId?: string) => {
        let query = `${PlatformApi.Finance.Root()}/SaveBillingAddress/${billerId}/${invoiceType}`;
        return query;
      },
      PagedGetAllByCompanyId: (
        invoiceType: InvoiceType,
        companyId?: string,
        pageSize?: number,
        pageNo?: number,
        searchText?: string,
        filterDataChange?: number
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetPagedCompanyInvoices/${invoiceType}?PageSize=${pageSize}&PageNo=${pageNo}&companyId=${companyId}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        if (filterDataChange) query = `${query}&random=${filterDataChange}`;
        return query;
      },
      PagedGetCompanyBookingInvoice: (
        companyId: string,
        invoiceType: InvoiceType,
        pageSize?: number,
        pageNo?: number,
        searchText?: string,
        filterDataChange?: number
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetPagedCompanyBookingInvoices/${invoiceType}?PageSize=${pageSize}&PageNo=${pageNo}&companyId=${companyId}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        if (filterDataChange) query = `${query}&random=${filterDataChange}`;
        return query;
      },
      GetPagedCompanyInvoices: (
        invoiceType: InvoiceType,
        pageSize?: number,
        pageNo?: number,
        searchText?: string,
        filterDataChange?: number
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetPagedCompanyInvoices/${invoiceType}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        if (filterDataChange) query = `${query}&random=${filterDataChange}`;
        return query;
      },
      GetPagedOperatorInvoices: (
        invoiceType: InvoiceType,
        userId?: string,
        pageSize?: number,
        pageNo?: number,
        searchText?: string,
        filterDataChange?: number
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetPagedOperatorInvoices/${invoiceType}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (userId) query = `${query}&userId=${userId}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        if (filterDataChange) query = `${query}&random=${filterDataChange}`;
        return query;
      },
      GetPagedOperatorBookingInvoices: (
        invoiceType: InvoiceType,
        userId?: string,
        pageSize?: number,
        pageNo?: number,
        searchText?: string,
        filterDataChange?: number
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetPagedOperatorBookingInvoices/${invoiceType}?PageSize=${pageSize}&PageNo=${pageNo}&userId=${userId}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        if (filterDataChange) query = `${query}&random=${filterDataChange}`;
        return query;
      },
      // GetAllInvoicesByCompanyId: (companyId: string) =>
      //   `${PlatformApi.Finance.Root()}/GetAllInvoicesByCompanyId/${companyId}`,
      // GetAllInvoicesByUserId: (userId: string) =>
      //   `${PlatformApi.Finance.Root()}/GetAllInvoicesByUserId/${userId}`,
      GetInvoiceById: (invoiceId: string, invoiceType: InvoiceType) =>
        `${PlatformApi.Finance.Root()}/GetInvoiceById/${invoiceId}/${invoiceType}`,
      DownloadInvoicePdf: (invoiceId: string, invoiceType: InvoiceType) =>
        `${PlatformApi.Finance.Root()}/DownloadInvoicePdf/${invoiceId}/${invoiceType}`,
      GetCompanyAccount: () =>
        `${PlatformApi.Finance.Root()}/GetCompanyAccount`,
      GenerateBookingInvoice: (bookingId: string) =>
        `${PlatformApi.Finance.Root()}/GenerateBookingInvoice/${bookingId}`,
    },
    Transaction: {
      PagedGetAllById: (
        id: string,
        invoiceType: InvoiceType,
        pageSize?: number,
        pageNo?: number,
        searchText?: string
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetTransactionListById/${id}/${invoiceType}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        return query;
      },
      PagedGetAll: (
        pageSize?: number,
        pageNo?: number,
        searchText?: string,
        id?: string
      ) => {
        let query = `${PlatformApi.Finance.Root()}/GetAllTransactions/${id}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        return query;
      },

      GetAll: () => `${PlatformApi.Finance.Root()}/GetAllTransactions`,
      MarkAsPaid: () => `${PlatformApi.Finance.Root()}/MarkAsPaid`,
    },
  },
  FinancialAccount: {
    Root: () => `${PlatformApi.Root}/FinancialAccount`,
    CompanyBillingAddress: (billerId?: string) => `${PlatformApi.FinancialAccount.Root()}/GetCompanyBillingAddress/${billerId}`,
    OperatorBillingAddress: (billerId?: string) => `${PlatformApi.FinancialAccount.Root()}/GetOperatorBillingAddress/${billerId}`,
    GetCompanyBalanceWithAddress: (CompanyId?: string) => `${PlatformApi.FinancialAccount.Root()}/GetCompanyBalanceWithAddress/${CompanyId}`,
    GetOperatorBalanceWithAddress: (OperatorUserId?: string) => `${PlatformApi.FinancialAccount.Root()}/GetOperatorBalanceWithAddress/${OperatorUserId}`,
  },
  Invoice: {
    Root: () => `${PlatformApi.Root}/Invoice`,
    invoiceGenerate: (invoiceId: string, invoiceGenerationType: InvoiceGenerationType) => `${PlatformApi.Invoice.Root()}/RegenerateInvoice/${invoiceId}/${invoiceGenerationType}`
  },
  Booking: {
    Root: () => `${PlatformApi.Root}/Booking`,
    CreateBooking: () => `${PlatformApi.Booking.Root()}/Create`,
    GetById: (id: string) => `${PlatformApi.Booking.Root()}/GetById/${id}`,
    GetDetailsById: (id: string) =>
      `${PlatformApi.Booking.Root()}/GetDetailsById/${id}`,
    PagedGetAll: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Booking.Root()}/GetAll?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    PagedFilteredGetAll: (
      pageSize?: number,
      pageNo?: number,
      searchText?: string,
      filterDataChange?: number
    ) => {
      let query = `${PlatformApi.Booking.Root()}/FilteredGetAll?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      if (filterDataChange) query = `${query}&random=${filterDataChange}`;
      return query;
    },
    EditCustomRate: (id: string) =>
      `${PlatformApi.Booking.Root()}/EditCustomRate/${id}`,
    EditNotes: (id: string) => `${PlatformApi.Booking.Root()}/EditNotes/${id}`,
    EditBooking: (id: string) => `${PlatformApi.Booking.Root()}/Edit/${id}`,
    BookingFormFields: (userId: string) =>
      `${PlatformApi.Booking.Root()}/GetBookingFormFields/${userId}`,
    GetOperatorListToAssign: (id: string) =>
      `${PlatformApi.Booking.Root()}/GetOperatorListToAssign/${id}`,
    ChangeAppointedOperator: (id: string) =>
      `${PlatformApi.Booking.Root()}/ChangeAppointedOperator/${id}`,
    ChangeStatus: (id: string) =>
      `${PlatformApi.Booking.Root()}/ChangeStatus/${id}`,
    GetBookingCancellationReasons: (id: string) =>
      `${PlatformApi.Booking.Root()}/GetBookingCancellationReasons/${id}`,
    CloseJob: (id: string) => `${PlatformApi.Booking.Root()}/CloseJob/${id}`,
    SetActualTime: (id: string) =>
      `${PlatformApi.Booking.Root()}/SetActualTime/${id}`,
    AcceptJobOffer: (id: string) =>
      `${PlatformApi.Booking.Root()}/AcceptJobOffer/${id}`,
    SubmitVos: (id: string) => `${PlatformApi.Booking.Root()}/SubmitVos/${id}`,
    GetBookingBills: (id: string) =>
      `${PlatformApi.Booking.Root()}/GetBookingBills/${id}`,
    GetAgentBillDetails: (bookingId: string, operatorUserId: string) =>
      `${PlatformApi.Booking.Root()}/GetAgentBillDetails/${bookingId}/${operatorUserId}`,
    GetCloseJobInfo: (bookingId: string, operatorUserId: string) =>
      `${PlatformApi.Booking.Root()}/GetCloseJobInfo/${bookingId}/${operatorUserId}`,
    GetBookingSummary: (bookingId: string) =>
      `${PlatformApi.Booking.Root()}/GetBookingSummary/${bookingId}`,
    GetBookingHistories: (
      bookingId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Booking.Root()}/GetBookingHistories/${bookingId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetAllForCalendar: (
      startDate: string,
      endDate?: string,
      statusType?: number
    ) => {
      let query = `${PlatformApi.Booking.Root()}/GetAllForCalendar?StartDate=${startDate}`;
      if (endDate) query = `${query}&EndDate=${endDate}`;
      if (statusType) query = `${query}&StatusType=${statusType}`;
      return query;
    },
  },
  Language: {
    Root: () => `${PlatformApi.Root}/Language`,
    GetAll: () => `${PlatformApi.Language.Root()}/GetAll`,
    PagedGetAll: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Language.Root()}/GetAll?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetByUserId: (userId: string, platform: PlatformType) => {
      let query = `${PlatformApi.Language.Root()}/GetLanguageNamesByUserId?userId=${userId}`;
      if (platform != PlatformType.All) query = `${query}&platform=${platform}`;
      return query;
    },

    GetByCompanyId: (companyId: string, platform?: PlatformType) => {
      let query = `${PlatformApi.Language.Root()}/GetNameListByCompanyId/${companyId}`;
      if (platform != PlatformType.All) query = `${query}?platform=${platform}`;
      return query;
    },
    Create: () => `${PlatformApi.Language.Root()}/Create`,
    Edit: (languageId: string) =>
      `${PlatformApi.Language.Root()}/Update/${languageId}`,
    Delete: () => `${PlatformApi.Language.Root()}/Delete`,
    IsPinExists: (pin: string) =>
      `${PlatformApi.Language.Root()}/IsPinExists/${pin}`,
    IsCodeExists: (code: string) =>
      `${PlatformApi.Language.Root()}/IsCodeExists/${code}`,
    IsNameExists: (name: string) =>
      `${PlatformApi.Language.Root()}/IsNameExists/${name}`,
  },
  LanguagePack: {
    Root: () => `${PlatformApi.Root}/LanguagePack`,
    GetAll: (companyId: string) =>
      `${PlatformApi.LanguagePack.Root()}/GetAll/${companyId}`,
    GetNotTakenLanguagePackAll: (
      companyId: string,
      plaformType: PlatformType
    ) =>
      `${PlatformApi.LanguagePack.Root()}/GetNotTakenLanguagePackAll/${companyId}?platformType=${plaformType}`,
    GetById: (id: string) => `${PlatformApi.LanguagePack.Root()}/GetById/${id}`,
    PagedGetAll: (
      companyId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.LanguagePack.Root()}/GetAll/${companyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    IsNameExists: (vendorCompanyId: string, languagePackName: string, languageId?: string) => {
      if (languageId) {
        return `${PlatformApi.LanguagePack.Root()}/IsNameExists/${vendorCompanyId}/${languagePackName}?languagePackId=${languageId}`;
      } else {
        return `${PlatformApi.LanguagePack.Root()}/IsNameExists/${vendorCompanyId}/${languagePackName}`;
      }
    },
    Create: () => `${PlatformApi.LanguagePack.Root()}/Create`,
    Edit: (languagePackId: string) =>
      `${PlatformApi.LanguagePack.Root()}/${languagePackId}`,
    Delete: () => `${PlatformApi.LanguagePack.Root()}/Delete`,
  },
  Location: {
    Root: () => `${PlatformApi.Root}/Location`,
    PagedGetAll: (
      companyId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Location.Root()}/GetAll/${companyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetAll: (companyId: string) =>
      `${PlatformApi.Location.Root()}/GetAll/${companyId}`,
    GetNames: (companyId: string) =>
      `${PlatformApi.Location.Root()}/GetNames?companyId=${companyId}`,
    GetDetailsById: (locationId: string) =>
      `${PlatformApi.Location.Root()}/${locationId}`,
    Create: () => `${PlatformApi.Location.Root()}/Create `,
    Update: (id: string) => `${PlatformApi.Location.Root()}/Update/${id}`,
    Delete: (id: string) => `${PlatformApi.Location.Root()}/${id}`,
  },
  Medical: {
    Root: () => `${PlatformApi.Root}/MedicalHistory`,
    GetAll: () => `${PlatformApi.Medical.Root()}/Get`,
  },
  Notification: {
    Root: () => `${PlatformApi.Root}/Notification`,
    GetAll: {
      Root: (pageSize = 10, pageNo = 1, searchText: string) => {
        let query = `${PlatformApi.Notification.Root()}/GetAll?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) {
          query = `${query}&SearchText=${searchText}`;
        }
        return query;
      },
    },
    Read: {
      Root: (notificationId: string) =>
        `${PlatformApi.Notification.Root()}/Read/${notificationId}`,
    },
    CountUnread: {
      Root: () => `${PlatformApi.Notification.Root()}/CountUnread`,
    },
  },
  Operator: {
    Root: () => `${PlatformApi.Root}/Operator`,
    GetOperatorSearchCriteriaList: (userId: string, platform: PlatformType) =>
      `${PlatformApi.Operator.Root()}/GetOperatorSearchCriteriaList?platform=${platform}&userId=${userId}`,
    FilterOperator: (platformType: number) => {
      return `${PlatformApi.Operator.Root()}/FilterOperator/${platformType}`;
    },
    InCallFilterOperator: (callId: string) => {
      return `${PlatformApi.Operator.Root()}/FilterOperatorInCall/${callId}`;
    },
    ChangeCurrentStatus: (
      operatorId: string | undefined,
      availabilityStatusType: number
    ) =>
      `${PlatformApi.Operator.Root()}/ChangeCurrentStatus/${operatorId}?availabilityStatusType=${availabilityStatusType}`,
    GetAll: (
      callCenterId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string,
      filterDataChange?: number
    ) => {
      let query = `${PlatformApi.Operator.Root()}/GetAllOperator/${callCenterId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      if (filterDataChange) query = `${query}&random=${filterDataChange}`;
      return query;
    },
    GetSupportedPlatform: (operatorId: string) =>
      `${PlatformApi.Operator.Root()}/GetSupportedPlatform/${operatorId}`,
    UpdateSupportedPlatform: (operatorId: string) =>
      `${PlatformApi.Operator.Root()}/UpdateSupportedPlatforms/${operatorId}`,
    GetFaceToFaceAssignment: (operatorId: string) =>
      `${PlatformApi.Operator.Root()}/GetFaceToFaceAssignment/${operatorId}`,
    UpdateFaceToFaceAssignment: (operatorId: string) =>
      `${PlatformApi.Operator.Root()}/UpdateFaceToFaceAssignment/${operatorId}`,
    GetOperatorLanguage: (operatorId: string) =>
      `${PlatformApi.Operator.Root()}/GetOperatorLanguage/${operatorId}`,
    GetOperatorSkill: (operatorId: string) =>
      `${PlatformApi.Operator.Root()}/GetOperatorSkill/${operatorId}`,
    Create: () => `${PlatformApi.Operator.Root()}/Create`,
    CreateOperatorLanguage: () =>
      `${PlatformApi.Operator.Root()}/CreateOperatorLanguage`,
    UpdateOperatorLanguage: (operatorLanguageId: string) =>
      `${PlatformApi.Operator.Root()}/UpdateOperatorLanguage/${operatorLanguageId}`,
    DeleteOperatorLanguage: (operatorLanguageId: string) =>
      `${PlatformApi.Operator.Root()}/DeleteOperatorLanguage/${operatorLanguageId}`,
    CreateOperatorSkill: (operatorId?: string, skillId?: string) => {
      let query = `${PlatformApi.Operator.Root()}/CreateOperatorSkill`;
      if (operatorId) query = `${query}?operatorId=${operatorId}`;
      if (skillId) query = `${query}&skillId=${skillId}`;
      return query;
    },
    UpdateOperatorSkill: (
      operatorSkillId: string,
      operatorId?: string,
      skillId?: string
    ) => {
      let query = `${PlatformApi.Operator.Root()}/UpdateOperatorSkill/${operatorSkillId}`;
      if (operatorId) query = `${query}?operatorId=${operatorId}`;
      if (skillId) query = `${query}&skillId=${skillId}`;
      return query;
    },
    DeleteOperatorSkill: (operatorSkillId: string) =>
      `${PlatformApi.Operator.Root()}/DeleteOperatorSkill/${operatorSkillId}`,
    CreateOperatorMedicalHistory: (
      operatorId?: string,
      medicalHistoryId?: string
    ) => {
      let query = `${PlatformApi.Operator.Root()}/CreateOperatorMedicalHistory`;
      if (operatorId) query = `${query}?operatorId=${operatorId}`;
      if (medicalHistoryId)
        query = `${query}&medicalHistoryId=${medicalHistoryId}`;
      return query;
    },
    GetOperatorRatings: (
      operatorId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Operator.Root()}/GetOperatorRatings/${operatorId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    CreateOperatorRating: () =>
      `${PlatformApi.Operator.Root()}/CreateOperatorRating/`,
    DeleteOperatorRating: (operatorRatingId: string) =>
      `${PlatformApi.Operator.Root()}/DeleteOperatorRating/${operatorRatingId}`,
    UpdateOperatorRating: (operatorRatingId: string) =>
      `${PlatformApi.Operator.Root()}/UpdateOperatorRating/${operatorRatingId}`,
    GetOperatorMedicalHistory: (operatorId: string) =>
      `${PlatformApi.Operator.Root()}/GetOperatorMedicalHistory/${operatorId}`,
    UpdateOperatorMedicalHistory: (
      operatorMedicalHistoryId: string,
      operatorId?: string,
      medicalHistoryId?: string
    ) => {
      let query = `${PlatformApi.Operator.Root()}/UpdateOperatorMedicalHistory/${operatorMedicalHistoryId}`;
      if (operatorId) query = `${query}?operatorId=${operatorId}`;
      if (medicalHistoryId)
        query = `${query}&medicalHistoryId=${medicalHistoryId}`;
      return query;
    },
    UploadCertificate: () =>
      `${PlatformApi.Operator.Root()}/UploadOperatorCertificate`,
    GetCertificate: (operatorId: string, criteriaType: OperatorCriteriaType) =>
      `${PlatformApi.Operator.Root()}/GetOperatorCertificate/${operatorId}?operatorCriteriaType=${criteriaType}`,
    DeleteOperatorMedicalHistory: (medicalHistoryId: string) =>
      `${PlatformApi.Operator.Root()}/DeleteOperatorMedicalHistory/${medicalHistoryId}`,
    OperatorNames: (
      platform: PlatformType,
      languageId: string,
      genderType?: GenderType,
      skillId?: string
    ) => {
      if (!platform) return "";
      if (!languageId) return "";
      let query = `${PlatformApi.Operator.Root()}/GetOperatorNames/${platform}?languageId=${languageId}`;

      if (genderType) query = `${query}&gender=${genderType}`;
      if (skillId) query = `${query}&skillId=${skillId}`;
      return query;
    },
    GetOperatorNames: () => `${PlatformApi.Operator.Root()}/GetOperatorNames`,
    GetOperatorNamesByCallCenterId: (callCenterId: string) =>
      `${PlatformApi.Operator.Root()}/GetOperatorNamesByCallCenterId/${callCenterId}`,
  },
  Reseller: {
    Root: () => `${PlatformApi.Root}/Reseller`,
    GetAll: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Reseller.Root()}/GetAll/?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    // GetAll: () => `${PlatformApi.Company.Root()}/GetAll`,
    Create: () => `${PlatformApi.Reseller.Root()}/Create`,
    GetById: (resellerId: string) =>
      `${PlatformApi.Reseller.Root()}/GetById/${resellerId}`,
    GetResellerNames: () => `${PlatformApi.Reseller.Root()}/GetResellerNames`,
  },
  Skill: {
    Root: () => `${PlatformApi.Root}/Skill`,
    PagedAll: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Skill.Root()}/Get?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    PagedGetAll: (CompanyId?: string, pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Skill.Root()}/GetAll?companyId=${CompanyId}&PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    SkillTabGetAll: (CompanyId?: string) => {
      let query = `${PlatformApi.Skill.Root()}/GetAll?companyId=${CompanyId}`;
      return query;
    },
    GetAll: () => `${PlatformApi.Skill.Root()}/Get`,
    GetAllSkillName: (userId?:string) => `${PlatformApi.Skill.Root()}/GetNames?userId=${userId}`,
    GetById: (id: string) => `${PlatformApi.Skill.Root()}/GetById/${id}`,
    Create: () => `${PlatformApi.Skill.Root()}/Create`,
    Edit: (id: string) => `${PlatformApi.Skill.Root()}/Update/${id}`,
    Delete: () => `${PlatformApi.Skill.Root()}/Delete`,
    IsPinExists: (pin: string) =>
      `${PlatformApi.Skill.Root()}/IsPinExists/${pin}`,
    IsNameExists: (name: string) =>
      `${PlatformApi.Skill.Root()}/IsNameExists/${name}`,
  },
  Modality: {
    Root: () => `${PlatformApi.Root}/Modality`,
    PagedAll: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Modality.Root()}/Get?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    PagedGetAll: (CompanyId?: string, pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Modality.Root()}/GetALl?companyId=${CompanyId}&PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    ModalityTabGetAll: (CompanyId?: string) => {
      let query = `${PlatformApi.Modality.Root()}/GetALl?companyId=${CompanyId}`;
      return query;
    },
    GetNames: (companyId: string, serviceType: PlatformType) => {
      return `${PlatformApi.Modality.Root()}/GetNames?companyId=${companyId}&serviceType=${serviceType}`;
    },
    GetAll: () => `${PlatformApi.Modality.Root()}/Get`,
    GetById: (id: string) => `${PlatformApi.Modality.Root()}/GetById/${id}`,
    Create: () => `${PlatformApi.Modality.Root()}/Create`,
    Edit: (id: string) => `${PlatformApi.Modality.Root()}/Update/${id}`,
    Delete: () => `${PlatformApi.Modality.Root()}/Delete`,
  },
  AppointmentCategory: {
    Root: () => `${PlatformApi.Root}/AppointmentCategory`,
    PagedGet: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.AppointmentCategory.Root()}/Get?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    PagedGetAll: (CompanyId?: string, pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.AppointmentCategory.Root()}/GetAll?companyId=${CompanyId}&PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    AppointmentCategoryTabGetAll: (CompanyId?: string) => {
      let query = `${PlatformApi.AppointmentCategory.Root()}/GetAll?companyId=${CompanyId}`;
      return query;
    },
    GetNames: (companyId?: string) => {
      if (companyId)
        return `${PlatformApi.AppointmentCategory.Root()}/GetNames?companyId=${companyId}`;
      return `${PlatformApi.AppointmentCategory.Root()}/GetNames`;
    },
    GetAll: () => `${PlatformApi.AppointmentCategory.Root()}/Get`,
    GetById: (id: string) =>
      `${PlatformApi.AppointmentCategory.Root()}/GetById/${id}`,
    Create: () => `${PlatformApi.AppointmentCategory.Root()}/Create`,
    Edit: (id: string) =>
      `${PlatformApi.AppointmentCategory.Root()}/Update/${id}`,
    Delete: () => `${PlatformApi.AppointmentCategory.Root()}/Delete`,
  },
  AppointmentDescription: {
    Root: () => `${PlatformApi.Root}/AppointmentDescription`,
    PagedGet: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.AppointmentDescription.Root()}/Get?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    PagedGetAll: (CompanyId?: string, pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.AppointmentDescription.Root()}/GetAll?companyId=${CompanyId}&PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    AppointmentDescriptionTabGetAll: (CompanyId?: string) => {
      let query = `${PlatformApi.AppointmentDescription.Root()}/GetAll?companyId=${CompanyId}`;
      return query;
    },
    GetNames: (companyId: string, appointmentCategoryId: string) => {
      return `${PlatformApi.AppointmentDescription.Root()}/GetNames?companyId=${companyId}&appointmentCategoryId=${appointmentCategoryId}`;
    },
    GetAll: () => `${PlatformApi.AppointmentDescription.Root()}/Get`,
    GetById: (id: string) =>
      `${PlatformApi.AppointmentDescription.Root()}/GetById/${id}`,
    Create: () => `${PlatformApi.AppointmentDescription.Root()}/Create`,
    Edit: (id: string) =>
      `${PlatformApi.AppointmentDescription.Root()}/Update/${id}`,
    Delete: () => `${PlatformApi.AppointmentDescription.Root()}/Delete`,
  },
  Tin: {
    Root: () => `${PlatformApi.Root}/Tin`,
    PagedGetAll: (pageSize?: number, pageNo?: number, searchText?: string) => {
      let query = `${PlatformApi.Tin.Root()}/GetAll?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    GetAll: () => `${PlatformApi.Skill.Root()}/GetAll`,
    GetUserTinById: (id: string) =>
      `${PlatformApi.Tin.Root()}/GetUserTinById/${id}`,
    UpdateUserTin: (userId: string, tin: string) =>
      `${PlatformApi.Tin.Root()}/UpdateUserTin/${userId}?tin=${tin}`,
    IsTinExists: (tin: string) =>
      `${PlatformApi.Tin.Root()}/IsTinExists/${tin}`,
  },
  Users: {
    Root: () => `${PlatformApi.Root}/User`,
    GetAll: (
      companyId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${PlatformApi.Users.Root()}/GetAll/${companyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },

    GetUserById: (userId: string) =>
      `${PlatformApi.Users.Root()}/GetUserById/${userId}`,
    GetNames: (companyId?: string, searchText?: string) => {
      let url = `${PlatformApi.Users.Root()}/GetNames`;
      if (companyId) {
        url = `${url}?companyId=${companyId}`;
        if (searchText) url = `${url}&searchText=${searchText}`;
        return url;
      }
      if (searchText) url = `${url}?searchText=${searchText}`;
      return url;
    },
    GetNamesWithActiveStatus: (companyId?: string, searchText?: string) => {
      let url = `${PlatformApi.Users.Root()}/GetNamesWithActiveStatus`;
      if (companyId) {
        url = `${url}?companyId=${companyId}`;
        if (searchText) url = `${url}&searchText=${searchText}`;
        return url;
      }
      if (searchText) url = `${url}?searchText=${searchText}`;
      return url;
    },
    GetAdminNames: (companyId?: string, searchText?: string,) => {
      let url = `${PlatformApi.Users.Root()}/GetAdminNames`;
      if (searchText) url = `${url}?searchText=${searchText}`;
      if (companyId) url = `${url}?companyId=${companyId}`;
      return url;
    },
    GetAdminNamesWithActiveStatus: (companyId?: string, searchText?: string,) => {
      let url = `${PlatformApi.Users.Root()}/GetAdminNames`;
      if (searchText) url = `${url}?searchText=${searchText}`;
      if (companyId) url = `${url}?companyId=${companyId}`;
      return url;
    },
    GetCompanyUserNames: (companyId?: string, searchText?: string) => {
      let url = `${PlatformApi.Users.Root()}/GetCompanyUserNames`;
      if (companyId) {
        url = `${url}?companyId=${companyId}`;
        if (searchText) url = `${url}&searchText=${searchText}`;
        return url;
      }
      if (searchText) url = `${url}?searchText=${searchText}`;
      return url;
    },
    GetNamesForExternalUser: (companyId: string, searchText?: string) => {
      let url = `${PlatformApi.Users.Root()}/GetNamesForExternalUser/${companyId}`;
      if (searchText) url = `${url}?searchText=${searchText}`;
      return url;
    },
    GetNamesForBooking: () => `${PlatformApi.Users.Root()}/GetNamesForBooking`,
    GetDetailsById: (userId: string) =>
      `${PlatformApi.Users.Root()}/GetDetailsById/${userId}`,
    GetUserStatus: (userId: string) =>
      `${PlatformApi.Users.Root()}/GetUserStatus/${userId}`,
    ChangeStatus: () => `${PlatformApi.Users.Root()}/ChangeStatus`,
    ChangeRole: () => `${PlatformApi.Users.Root()}/ChangeRole`,
    UpdatePassword: () => `${PlatformApi.Users.Root()}/UpdatePassword`,
    Create: () => `${PlatformApi.Users.Root()}/CreateUser`,
    Update: () => `${PlatformApi.Users.Root()}/Update`,
    UpdateTimezone: () => `${PlatformApi.Users.Root()}/UpdateTimeZone`,
    UploadProfilePhoto: (id: string) =>
      `${PlatformApi.Users.Root()}/UploadProfilePic/${id}`,

    Delete: (id: string) => `${PlatformApi.Users.Root()}/DeleteUser/${id}`,
    IsEmailExist: (email: string) =>
      `${PlatformApi.Users.Root()}/IsEmailExist?email=${email}`,
    GetUserMenuSettings: () =>
      `${PlatformApi.Users.Root()}/GetUserMenuSettings`,
    GetAvailableManager: (companyId: string) =>
      `${PlatformApi.Users.Root()}/GetAvailableManager/${companyId}`,
  },
  Vri: {
    Root: () => `${PlatformApi.Root}/Vri`,
    LanguagesByCompany: {
      Root: (companyId: string) =>
        `${PlatformApi.Vri.Root()}/LanguagesByCompany/${companyId}`,
    },
  },
};
export const FileBucketURL = {
  Root: `${RootFileBucketURL}/`,
};
export const WebSocketApi = {
  Root: `${RootWebSocket}`,
};

export const OpiApi = {
  Root: `${RootOPI}/api`,
  Authentication: {
    Root: () => `${OpiApi.Root}/Auth`,
    Token: () => `${OpiApi.Authentication.Root()}/WebToken`,
  },
  Operator: {
    Root: () => `${OpiApi.Root}/Operator`,
    StartInitiated: () => `${OpiApi.Operator.Root()}/StartInitiated`,
  },
  ThreeWayCall: {
    Root: () => `${OpiApi.Root}/ThreeWayCall`,
    OutboundRedial: () => `${OpiApi.ThreeWayCall.Root()}/RedialOutbound`,
  },
  Vendors: {
    Root: () => `${OpiApi.Root}/Vendor`,
    GetAll: (companyId?: string) => `${OpiApi.Vendors.Root()}/Get/${companyId}`,
    PagedGetAll: (
      companyId?: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${OpiApi.Vendors.Root()}/Get/${companyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    Create: () => `${OpiApi.Vendors.Root()}/Create`,
    GetCompanyWithoutVendor: (companyId: string) =>
      `${OpiApi.Vendors.Root()}/GetCompanyWithoutVendor/${companyId}`,
    GetAllCompanyById: (id: string) => `${OpiApi.Vendors.Root()}/GetCompany/${id}`,
    GetCompanyById: (
      companyId: string,
      pageSize?: number,
      pageNo?: number,
      searchText?: string
    ) => {
      let query = `${OpiApi.Vendors.Root()}/GetCompany/${companyId}?PageSize=${pageSize}&PageNo=${pageNo}`;
      if (searchText) query = `${query}&SearchText=${searchText}`;
      return query;
    },
    CreateCompany: (id: string, companyId: string) =>
      `${OpiApi.Vendors.Root()}/CreateCompany/${id}?companyId=${companyId}`,
    UpdatePhoneNumber: (id: string, phoneNumber: string) =>
      `${OpiApi.Vendors.Root()}/UpdatePhoneNumber/${id}?phoneNumber=${phoneNumber}`,
    ChangeCallForwardingOption: (
      companyId: string,
      callForwardingEnableStatus: boolean
    ) =>
      `${OpiApi.Vendors.Root()}/ChangeCallForwardingOption/${companyId}?isCallForwardingEnabled=${callForwardingEnableStatus}`,
    DeleteVendor: (vendorId: string) =>
      `${OpiApi.Vendors.Root()}/Delete/${vendorId}`,
    DeleteCompany: (id: string, companyId: string) =>
      `${OpiApi.Vendors.Root()}/DeleteCompany/${id}?companyId=${companyId}`,

    Language: {
      Root: () => `${OpiApi.Root}/VendorLanguage`,
      GetLanguageById: (
        vendorId: string,
        pageSize?: number,
        pageNo?: number,
        searchText?: string
      ) => {
        let query = `${OpiApi.Vendors.Language.Root()}/Get/${vendorId}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        return query;
      },
      GetAllLanguageById: (vendorId: string) =>
        `${OpiApi.Vendors.Language.Root()}/Get/${vendorId}`,
      AddLanguage: () => `${OpiApi.Vendors.Language.Root()}/Create`,
      DeleteLanguage: (languageId: string) =>
        `${OpiApi.Vendors.Language.Root()}/Delete/${languageId}`,
    },
  },
  CustomInput: {
    Root: () => `${OpiApi.Root}/CustomInput`,
    GetCustomInput: (callerId: string) =>
      `${OpiApi.CustomInput.Root()}/GetCustomInput/${callerId}`,
    UpdateCustomInputFields: (callId?: string) =>
      `${OpiApi.CustomInput.Root()}/UpdateCustomInputFields/${callId}`,
  },
};

export const TwilioApi = {
  RootVRI: `${RootVRI}/api`,
  RootOPI: `${RootOPI}/api`,
  RootPlatform: `${RootPlatform}/api`,
  VRI: {
    CompleteRoom: () => `${TwilioApi.RootVRI}/Room/CompleteRoom`,
  },
  Conversation: {
    Root: () => `${TwilioApi.RootPlatform}/Conversation`,
    CreateConversation: {
      Root: () => `${TwilioApi.Conversation.Root()}/CreateConversation`,
    },
    GetConversations: {
      Root: () => `${TwilioApi.Conversation.Root()}/GetConversations`,
    },
    GetGroupConversations: {
      Root: () => `${TwilioApi.Conversation.Root()}/GetGroupConversations`,
    },
    GetParticipants: {
      Root: (id: string) =>
        `${TwilioApi.Conversation.Root()}/GetParticipants?conversationSid=${id}`,
    },
    AddMultipleParticipant: {
      Root: (convoId: string) =>
        `${TwilioApi.Conversation.Root()}/AddMultipleParticipant?conversationSid=${convoId}`,
    },
    LeaveConversation: {
      Root: (convoId: string, userId: string) =>
        `${TwilioApi.Conversation.Root()}/LeaveConversation?conversationSid=${convoId}&participantId=${userId}`,
    },
    UpdateConversationName: {
      Root: () => `${TwilioApi.Conversation.Root()}/UpdateConversationName`,
    },
    GetParticipantDetailsById: {
      Root: (id: string) =>
        `${TwilioApi.Conversation.Root()}/GetParticipantDetailsById/${id}`,
    },
  },

  CallSummary: {
    Root: () => `${TwilioApi.RootOPI}/CallSummary`,
    CallLog: {
      Root: (id: string) =>
        `${TwilioApi.CallSummary.Root()}/GetAllFromDb/${id}`,
    },
  },
};
export const VriApi = {
  Root: `${RootVRI}/api`,
  Conference: {
    Root: () => `${VriApi.Root}/Conference`,
    CreateConference: {
      Root: () => `${VriApi.Conference.Root()}/Create`,
    },
    CreateScheduleConferenceCall: (roomId: string) =>
      `${VriApi.Conference.Root()}/CreateScheduleConferenceCall/${roomId}`,
    GetConference: {
      Root: (pageSize: number, pageNo: number) =>
        `${VriApi.Conference.Root()}/GetAll?PageSize=${pageSize}&PageNo=${pageNo}`,
    },
    GetConsumers: {
      Root: (consumerId: string) =>
        `${VriApi.Conference.Root()}/GetConferencesByConsumer/${consumerId}`,
    },
    GetCalenderProviders: {
      Root: () => `${VriApi.Conference.Root()}/GetCalendarStatus`,
    },
    ConnectWithCalendars: {
      Root: (calenderType: CalendarProvider) =>
        `${VriApi.Conference.Root()}/ConnectWithCalendar/${calenderType}`,
    },
    ConnectCalendar: {
      Root: () => `${VriApi.Conference.Root()}/ConnectCalendar`,
    },
    DisconnectCalendar: {
      Root: (calenderType: CalendarProvider) =>
        `${VriApi.Conference.Root()}/DisconnectCalendar/${calenderType}`,
    },
    GetFutureConferences: {
      Root: (userId: string, startDate?: string, endDate?: string) => {
        let query = `${VriApi.Conference.Root()}/GetConferencesForCalender/${userId}?StartDate=${startDate}`;
        if (endDate) query = `${query}&EndDate=${endDate}`;
        return query;
      },
    },
    GetConferenceTinByRoomId: {
      Root: (roomId: string) =>
        `${VriApi.Conference.Root()}/GetConferenceTinByRoomId/${roomId}`,
    },
    GetConferencesByConsumer: {
      Root: (
        consumerId: string,
        pageNo?: number,
        pageSize?: number,
        searchText?: string
      ) => {
        let query = `${VriApi.Conference.Root()}/GetConferencesByConsumer/${consumerId}?PageSize=${pageSize}&PageNo=${pageNo}`;
        if (searchText) query = `${query}&SearchText=${searchText}`;
        return query;
      },
    },
  },

  Room: {
    Root: () => `${VriApi.Root}/Room`,
    CreateRoom: (roomId: string) =>
      `${VriApi.Room.Root()}/CreateRoom/${roomId}`,
    IsRoomCreated: {
      Load: (roomId: string) => `${VriApi.Room.Root()}/IsRoomCreated/${roomId}`,
    },
  },
};

export const AppRouteUi = {
  Root: RootUi,
  Log: {
    Root: `/log`,
  },
  Login: {
    Root: `/login`,
    ExternalLogin: `/external-login`,
  },
  ActivateAccount: {
    Root: `/activate-account`,
  },
  ForgotPassword: {
    Root: `/forgot-password`,
  },
  ResetPassword: {
    Root: `/reset-password`,
  },
  Home: {
    Root: `/home`,
  },
  Chat: {
    Root: `/chat`,
  },
  Jobs: {
    Root: `/jobs`,
  },
  EditJobs: {
    Root: `/edit-job`,
  },
  EVosForm: {
    Root: `/vos-form`,
  },
  Calendar: {
    Root: `/calendar`,
  },
  VideoScheduler: {
    Root: `/video-schedules`,
  },
  VideoConferenceRoom: {
    Root: `/video-conference-room`,
  },
  Settings: {
    Root: `/settings`,
  },
  Profile: {
    Root: `/profile`,
  },

  IncomingCall: {
    Root: `/incoming-call`,
  },
  OutgoingCall: {
    Root: `/outgoing-call`,
  },
  ChangePassword: {
    Root: `/change-password/:email/:pass`,

  },
  Lobby: {
    Root: `/lobby`,
    RoomId: "roomId",
    WaitingRoom: {
      Root: () => `${AppRouteUi.Lobby.Root}/:${AppRouteUi.Lobby.RoomId}`,
      useParam: (): string | undefined => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.Lobby.RoomId];
      },
      Load: (roomId: string) => `${AppRouteUi.Lobby.Root}/${roomId}`,
    },
  },
  VideoCall: {
    Root: `/room`,
    RoomId: "roomId",
    Room: {
      Root: () =>
        `${AppRouteUi.VideoCall.Root}/:${AppRouteUi.VideoCall.RoomId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.VideoCall.RoomId];
      },
      Load: (roomId: string) => `${AppRouteUi.VideoCall.Root}/${roomId}`,
    },
  },
  VideoConference: {
    Root: `/video-conference`,
    ConferenceId: "confereneId",
    InterpretorRequired: "interpretorrequired",
    InterpretorId: "interpretorId",
    Room: {
      Root: () =>
        `${AppRouteUi.VideoConference.Root}/:${AppRouteUi.VideoConference.ConferenceId}/:${AppRouteUi.VideoConference.InterpretorRequired}`,
      RootConsumer: () =>
        `${AppRouteUi.VideoConference.Root}/:${AppRouteUi.VideoConference.ConferenceId}`,
      RootIncludeOperator: () =>
        `${AppRouteUi.VideoConference.Root}/:${AppRouteUi.VideoConference.ConferenceId}/:${AppRouteUi.VideoConference.InterpretorId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.VideoConference.ConferenceId];
      },
      InterpretorRequired: (): boolean => {
        const routeParam = useParams<{ [key: string]: string }>();
        const interpreterRequired =
          routeParam[AppRouteUi.VideoConference.InterpretorRequired];
        if (interpreterRequired && interpreterRequired.toLowerCase() == "true")
          return true;
        return false;
      },
      InterpreterId: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        const InterpretorId =
          routeParam[AppRouteUi.VideoConference.InterpretorId];
        return InterpretorId ?? "";
      },
      Load: (roomId: string, isInterpretorRequired: boolean) =>
        `${AppRouteUi.VideoConference.Root}/${roomId}/${isInterpretorRequired}`,
      LoadWithInterPretor: (roomId: string, interpretorId: string) =>
        `${AppRouteUi.VideoConference.Root}/${roomId}/${interpretorId}`,
      LoadConsumer: (roomId: string) =>
        `${AppRouteUi.VideoConference.Root}/${roomId}`,
    },
    Rejoin: {
      RootUrl: `/video-rejoin`,
      Root: () =>
        `${AppRouteUi.VideoConference.Rejoin.RootUrl}/:${AppRouteUi.VideoConference.ConferenceId}`,
      Load: (conferenceId: string) =>
        `${AppRouteUi.VideoConference.Rejoin.RootUrl}/${conferenceId}`,
    },
  },
  Consumer: {
    Root: `/consumer`,
    Load: () => `${AppRouteUi.Consumer.Root}`,
  },
  UserCalendar: {
    Root: `/make-schedule`,
    CompanyId: "companyId",
    Scheduler: {
      Root: () =>
        `${AppRouteUi.UserCalendar.Root}/:${AppRouteUi.UserCalendar.CompanyId}`,
      useParam: (): string | undefined => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.UserCalendar.CompanyId];
      },
      Load: (companyId: string) =>
        `${AppRouteUi.UserCalendar.Root}/${companyId}`,
    },
  },
  CallLog: {
    Root: `/call-log`,
  },

  InternalCallLog: {
    Root: `/internal-call-log`,
  },
  OverflowCallLog: {
    Root: `/overflow-call-log`,
  },
  InflowCallLog: {
    Root: `/inflow-call-log`,
  },
  OutflowCallLog: {
    Root: `/outflow-call-log`,
  },
  Company: {
    Root: `/company`,
    CompanyId: "companyId",
    Profile: {
      Root: () => `${AppRouteUi.Company.Root}/:${AppRouteUi.Company.CompanyId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.Company.CompanyId];
      },
      Load: (companyId: string) => `${AppRouteUi.Company.Root}/${companyId}`,
    },
  },
  CompanyProfile: {
    Root: `/company/profile`,
    CompanyId: "companyId",
    Profile: {
      Root: () =>
        `${AppRouteUi.CompanyProfile.Root}/:${AppRouteUi.CompanyProfile.CompanyId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.CompanyProfile.CompanyId];
      },
      Load: (companyId: string) =>
        `${AppRouteUi.CompanyProfile.Root}/${companyId}`,
    },
  },

  Reseller: {
    Root: `/reseller`,
    ResellerId: "resellerId",
    Profile: {
      Root: () =>
        `${AppRouteUi.Reseller.Root}/:${AppRouteUi.Reseller.ResellerId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.Reseller.ResellerId];
      },
      Load: (resellerId: string) => `${AppRouteUi.Reseller.Root}/${resellerId}`,
    },
  },
  PhoneNumber: {
    Root: `/phone-number`,
  },
  Vendors: {
    Root: `/vendors`,
    VendorId: "vendorId",
    Profile: {
      Root: () => `${AppRouteUi.Vendors.Root}/:${AppRouteUi.Vendors.VendorId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.Vendors.VendorId];
      },
      Load: (vendorId: string) => `${AppRouteUi.Vendors.Root}/${vendorId}`,
    },
  },
  CallOutflow: {
    Root: `/call-outflow`,
  },
  CallInflow: {
    Root: `/call-inflow`,
  },
  User: {
    Root: `/users`,
    UserId: "userId",
    Profile: {
      Root: () => `${AppRouteUi.User.Root}/:${AppRouteUi.User.UserId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.User.UserId];
      },
      Load: (userId: string) => `${AppRouteUi.User.Root}/${userId}`,
    },
  },
  TIN: {
    Root: `/tin`,
  },
  Language: {
    Root: `/language`,
  },
  LanguagePacks: {
    Root: `/language-packs`,
  },
  Skill: {
    Root: `/skill`,
  },
  Modality: {
    Root: `/modality`,
  },
  AppointmentCategory: {
    Root: `/appointment-category`,
  },
  AppointmentDescription: {
    Root: `/appointment-description`,
  },
  AuditLogs: {
    Root: `/audit-logs`,
  },
  NotFound: {
    Root: `/404`,
  },
  VideoIncomingCall: {
    Root: `/video-incoming-call`,
  },
  VideoOutgoingCall: {
    Root: `/video-outgoing-call`,
  },
  IVROutgoingCall: {
    Root: `/ivr`,
  },
  OutgoingCallFailed: {
    Root: `/call-outgoing`,
  },
  OutgoingCallFailedForMic: {
    Root: `/call-failed-mic`,
  },
  OutgoingCallFailedForMedia: {
    Root: `/call-failed-media`,
  },
  Operator: {
    Root: `/operator`,
    OperatorId: "operatorId",
    Profile: {
      Root: () =>
        `${AppRouteUi.Operator.Root}/:${AppRouteUi.Operator.OperatorId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.Operator.OperatorId];
      },
      Load: (operatorId: string) => `${AppRouteUi.Operator.Root}/${operatorId}`,
    },
  },
  Activity: {
    Root: `/activity`,
  },
  Message: {
    Root: `/message`,
  },

  Account: {
    Root: `/finance/account`,
  },
  CompanyFinance: {
    Root: `/finance/company`,
    CompanyId: "companyId",
    Profile: {
      Root: () =>
        `${AppRouteUi.CompanyFinance.Root}/:${AppRouteUi.CompanyFinance.CompanyId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.CompanyFinance.CompanyId];
      },
      Load: (companyId: string) =>
        `${AppRouteUi.CompanyFinance.Root}/${companyId}`,
    },
  },
  OperatorFinance: {
    Root: `/finance/operator`,
    userId: "userId",
    Profile: {
      Root: () =>
        `${AppRouteUi.OperatorFinance.Root}/:${AppRouteUi.OperatorFinance.userId}`,
      useParam: (): string => {
        const routeParam = useParams<{ [key: string]: string }>();
        return routeParam[AppRouteUi.OperatorFinance.userId];
      },
      Load: (userId: string) => `${AppRouteUi.OperatorFinance.Root}/${userId}`,
    },
  },
  Download: {
    root: `/download`,
    Link: process.env.downloadLink
  }
};
