import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { InfoBox, Searchbar } from "..";
import { AssetSvg } from "../../assets";
import { Pagination } from "../../components";
import { AppConstants, StepNumber, topLoaderProgress } from "../../configs";
import { useAppSelector } from "../../core";
import { ApiSchemaJobList, ColumnSelectionsModel, InvoiceModel, TableColumnExtend, } from "../../models";
import { ApiSchemaAuditLogsData } from "../../models/api/AuditLogs";
import { ApiSchemaCallCenterActivityData } from "../../models/api/CallCenterActivity";
import { ApiSchemaOperatorResponseData } from "../../models/api/Operator";
import { ApiSchemaFilter } from "../../models/api/filter";
import { CommonAuditActivityFilter } from "../Common/Components/CommonInAuditActivity/commonAuditActivityFilter";
import { NoDataComponent } from "./noDataComponent";

type Props = {
  pageTitle?: string;
  modalControlBtnLabel?: string;
  tableHeaders: TableColumnExtend<
    | InvoiceModel
    | ApiSchemaJobList
    | ApiSchemaOperatorResponseData
    | ApiSchemaAuditLogsData
    | ApiSchemaCallCenterActivityData
    | any
  >[];
  tableData: any[];
  setCreateModalShow?: (value: boolean) => void;
  selectableRows?: boolean;
  handleSelectedRowsChange?: any;
  showFilter?: boolean;
  showExportOptions?: boolean;
  filterPanel?: string;
  setStepCounter?: any;
  pageSize?: number;
  pageIndex?: number;
  setPageIndex?: Function;
  count?: number;
  onChangePageIndex?: any;
  onChangePageSize?: any;
  searchText?: string;
  handleSearch?: any;
  enableAllToggle?: boolean;
  enableAllToggleHandler?: any;
  callLogInfoBoxData?: [string, string][];
  pageBreadCrumbTile?: string;
  hideSearchBar?: boolean;
  loaderProgress: number;
  onExportData?: () => Promise<Object>;
  isPaging?: boolean;
  pagedOperatorUrl?: string;
  handleBookingRowClick?: any;
  hasPagingData?: boolean;
  setHasPagingData?: any;
  setFilterDataForAnyTable?: Function;
  setFilterDataChangeForAnyTable?: Function;
  showAutoRefresh?: boolean;
  columnSelectionModalShow?: boolean;
  setColumnSelectionModalShow?: (value: boolean) => void;
  columnSelection?: ColumnSelectionsModel[];
  setColumnSelection?: any;
  pageName?: "auditLogsPage" | "activityPage";
  downloadedFileName?: string;
  tableType?: number;
  companyId?: string;
  callCenterId?: string;
  filterData?: ApiSchemaFilter;
  setLoaderProgress?: any;
  mutateUrl?: string;
  setIsShowCommonFilter?: any;
  isShowCommonFilter?: boolean;
  isHideAddButton?: boolean
};
export function AdminPageTemplate(props: Props): JSX.Element {
  const {
    pageTitle,
    pageBreadCrumbTile,
    tableHeaders,
    tableData,
    setCreateModalShow,
    selectableRows,
    handleSelectedRowsChange,
    showFilter,
    showExportOptions,
    filterPanel,
    setStepCounter,
    modalControlBtnLabel,
    pageSize,
    pageIndex,
    setPageIndex,
    count,
    onChangePageIndex,
    onChangePageSize,
    searchText,
    handleSearch,
    enableAllToggle,
    enableAllToggleHandler,
    callLogInfoBoxData,
    hideSearchBar,
    loaderProgress,
    onExportData,
    isPaging,
    pagedOperatorUrl,
    handleBookingRowClick,
    hasPagingData,
    setHasPagingData,
    setFilterDataForAnyTable,
    setFilterDataChangeForAnyTable,
    showAutoRefresh,
    columnSelectionModalShow,
    setColumnSelectionModalShow,
    columnSelection,
    setColumnSelection,
    pageName,
    downloadedFileName,
    tableType,
    companyId,
    callCenterId,
    filterData,
    setLoaderProgress,
    mutateUrl,
    setIsShowCommonFilter,
    isShowCommonFilter,
    isHideAddButton = false
  } = props;
  const history = useHistory();
  const [noDataComponentText, setNoDataComponentText] = useState("Loading...");

  useEffect(() => {
    if (loaderProgress) {
      setNoDataComponentText("Loading...");
      if (loaderProgress === topLoaderProgress.complete) {
        if (tableData?.length === 0) {
          setNoDataComponentText("no data found");
        } else {
          setNoDataComponentText("Loading...");
        }
      }
    }
    if (tableData?.length === 0) {
      setHasPagingData?.(false);
    } else {
      setHasPagingData?.(true);
    }
  }, [loaderProgress, pageSize, pageIndex, tableData]);

  useEffect(() => { }, [hasPagingData]);
  const data = useAppSelector((state) => ({ userRole: state.auth.profile?.userRole }));

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "700",
        fontSize: "13px",
        color: "#6e7476",
        textTransform: "uppercase",
      },
    },
  };
  const conditionalRowStyles = [
    {
      when: (row: ApiSchemaJobList) => row.isUrgent === true,
      style: (row: ApiSchemaJobList) => ({
        color: row.isUrgent ? "#00008b" : "",
      }),
    },
  ];

  return (
    <>
      {pageTitle ? (
        <h4 className="mt-3 mb-0 font-weight-light">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent p-0">
              {pageBreadCrumbTile ? (
                <li className="breadcrumb-item ">
                  <a
                    href="#"
                    onClick={() => history.goBack()}
                    className="font-weight-light"
                  >
                    {pageBreadCrumbTile}
                  </a>
                </li>
              ) : null}
              <li
                className="breadcrumb-item font-weight-light active"
                aria-current="page"
              >
                {" "}
                {pageTitle !== "Jobs" ? pageTitle : ""}{" "}
                {/* TODO: would try for a better solution */}
              </li>
            </ol>
          </nav>
        </h4>
      ) : null}

      <div className="row ">
        <div className="col-12 d-flex mt-3">
          <div className="flex-shrink-0">
            {(modalControlBtnLabel && data.userRole === AppConstants.UserRoles.Admin) || ((data.userRole === AppConstants.UserRoles.ResellerAdmin || data.userRole === AppConstants.UserRoles.CompanyAdmin) && isHideAddButton) ? (
              <button
                className="btn btn-primary d-flex align-items-center mr-2"
                onClick={() => {
                  setCreateModalShow?.(true);
                  setStepCounter?.(StepNumber.firstStep);
                }}
              >
                <span className="flex-shrink-0 mr-2">
                  <span >
                    <AssetSvg.Plus />
                  </span>
                </span>
                <span>{modalControlBtnLabel}</span>
              </button>
            ) : null}
          </div>
          <div className="w-100">
            {!hideSearchBar ? (
              <Searchbar
                showFilter={showFilter}
                showExportOptions={showExportOptions}
                filterPanel={filterPanel}
                searchText={searchText}
                handleSearch={handleSearch}
                // columnSelectionModalShow={columnSelectionModalShow}
                // setColumnSelectionModalShow={setColumnSelectionModalShow}
                // mutateUrl={pagedOperatorUrl}
                showAutoRefresh={showAutoRefresh}
                columnSelectionModalShow={columnSelectionModalShow}
                setColumnSelectionModalShow={setColumnSelectionModalShow}
                columnSelection={columnSelection}
                setColumnSelection={setColumnSelection}
                tableType={tableType}
                downloadedFileName={downloadedFileName}
                companyId={companyId}
                callCenterId={callCenterId}
                filterData={filterData}
                setLoaderProgress={setLoaderProgress}
                mutateUrl={mutateUrl}
                setIsShowCommonFilter={setIsShowCommonFilter}
              />
            ) : null}
          </div>
        </div>
        {isShowCommonFilter && (
          <CommonAuditActivityFilter
            setFilterData={setFilterDataForAnyTable}
            setFilterDataChange={setFilterDataChangeForAnyTable}
            pageName={pageName!}
            setPageIndex={setPageIndex!}
          />
        )}

        {enableAllToggle ? (
          <div className="col-12 mt-3">
            <div className="row justify-content-end flex-wrap align-items-center mx-0">
              <>
                <button
                  type="button"
                  className={"btn btn-toggle  active"}
                  onClick={enableAllToggleHandler}
                >
                  <div className="handle"></div>
                </button>
                <span className="toggle-button-label  text-uppercase ">
                  Enable All
                </span>
              </>
            </div>
          </div>
        ) : pageTitle === "Call Outflow" ? (
          <div className="col-12 mt-3">
            <div className="row justify-content-end flex-wrap  align-items-center mx-0">
              <button
                type="button"
                className={"btn btn-toggle  disable"}
                onClick={enableAllToggleHandler}
              >
                <div className="handle"></div>
              </button>
              <span className="toggle-button-label  text-uppercase ">
                Enable All
              </span>
            </div>
          </div>
        ) : null}

        {callLogInfoBoxData ? (
          <div className="col-12 mt-3">
            <div className="summary-box-wrapper d-flex ">
              <InfoBox data={callLogInfoBoxData} />
            </div>
          </div>
        ) : null}
        <div className="col-12 mt-3 mb-3">
          <div className="component-card ">
            <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left  ">
              <div>
                <span>{pageTitle}</span>
              </div>
            </div>
            <div className="component-card-body border-top border-bottom border-right border-left p-0">
              <DataTable
                columns={tableHeaders}
                noDataComponent={
                  <NoDataComponent title={noDataComponentText} />
                }
                data={tableData}
                selectableRows={selectableRows}
                onSelectedRowsChange={handleSelectedRowsChange}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                highlightOnHover={true}
                pointerOnHover={true}
                onRowClicked={handleBookingRowClick}
                sortServer={true}
                onSort={(
                  selectedColumn: TableColumnExtend<
                    | ApiSchemaJobList
                    | InvoiceModel
                    | ApiSchemaOperatorResponseData
                    | ApiSchemaAuditLogsData
                    | ApiSchemaCallCenterActivityData
                  >,
                  sortDirection
                ) => {
                  setFilterDataForAnyTable &&
                    setFilterDataForAnyTable((prevState: ApiSchemaFilter) => {
                      return {
                        ...prevState,
                        sortQuery: {
                          columnName: selectedColumn.sortField,
                          direction: sortDirection === "asc" ? 1 : 2,
                        },
                      };
                    });
                  setFilterDataChangeForAnyTable &&
                    setFilterDataChangeForAnyTable(
                      Math.floor(Math.random() * 100) + 1
                    );
                }}
              />
              {hasPagingData && (
                <Pagination
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  count={count}
                  onChangePageIndex={onChangePageIndex}
                  onChangePageSize={onChangePageSize}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
