import { Modal, ModalBody } from "react-bootstrap";
import { Modals } from "../../configs";
import { BootstrapUtils } from "../../utils/bootstrap";
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

export function ModalInfo(props: {
  info: any; show: boolean;
  handleShow: (value: boolean) => void;
}): JSX.Element {
  return (
    <Modal show={props.show}
      onHide={() => props.handleShow(false)}
      centered className="scheduleInfo-modal">
      <ModalHeader closeButton>
        Meeting Link
      </ModalHeader>
      <ModalBody>
        <div className="form-row">
          <p className="form-group col-12">
                Here's the link to your meeting! Copy this link and save it for future use.
          </p>
          <a className="form-group col-md-12 font-sz-12 btn btn-link word-wrap text-left"   onClick={() => {
              navigator.clipboard.writeText(props?.info);
            }}>
          
              {props?.info}
            
          </a>
        </div>
        <div
          className="form-row 
              justify-content-lg-end justify-content-xl-end justify-content-md-end justify-content-sm-center justify-content-center 
              flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
              mx-0"
        >
          <button
            className="  btn btn-outline-dark border-grey 
                    d-flex d-sm-flex d-md-inline-block d-lg-inline-block d-xl-inline-block 
                    flex-grow-1 flex-sm-grow-1 flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 
                    justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 "
            onClick={() => {
              navigator.clipboard.writeText(props?.info);
              BootstrapUtils.ModalHideById(Modals.InfoModal);
            }}
          >
            Copy
          </button>

        </div>
      </ModalBody>
    </Modal>
  );
}
