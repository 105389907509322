import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import useDevices from '../../../../pages/hooks/useDevices';
import { useAppState } from '../../../../state';

interface IAudioOutputProp {
  selectedAudioOutputDevice?: MediaDeviceInfo[];
  setSelectedAudioOutputDevice: Dispatch<SetStateAction<MediaDeviceInfo[]>>;
}
function AudioOutputList({
  selectedAudioOutputDevice,
  setSelectedAudioOutputDevice,
}: IAudioOutputProp) {
  //const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const [audioOutputDevice, setAudioOutputDevice] = useState<MediaDeviceInfo[]>(
    []
  );
  const activeOutputLabel = audioOutputDevice.find(
    (device) => device.deviceId === activeSinkId
  )?.label;

  useEffect(() => {
    function getAudioOutputDevices() {
      navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        const audioInputDeviceList = mediaDevices.filter(
          (mediaDevice) => mediaDevice.kind === 'audiooutput'
        );
        setAudioOutputDevice(audioInputDeviceList);
      });
    }
    getAudioOutputDevices();
  }, []);
  return (
    <div className="inputSelect">
      {audioOutputDevice.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            Audio Output
          </Typography>
          <Select
            onChange={(e) => setActiveSinkId(e.target.value as string)}
            value={activeSinkId}
            variant="outlined"
          >
            {audioOutputDevice.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2">Audio Output</Typography>
          <Typography>
            {activeOutputLabel || 'System Default Audio Output'}
          </Typography>
        </>
      )}
    </div>
  );
}

export default AudioOutputList;
