import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../../components";
import { ApiBodyChangePassword } from '../../../models';
import { useFunctionalityAuth } from '../../../pages/hooks/functionalityAuthentication';

type Props = {
  show: boolean,
  handleShow: (value: boolean) => void
}
export function ModalResetPassword(props: Props): JSX.Element {
  const { onResetPass } = useFunctionalityAuth();
  const [passMatchErr, setPassMatchErr] = useState("")
  const [mail, setMail] = useState("")
  const [confMail, setConfMail] = useState("")
  const { show, handleShow } = props;
  const { register, handleSubmit, errors } = useForm<ApiBodyChangePassword>();

  const passValidation = (e: any) => {
    if (e.target.value !== mail && e.target.value != "") {
      setPassMatchErr("Passwords don't match")
    }
    else {
      setPassMatchErr("")
    }
  }
  return (
    <Modal
      show={show}
      onHide={() => handleShow(false)}
      centered
    >
      <form onSubmit={handleSubmit(onResetPass)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Current Password</label>
                <input
                  name="currentPassword"
                  type="password"
                  className="form-control"
                  placeholder="Current Password"
                  ref={register({ required: true })}
                />
                {
                  errors.currentPassword && (
                    <FormErrorMessage message="required" />
                  )
                }
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">New Password</label>
                <input
                  name="newPassword"
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  onChange={(e) => {
                    setMail(e.target.value)
                  }}
                  ref={register({ required: true })}
                />
                {
                  errors.newPassword && (
                    <FormErrorMessage message="required" />
                  )
                }
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Confirm Password</label>
                <input
                  name="confirmPassword"
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  ref={register({ required: true })}
                  onChange={(e) => {
                    setConfMail(e.target.value)
                    passValidation(e)
                  }}
                />
                {
                  errors.confirmPassword && (
                    <FormErrorMessage message="required" />
                  )
                }
                {
                  passMatchErr && (
                    <FormErrorMessage message={passMatchErr} />
                  )
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              setPassMatchErr("")
              handleShow(false)
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase change-from-submit-btn"
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}