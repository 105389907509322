import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { useFunctionalityJobNoteAddOrEdit } from "../../pages/hooks";
import { useFunctionalityInputChecker } from '../../pages/hooks/functionalityInputChecker';
import { useForm } from 'react-hook-form';
import { ApiSchemaReportProblem } from '../../models/api/reportProblem';
import { useFunctionalityReportProblem } from '../../pages/hooks/functionalityReportProblem';
import { useAppSelector } from '../../core';
import { shallowEqual } from "react-redux";
import * as Sentry from "@sentry/react";
import { toast } from 'react-toastify';

type Props = {
    show: boolean,
    handleShow: (value: boolean) => void,
    title?: string
    description?: string
}
export function ModalReportProblem(props: Props): JSX.Element {

    const { JobAddOrEditNotes } = useFunctionalityJobNoteAddOrEdit();
    const { handleSpecialCharacterWhiteSpacesForNote } = useFunctionalityInputChecker()
    const { register, handleSubmit, errors, setValue } = useForm<ApiSchemaReportProblem>();
    const { SendProblemReport } = useFunctionalityReportProblem()
    const modalHideHandler = () => {
        props.handleShow(false);
    }

    const userData = useAppSelector(
        (state) => ({
            mail: state.auth.profile?.email,
        }),
        shallowEqual
    );

    const methodDoesNotExist = () => {
        // throw new Error("I am from sentry")
        // Sentry.captureMessage("Something went wrong");
        var errObj = {
            title: "test",
            description: "yo working here"
        }
        // Sentry.captureMessage("Something went wrong here",errObj);
        // Sentry.captureException(errObj);
        // throw new UserException("errObj")

        // let err:any = new Error('Email already exists');
        // err.status = 400;
        // err.whateverElse = "";
        // throw err;
        // throw new Error(JSON.stringify({ title: , description: , email: }));


    };
    const ErrorSubmit = (form: ApiSchemaReportProblem) => {

        Sentry.captureMessage(JSON.stringify({ title: form.title, description: form.description, email: userData.mail }))
        toast.success("Successfully Submitted")
        modalHideHandler()
        // throw new Error(JSON.stringify({ title:form.title , description:form.description , email: userData.mail}))
    }

    return (
        <Modal
            show={props.show}
            onHide={() => props.handleShow(false)}
            centered
        >
            <form onSubmit={handleSubmit(ErrorSubmit)} noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>Submit error report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="col-12">
                            <label htmlFor="" className="col-12 p-0">Title </label>
                            <textarea name="title"
                                id="title"
                                className="form-control"
                                placeholder={"title"}
                                defaultValue={props.title ?? ""}
                                ref={register({ validate: handleSpecialCharacterWhiteSpacesForNote })}
                            // style={{ border: props.errors.customerNote ? '1px solid red' : '' }}
                            />
                            {/* {props.errors.customerNote && props.errors.customerNote.type == "validate" && (
                  <FormErrorMessage message="Please enter a valid value" />
                )} */}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12">
                            <label htmlFor="" className="col-12 p-0">Description</label>
                            <textarea name="description"
                                id="description"
                                className="form-control"
                                placeholder={"Description"}
                                defaultValue={props.description ?? ""}
                                ref={register({ validate: handleSpecialCharacterWhiteSpacesForNote })}
                            // style={{ border: props.errors.operatorNote ? '1px solid red' : '' }}
                            />
                            {/* {props.errors.operatorNote && props.errors.operatorNote.type == "validate" && (
                  <FormErrorMessage message="Please enter a valid value" />
                )} */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-link text-site text-uppercase" onClick={() => props.handleShow(false)}>Cancel</button>
                    <button type="submit" className="login-from-submit-btn btn btn-primary text-uppercase">Submit</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}