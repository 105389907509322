export const ShowEveryNameExceptUser = (
  message: any,
  currentUser: any,
  contactList: any
) => {
  let friendlyNameList = message?.conversation?.friendlyName?.split("+");

  let headlineString = [] as string[];
  if (friendlyNameList) {
    friendlyNameList.map((friendlyName: any) => {
      if (friendlyName !== currentUser.uid) {
        let participant = contactList?.find(
          (contact: { value: any }) => contact.value === friendlyName
        )?.label as string;
        headlineString.push(participant);
      }
    });
  }

  return headlineString.join(", ");
};
