import { useEffect, useState } from "react";
import { AppConstants, ApplicationName, AppRouteUi } from "../../configs";
import { Link, useHistory, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { ApiSchemaJobVosFormById, EVosExportPayload, VOSViewModel, } from "../../models";
import { eVosExportAsPdf, useFunctionalityVosForm, } from "../hooks/functionalityVosForm";
import { useForm } from "react-hook-form";
import moment from "moment";
import { SignatureModal } from "../../features/modals/SignatureModal";
import { AssetSvg } from "../../assets";
import { useFunctionalityInputChecker } from "../hooks/functionalityInputChecker";
import { useAppSelector } from "../../core";
import { timeZones } from "../../features/modals/jobFormData";
import { b64toBlob, dateFormatInUSA, downLoadBlob } from "../../utils";
import { PageVOSPRint } from "./vosPrintPage";

export function VosFormPage(): JSX.Element {
    const { state: jobData } = useLocation<any>();
    const { handleSubmit } = useForm<ApiSchemaJobVosFormById>();
    const history = useHistory();
    const { numberFieldValidation, handleLimitDecimalPlaces } = useFunctionalityInputChecker();
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [mileage, setMileage] = useState("");
    const [sigModalShowForInterPreter, setSigModalShowForInterPreter] = useState(false);
    const [sigModalShowForRepresentative, setSigModalShowForRepresentative] = useState(false);
    const [loaderProgress, setLoaderProgress] = useState(0);
    const { profile } = useAppSelector((state) => state.auth);
    const [interpreterImgUrl, setInterpreterImgUrl] = useState("");
    const [representativeImgUrl, setRepresentativeImgUrl] = useState("");
    const [questionnaire1, setQuestionnaire1] = useState("");
    const [questionnaire2, setQuestionnaire2] = useState("");
    const [questionnaire3, setQuestionnaire3] = useState("");
    const [questionnaire4, setQuestionnaire4] = useState("");
    const { onSaveVosForm } = useFunctionalityVosForm({
        id: jobData?.jobData.id ? jobData?.jobData.id : "",
        setLoaderProgress: setLoaderProgress,
    });
    const defaultStartTime = jobData?.actualStartTime
        ? new Date(jobData?.actualStartTime).toISOString()
        : "";
    const defaultEndTime = jobData?.actualEndTime
        ? new Date(jobData?.actualEndTime).toISOString()
        : "";
    const selectedTime = (time: string) => {
        var today = new Date();
        var date =
            today.getFullYear() +
            "/" +
            (today.getMonth() + 1) +
            "/" +
            today.getDate();
        let todayStartTime = new Date(date + " " + time.substring(0, 8));
        return todayStartTime.toISOString();
    };

    const selectedStartTime = startTime && selectedTime(startTime);
    const selectedEndTime = endTime && selectedTime(endTime);

    const getTime = (time: string) => {
        return moment(time).local().format("HH:mm");
    };

    const showStartTime = getTime(jobData?.actualStartTime);
    const showEndTime = getTime(jobData?.actualEndTime);
    const onVosFormHandler = (form: ApiSchemaJobVosFormById) => {
        const actualStartUTC = new Date(
            selectedStartTime ? selectedStartTime : defaultStartTime
        ).toUTCString();
        const actualEndUTC = new Date(
            selectedEndTime ? selectedEndTime : defaultEndTime
        ).toUTCString();
        var baseString = $("#rootPrintElement").html();
        form = {
            actualStart: actualStartUTC,
            actualEnd: actualEndUTC,
            mileage: mileage,
            vosFormFile: baseString,
        };
        onSaveVosForm(form)
            ?.then(() => {
                history.push(AppRouteUi.Jobs.Root);
            })
            .catch((err) => console.log(err));
    };
    const data: VOSViewModel = {
        Interpreter: profile?.name ?? "-",
        Language: jobData?.jobData?.languageName ?? "-",
        Date: jobData?.jobData?.date ?? "-",
        JobID: jobData?.jobData?.serialNo,
        Customer: jobData?.jobData?.companyName ?? "-",
        Location:
            jobData?.jobData?.address && jobData?.jobData?.address?.countryName
                ? jobData?.jobData?.address.description +
                " " +
                jobData?.jobData?.address?.city +
                " " +
                jobData?.jobData?.address?.stateName +
                " " +
                jobData?.jobData?.address?.zipCode +
                " " +
                jobData?.jobData?.address?.countryName
                : "N/A",
        ContactInfo: jobData?.jobData?.requestedBy ?? "-",
        FileNumber: "N/A",
        RequestedStartTime: jobData?.jobData?.start
            ? moment(jobData?.jobData?.start).format("hh:mm:ss a, MMM DD YYYY")
            : "",
        ActualStartTime: startTime
            ? moment(selectedStartTime).format("hh:mm:ss a, MMM DD YYYY")
            : jobData?.actualStartTime
                ? moment(jobData?.actualStartTime).format("hh:mm:ss a, MMM DD YYYY")
                : "",
        ActualEndTime: endTime
            ? moment(selectedEndTime).format("hh:mm:ss a, MMM DD YYYY")
            : jobData?.actualEndTime
                ? moment(jobData?.actualEndTime).format("hh:mm:ss a, MMM DD YYYY")
                : "",
        Mileage: mileage ? mileage : "",
        Consumer: jobData?.jobData?.consumer ?? "-",
        Questionnaire1: questionnaire1 ? questionnaire1 : "",
        Questionnaire2: questionnaire2 ? questionnaire2 : "",
        Questionnaire3: questionnaire3 ? questionnaire3 : "",
        Questionnaire4: questionnaire4 ? questionnaire4 : "",
        InterpretorSignature: interpreterImgUrl,
        ClientSignature: representativeImgUrl,
        timeZone: jobData?.jobData?.timeZone
    };
    const handleExportAsPdf = async () => {
        const printVos = $("#rootPrintElement").html();
        const exportPayload: EVosExportPayload = {
            htmlString: printVos,
            orientation: "Portrait",
            size: "A4",
        };
        const responseData = await eVosExportAsPdf({
            id: jobData?.jobData.id ? jobData?.jobData.id : "",
            exportPayload,
            setLoaderProgress,
        });
        if (responseData) {
            const blob = b64toBlob(responseData.data, "application/Pdf");
            // var blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl);
            downLoadBlob(blob, `E-VOS ${jobData?.jobData?.serialNo}.pdf`);
        }
    };
    return (
        <>
            <LoadingBar
                progress={loaderProgress}
                onLoaderFinished={() => setLoaderProgress(0)}
            />
            <h4 className="my-3 font-weight-light">Vos Form</h4>
            <Link
                to={AppRouteUi.Jobs.Root}
                className="btn btn-primary text-uppercase mb-4"
                role="button"
            >
                back
            </Link>
            <div className="componet-card">
                <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
                    <div>
                        Booking :{" "}
                        <span>
                            <strong>#{jobData?.jobData?.serialNo}</strong>
                        </span>
                    </div>
                </div>
                <div className="component-card-body border">
                    <div className="container-fluid user-general-info px-0">
                        <form onSubmit={handleSubmit(onVosFormHandler)}>
                            <div id="rootElementId">
                                <div className="card ">
                                    <div className="row mx-auto align-items-center justify-content-center VOSform-title">
                                        <div className="profile-image pl-3 pt-3 pb-3 bg-white ">
                                            <AssetSvg.VRILogo />
                                        </div>
                                        <div className="col ">
                                            <div className="profile-detail ">
                                                <p className="mb-0">
                                                    Address: 32910 W. Thirteen Mile Road , Suite E-504, Farmington Hills, MI 48334, USA
                                                </p>
                                                <p className="mb-0">
                                                    Phone: (313) 871-0080 Ext.
                                                    1, Fax: (888) 225-1912
                                                    {/* Emergency: (313) 878-4242 */}
                                                </p>
                                                <p className="mb-0">
                                                    Website:
                                                    www.brombergtranslations.com
                                                </p>
                                                <p className="mb-0">
                                                    Email:{" "}
                                                    <a href="">
                                                        {" "}
                                                        Scheduler@BrombergTranslations.com
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="mb-3  mt-4 text-uppercase font-400">
                                            Interpreter assignment statement
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card border-radius-xl">
                                            <div className="card-body p-0">
                                                <div className="row border-bottom mx-0">
                                                    <div className="col-7 border-right">
                                                        <div className="row ">
                                                            <div className="col py-2">
                                                                <span className="font-600">Interpreter:{" "}</span>
                                                                <span>{profile?.name ?? "-"}</span>
                                                            </div>
                                                            <div className="col py-2">
                                                                <span className="font-600"> Language:{" "}</span>
                                                                <span>{jobData?.jobData?.languageName ?? "-"} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-5">
                                                        <div className="row">
                                                            <div className="col py-2">
                                                                <span className="font-600">Date:{" "} </span>
                                                                <span>{jobData?.jobData?.date ? dateFormatInUSA(jobData?.jobData?.date) : "-"}
                                                                </span>
                                                            </div>
                                                            <div className="col py-2">
                                                                <span className="font-600">
                                                                    Job ID#:{" "}
                                                                </span>
                                                                <span>
                                                                    {
                                                                        jobData
                                                                            ?.jobData
                                                                            ?.serialNo
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="col-7 border-right ">
                                                        <div className="row">
                                                            <div className="col-5 pt-3">
                                                                <p>
                                                                    <strong>
                                                                        Customer:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col pt-3">
                                                                <p className="word-wrap">
                                                                    {jobData
                                                                        ?.jobData
                                                                        ?.companyName ??
                                                                        "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p>
                                                                    <strong>
                                                                        Location:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col">
                                                                <p className="word-wrap">
                                                                    {jobData
                                                                        ?.jobData
                                                                        ?.address &&
                                                                        jobData
                                                                            ?.jobData
                                                                            ?.address
                                                                            ?.countryName ? (
                                                                        <span>
                                                                            {jobData
                                                                                .jobData
                                                                                ?.address
                                                                                .description
                                                                                ? jobData.jobData?.address.description.concat(
                                                                                    ", "
                                                                                )
                                                                                : ""}
                                                                            {jobData
                                                                                .jobData
                                                                                ?.address
                                                                                .city
                                                                                ? jobData.jobData?.address.city.concat(
                                                                                    ", "
                                                                                )
                                                                                : ""}
                                                                            {jobData
                                                                                .jobData
                                                                                ?.address
                                                                                .stateName
                                                                                ? jobData.jobData?.address.stateName.concat(
                                                                                    ", "
                                                                                )
                                                                                : ""}
                                                                            {jobData
                                                                                .jobData
                                                                                ?.address
                                                                                .zipCode
                                                                                ? jobData.jobData?.address.zipCode.concat(
                                                                                    ", "
                                                                                )
                                                                                : ""}
                                                                            {
                                                                                jobData
                                                                                    .jobData
                                                                                    ?.address
                                                                                    .countryName
                                                                            }
                                                                            .
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            N/A
                                                                        </span>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p>
                                                                    <strong>
                                                                        Contact
                                                                        Info:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col">
                                                                <p className="word-wrap">
                                                                    {jobData
                                                                        ?.jobData
                                                                        ?.requestedBy ??
                                                                        "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p>
                                                                    <strong>
                                                                        File
                                                                        Number/
                                                                        Details:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col">
                                                                <p className="word-wrap">
                                                                    N/A
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-5 ">
                                                        <div className="row">
                                                            <div className="col-6 pt-3">
                                                                <p>
                                                                    <strong>
                                                                        Requested
                                                                        Start
                                                                        Time:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col pt-3">
                                                                <p className="word-wrap">
                                                                    {
                                                                        jobData
                                                                            ?.jobData
                                                                            ?.startTime
                                                                    }
                                                                    {timeZones.find((x) => x.value === jobData?.jobData?.timeZone)?.label}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p>
                                                                    <strong>
                                                                        Actual
                                                                        Start
                                                                        Time:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col-6 form-group">
                                                                {
                                                                    jobData?.actualStartTime ? (
                                                                        <input
                                                                            type="time"
                                                                            name="ActualStart"
                                                                            className="form-control"
                                                                            defaultValue={showStartTime}
                                                                            onBlur={(e) => {
                                                                                setStartTime(e.target.value);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            type="time"
                                                                            name="ActualStart"
                                                                            className="form-control"
                                                                            onBlur={(e) => {
                                                                                setStartTime(e.target.value);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p>
                                                                    <strong>
                                                                        Actual
                                                                        End
                                                                        Time:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col-6 form-group ">
                                                                {
                                                                    jobData?.actualStartTime ? (
                                                                        <input
                                                                            type="time"
                                                                            name="ActualEnd"
                                                                            className="form-control"
                                                                            defaultValue={showEndTime}
                                                                            onBlur={(e) => {
                                                                                setEndTime(e.target.value);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            type="time"
                                                                            name="ActualEnd"
                                                                            className="form-control"
                                                                            onBlur={(e) => {
                                                                                setEndTime(e.target.value);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-6 ">
                                                                <p>
                                                                    <strong>
                                                                        Mileage:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col-6 form-group ">
                                                                <input
                                                                    type="float"
                                                                    name="Mileage"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]|\./.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => { setMileage(e.target.value); }}
                                                                    onKeyDown={(evt) => numberFieldValidation(evt)}
                                                                    onInput={(event) => handleLimitDecimalPlaces(event, 2)
                                                                    }
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p>
                                                                    <strong>
                                                                        Consumer:
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                            <div className="col">
                                                                <p className="word-wrap">
                                                                    {jobData
                                                                        ?.jobData
                                                                        ?.consumer ??
                                                                        "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 border-top">
                                                    <div className="col-7 pt-3 border-right">
                                                        <p className="font-600">
                                                            If you are requested
                                                            to stay longer than
                                                            the assignment
                                                            schedule time,
                                                            please contact us
                                                            immediately at
                                                            (313)871-0080 Ext. 1
                                                            during business
                                                            hours or
                                                            (313)878-4242 after
                                                        </p>
                                                    </div>
                                                    <div className="col-5 pt-3">
                                                        <p className="font-600">
                                                            If The consumer or
                                                            client do not arrive
                                                            within 30 minutes of
                                                            the start time,
                                                            please contact
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom">
                                            <p className="mb-0 py-3">
                                                <strong>Note:</strong> It is the
                                                responsibility of the
                                                Interpreter to complete the
                                                assignment successfully, on
                                                time, with the highest quality
                                                results and according to the
                                                client’s specifications.
                                            </p>
                                        </div>
                                        <div className="questionnaire-section">
                                            <p className="py-3">
                                                <strong>
                                                    QUESTIONNAIRE (FILLING THIS
                                                    SHORT FORM OUT HELPS US TO
                                                    UPHOLD THE HIGH STANDARDS OF
                                                    INTERPRETING)
                                                </strong>
                                            </p>
                                            <div className="card border-radius-xl">
                                                <div className="row mx-0  border-bottom">
                                                    <p className="col-12 pt-3 pb-0 ">
                                                        <strong>
                                                            Please Indicate what
                                                            level you agree or
                                                            disagree with each
                                                            statement below
                                                        </strong>
                                                    </p>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row border-bottom mb-3">
                                                        <div className="col-12 text-center ">
                                                            <p>
                                                                1. The
                                                                Interpreter
                                                                conducted
                                                                himself/herself
                                                                in a
                                                                professional
                                                                demeanor
                                                                (punctuality,
                                                                professional
                                                                appearance,
                                                                etc.)
                                                            </p>
                                                        </div>
                                                        <div className="col-12 py-4">
                                                            <div className="d-flex justify-content-sm-center align-items-center questionnaire-rating">
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Disagree
                                                                </div>
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="a1"
                                                                    name="a"
                                                                    value="a1"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire1(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="a2"
                                                                    name="a"
                                                                    value="a2"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire1(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="a3"
                                                                    name="a"
                                                                    value="a3"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire1(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="a4"
                                                                    name="a"
                                                                    value="a4"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire1(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="a5"
                                                                    name="a"
                                                                    value="a5"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire1(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Agree
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row border-bottom mb-3">
                                                        <div className="col-12 text-center">
                                                            <p>
                                                                2. The
                                                                Interpreter was
                                                                familiar with
                                                                the appropriate
                                                                terminology and
                                                                procedures
                                                                (legal, medical,
                                                                business, etc.)
                                                            </p>
                                                        </div>
                                                        <div className="col-12 py-4">
                                                            <div className="d-flex justify-content-sm-center align-items-center questionnaire-rating">
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Disagree
                                                                </div>
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="b1"
                                                                    name="b"
                                                                    value="b1"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire2(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="b2"
                                                                    name="b"
                                                                    value="b2"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire2(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="b3"
                                                                    name="b"
                                                                    value="b3"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire2(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="b4"
                                                                    name="b"
                                                                    value="b4"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire2(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="b5"
                                                                    name="b"
                                                                    value="b5"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire2(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Agree
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row border-bottom mb-3">
                                                        <div className="col-12 text-center">
                                                            <p>
                                                                3. The
                                                                Interpreter
                                                                adhered to the
                                                                professional
                                                                code of ethics
                                                                by refraining
                                                                from conseving
                                                                with parties,
                                                                interpretingfully
                                                                for both
                                                                parties,
                                                                appearing
                                                                neutral,
                                                                representing his
                                                                /her
                                                                qualifications
                                                            </p>
                                                        </div>
                                                        <div className="col-12 py-4">
                                                            <div className="d-flex justify-content-sm-center align-items-center questionnaire-rating">
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Disagree
                                                                </div>
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="c1"
                                                                    name="c"
                                                                    value="c1"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire3(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="c2"
                                                                    name="c"
                                                                    value="c2"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire3(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="c3"
                                                                    name="c"
                                                                    value="c3"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire3(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="c4"
                                                                    name="c"
                                                                    value="c4"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire3(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="c5"
                                                                    name="c"
                                                                    value="c5"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire3(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Agree
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-12 text-center">
                                                            <p>
                                                                4. I would like
                                                                to work with
                                                                this interpreter
                                                                again
                                                            </p>
                                                        </div>
                                                        <div className="col-12 py-4">
                                                            <div className="d-flex justify-content-sm-center align-items-center questionnaire-rating">
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Disagree
                                                                </div>
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="d1"
                                                                    name="d"
                                                                    value="d1"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire4(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="d2"
                                                                    name="d"
                                                                    value="d2"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire4(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="d3"
                                                                    name="d"
                                                                    value="d3"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire4(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="d4"
                                                                    name="d"
                                                                    value="d4"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire4(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <input
                                                                    className="medium"
                                                                    type="radio"
                                                                    id="d5"
                                                                    name="d"
                                                                    value="d5"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setQuestionnaire4(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <div className="input-label">
                                                                    Strongly
                                                                    Agree
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row py-3 mt-3">
                                                <div className="col d-flex flex-column">
                                                    {interpreterImgUrl ? (
                                                        <div className="signature-ctl">
                                                            {" "}
                                                            <img
                                                                src={
                                                                    interpreterImgUrl
                                                                }
                                                                onClick={() =>
                                                                    setSigModalShowForInterPreter(
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="col-6 border-bottom py-2"
                                                            onClick={() =>
                                                                setSigModalShowForInterPreter(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            &nbsp;
                                                        </div>
                                                    )}
                                                    <div className="col-12 px-0">
                                                        <p className=" py-2">
                                                            Interpreter
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col d-flex flex-column ml-auto text-right">
                                                    {representativeImgUrl ? (
                                                        <div className="signature-ctl">
                                                            {" "}
                                                            <img
                                                                src={
                                                                    representativeImgUrl
                                                                }
                                                                onClick={() =>
                                                                    setSigModalShowForRepresentative(
                                                                        true
                                                                    )
                                                                }
                                                            />{" "}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="col-6 border-bottom py-2 ml-auto"
                                                            onClick={() =>
                                                                setSigModalShowForRepresentative(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            &nbsp;
                                                        </div>
                                                    )}
                                                    <div className="col-12 px-0">
                                                        <p className=" py-2">
                                                            Client or authorized
                                                            representative
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row py-4 ">
                                                <div className="col-12 text-center">
                                                    Powered by&ensp;
                                                    <a
                                                        href="https://vrigateway.com/"
                                                        target="_blank"
                                                    >
                                                        {ApplicationName}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {profile?.userRole ===
                                AppConstants.UserRoles.Operator && (
                                    <div className="row pt-3 ">
                                        <div className="col-12 text-right">
                                            <button
                                                type="submit"
                                                className="btn btn-primary text-uppercase voss-form-submit-btn"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                )}
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 py-4">
                        <button
                            className="btn btn-primary text-uppercase voss-form-download-btn"
                            onClick={() => {
                                // downloadPdfDocument("rootElementId", "download");
                                handleExportAsPdf();
                            }}
                        >
                            Download
                        </button>
                        <button
                            type="button"
                            className="btn btn-link text-site text-uppercase "
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <SignatureModal
                show={sigModalShowForInterPreter}
                handleShow={setSigModalShowForInterPreter}
                header={"Add Signature"}
                setImgUrl={setInterpreterImgUrl}
            />
            <SignatureModal
                show={sigModalShowForRepresentative}
                handleShow={setSigModalShowForRepresentative}
                header={"Add Signature"}
                setImgUrl={setRepresentativeImgUrl}
            />
            {data && <PageVOSPRint data={data} />}
        </>
    );
}
