import { DatesSetArg } from "@fullcalendar/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { AppRouteUi, Modals, PlatformType, VriApi } from "../../configs";
import { AxiosAuth, StorageUserCalendar, useAppSelector } from "../../core";
import {
  Calendar,
  EventData,
  ModalCalendarUpcomingSchedule,
  ModalCreateSchedule,
  ModalInfo,
  TopNavPublic,
} from "../../features";
import { genders } from "../../features/modals/jobFormData";
import UpcomingSchedule from "../../features/UserCalendar/upcomingSchedule";
import UserCalendarHeader from "../../features/UserCalendar/userCalendarHeader";
import { CalendarTime, ConsumerConferenceData, Option } from "../../models";
import { BootstrapUtils } from "../../utils";
import {
  useFunctionalityConference,
  useFunctionalityConsumerConference,
  useFunctionalityLanguage,
  useFunctionalityPagedCompany,
  useFunctionalitySkill,
  useFunctionalityUser,
  useFunctionalityUserForExternal,
} from "../hooks";
import { useFunctionalityConsumer } from "../hooks/functionalityConsumer";
import { dateFormatterInYMD, time24hrFormatter } from "../../utils";
import { useFunctionalityMakeSchedule } from "../hooks/functionalityMakeSchedule";
import { Button } from "react-bootstrap";
export function PageUserCalendar(): JSX.Element {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const persistedCalendarDate = StorageUserCalendar.CalendarDate;
  const persistedCompanyUser = StorageUserCalendar.CompanyUser;
  const persistedInterpreterSettings =
    StorageUserCalendar.CalendarInterpreterSettingsData;

  const [language, setLanguage] = React.useState(
    persistedInterpreterSettings?.language || null
  );
  const [skill, setSkill] = React.useState(
    persistedInterpreterSettings?.skill || null
  );
  const [gender, setGender] = React.useState(
    persistedInterpreterSettings?.gender || null
  );
  const [interpreterRequired, setInterpreterRequired] = React.useState(
    persistedInterpreterSettings?.interpreterRequired || 0
  );
  const [selectedUser, setSelectedUser] = React.useState(
    persistedCompanyUser || null
  );
  const [initialDate, setInitialDate] = React.useState(persistedCalendarDate);
  const [roomId, setRoomId] = React.useState("");
  const [calendarInfo, setCalendarInfo] = React.useState<any>();
  const [upcomingScheduleDetails, setUpcomingScheduleDetails] = React.useState(
    {}
  );
  const { onCreateConference } = useFunctionalityMakeSchedule();

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const { optionMappedSkillList } = useFunctionalitySkill({
    shouldSkillGetAllInvoke: true,
  });
  const routeParamCompanyId = AppRouteUi.UserCalendar.Scheduler.useParam();
  const { languageList } = useFunctionalityLanguage({
    plaformType: PlatformType.All,
    companyId: routeParamCompanyId,
    shouldLanguageGetAllInvoke: true,
  });

  const { userNames: companyUserList } = useFunctionalityUserForExternal({
    companyId: routeParamCompanyId ?? "",
  });

  const data = useAppSelector((state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    userRole: state.auth.profile?.userRole,
    userId: state.auth.profile?.id,
  }));

  const [listConference, setListConference] = useState<
    ConsumerConferenceData[]
  >([]);
  const { onGetConferenceList } = useFunctionalityConsumer();
  const [showUpcomingSchedule, setShowUpcomingSchedule] =
    useState<boolean>(false);
  const [showCreateSchedule, setShowCreateSchedule] = useState<boolean>(false);
  const [showRoomLink, setShowRoomLink] = useState<boolean>(false);

  const [startTime, setStartTime] = React.useState<any>();

  const consumerId = data?.userId;
  const { consumerConferenceList } = useFunctionalityConsumerConference(
    consumerId ? consumerId : ""
  );
  const { conferenceList, conferenceByUserIdUrl } = useFunctionalityConference(
    selectedUser ? selectedUser.value : "",
    startDate,
    endDate
  );
  const rootUrl = process.env.REACT_APP_CLIENT_ROOT || "";

  const generateRoomLink = (roomId: string) => {
    return `${rootUrl}/lobby/${roomId}`;
  };

  const [createScheduleShowModal, setCreateScheduleShowModal] =
    React.useState(false);
  const handleCreateScheduleCloseModal = () => {
    // setCreateScheduleShowModal(false);
    setShowCreateSchedule(false);
  };

  // for translation

  let en_dict = {
    "Create New Schedule": "Create New Schedule",
    "Upcoming Schedule List": "Upcoming Schedule List",
    "Include language interpreter": "Include language interpreter",
    "Select a user": "Select a user",
    "Select a language": "Select a language",
    "Select a skill": "Select a skill",
    "Select a gender": "Select a gender",
    "No upcoming schedule": "No upcoming schedule",
  };

  let sp_dict = {
    "Create New Schedule": "Crear nuevo horario",
    "Upcoming Schedule List": "Lista de programación próxima",
    "Include language interpreter": "Incluir intérprete de idioma",
    "Select a user": "Selecciona un usuario",
    "Select a language": "Selecciona un idioma",
    "Select a skill": "Selecciona una habilidad",
    "Select a gender": "Seleccione un género",
    "No upcoming schedule": "Sin horario próximo",
  };

  let ar_dict = {
    "Create New Schedule": "إنشاء جدول جديد",
    "Upcoming Schedule List": "قائمة الجدول القادمة",
    "Include language interpreter": "تضمين مترجم لغة",
    "Select a user": "حدد مستخدمًا",
    "Select a language": "اختر لغة",
    "Select a skill": "اختر مهارة",
    "Select a gender": "اختر الجنس",
    "No upcoming schedule": "لا يوجد جدول زمني قادم",
  };

  const [english_dictionary, setEnglish_dictionary] = useState(en_dict);

  const [spanish_dictionary, setSpanish_dictionary] = useState(sp_dict);

  const [arabic_dictionary, setArabic_dictionary] = useState(ar_dict);

  const [dictionary, setDictionary] = useState(english_dictionary);

  useEffect(() => {
    if (currentLanguage === "en") {
      setDictionary(english_dictionary);
    } else if (currentLanguage === "sp") {
      setDictionary(spanish_dictionary);
    } else if (currentLanguage === "ar") {
      setDictionary(arabic_dictionary);
    }
  });

  const selectCompanyUserHandler = (selectedUser: Option) => {
    setSelectedUser(selectedUser);
  };
  const checkInterpreterHandler = (checked: number) => {
    checked === 0 ? setInterpreterRequired(1) : setInterpreterRequired(0);
  };
  const selectLanguageHandler = (selectedLanguage: Option) => {
    setLanguage(selectedLanguage);
  };

  const selectSkillHandler = (selectedSkill: Option) => {
    setSkill(selectedSkill);
  };

  const selectGenderHandler = (selectedGender: Option) => {
    setGender(selectedGender);
  };

  const onClickUpcomingSchedule = (schedule: ConsumerConferenceData) => {
    setUpcomingScheduleDetails(schedule);
    setShowUpcomingSchedule(true);
  };

  const validateScheduledTime = (info: any) => {
    const timeIsBetween = (startDate: any, endDate: any) => {
      let scheduleTimeOverlaps = false;
      conferenceList.map((conference) => {
        let conferenceStartTime = new Date(
          conference?.start ? conference.start : ""
        ).getTime();
        let conferenceEndTime = new Date(
          conference?.end ? conference.end : ""
        ).getTime();
        conferenceStartTime < endDate && conferenceEndTime > startDate
          ? (scheduleTimeOverlaps = true)
          : console.log("");
      });
      return scheduleTimeOverlaps;
    };
    let today = new Date();
    return today.getTime() - info?.start.getTime() <= 0 &&
      info.end >= info.start &&
      !timeIsBetween(info?.start.getTime(), info?.end.getTime())
      ? true
      : false;
  };

  const handleCreateSchedule = (scheduleTime: CalendarTime) => {
    if (!validateScheduledTime(scheduleTime)) {
      return toast.error(
        "Scheduled time is passed away or selected time range is invalid."
      );
    } else if (interpreterRequired && !language?.value) {
      setShowCreateSchedule(false);
      return toast.error("Please select language");
    }
    let roomId = uuidv4();
    setRoomId(roomId);
    const body = new FormData();

    body.append("roomId", roomId);
    body.append("startDate", scheduleTime?.startDate ?? "");
    body.append("endDate", scheduleTime?.endDate ?? "");
    body.append("startTime", scheduleTime?.startTime ?? "");
    body.append("endTime", scheduleTime?.endTime ?? "");
    body.append("interpreterRequired", interpreterRequired ? "true" : "false");
    body.append("language", language?.value ?? "");
    body.append("skill", skill?.value ?? "");
    body.append("gender", gender ? gender.value : "");
    body.append("assignedUserId", selectedUser?.value ?? "");
    body.append("consumerId", consumerId ?? "");
    body.append("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);

    onCreateConference(body)
      ?.then((response) => {
        if (!response?.data?.isSuccess) {
          return toast.error("Schedule creation failed.");
        }
        mutate(conferenceByUserIdUrl);
        mutate(VriApi.Conference.GetConsumers.Root(consumerId || ""));
        setShowCreateSchedule(false);
        setShowRoomLink(true);
        StorageUserCalendar.Clear();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDateClick = (info: any) => {
    let calendarApi = info.view.calendar;
    if (calendarApi.view.type !== "timeGridDay") {
      calendarApi.changeView("timeGridDay", info.date);
    }
  };
  const handleCalendarSelectClick = (info: any) => {
    let t = time24hrFormatter(calendarInfo?.start);
    setStartTime(t);
    setCalendarInfo(info);
    let calendarApi = info.view.calendar;
    if (!data.isLoggedIn) {
      StorageUserCalendar.CalendarDate = info?.view?.title
        ? new Date(info?.view?.title)
        : new Date();
      setInitialDate(StorageUserCalendar.CalendarDate);
      selectedUser
        ? (StorageUserCalendar.CompanyUser = selectedUser)
        : console.log("");
      StorageUserCalendar.CalendarInterpreterSettingsData = {
        language,
        skill,
        gender,
        interpreterRequired,
      };
      return toast.error("Please login first");
    }
    if (!selectedUser) {
      calendarApi.unselect();
      return toast.error("Please select a user.");
    }
    if (calendarApi.view.type !== "timeGridDay") {
      return calendarApi.unselect();
    }
    setShowCreateSchedule(true);
    //setCreateScheduleShowModal(true);
    BootstrapUtils.ModalShowById(Modals.CreateScheduleModal);
  };

  const handleChangeDatesSet = (dateInfo: DatesSetArg) => {
    setStartDate(dateInfo.start.toISOString());
    setEndDate(dateInfo.end.toISOString());
  };

  interface ConferenceListData {
    start?: string;
    end?: string;
  }

  const calendarEventList: EventData[] = conferenceList?.map(
    (calendarData: ConferenceListData) => {
      return {
        start: calendarData.start,
        end: calendarData.end,
        backgroundColor: "#6448939c",
        borderColor: "#6448939c",
        display: "block",
      };
    }
  );

  return (
    <>
      <TopNavPublic />

      <div className="container-fluid py-4 bg-gradient full-calendar-height">
        {/* <div className="row">
          <UserCalendarHeader companyUserList={companyUserList}
            selectCompanyUserHandler={selectCompanyUserHandler}
            interpreterRequired={interpreterRequired}
            checkInterpreterHandler={checkInterpreterHandler}
            languageList={languageList}
            selectLanguageHandler={selectLanguageHandler}
            optionMappedSkillList={optionMappedSkillList}
            selectSkillHandler={selectSkillHandler}
            genders={genders}
            selectGenderHandler={selectGenderHandler} />
        </div> */}
        <div className="custom-container-width">
          <div className="row mx-1">
            <div className="col order-1 order-sm-1 order-md-1 order-lg-0 mt-4 mt-sm-4 mt-md-4 mt-lg-0 scheduler-calendar">
              <Calendar
                calendarEvents={calendarEventList}
                handleDateClick={handleDateClick}
                handleSelectClick={handleCalendarSelectClick}
                initialDate={initialDate}
                handleChangeDatesSet={handleChangeDatesSet}
                whichPage="userCalendarPage"
              />
            </div>
            <div className="col-scheduler-width px-3 order-0 order-sm-0 order-md-0 order-lg-1">
              <ul
                className="nav mb-3 scheduler-tab-nav"
                id="scheduler-tab"
                role="tabllist"
              >
                <li className="nav-item " role="presentation">
                  <a
                    href="#calendar-make-schedule"
                    title="Create Schedule"
                    className="nav-link btn round-border-btn-sm btn-opaque mr-2 active"
                    id="new-schedule-tab"
                    data-toggle="pill"
                    aria-controls="new-schedule-tab"
                    aria-selected="true"
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 20 20"
                    >
                      <polygon
                        fill="currentColor"
                        points="10.9,9.1 10.9,1 9.1,1 9.1,9.1 1,9.1 1,10.9 9.1,10.9 9.1,19 10.9,19 10.9,10.9 19,10.9 19,9.1 "
                      />
                    </svg>
                    <span>{dictionary["Create New Schedule"]}</span>
                  </a>
                </li>
                {data.isLoggedIn && (
                  <li className="nav-item " role="presentation">
                    <a
                      href="#calendar-upcoming-schedule-list"
                      title="Schedule List"
                      className="nav-link btn round-border-btn-sm btn-opaque mx-2"
                      id="current-schedule-list"
                      data-toggle="pill"
                      aria-controls="current-schedule-list"
                      aria-selected="true"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 20 20"
                      >
                        <g>
                          <path
                            fill="currentColor"
                            d="M1,3.9c0-0.7,0.6-1.4,1.2-1.4s1.2,0.6,1.2,1.4S2.9,5.2,2.2,5.2C1.6,5.2,1,4.6,1,3.9z"
                          />
                          <path
                            fill="currentColor"
                            d="M2.2,14.8c0.7,0,1.2,0.6,1.2,1.4c0,0.7-0.6,1.4-1.2,1.4S1,16.9,1,16.1C1,15.4,1.6,14.8,2.2,14.8z"
                          />
                          <path
                            fill="currentColor"
                            d="M2.2,11.3C1.6,11.3,1,10.7,1,9.9s0.6-1.4,1.2-1.4s1.2,0.6,1.2,1.4C3.4,10.8,2.9,11.3,2.2,11.3z"
                          />
                          <path
                            fill="currentColor"
                            d="M18.1,2.9h-12c-0.4,0-0.9,0.5-0.9,1l0,0c0,0.5,0.4,1,0.9,1h12c0.5,0,0.9-0.5,0.9-1l0,0
                      C19,3.4,18.6,2.9,18.1,2.9z"
                          />
                          <path
                            fill="currentColor"
                            d="M18.1,8.9h-12c-0.4,0-0.9,0.5-0.9,1l0,0c0,0.5,0.4,1,0.9,1h12c0.5,0,0.9-0.5,0.9-1l0,0
                      C19,9.4,18.6,8.9,18.1,8.9z"
                          />
                          <path
                            fill="currentColor"
                            d="M18.1,15.1h-12c-0.4,0-0.9,0.5-0.9,1l0,0c0,0.5,0.4,1,0.9,1h12c0.5,0,0.9-0.5,0.9-1l0,0
                      C19,15.6,18.6,15.1,18.1,15.1z"
                          />
                        </g>
                      </svg>
                      <span>{dictionary["Upcoming Schedule List"]}</span>
                    </a>
                  </li>
                )}
              </ul>
              <div
                className="card card-body px-0 site-rounded-border scheduler-tab-content tab-content"
                id="pills-tabContent"
              >
                <UserCalendarHeader
                  companyUserList={companyUserList}
                  selectCompanyUserHandler={selectCompanyUserHandler}
                  interpreterRequired={interpreterRequired}
                  checkInterpreterHandler={checkInterpreterHandler}
                  languageList={languageList}
                  selectLanguageHandler={selectLanguageHandler}
                  optionMappedSkillList={optionMappedSkillList}
                  selectSkillHandler={selectSkillHandler}
                  genders={genders}
                  selectGenderHandler={selectGenderHandler}
                  onClickUpcomingSchedule={onClickUpcomingSchedule}
                  dictionary={dictionary}
                />
              </div>
              <div
                className="card card-body px-0 pt-3 pb-1  site-rounded-border scheduler-tab-content tab-content mt-3"
                id="pills-tabContent"
              >
                <div className="row">
                <div className="col-12 tab-pane fade show active text-center" id="calendar-language-card" role="tab-panel" aria-labelledby="new-schedule-tab">
                  <div className="nav nav-pills mx-auto">
                  <a
                  onClick={() => {
                    setCurrentLanguage("en");
                  }}
                role="button" className={`btn btn-block nav-link mx-3 ${currentLanguage === "en" ? "active" : null}`}>
                  English
                </a>
                  </div>
               <div className="nav nav-pills mx-auto">
               <a
                  onClick={() => {
                    setCurrentLanguage("sp");
                  }}
                  role="button" className={`btn btn-block nav-link mx-3 ${currentLanguage === "sp" ? "active" : null}`}>
                  Spanish
                </a>
               </div>
               <div className="nav nav-pills mx-auto">
               <a
                  onClick={() => {
                    setCurrentLanguage("ar");
                  }}
                role="button" className={`btn btn-block nav-link mx-3 ${currentLanguage === "ar" ? "active" : null}`}>
                  Arabic
                </a>
               </div>
                
               
                </div>
                </div>
                
               
              </div>
            </div>
          </div>
        </div>
        <ModalCalendarUpcomingSchedule
          show={showUpcomingSchedule}
          handleShow={setShowUpcomingSchedule}
          scheduleInfo={upcomingScheduleDetails}
        />
      </div>
      <ModalInfo
        show={showRoomLink}
        handleShow={setShowRoomLink}
        info={generateRoomLink(roomId)}
      />
      <ModalCreateSchedule
        show={showCreateSchedule}
        handleShow={setShowCreateSchedule}
        calendarInfo={calendarInfo}
        handleCreateSchedule={handleCreateSchedule}
        handleCloseModal={handleCreateScheduleCloseModal}
        startTime={time24hrFormatter(calendarInfo?.start)}
        startDate={dateFormatterInYMD(calendarInfo?.start)}
        endTime={time24hrFormatter(calendarInfo?.end)}
        endDate={dateFormatterInYMD(calendarInfo?.end)}
      />
    </>
  );
}
