import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { LocalAudioTrack, createLocalAudioTrack } from 'twilio-video';
import { VideoCallContext } from '../../../../core';
import AudioLevelIndicator from '../../../AudioLevelIndicator/AudioLevelIndicator';

interface IAudioInputProp {
  selectedAudioTrack?: LocalAudioTrack;
  setSelectedAudioTrack: Dispatch<SetStateAction<LocalAudioTrack | undefined>>;
  setAudioInputDeviceChangeStatus: Dispatch<SetStateAction<boolean>>;
}
function AudioInputList({
  selectedAudioTrack,
  setSelectedAudioTrack,
  setAudioInputDeviceChangeStatus,
}: IAudioInputProp) {
  const { room } = useContext(VideoCallContext);
  const [audioInputDevice, setAudioInputDevice] = useState<MediaDeviceInfo[]>(
    []
  );
  const participantAudioTrack = Array.from(
    room?.localParticipant.audioTracks.values()!
  )?.[0]?.track;
  const selectAudioInputDevice: MediaDeviceInfo | undefined =
    audioInputDevice.find(
      (device) => device.label === participantAudioTrack?.mediaStreamTrack.label
    );

  const replaceTrack = async (newDeviceId: string) => {
    await selectedAudioTrack?.restart({
      deviceId: { exact: newDeviceId },
    });
    if (
      participantAudioTrack?.mediaStreamTrack.label ===
      selectedAudioTrack?.mediaStreamTrack.label
    ) {
      setAudioInputDeviceChangeStatus(false);
    } else {
      setAudioInputDeviceChangeStatus(true);
    }
  };

  useEffect(() => {
    let initialAudioTrack: LocalAudioTrack;
    let audioInputDevices: MediaDeviceInfo[];

    const getAudioTrack = async () => {
      initialAudioTrack = await createLocalAudioTrack();
      const currentDevice = audioInputDevices?.find(
        (device) =>
          device.label === participantAudioTrack?.mediaStreamTrack?.label
      );
      await initialAudioTrack.restart({
        deviceId: currentDevice?.deviceId,
      });
      setSelectedAudioTrack(initialAudioTrack);
    };

    const getAudioInputDevices = async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      audioInputDevices = mediaDevices.filter(
        (mediaDevice) => mediaDevice.kind === 'audioinput'
      );
      getAudioTrack();
      setAudioInputDevice(audioInputDevices);
    };

    getAudioInputDevices();

    return () => {
      if (!initialAudioTrack) {
        return;
      }

      room!.localParticipant.audioTracks.forEach((track) => {
        if (
          track.track.mediaStreamTrack.label ===
          initialAudioTrack.mediaStreamTrack.label
        ) {
          initialAudioTrack.stop();
        }
      });
    };
  }, [participantAudioTrack, room, setSelectedAudioTrack]);

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Audio Input
      </Typography>
      <Grid container alignItems="center" justifyContent="space-between">
        <div className="inputSelect">
          {audioInputDevice.length ? (
            <FormControl fullWidth>
              <Select
                onChange={(e) => replaceTrack(e.target.value as string)}
                defaultValue={selectAudioInputDevice?.deviceId}
                variant="outlined"
              >
                {audioInputDevice.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>
              {selectedAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}
            </Typography>
          )}
        </div>
        <AudioLevelIndicator audioTrack={selectedAudioTrack} color="black" />
      </Grid>
    </div>
  );
}

export default AudioInputList;
