import React, { useState } from "react";
import { GoogleLogout } from "react-google-login";
import { useDispatch } from "react-redux";
import {
  CalendarProvider,
  ExternalLoginConstants,
} from "../../../configs/appConstants";
import { StorageUserCalendar } from "../../../core";
import { StorageAuth } from "../../../core/LocalStorage/StorageAuth";
import { ActionApp } from "../../../core/Redux/ReduxStore";
import { useFunctionalityUserConnections } from "../../../pages/hooks";

const clientId = ExternalLoginConstants.Google.ClientId;

type Props = {
  setChangeProp: any;
  key?: number;
};
function GoogleLogoutComForCalendar(props: Props): JSX.Element {
  const { connect, disconnect } = useFunctionalityUserConnections();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onDisconnect = (connectionType: CalendarProvider) => {
    disconnect(connectionType)
      .then((r) => {
        // toast
        props.setChangeProp((prev: number) => prev + 1);
      })
      .catch((e) => {
        // toast
      });
  };
  const onLogout = React.useCallback(() => {
    onDisconnect(CalendarProvider.Google);

    // StorageAuth.Clear();
    // StorageUserCalendar.Clear();
    // dispatch(ActionApp.ResetStore());
  }, [dispatch]);

  return (
    <div>
      <GoogleLogout
        className="btn"
        clientId={clientId}
        buttonText="Logout"
        onLogoutSuccess={onLogout}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="btn btn-outline-purple"
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm m-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
            DISCONNECT FROM GOOGLE
          </button>
        )}
      />
    </div>
  );
}

export default GoogleLogoutComForCalendar;
