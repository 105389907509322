export enum EnumAppModal {
  Register = "modal-registration-form",
}

export interface IScreen {
  image: string;
  id: string;
  name: string;
}

export interface IScreenPickerModalProp {
  getDesktopVideoStream: Function;
  setAllScreens: Function;
  allScreens: IScreen[];
};