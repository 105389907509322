import Select from "react-select";
import { Modal } from "react-bootstrap";
import {
  ICustomInputRequestModels,
  ITypeCustomInput,
  ITypeCustomInputPayload,
  IvrCustomField,
} from "../../../models";
import { useForm } from "react-hook-form";
import {
  updateCustomInputFields,
  useFunctionalityDepartment,
  useFunctionalityGetCustomInput,
  useFunctionalityLocation,
} from "../../../pages/hooks";
import { ExtendedCriteriaType, InputFieldType } from "../../../configs";
import { FormErrorMessage } from "../../../components";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  header?: string;
  ivrId?: string;
  customFields?: IvrCustomField[];
  onSaveHandler?: any;
  callId?: string;
  callerId?: string;
  callerCompanyId?: string;
  getCustomInputData?: ITypeCustomInput[];
};

export function ModalIvrCustomField(props: Props) {
  const {
    show,
    handleShow,
    callId,
    callerId,
    callerCompanyId,
    getCustomInputData,
  } = props;
  const { optionMappedLocationList } = useFunctionalityLocation({
    companyId: callerCompanyId!, shouldLocationGetAllInvoke: show
  });
  const { optionMappedDepartmentList } = useFunctionalityDepartment({
    companyId: callerCompanyId!, shouldDepartmentGetAllInvoke: show
  });

  // const { getCustomInputData }: { getCustomInputData: ITypeCustomInput[] | undefined } = useFunctionalityGetCustomInput(callinfo.customParameters?.("CallId"))

  const updatedOptionMappedLocationList = [
    { value: "", label: "Any" },
    ...optionMappedLocationList,
  ];
  const updatedOptionMappedDepartmentList = [
    { value: "", label: "Any" },
    ...optionMappedDepartmentList,
  ];

  const { register, handleSubmit, errors, setValue } = useForm<any>();

  const onUpdateCustomInputFieldsHandler = (form: any) => {
    let customInputRequestModels: ICustomInputRequestModels[] = [];
    getCustomInputData?.forEach((customInput: ITypeCustomInput) => {
      if (form[customInput.name.replace(/[^a-zA-Z ]/g, "")]) {
        customInputRequestModels.push({
          name: customInput.name,
          value: form[customInput.name.replace(/[^a-zA-Z ]/g, "")],
          isEnabled: true,
          criteriaType: customInput.criteriaType,
        });
      }
    });

    const payload: ITypeCustomInputPayload | undefined =
      customInputRequestModels.length
        ? { customInputRequestModels: customInputRequestModels }
        : undefined;
    updateCustomInputFields(callId, payload, handleShow);
  };

  return (
    <Modal
      show={show}
      onHide={() => handleShow(false)}
      centered
      backdrop="static"
    >
      <form onSubmit={handleSubmit(onUpdateCustomInputFieldsHandler)}>
        <Modal.Header closeButton>
          <Modal.Title>Custom Inputs</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="px-3 py-2">
            <div className="row mt-1">
              <div className="col-12">
                {getCustomInputData ? (
                  getCustomInputData.length ? (
                    getCustomInputData?.map(
                      (customInput: ITypeCustomInput, index) => (
                        <div key={index}>
                          <div className="row mb-2">
                            <div className="col-4">
                              <label
                                htmlFor=""
                                className={`text-muted font-sz-12 ${customInput.isMandatory ? "required" : ""
                                  }`}
                              >
                                {customInput.name}
                              </label>
                            </div>
                            <div className="col-8">
                              {customInput.inputType ===
                                InputFieldType.DropDown ? (
                                <>
                                  <input
                                    className="d-none"
                                    name={customInput.name.replace(/[^a-zA-Z ]/g, "")}
                                    ref={register({
                                      required: customInput.isMandatory,
                                    })}
                                  />
                                  <Select
                                    className={` ${errors[customInput.name]
                                      ? "field-required"
                                      : ""
                                      } `}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    options={
                                      customInput.criteriaType ===
                                        ExtendedCriteriaType.Department
                                        ? updatedOptionMappedDepartmentList
                                        : customInput.criteriaType ===
                                          ExtendedCriteriaType.Location
                                          ? updatedOptionMappedLocationList
                                          : []
                                    }
                                    placeholder={`Select a ${customInput.name}`}
                                    onChange={(option) => {
                                      setValue(
                                        customInput.name.replace(/[^a-zA-Z ]/g, ""),
                                        option?.value,
                                        { shouldValidate: true }
                                      );
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    className={`form-control ${errors[customInput.name]
                                      ? "field-required"
                                      : ""
                                      }`}
                                    name={customInput.name.replace(/[^a-zA-Z ]/g, "")}
                                    ref={register({
                                      required: customInput.isMandatory,
                                    })}
                                    onBlur={(event) => {
                                      setValue(
                                        customInput.name.replace(/[^a-zA-Z ]/g, ""),
                                        event.target.value,
                                        { shouldValidate: true }
                                      );
                                    }}
                                  />
                                </>
                              )}
                              <span>
                                {errors[customInput.name] && (
                                  <FormErrorMessage message="This field is required" />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className="text-center my-4">
                      Have no custom inputs!
                    </div>
                  )
                ) : (
                  <div className="text-center my-4">Loading...</div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase btn-message-submit custom-field-submit-btn-save"
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
