import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ApiSchemaCreateJob } from "../../../models";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  setWarningModalShow: any;
  msg?: string;
  form: ApiSchemaCreateJob;
  handleWarning: any;
};

export function JobCreateWarningModal(props: Props): JSX.Element {

  useEffect(() => {
    const modals = document.getElementsByClassName('modal-backdrop');
    const warningModal = modals[1] as HTMLElement;
    if (warningModal) {
      warningModal.style.zIndex = '1050';
    }
  }, []);

  return (
    <div className="modal-warning">
      <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
        <form action="">
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <p className="font-sz-16">
                  The job date you entered is less than 24-hours from now. For
                  rare languages, we may not be able to secure an interpreter
                  and options with different dates or modality may be offered.
                </p>
                <p className="font-sz-16 mb-0">Do you want to proceed?</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-link text-site text-uppercase"
              onClick={() => props.handleShow(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="delete-button btn btn-primary text-uppercase
						user-from-submit-btn"
              onClick={() => {
                props.handleShow(false);
                props.handleWarning(props.form);
              }}
            >
              Yes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
