import { MouseEventHandler } from 'react';
import { Modal } from 'react-bootstrap';

type TypeConfirmationModalProps = {
  show: boolean;
  handleShow: MouseEventHandler;
  handleConfirmation: MouseEventHandler;
  message: string;
};

export function ConfirmationModal({
  show,
  handleShow,
  handleConfirmation,
  message,
}: TypeConfirmationModalProps) {
  return (
    <Modal show={show} onHide={handleShow} centered>
      <Modal.Body>Are you sure you want to {message} ?</Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-link text-site text-uppercase'
          onClick={handleShow}
        >
          Cancel
        </button>
        <button
          type='button'
          className='cofirm-button btn btn-primary text-uppercase'
          onClick={handleConfirmation}
        >
          confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}
