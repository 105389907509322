import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { AccountType, AppRouteUi, PaginationSettings } from "../../configs";
import { useAppSelector } from "../../core";
import { AdminPageTemplate, ColumnToolTip } from "../../features";
import { AccountResponseModel } from "../../models";
import { ApiSchemaFilter } from "../../models/api/filter";
import { handleNumberToTwoDecimalPoint } from "../../utils";
import { useFunctionalityPagedAccount } from "../hooks/functionalityAccount";

export function PageCompanyFinance(): JSX.Element {
  const [filterDataChange, setFilterDataChange] = useState<number>(0);
  const [modalShow, setModalShow] = React.useState(false);

  const [loaderProgress, setLoaderProgress] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [pageIndex, setPageIndex] = useState(
    PaginationSettings.initialPageIndex
  );
  let filter: ApiSchemaFilter = {
    filterQueries: [],
  };
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [isPageFilterData, setIsPageFilterData] = useState(false);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<ApiSchemaFilter>(filter);
  const data = useAppSelector(
    (state) => ({
      companyId: state.auth.profile?.companyId,
    }),
    shallowEqual
  );
  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  if ((filterData && filterDataChange) || searchText) {
    if (!isPageFilterData) setIsPageFilterData(true);
  }
  const { pagedAccountList, pagedAccountListCount } =
    useFunctionalityPagedAccount({
      parentCompanyId: data.companyId ?? "",
      accountType: AccountType.Company,
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
      filterData: filterData,
      filterDataChange: filterDataChange,
      isPageFilterData: isPageFilterData,
      isPageDataLoad: true,
    });

  useEffect(() => {
    if (pagedAccountList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedAccountList]);

  const TableColumns = [
    {
      name: <ColumnToolTip data="CODE" />,
      selector: (row: AccountResponseModel) => {
        return <ColumnToolTip data={row.code} />;
      },
    },
    {
      name: <ColumnToolTip data="NAME" />,
      selector: (row: AccountResponseModel) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: <ColumnToolTip data="BALANCE" />,
      selector: (row: AccountResponseModel) => {
        return <ColumnToolTip data={handleNumberToTwoDecimalPoint(row.balance)} />;
      },
      conditionalCellStyles: [
        {
          when: (row: AccountResponseModel) => row.balance < 0,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      selector: (row: AccountResponseModel) => {
        return (
          <>
            <Link
              to={AppRouteUi.CompanyFinance.Profile.Load(row.companyId ?? "")}
              className="btn svg-color"
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Company"
        tableHeaders={TableColumns}
        tableData={pagedAccountList || []}
        setCreateModalShow={setModalShow}
        selectableRows={true}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedAccountListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        setIsShowCommonFilter={setIsShowCommonFilter}
        isShowCommonFilter={isShowCommonFilter}
      />
    </>
  );
}
