import { useState } from "react";
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { mutate } from "swr";
import { FormErrorMessage } from "../../components"
import { ApiSchemaVendorData } from "../../models";
import { useFunctionalityVendors } from '../../pages/hooks';
import useFunctionalityValidateUSAPhoneNumber from "../../pages/hooks/functionalityValidateUSAPhoneNumber";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  selectedVendor: ApiSchemaVendorData;
  parentCompanyId: string;
  pagedVendorUrl: string
}

export function ModalEditVendorPhone(props: Props): JSX.Element {
  const { handleShow } = props;
  const { onEditPhone } = useFunctionalityVendors({ parentCompanyId: props.parentCompanyId ?? "" });
  const { register, handleSubmit, errors } = useForm<{ phoneNumber: string }>();
  const { validateTeNumber, numberErrMsg } = useFunctionalityValidateUSAPhoneNumber();

  const editPhoneHandler = (form: { phoneNumber: string }) => {
    onEditPhone(form, props.selectedVendor?.id)
      .then(() => {
        props.handleShow(false);
        mutate(props.pagedVendorUrl)
      })
      .catch((e) => console.error(e));;
  };
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      id="editVendorPhoneModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Phone Number</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(editPhoneHandler)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Phone Number"
                  defaultValue={props.selectedVendor?.phoneNumber}
                  ref={register({ required: true })}
                  onChange={(event) => validateTeNumber(event)}
                />
                {
                  errors.phoneNumber && (
                    <FormErrorMessage message="required" />
                  )
                }
                {errors.phoneNumber &&
                  errors.phoneNumber.type == "validate" && (
                    <FormErrorMessage message="Please enter a valid contact number" />
                  )}
                {numberErrMsg && <FormErrorMessage message={numberErrMsg} />}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-uppecase text-site"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase editPhone-from-submit-btn"
            disabled={numberErrMsg ? true : false}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}